import { ITransactionValidator, IMessageError } from './transaction-validators';
import { Transaction } from '@app/transactions/transaction';
import { TransactionPrivate } from '@app/core/resources/transaction-private';

export class ValueIncorrectValidator implements ITransactionValidator {
    public validate(transaction: Transaction | TransactionPrivate): Array<IMessageError> {
        let error: IMessageError = {
            title: 'Revisa este dato.',
            pointer_attribute: '',
            detail: 'El valor ingrensado es nulo'
        };
        if (!transaction.attributes.amount1) {
            error.pointer_attribute = 'system1';

            return [error];
        }
        if (!transaction.attributes.amount2) {
            error.pointer_attribute = 'system2';

            return [error];
        }

        return [];
    }
}
