import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SystemInfoComponent } from './system-info.component';
import { FeeMessageModule } from '../../fee-message/fee-message.module';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { TooltipModule } from '@app/core/components/tooltip/tooltip.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [SystemInfoComponent],
    exports: [SystemInfoComponent],
    imports: [CommonModule, FeeMessageModule, MatMenuModule, TooltipModule, TranslateModule]
})
export class SystemInfoModule {}
