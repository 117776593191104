import { Injectable } from '@angular/core';
import { GeneralInfo } from '@app/core/resources/general-info';
import { Service } from 'ngx-jsonapi';

@Injectable({
    providedIn: 'root'
})
export class GeneralsInfoService extends Service<GeneralInfo> {
    public resource: typeof GeneralInfo = GeneralInfo;
    public type: string = 'generals_info';

    public constructor() {
        super();
        this.register();
    }
}
