<div *ngIf="way !== 'not_way' || isTransactionData" class="transactions-comments-container">
    <div *ngIf="informationRepository.getSystemInformation(sendSystem.id).getAvailableSend()"
        [ngClass]="{'send-commission transaction-data-info': isTransactionData}">
        <div *ngIf="isTransactionData" class="system-icon-container">
            <app-system-icon systemId="{{sendSystem.id}}" alt="{{sendSystem.attributes.name}}"></app-system-icon>
        </div>
        <div class="container-info">
            <div *ngIf="informationRepository.getSystemInformation(sendSystem.id).getInfoSend() && isTransactionData"
                class="transaction-comments">
                <p>
                    {{informationRepository.getSystemInformation(sendSystem.id).getInfoSend() | translate}}</p>
            </div>
            <div *ngIf="feeSend && (way === 'send' || isTransactionData)" class="transaction-comments">
                <p class="mat-subtitle-2 color-heading" [innerHTML]="(transaction.relationships.system1.data?.id === 'neteller' ? 'warning_message_neteller_send' : 'warning_message_skill_send') | translate: {
                    element: sendSystem.attributes.name,
                    element_one: (transaction.attributes.amount1 | decimalBySystem: sendSystem),
                    element_two: (feeSend | decimalBySystem: sendSystem),
                    element_three: sendSystem.attributes.currency,
                    element_four: (feeStandard | decimalBySystem: sendSystem),
                    element_five: (feeSend | decimalBySystem: sendSystem),
                }">
                </p>
            </div>
        </div>
    </div>
    <div *ngIf="informationRepository.getSystemInformation(receiveSystem.id).getAvailableReceive()"
        [ngClass]="{'transaction-data-info': isTransactionData}" class="container-icon-info">
        <div class="system-icon-container">
            <app-system-icon systemId="{{receiveSystem.id}}" alt="{{receiveSystem.attributes.name}}"></app-system-icon>
        </div>
        <div class="container-info">
            <div *ngIf="informationRepository.getSystemInformation(receiveSystem.id).getInfoReceive() && isTransactionData"
                class="transaction-comments">
                <p
                    innerHTML="{{informationRepository.getSystemInformation(receiveSystem.id).getInfoReceive() | translate}}">
                </p>
            </div>
            <div *ngIf="feeReceive && (way === 'receive' || isTransactionData)" class="transaction-comments">
                <p class="mat-subtitle-2 color-heading" [innerHTML]="'warning_message_commission' | translate: {
                    element: receiveSystem.attributes.name,
                    element_one: (feeReceive | decimalBySystem: receiveSystem),
                    element_two: receiveSystem.attributes.currency,
                    element_three: (finalFee | decimalBySystem: receiveSystem),
                    element_four: receiveSystem.attributes.currency
                }">
                </p>
                <mat-slide-toggle class="slide-toggle" color="primary" [checked]="toggleChecked"
                    (toggleChange)="onChangeSlideToggle()">
                    {{ 'warning_message_commission_button_on_off'| translate }}
                    {{ (!toggleChecked ? transaction.attributes.amount2 : amountNet) | decimalBySystem: receiveSystem }}
                    {{ receiveSystem.attributes.currency }}
                </mat-slide-toggle>
            </div>
        </div>
    </div>
</div>