<app-deals-skeleton-loader *ngIf="isLoaded"></app-deals-skeleton-loader>
<div>
    <ng-container *ngIf="title !== ''">
        <p class="secondary-text">{{title}}</p>
    </ng-container>
    <ng-content *ngIf="publicDeals.length !== 0"></ng-content>
    <app-slide (draggingEvent)="receiveEvent($event)" [margin]="21" [elementWidth]="372"
        [elementQty]="publicDeals.length" *ngIf="publicDeals.length !== 0">
        <ng-container>
            <app-public-deal-card *ngFor="let item of publicDeals; first as isFirst" [publicDeals]="item"
                [filterPublicDeals]="system" [notShowMatDialog]="notShowMatDialog"></app-public-deal-card>
        </ng-container>
    </app-slide>
</div>
<div *ngIf="showMoreInfo" class="info-public-deals-container">
    <p *ngIf="!showDeals;else levelValidDeals" class="color-heading">{{'public_dials_level_valid' | translate }}
        <a routerLink="/wiki/deals" class="rs-link">{{'public_dials_level_valid_info_button' | translate}}</a>
    </p>
    <a mat-button routerLink="/my/dashboard/deals">{{'public_dials_view_offers_button' | translate}}</a>
</div>
<ng-template #spinner>
    <div class="flex-column-center-center">
        <mat-spinner diameter="80"></mat-spinner>
    </div>
</ng-template>
<ng-template #levelValidDeals>
    <p class="color-heading">{{'public_dials_level_valid_deals' | translate }}</p>
</ng-template>
