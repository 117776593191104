<mat-card (click)="openDialogInfo()">
    <mat-card-content>
        <app-quotation-public-deal [publicDeal]="publicDeals" [receiveSystem]="publicDealsSystemService.receiveSystem" [amount2]="publicDealsSystemService.amount2"
            [amountWithoutGain]="publicDealsSystemService.amountWithoutGain"></app-quotation-public-deal>
    </mat-card-content>
    <mat-card-content class="d-flex f-row j-between a-end">
        <div class="public-deals-info-container">
            <p class="color-heading">{{'public_dials_you_send' | translate}}</p>
            <p class="color-heading text-number">{{publicDeals.attributes.rest_to_pay | decimalBySystem: publicDeals.relationships.system.data ?? system}}
                {{publicDeals.attributes.currency}}</p>
        </div>
        <div class="flex-row-center-center deal-system">
            <app-system-picture systemId="{{publicDeals.attributes.group_id}}"></app-system-picture>
        </div>
    </mat-card-content>
    <mat-card-content *ngIf="publicDealsSystemService.receiveSystem.id !== ''" class="d-flex f-row j-between a-end">
        <div class="public-deals-info-container">
            <p class="color-heading">{{'public_dials_we_send' | translate}}</p>
            <p class="text-number color-heading">{{publicDealsSystemService.amount2 | decimalBySystem: publicDealsSystemService.receiveSystem}} {{publicDealsSystemService.currency}}</p>
        </div>
        <div class="deal-system">
            <app-system-picture systemId="{{publicDealsSystemService.groupId}}"></app-system-picture>
        </div>
    </mat-card-content>
</mat-card>
