import { AppError } from './services/app-error';
export class BaseException extends Error {
    /**
     * @link https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
     */
    public constructor(m: string) {
        super(m);
        Object.setPrototypeOf(this, BaseException.prototype);
    }

    public getAppError(): AppError {
        let app_error: AppError = new AppError();
        app_error.message = this.message;
        app_error.original_error = this;
        app_error.render = true;
        app_error.report = false;

        return app_error;
    }
}
