import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormatDecimalsHelper } from '@app/core/helpers/format-decimals-helper';
import { System } from '@app/core/resources/system';

@Component({
    selector: 'app-amount-list',
    templateUrl: './amount-list.component.html',
    styleUrls: ['./amount-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AmountListComponent {
    @Input() public decimalPlaces: number = 0;
    @Input() public system: System = new System();
    @Input() public value: number = 0;
    public formatDecimalsHelper: FormatDecimalsHelper = new FormatDecimalsHelper();
}
