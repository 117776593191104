/* eslint-disable */
export const landing_en_US: any = {
    title: 'Exchange, buy or sell in just 3 steps',
    subtitle: 'Follow these steps.',
    step_one: 'Create your order.',
    step_two: 'Follow the instructions.',
    step_three: 'We send your balance.',

    calculator_label_one: 'You send {{ element }} ',
    calculator_label_two: 'You receive {{ element }}',

    calculator_label_one_search: 'Select exchange method',
    calculator_label_two_search: 'Select exchange method',

    same_crypto_selected_error: 'Select other exchange method',

    calculator_commision_info_you_need: 'You need',
    calculator_commision_info_you_need_advanced_p_one: 'You need {{ element }} ',

    calculator_commision_info_seller_get: 'Receive',
    calculator_commision_info_seller_get_advanced_p_one: 'Total net to destination {{ element_one }}',

    calculator_commision_info_the_tolal: 'The total net sending',
    calculator_commision_info_the_tolal_advanced_p_one: 'Total net sending {{ element_one }}',

    calculator_commision_info_you_get: 'You receive net',
    calculator_commision_info_you_get_advanced_p_one: 'Total net you receives {{ element }}',

    calculator_commision_info:
        'Commission of {{ element_one }} is {{ element_two }}% plus an additional {{ element_three }} cents of {{ element_four }}',

    calculator_exactly_amount: 'You will receive exactly <b>{{element_one}} {{ element_two}}</b>',

    calculator_exactly_amount_info: 'Regardless of the exchange or wallet you use, <b>Saldo will cover the network fee.</b>',

    next_button: 'Continue',

    last_10d_operations: 'Succesful transactions in the last 10 days',

    buy_usd_ars: 'Buy',
    sell_usd_ars: 'Sell',

    buy_eur_ars: 'Buy',
    sell_eur_ars: 'Sell',

    public_dials: 'Catch deals and pay up to 5% less',
    public_dials_you_send: 'You send',
    public_dials_we_send: 'We send to you',
    public_dials_level_valid: '*Valid only for level 3, 4 and 5 users. Get',
    public_dials_level_valid_info_button: 'more information here',
    public_dials_view_offers_button: 'View All Offers',
    public_dials_view_offers_button_close: ' Close',

    public_dials_view_offers_button_save: 'Save yourserf {{ element }}%!',
    public_deals_view_offers_would_send: 'We would send to you',
    public_dials_view_offers_button_save_br_one:
        'We send you {{ element_one }} {{element_two }} every {{ element_three }} {{ element_four }} {{ element_five }} ',
    public_dials_view_offers_button_save_br_one_way1: `For every {{ element_one }}, we send you <span class="text-disable">{{element_two }}</span> {{ element_three }} {{ element_four }}`,
    public_dials_view_offers_button_save_br_two: ' {{ element_one }} {{ element_two }} saved by taking this offer.',

    change_usd_or_eur_to_ARS: 'Exchange USD and EUR for ARS',
    change_usd_or_eur_to_USD: 'Exchange USD and EUR for USD',
    change_usd_or_eur_to_COP: 'Exchange USD and EUR for COP',
    change_usd_or_eur_to_MXN: 'Exchange USD and EUR for MXN',
    change_usd_or_eur_to_VES: 'Exchange USD and EUR for VES',
    change_usd_or_eur_to_ars_info_text:
        '<p>With Saldo, you can buy, sell and exchange USD and EUR using your bank account, PayPal, Skill, Neteller, Perfect Money, and more.</p>',
    exchange_eur: 'Exchange Euros to',
    change_buttom: 'Explore more methods',

    want_to_buy_paypal: 'Want to buy PayPal balance?',
    want_to_buy_paypal_info_text:
        '<p>We offer you the option to do it smoothly and safely. You send the currency, you can do it in many ways, for example with a bank transfer. Then we send the PayPal balance.</p>',
    buy_paypal_button: 'Buy PayPal balance',

    VCC_paypal: 'Benefits of having a VCC in your Paypal Account.',
    VCC_paypal_text_info_p_one:
        '<p>We offer and deliver to your email a virtual card (VCC). This lets you activate your PayPal account without a credit card. It expires 5 years later after getting it.</p><p>You will have a higher online purchase limit, plus you can receive higher amounts of money than an unverified account.</p><p>Remember this option is not available in the USA.</p>',
    VCC_paypal_button: 'Get a VCC',

    trustpilot_opinions_title: "Don't listen to us, listen to them",
    trustpilot_opinions_sub: 'Our users say <b> Excellent {{ element }}/5 </b>',
    trustpilot_opinions_hover_title: 'Is Saldoar trusty?',

    contact_title: 'Contact us',
    contact_subtitle: 'Having questions? We are here to help',
    contact_name: 'Name',
    contact_email: 'Email Address',
    contact_order_number: 'Order number (optional)',
    contact_message: 'Message',
    contact_send_ticket_button: 'Send',
    contact_sent_warning: 'The message was sent.',

    warning_message_bitcoin_recibe: 'You receive through the Bitcoin o BEP20 networks.',
    warning_message_bitcoin_send: 'You can send Bitcoins through the Binance Smart Chain (BEP20) or Bitcoin networks.',

    warning_message_dai_recibe: 'You receive DAI through the Ethereum (ERC20) network.',
    warning_message_dai_send: 'You can send DAI through the Ethereum or Binance Smart Chain (BEP20).,',

    warning_message_tether_recibe: 'You receive USDT through the Tron (TRC20) or Binance Smart Chain (BEP20) network.',
    warning_message_tether_send: 'You can send USDT through the Ethereum (ERC20), Tron (TRC20) or Binance Smart Chain (BEP20) networks.',

    warning_message_commission:
        'Remember that {{ element }} will charge a commission of {{ element_one }} {{ element_two }}, according you will receive a net of {{ element_three }} {{ element_four }}.',
    warning_message_commission_button_on_off: 'I want to receive a total net of',

    warning_message_skill_recibe:
        'The payments are made in USD. Make sure your account is configured to receive in this currency. In case of any doubt you can visit our <a href="https://blog.saldo.com.ar/abrir-una-cuenta-skrill-en-usd/" target="_blank" title="How to open a new account on Skrill?" class="rs-link">blog</a>.',
    warning_message_skill_button_blog_recibe: 'blog',
    warning_message_skill_send:
        'Commission {{ element }}: Keep in mind that you will be sending {{ element_one }} {{ element_three }} and {{ element_five }} will charge you a fee of {{ element_two }} {{ element_four }} for sending.',

    steps_to_complete_your_order_step_one: 'Create your order',
    step_one_first_string_info: 'Name and Surname',
    step_one_second_string_info: 'Email Address',
    step_one_third_string_info: 'Whatsapp Number',
    step_one_fourth_string_info_network: 'Network',
    place_an_order: 'Create your order',
    cancel: 'Cancel',

    steps_to_complete_your_order_step_two: 'Follow the instructions',
    steps_to_complete_your_order_step_three: 'We send your balance',

    step_one_first_string_info_ar_transfer: 'CBU/CVU/Alias of your {{ element }} account',
    step_one_second_string_info_ar_transfer_example: 'Example: 2850590940090418135201 o alias.example.saldo',
    step_one_third_string_info_ar_transfer_incorrect: 'CBU/CVU/Alias Incorrect, Example: 2850590940090418135201 o alias.example.saldo',

    step_one_first_string_info_email_transfer: 'Email Address of your {{ element }} account',
    step_one_second_string_info_email_example: 'Example: juan@dominio.com',
    step_one_third_string_info_email_incorrect: 'Incorrect Email Address, Expample: juan@dominio.com',
    step_one_fourd_string_info_email_raquered: 'This info is required',

    step_one_first_string_info_account_number: '{{ element }} Account Number',
    step_one_second_string_info_account_number_example: 'Example: P9050280',
    step_one_third_string_info_account_number_incorrect: 'Incorrect account number, Example: P9050280',

    step_one_alert_string_skrill:
        'The funds in my Skrill account do not come from debit/credit cards or from accounts that have been funded with such a method.',
    step_one_alert_string_payoneer:
        'I have previously received payments in my Payoneer account and have already received/sent payments with no issues.',
    step_one_alert_string_dai:
        'I confirm that the address entered corresponds to the Ethereum network (ERC20) and not to Binance Smart Chain (BSC).',
    step_one_alert_string_palpal:
        'I accept that PayPal will charge me a commission of {{ element_one }} USD upon receiving the payment. You will receive a net of {{ element_two }} USD',
    step_one_alert_string_nequi: 'I accept that when sending my payment, in the deposit message, I will only write "PAYMENT"',
    'meta_keywordsen-US': 'balance, online, exchange crypto, buy dolars',
    meta_keywords_update:
        'exchange {{ element_one }}, exchange {{ element_two }}, money, online, exchange money, argentinian cash, buy dolars',
    'meta_add_header_default_titleen-US': 'Exchange EUR for USD',
    'meta_add_header_default_descriptionen-US':
        'Discover the easiest and safest way to exhange your virtual currencies! Send money without problems and benefit from low commissions in more than 20 exchange methods!',
    meta_add_header_update_description:
        'Exchange Virtual Money {{ element_one }} to {{ element_two }} online in the fastest way. Purchase, exchange and sale of PayPal, Skrill, Neteller cash. Deposits with RapiPago, PagoFácil, MercadoPago, Bank Transfer and others.',

    meta_set_og_default_title: 'Saldoar | Exchange your virtual money in just 3 steps.',
    meta_set_og_update_title: 'Exchange your Virtual Money {{ element_one }} to {{ element_two }}',
    meta_set_og_default_description:
        'Choose from over 20 different exchange methods: Bank transfer, PayPal, Mercado Pago, Skrill, Ualá, Wise...',

    meta_exchange_display: 'Exchange {{ element_one }} to {{ element_two }}',
    insted_of: 'Instead of',

    meta_description_exchange: 'online easily and quickly'
};
