import { Component } from '@angular/core';
import { PlatformBrowserService } from '@app/core/services/platform-browser.service';
import { AppError } from '@app/exceptions/services/app-error';
import { ErrorReporterService } from '@app/exceptions/services/error-reporter.service';

@Component({
    selector: 'app-error-page',
    templateUrl: './error-page.component.html',
    styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent {
    public constructor(private errorReporterService: ErrorReporterService, private browserService: PlatformBrowserService) {
        if (this.browserService.isBrowser) {
            let app_error: AppError = new AppError();
            app_error.original_error = '404 Not Found';
            app_error.message = '404 Not Found';
            app_error.detail_bag = [{ message: 'Error url:', attribute_name: window.location.pathname }];
            this.errorReporterService.report(app_error);
        }
    }
}
