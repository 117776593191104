import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { System } from '@app/core/resources/system';
import { IntersectionObserverService } from '@app/core/services/intersection-observer.service';
import { environment } from 'environments/environment';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';

@Component({
    selector: 'app-payment-methods',
    templateUrl: './payment-methods.component.html',
    styleUrls: ['./payment-methods.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentMethodsComponent implements AfterViewInit, OnDestroy, OnChanges {
    private systemFilled$: Subject<any> = new Subject();
    private combineSubscription: Subscription = new Subscription();
    private imgSubject: Subject<IntersectionObserverEntry> = new Subject<IntersectionObserverEntry>();
    private img$: Observable<IntersectionObserverEntry> = this.imgSubject.asObservable();
    @Input() public isHelpCenter?: boolean;
    @Input() public systems: Array<System> = [];
    public urlLandingSystem: string = environment.CANONICAL_ORIGIN_URL + 'comprar-vender/';
    public systemsId: Array<string> = ['palpal', 'banco', 'zelle', 'neteller', 'banco_eur', 'bitcoin'];
    public isLoaded: boolean = false;

    public constructor(private changeDetectorRef: ChangeDetectorRef, private intersectionObserverService: IntersectionObserverService) {}

    public ngAfterViewInit(): void {
        this.getSystems();
    }

    public ngOnChanges(): void {
        if (this.systems.length !== 0) {
            setTimeout(() => {
                this.systemFilled$.next(true);
            });
        }
    }

    private getSystems(): void {
        this.combineSubscription = combineLatest(this.systemFilled$.asObservable(), this.img$).subscribe((): void => {
            this.systems = this.systems.filter(
                (system): boolean => (system.attributes.can_send || system.attributes.can_receive) && system.attributes.market !== 'crypto'
            );
            this.isLoaded = true;
            this.changeDetectorRef.detectChanges();
        });
        this.intersectionObserverService.observeElements('app-payment-methods', this.imgSubject);
    }

    public ngOnDestroy(): void {
        this.combineSubscription.unsubscribe();
    }
}
