import { Injectable } from '@angular/core';
import { Service } from 'ngx-jsonapi';
import { Network } from '../resources/networks';

@Injectable({
    providedIn: 'root'
})
export class NetworkService extends Service<Network> {
    public resource: typeof Network = Network;
    public type: string = 'networks';
    public constructor() {
        super();
        this.register();
    }
}
