import { Injectable } from '@angular/core';
import { PlatformBrowserService } from '@app/core/services/platform-browser.service';
import { Subject } from 'rxjs';
import { AdvancedOptions } from '../transaction-calculator.component';

@Injectable({
    providedIn: 'root'
})
export class SystemInfoService {
    public quotationPosition1!: number;
    public quotationPosition2!: number;
    public quotationPosition3!: number;
    public quotationPosition4!: number;
    public quotationPosition$: Subject<any> = new Subject();
    public advancedOptions: AdvancedOptions = 'closed';

    public constructor(private browserServices: PlatformBrowserService) {
        this.setQuotations();
    }

    private setQuotations(): void {
        this.quotationPosition$.subscribe((data): void => {
            this['quotationPosition' + data.position] = data.quotation;
        });
    }

    public getAdvanceOptionsLocalStorage(): void {
        if (!this.browserServices.isBrowser) {
            return;
        }
        let advancedOptions: string | null = localStorage.getItem('advancedOptions');
        if (localStorage && advancedOptions !== null) {
            this.advancedOptions = <AdvancedOptions>advancedOptions;
        }
    }

    public unfoldOrCloseCalculator(): void {
        if (this.advancedOptions === 'open') {
            this.advancedOptions = 'closed';
        } else {
            this.advancedOptions = 'open';
        }
        localStorage.setItem('advancedOptions', this.advancedOptions);
    }
}
