import { DocumentCollection, IRelationships, Resource } from 'ngx-jsonapi';
import { Transaction } from '@app/transactions/transaction';

export class User extends Resource {
    public type: string = 'users';

    public attributes: {
        [key: string]: any;
    } = {
        name: '',
        alias: '',
        email: '',
        pass: '',
        level: 0,
        level_power: 0,
        old_password: '',
        referral_balance: 0,
        user_id_token: '',
        has_previous_transactions: false,
        new_password: '',
        phone_number: 0,
        __repeat_new_password: ''
    };

    public relationships: IRelationships = {
        transactions: new DocumentCollection<Transaction>()
    };
}
