import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransactionCalculatorComponent } from './transaction-calculator.component';
import { SystemSelectComponent } from './system-select/system-select.component';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageErrorComponent } from './message-error/message-error.component';
import { SystemsUpdatedService } from '@app/transactions/services/systems-updated.service';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { CalculatorContentLoaderComponent } from './transaction-calculator-skeleton/calculator-content-loader.component';
import { CoreModule } from '@app/core/core.module';
import { AnimationSystemSelectComponent } from './animation-system-select/animation-system-select.component';
import { InvertSystemsModule } from './invert-systems/invert-systems.module';
import { CalculatorSkeletonComponent } from './calculator-skeleton/calculator-skeleton.component';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { SystemInfoModule } from './system-info/system-info.module';
import { RouterModule } from '@angular/router';
import { SystemTrendModule } from './system-select/system-trend/system-trend.module';
import { TranslateModule } from '@ngx-translate/core';
import { SkeletonModule } from '@app/core/components/skeleton/skeleton.module';
import { InfoCryptoFeeModule } from '../info-crypto-fee/info-crypto-fee.module';

@NgModule({
    declarations: [
        TransactionCalculatorComponent,
        MessageErrorComponent,
        CalculatorContentLoaderComponent,
        AnimationSystemSelectComponent,
        CalculatorSkeletonComponent,
        SystemSelectComponent
    ],
    exports: [
        TransactionCalculatorComponent,
        CalculatorSkeletonComponent,
        SystemSelectComponent,
        MessageErrorComponent,
        CalculatorContentLoaderComponent
    ],
    providers: [SystemsUpdatedService],
    imports: [
        CommonModule,
        MatSelectModule,
        MatInputModule,
        FlexLayoutModule,
        RouterModule,
        MatIconModule,
        MatCardModule,
        MatButtonModule,
        FormsModule,
        MatMenuModule,
        MatDividerModule,
        ReactiveFormsModule,
        TranslateModule,
        ContentLoaderModule,
        InvertSystemsModule,
        SystemInfoModule,
        SystemTrendModule,
        CoreModule,
        SkeletonModule,
        InfoCryptoFeeModule
    ]
})
export class TransactionCalculatorModule {}
