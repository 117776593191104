import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { PublicDeal } from '@app/core/resources/public-deal';
import { System } from '@app/core/resources/system';
import { QuotationHelper } from '../quotation.helper';
import { CurrenciesService } from '@app/core/services/currencies.service';
import { Currency } from '@app/core/resources/currency';
import { delay, filter } from 'rxjs/operators';

@Component({
    selector: 'app-quotation-public-deal',
    templateUrl: './quotation-public-deal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./quotation-public-deal.component.scss']
})
export class QuotationPublicDealComponent implements OnChanges {
    @Input() public publicDeal: PublicDeal = new PublicDeal();
    @Input() public receiveSystem: System = new System();
    @Input() public amount2: number = 0;
    @Input() public amountWithoutGain: number = 0;
    private quotationHelper: QuotationHelper = new QuotationHelper();
    public currency: Currency = new Currency();
    public quotationInfo: any = {
        realQuotation: 0,
        quotation: 0,
        pair: ''
    };

    public constructor(private currenciesServices: CurrenciesService, private changeDetectorRef: ChangeDetectorRef) {}

    public ngOnChanges(): void {
        this.currenciesServices
            .get(this.publicDeal.relationships.system.data?.attributes.currency ?? '')
            .pipe(
                delay(0),
                filter((currency: Currency) => {
                    return currency.source !== 'new';
                })
            )
            .subscribe((currency: Currency) => {
                this.currency = currency;
                this.publicDeal.relationships.system.data?.addRelationship(currency, 'currency');
                this.quotationInfo = this.quotationHelper.getQuotation({
                    system1: this.publicDeal.relationships.system.data ?? new System(),
                    system2: this.receiveSystem,
                    amount1: this.amountWithoutGain,
                    amount2: this.amount2,
                    restToPay: this.publicDeal.attributes.rest_to_pay
                });
                this.changeDetectorRef.markForCheck();
            });
    }
}
