<mat-form-field class="system-input-select" appearance="outline">
    <mat-label class="label">{{ mode === 'send' ? ('calculator_label_one' | translate: {element: selectedSystem ? selectedSystem.attributes.currency : ''}) : ('calculator_label_two' | translate: {element: selectedSystem ? selectedSystem.attributes.currency : ''}) }}</mat-label>
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="center center">
      <input inputmode="decimal" type="number" (focus)="focus()" (blur)="blur()" tabindex="1" [ngClass]="mode + '_input'" [@inputNumberAnimation]="run_animation_input ? 'open' : 'closed'" #valueChangeInput matInput [(ngModel)]="value" (ngModelChange)="focusAmountInput(); resizeInput(); valueChange.emit(value)" min="0">
      <div
      #selectedSystemTab
      tabindex="1"
      (click)="showListSystem(); onStopPropagation($event)"
      [ngStyle]="{ cursor: isOnlyTwoSystems ? 'default' : 'pointer' }"
      class="selected-system"
      fxLayout="row"
      fxLaoyoutAlign="center center"
      fxLayoutGap="10px">
        <mat-divider class="rshide-xs" horizontal></mat-divider>
        <mat-divider class="rshide-lt-xs" vertical></mat-divider>
        <div class="system-picture" [ngClass]="{'animation-system-send': mode === 'send', 'animation-system-receive': mode === 'receive'}">
          <app-animation-system-select systemId="{{selectedSystem ? selectedSystem.id : ''}}" alt="{{selectedSystem ? selectedSystem.attributes.name : ''}}"></app-animation-system-select>
        </div>
        <div rel="nofollow" class="icon-expand-container" fxLayoutAlign="center center">
          <a [ngClass]="{'object-disable': isOnlyTwoSystems}" class="material-icons-outlined icon-expand">
            expand_more
          </a>
        </div>
      </div>
    </div>
    <div #closeSystem (click)="closeListSystem(); onStopPropagation($event)" class="close-list-system"></div>
    <ul #listSystem tabindex="0" class="list-system-select" (click)="onStopPropagation($event)">
      <div class="container-system-search" fxFlexLayout="row" fxLayoutAlign="center center">
        <mat-icon (click)="onStopPropagation($event); focusInputSearch()">search</mat-icon>
        <input
            #systemSearchInput
            class="system-search"
            type="text"
            placeholder="Indica un método de intercambio"
            (click)="onStopPropagation($event)"
            [formControl]="searchCtrl">
        <mat-icon *ngIf="searchCtrl.value !== ''" (click)="onStopPropagation($event); restartSystemsSearch()" class="restart-value-search">clear</mat-icon>
      </div>
      <div #containerListAndSearchSystem class="container-list-and-search-system">
        <div *ngFor="let systemsOfCurrency of systemsByCurrency | async | keyvalue">
            <div>
              <div class="currency-system" fxLayoutAlign="end center">
                <p>{{ systemsOfCurrency.key }}</p>
              </div>
              <div *ngFor="let systemForList of systemsOfCurrency.value; let indice=index; trackBy:trackBySystem">
                <a
                    #system
                    class="system"
                    [style.animation-delay.s]="indice/40"
                    fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="26px"
                    (click)="selectSystemList(systemForList); selectedSystemChange.emit(systemForList); onStopPropagation($event)"
                    [ngClass]="{'selected-system-background': selectedSystem === systemForList, 'active': systemForList.id === preselectedSystemId}">
                  <div class="quotation-system" fxLayout="column" fxLayoutAlign="center end">
                    <p>{{ systemsQuotations[systemForList.id] }}  {{ systemForList.attributes.currency }}</p>
                    <app-system-trend [system]="systemForList" [trendWay]="mode"></app-system-trend>
                  </div>
                  <app-system-picture class="system-picture" *ngIf="systems_list_was_open" [systemId]="systemForList.id" [alt]="systemForList.attributes.name" title="Comprar/Vender {{systemForList.attributes.name}}"></app-system-picture>
                </a>
              </div>
            </div>
          </div>
      </div>
    </ul>
</mat-form-field>
