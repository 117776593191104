import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class PlatformBrowserService {
    public readonly isBrowser: boolean;

    public constructor(@Inject(PLATFORM_ID) private platformId: string) {
        this.isBrowser = isPlatformBrowser(this.platformId);
    }
}
