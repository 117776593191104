import { DocumentResource, IRelationships, Resource } from 'ngx-jsonapi';
import { ChannelToken } from './channel-token';

export class TransactionTemporalValue extends Resource {
    public type: string = 'transaction_temporal_values';
    public attributes: {
        state: number;
        instructions_url: string;
    } = {
        state: 0,
        instructions_url: ''
    };

    public relationships: IRelationships = {
        channels_tokens: new DocumentResource<ChannelToken>()
    };
}
