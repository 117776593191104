import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injectable, Input } from '@angular/core';
import { HeaderCountryService } from '@app/core/components/header/header-country.service';
import { IBankRepository } from '@app/core/country-provider/repositories/bank-repository-interface';
import { UserCountryService } from '@app/core/services/user-country.service';
import { infoBlockCountriesDependencyModel } from './info-block-model';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class SystemsFeatureService {
    public selectedSystem$: Subject<any> = new Subject();
}

@Component({
    selector: 'app-systems-feature',
    templateUrl: './systems-feature.component.html',
    styleUrls: ['./systems-feature.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SystemsFeatureComponent {
    public countryDefault!: IBankRepository;
    public infoBlockContent: any = [];
    @Input() public showDeals: boolean = false;

    public constructor(
        private userCountryService: UserCountryService,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private headerCountryService: HeaderCountryService
    ) {
        this.observerCountryDefault();
        this.observerChangeCountry();
    }

    private observerCountryDefault(): void {
        this.userCountryService.getCountry().subscribe((country): void => {
            this.infoBlockContent = infoBlockCountriesDependencyModel[country.code ? country.code : 'es-AR'];
            this.changeDetectorRef.markForCheck();
        });
    }

    private observerChangeCountry(): void {
        this.headerCountryService.selectedCountry$.subscribe((country): void => {
            this.infoBlockContent = infoBlockCountriesDependencyModel[country.code ? country.code : 'es-AR'];
            this.changeDetectorRef.markForCheck();
        });
    }

    public quote(url: string): void {
        this.router.navigateByUrl(url);
    }
}
