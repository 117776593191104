<div class="rs-wrapper-v4 first-skeleton-deals-container">
    <div class="width-complete">
        <ng-container *ngTemplateOutlet="publicDealSkeleton"></ng-container>
    </div>
    <div class="width-complete display-false">
        <ng-container *ngTemplateOutlet="publicDealSkeleton"></ng-container>
    </div>
    <div class="width-complete display-false">
        <ng-container *ngTemplateOutlet="publicDealSkeleton"></ng-container>
    </div>
</div>
<ng-template #publicDealSkeleton>
    <mat-card class="width-complete">
        <mat-card-content class="second-skeleton-deals-container">
            <div class="first-div-square">
                <app-skeleton></app-skeleton>
                <app-skeleton></app-skeleton>
            </div>
            <app-skeleton></app-skeleton>
            <app-skeleton></app-skeleton>
        </mat-card-content>
    </mat-card>
</ng-template>