import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { System } from '@app/core/resources/system';
import { SystemsService } from '@app/core/services/systems.service';
import { DocumentCollection } from 'ngx-jsonapi';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-system-filter',
    templateUrl: './system-filter.component.html',
    styleUrls: ['./system-filter.component.scss']
})
export class SystemFilterComponent implements OnDestroy, OnChanges {
    @Output() public updateFilter: EventEmitter<any> = new EventEmitter();
    @Input() public remoteFilter: any = {};
    private systems: Array<System> = [];
    private subscription: Subscription = new Subscription();
    public filterConfig: any = undefined;

    public constructor(private systemsService: SystemsService, private changeDetectorRef: ChangeDetectorRef) {
        this.subscription = this.systemsService
            .all()
            .pipe(filter((systems: DocumentCollection<System>): boolean => systems.source !== 'new'))
            .subscribe((systems: DocumentCollection<System>) => {
                this.systems = systems.data.filter(
                    (system: System): boolean =>
                        (system.attributes.account_required_send || system.attributes.account_required_receive) &&
                        (system.attributes.can_send || system.attributes.can_receive)
                );
                this.getFilterConfig();
            });
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public getFilterConfig(): void {
        this.filterConfig = {
            type: 'options',
            attribute: 'system.id',
            options: {
                '': { text: 'Todos los métodos' }
            },
            selected: ''
        };

        this.systems.forEach((system: System) => {
            this.filterConfig.options[system.id] = { text: system.attributes.name };
        });

        this.changeDetectorRef.markForCheck();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.remoteFilter && this.remoteFilter && this.filterConfig) {
            this.filterConfig.selected = '';
        }
    }
}
