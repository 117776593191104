import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuotationPublicDealComponent } from './quotation-public-deal.component';
import { GainPercentModule } from '@app/dashboard/deals/gain-percent/gain-percent.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [QuotationPublicDealComponent],
    exports: [QuotationPublicDealComponent],
    imports: [CommonModule, GainPercentModule, TranslateModule]
})
export class QuotationPublicDealModule {}
