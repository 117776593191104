import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-gain-percent',
    templateUrl: './gain-percent.component.html',
    styleUrls: ['./gain-percent.component.scss']
})
export class GainPercentComponent {
    @Input() public percent: number = 0;
}
