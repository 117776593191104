export class FormatDecimalsHelper {
    public formatDecimal(value: number | null | undefined, decimal_places: number): string {
        if (!value) {
            return '0.00';
        }

        if (decimal_places === 0) {
            return Math.floor(value).toString() + '.00';
        }

        if (value.toString().indexOf('.') === -1) {
            return value.toString() + '.00';
        }

        let decimalPlaces: number = decimal_places;

        return this.changePunctuationMark(
            value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: decimalPlaces })
        );
    }

    private changePunctuationMark(refactoredNumber: string): string {
        return refactoredNumber.replace(/,/g, '');
    }
}
