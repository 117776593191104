<header #header class="flex-column-center-center" [ngClass]="{'header-with-banner': false && withBanner}" rel="nofollow">
    <div class="banner" [ngClass]="{'new-system-banner': showNewContent}" *ngIf="false && withBanner">

        <div class="rs-wrapper-v4">
            <span>
                Ahora convierte pesos bolivianos <img src='../../../../assets/images/vcc/type-vcc/vcc-BOB.png' alt="Transferencia en pesos bolivianos"
                title="Transferencia en pesos bolivianos"> con Saldoar.
                <!-- <app-system-picture systemId="{{defaultImgUrl}}" alt="{{defaultImgAltText}}"
                    title="{{defaultImgAltText}}">
                </app-system-picture> -->
            </span>
            <mat-icon (click)="closeBanner()">clear</mat-icon>
        </div>
    </div>
    <div class="rs-wrapper-v4 flex-row-spacebetween-center container-logo">
        <div *ngIf="showItemHeader" class="icon-menu-container">
            <mat-icon [matMenuTriggerFor]="infoMenu">menu</mat-icon>
        </div>

        <div class="logo-and-wiki-container  flex-row-spacebetween-center">
            <app-logo-redirects [link]="link"></app-logo-redirects>
            <div *ngIf="showItemHeader" class="wiki-links hidden-links">
                <a *ngFor="let link of wikiLinks" class="rs-link" [routerLink]="linkWithLang(link.link)">{{ link.title | translate }}</a>
            </div>
        </div>

        <div *ngIf="showItemHeader && showLoginAndSignUp" class="user-options-container flex-row-spacebetween-center">
            <div *appShellNoRender class="user-links hidden-links">
                <a *ngFor="let link of userLinks" class="rs-link" [routerLink]="linkWithLang(link.link)">{{ link.title | translate }}</a>
            </div>
            <div [matMenuTriggerFor]="countryMenu" rel="nofollow" class="flex-row-spacebetween-center dropdown">
                <div class="img-country" [ngStyle]="{'backgroundPosition': selectedCountry.positionSprite}"></div>
                <mat-icon>arrow_drop_down</mat-icon>
            </div>
        </div>
    </div>
</header>

<mat-menu #countryMenu="matMenu">
    <a mat-menu-item class="flex-row-start-center" *ngFor="let country of countries;trackBy:trackByCountry"
        href="{{country.urlHref}}" title="{{country.title | translate}}" (click)="selectCountry($event,country)">
        <div class="img-country" [ngStyle]="{'background-position': country.positionSprite}"></div>
        <div>{{ country.name | translate }}</div>
    </a>
</mat-menu>

<mat-menu #infoMenu="matMenu" rel="nofollow">
    <a mat-menu-item *ngFor="let link of wikiLinks" [routerLink]="linkWithLang(link.link)">{{ link.title | translate }}</a>
    <a mat-menu-item (click)="openStateViewer()" class="rs-link"> {{'header_my_order_button'| translate}}</a>
    <a mat-menu-item  [routerLink]="linkWithLang('/comprar-vcc')"> {{'VCC_buy' | translate}} </a>
    <mat-divider horizontal></mat-divider>
    <a mat-menu-item *ngFor="let link of userLinks"[routerLink]="linkWithLang(link.link)">{{ link.title | translate }}</a>
</mat-menu>
