import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
    selector: 'app-custom-video',
    templateUrl: './custom-video.component.html',
    styleUrls: ['./custom-video.component.scss']
})
export class CustomVideoComponent implements AfterViewInit {
    @Input() public className: string = 'video-custom';
    @Input() public src: string = '';
    @Input() public width: number = 0;
    @Input() public height: number = 0;
    @ViewChild('customVideo') public video!: ElementRef<HTMLVideoElement>;

    public ngAfterViewInit(): void {
        this.video.nativeElement.muted = true;
    }
}
