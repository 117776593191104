let imageSettingsDefaults: any = {
    width: 750,
    height: 750
};

let samePaypalTitle: string = 'Recarga tu cuenta PayPal, paga en linea y mucho más';
let titleBolivia: string = 'Pasa tus dólares a pesos bolivianos';
let samePaypalTitleBolivia: string = 'Retira tus dólares de tu cuenta PayPal';
let sameReferralTitle: string = 'Gana dólares invitando a tus amigos y familiares';
let sameReferralText: string =
    '<p>Para unirte a nuestro programa de afiliados y ganar, debes haber concretado al menos una operación. De esa forma, podrás contarles a tus invitados cómo funciona Saldoar y lo simple que es utilizarlo. ¡Invita y recibe hasta 30 USD por cada referido!</p>';
let sameReferralLinkText: string = 'Refiere ahora y gana';

export const infoBlockCountriesDependencyModel: any = {
    'es-AR': [
        {
            countryDependency: true,
            title: 'change_usd_or_eur_to_ARS',
            content: 'change_usd_or_eur_to_ars_info_text',

            linkUrl: 'a/banco_eur/banco/100',

            imageUrl: '/assets/landing-page/images-landing-2-v2.svg',
            linkText: 'change_button',
            imageSettings: imageSettingsDefaults
        },
        {
            countryDependency: true,
            title: 'want_to_buy_paypal',
            content: 'want_to_buy_paypal_info_text',

            linkUrl: 'a/banco/palpal/0/100',

            imageUrl: '/assets/landing-page/images-landing-3-v2.svg',
            linkText: 'Cotizar recarga PayPal con cuenta Bancaria',
            imageSettings: imageSettingsDefaults
        },
        {
            countryDependency: false,
            title: 'VCC_paypal',
            content: 'VCC_paypal_text_info_p_one',

            linkUrl: '/comprar-vcc',

            imageUrl: '/assets/landing-page/images-landing-4-v2.svg',
            linkText: 'VCC_paypal_button',
            imageSettings: imageSettingsDefaults
        }
    ],
    'es-BO': [
        {
            countryDependency: true,
            title: titleBolivia,
            content:
                '<p>¡Cambia tus dólares de Wise, Payoneer, Skrill y más por pesos bolivianos de manera simple! Te ofrecemos la solución más confiable para convertir dólares a tu moneda local.</p>',

            linkUrl: '/a/wise_usd/banco_bol/0/100',

            imageUrl: '/assets/landing-page/images-landing-2-v2.svg',
            linkText: 'Cotizar dólares de Wise a pesos bolivianos',
            imageSettings: imageSettingsDefaults
        },
        {
            countryDependency: true,
            title: samePaypalTitleBolivia,
            content: '<p>Convierte tus dólares de PayPal a pesos bolivianos y aprovecha las oportunidades del arbitraje.</p>',

            linkUrl: 'a/palpal/banco_bol/100',

            imageUrl: '/assets/landing-page/images-landing-3-v2.svg',
            linkText: 'Retira tus USD de PayPal',
            imageSettings: imageSettingsDefaults
        },
        {
            countryDependency: false,
            title: sameReferralTitle,
            content:
                '<p>Para unirte a nuestro programa de afiliados y ganar, debes haber concretado al menos una operación. De esa forma, podrás contarle a tus invitados cómo funciona Saldoar y lo simple que es utilizarlo. ¡Invita y recibe hasta 30 USD por cada referido!</p>',
            linkUrl: '/my/dashboard/more-options/referral-transactions',

            imageUrl: '/assets/landing-page/images-landing-5-v2.svg',
            linkText: sameReferralLinkText,
            imageSettings: imageSettingsDefaults
        }
    ],
    'es-MX': [
        {
            countryDependency: true,
            title: 'Pasa tus dólares a pesos mexicanos',
            content:
                '<p>¡Cambia tus dólares de Wise, Zelle, PayPal y más por pesos mexicanos de manera simple! Te ofrecemos la solución más confiable para convertir dólares a tu moneda local.<p>',

            linkUrl: '/a/palpal/banco_mex/0/100',

            imageUrl: '/assets/landing-page/images-landing-2-v2.svg',
            linkText: 'Cotizar dólares de Paypal a pesos mexicanos',
            imageSettings: imageSettingsDefaults
        },
        {
            countryDependency: true,
            title: samePaypalTitle,
            content:
                '<p>¡Convierte tus pesos mexicanos a saldo PayPal y disfruta de la libertad de hacer compras en tiendas digitales como Mercado Libre o Amazon! Aunque también puedes ahorrar en dólares recargando tu cuenta y mucho más.</p>',

            linkUrl: 'a/banco_mex/palpal/0/100',

            imageUrl: '/assets/landing-page/images-landing-3-v2.svg',
            linkText: 'Cotizar recarga PayPal con cuenta Bancaria',
            imageSettings: imageSettingsDefaults
        },
        {
            countryDependency: false,
            title: sameReferralTitle,
            content: sameReferralText,

            linkUrl: '/my/dashboard/more-options/referral-transactions',

            imageUrl: '/assets/landing-page/images-landing-5-v2.svg',
            linkText: sameReferralLinkText,
            imageSettings: imageSettingsDefaults
        }
    ],
    'es-CO': [
        {
            countryDependency: true,
            title: 'Cambia tus dólares a pesos colombianos',
            content:
                '<p>Somos la mejor opción para cambiar dólares a tu moneda local de forma sencilla y conveniente. Realiza el cambio utilizando plataformas reconocidas mundialmente como Skrill, PayPal, Perfect Money y muchas más.</p>',

            linkUrl: 'a/palpal/banco_col/100',

            imageUrl: '/assets/landing-page/images-landing-2-v2.svg',
            linkText: 'Cotiza dolares de Paypal a pesos colombianos',
            imageSettings: imageSettingsDefaults
        },
        {
            countryDependency: true,
            title: samePaypalTitle,
            content:
                '<p>¡Carga tu cuenta PayPal con pesos colombianos de forma segura y sin complicaciones! Aprovecha ofertas o ahorra en dólares de PayPal recargando tu cuenta en tres simples pasos.</p>',

            linkUrl: 'a/banco_col/palpal/0/100',

            imageUrl: '/assets/landing-page/images-landing-3-v2.svg',
            linkText: 'Cotizar recarga PayPal con cuenta Bancaria',
            imageSettings: imageSettingsDefaults
        },
        {
            countryDependency: false,
            title: sameReferralTitle,
            content: sameReferralText,

            linkUrl: '/my/dashboard/more-options/referral-transactions',

            imageUrl: '/assets/landing-page/images-landing-5-v2.svg',
            linkText: sameReferralLinkText,
            imageSettings: imageSettingsDefaults
        }
    ],
    'es-VE': [
        {
            countryDependency: true,
            title: 'Convierte tus dólares a bolívares',
            content:
                '<p>Realiza el cambio de dólar Zelle, Skrill, PayPal a bolívar de manera simple. Dile adiós a la forma tradicional de hacer transferencias internacionales, paga con tu dinero del exterior y recibe en tu moneda local a la mejor cotización.</p>',

            linkUrl: '/a/palpal/pago_movil/100/0',

            imageUrl: '/assets/landing-page/images-landing-2-v2.svg',
            linkText: 'Cotizar dólares de Paypal a bolívares venezolanos',
            imageSettings: imageSettingsDefaults
        },
        {
            countryDependency: true,
            title: 'Carga tu cuenta de PayPal con Pago Móvil',
            content:
                '<p>Recarga tu cuenta de forma simple y segura, tal como lo necesitas. No te pierdas las increíbles ofertas en tus páginas web favoritas simplemente porque no tienes suficiente saldo en tu cuenta.</p>',

            linkUrl: '/a/pago_movil/palpal/0/100',

            imageUrl: '/assets/landing-page/images-landing-3-v2.svg',
            linkText: 'Recargar dólares de PayPal ahora',
            imageSettings: imageSettingsDefaults
        },
        {
            countryDependency: false,
            title: 'Ventajas de cargar una tarjeta en tu cuenta PayPal',
            content:
                '<p>Ofrecemos una tarjeta virtual (VCC) para que verifiques tu cuenta, con vigencia de 3 a 5 años.</p><p>Con una VCC podrás realizar un mayor número de compras en línea y también aumentar el límite de movimientos de tu cuenta.</p>',

            linkUrl: 'comprar-vcc',

            imageUrl: '/assets/landing-page/images-landing-4-v2.svg',
            linkText: 'Validar cuenta PayPal',
            imageSettings: imageSettingsDefaults
        }
    ],
    'en-US': [
        {
            countryDependency: true,
            title: 'Exchange EUR for USD',
            content:
                '<p>You have the capability to purchase, sell, and trade EUR for USD using multiple payment options such as bank accounts, Wise, PayPal, Zelle, Perfect Money, and other available methods.</p>',

            linkUrl: '/a/banco_eur/wise_usd/100',

            imageUrl: '/assets/landing-page/images-landing-2-v2.svg',
            linkText: 'EUR To USD Wise',
            imageSettings: imageSettingsDefaults
        },
        {
            countryDependency: true,
            title: 'Add funds to your PayPal account',
            content:
                '<p>We offer you a smooth and reliable way to complete the process. You have the flexibility to choose from multiple methods to send the currency, including options like bank transfers. Once we receive the funds, we will transfer the balance to your PayPal Account.</p>',

            linkUrl: '/a/wise_usd/palpal/100',

            imageUrl: '/assets/landing-page/images-landing-3-v2.svg',
            linkText: 'Buy PayPal balance',
            imageSettings: imageSettingsDefaults
        },
        {
            countryDependency: false,
            title: 'Invite Friends to Earn',
            content:
                '<p>To join our referral program and earn money, you must have completed at least one transaction. This ensures that you have personal experience with us and can easily explain how to use our platform. By referring others to Saldoar, you have the opportunity to receive up to $30 for each referral.</p>',

            linkUrl: '/my/dashboard/more-options/referral-transactions',

            imageUrl: '/assets/landing-page/images-landing-5-v2.svg',
            linkText: 'Sign up for free and start earning',
            imageSettings: imageSettingsDefaults
        }
    ],
    'es-US': [
        {
            countryDependency: true,
            title: 'change_usd_or_eur_to_USD',
            content: 'change_usd_or_eur_to_ars_info_text',

            linkUrl: '/a/banco_eur/zelle/0/100',

            imageUrl: '/assets/landing-page/images-landing-2-v2.svg',
            linkText: 'Cotiza Euros a Zelle',
            imageSettings: imageSettingsDefaults
        },
        {
            countryDependency: true,
            title: 'want_to_buy_paypal',
            content: 'want_to_buy_paypal_info_text',

            linkUrl: '/a/banco_eur/palpal/0/100',

            imageUrl: '/assets/landing-page/images-landing-3-v2.svg',
            linkText: 'Cotizar recarga PayPal con cuenta Bancaria',
            imageSettings: imageSettingsDefaults
        },
        {
            countryDependency: false,
            title: 'VCC_paypal',
            content: 'VCC_paypal_text_info_p_one',

            linkUrl: '/comprar-vcc',

            imageUrl: '/assets/landing-page/images-landing-4-v2.svg',
            linkText: 'VCC_paypal_button',
            imageSettings: imageSettingsDefaults
        }
    ]
};
