import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentMethodsComponent } from './payment-methods.component';
import { CoreModule } from '@app/core/core.module';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [PaymentMethodsComponent],
    exports: [PaymentMethodsComponent],
    imports: [CommonModule, CoreModule, RouterModule]
})
export class PaymentMethodsModule {}
