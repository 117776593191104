import { PlatformBrowserService } from './core/services/platform-browser.service';

export class TokenStorage {
    private static isBrowser: boolean;

    public constructor(private browserService: PlatformBrowserService) {
        TokenStorage.isBrowser = this.browserService.isBrowser;
    }

    public static getToken(): string {
        if (!TokenStorage.isBrowser) {
            return '';
        }

        return localStorage.getItem('access_token') ?? '';
    }
}
