import { Component, ErrorHandler, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { pathsSaldo } from 'environments/paths-saldo';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
    @Input() public title: string = '';
    @Input() public subtitle: string = 'contact_subtitle';
    public stateMailSend: 'iddle' | 'loading' | 'sent' = 'iddle';
    public contact_form: FormGroup = new FormGroup({
        name: new FormControl('', Validators.required),
        mid: new FormControl(''),
        email: new FormControl('', [Validators.required, Validators.pattern('[^ @]*@[^ @]*')]),
        message: new FormControl('', Validators.required)
    });

    public constructor(public httpClient: HttpClient, public error: ErrorHandler) {}

    public submit(): void {
        if (this.contact_form.status === 'INVALID') {
            return;
        }

        if (this.stateMailSend !== 'iddle') {
            return;
        }

        this.stateMailSend = 'loading';

        let midMessage: string =
            this.contact_form.controls.mid.value !== '' ? 'Pedido #' + this.contact_form.controls.mid.value + ' - ' : '';

        this.httpClient
            .post(`${environment.API_URL + pathsSaldo.API_PATH}contacts`, {
                name: this.contact_form.controls.name.value,
                email: this.contact_form.controls.email.value,
                message: midMessage + this.contact_form.controls.message.value
            })
            .subscribe(
                (): void => {
                    // @todo show success message
                    this.stateMailSend = 'sent';
                },
                (error): void => {
                    this.stateMailSend = 'iddle';
                    this.error.handleError(error);
                }
            );
    }
}
