import { trigger, state, style, animate, transition, AnimationTriggerMetadata } from '@angular/animations';

export const animationEqualSystem: AnimationTriggerMetadata = trigger('animationEqualSystems', [
    state(
        'open',
        style({
            transform: 'translateX(0px)'
        })
    ),
    state(
        'closed',
        style({
            transform: 'translateX(0px)'
        })
    ),
    transition('open <=> closed', [
        animate('0.1s ease-in', style({ transform: 'translateX(-3px)' })),
        animate('0.1s ease-in', style({ transform: 'translateX(3px)' })),
        animate('0.1s ease-in', style({ transform: 'translateX(-3px)' })),
        animate('0.1s ease-in', style({ transform: 'translateX(3px)' }))
    ])
]);
