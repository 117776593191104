import { TransferState } from '@angular/platform-browser';
import { ErrorHandler, NgModule, Injector, APP_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomErrorHandlerService } from '@app/exceptions/custom-error-handler.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CookieModule } from '@gorniv/ngx-universal';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { HomeModule } from '@app/home/home.module';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserStateInterceptor } from './core/services/http-interceptors/browserstate.interceptor';
import { UserInterceptor } from '@app/transactions/pages/user-info/user.interceptor';
import { TransactionInterceptor } from '@app/transactions/pages/transaction-info/transaction.interceptor';
import { TokenStorage } from './get-token';
import { JwtModule } from '@auth0/angular-jwt';
import { PlatformBrowserService } from './core/services/platform-browser.service';
import { GlobalStateService } from './core/services/global-state.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AuthFactorErrorInterceptor } from './my/auth-factor-error.interceptor';
import { OAuthInterceptor } from './my/oauth-interceptor';
import { OAuthModule } from 'angular-oauth2-oidc';
import { ServiceLoader } from './core/services/service-loader';
import { pathsSaldo } from 'environments/paths-saldo';
import { DialogErrorRedirectModule } from './core/components/dialog-error-redirect/dialog-error-redirect.module';
import { DashboardLazyLoadService } from './dashboard/dashboard-lazy-load.service';
import { translateBrowserLoaderFactory } from './translate/translate-browser.loader';
import { CoreModule } from './core/core.module';
import { NgxJsonapiModule } from 'ngx-jsonapi';
import { ApiErrorHandlingService } from './exceptions/services/api-error-handling.service';
import { MatDialogModule } from '@angular/material/dialog';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { MessagingModule } from '@angular/fire/messaging';
import { RecaptchaV3Module } from 'ng-recaptcha';

@NgModule({
    declarations: [AppComponent],
    imports: [
        NgxJsonapiModule.forRoot({
            url: environment.API_URL + pathsSaldo.API_PATH
        }),
        RecaptchaV3Module,
        CookieModule.forRoot(),
        BrowserAnimationsModule,
        provideFirebaseApp(() => initializeApp(environment.FIREBASE_CONFIG)),
        provideFirestore(() => getFirestore()),
        MessagingModule,
        HomeModule,
        MatDialogModule,
        OAuthModule.forRoot(),
        FlexLayoutModule.withConfig({ ssrObserveBreakpoints: ['sm', 'xs', 'lt-md', 'gt-xs', 'gt-sm'] }),
        TransferHttpCacheModule,
        ContentLoaderModule,
        DialogErrorRedirectModule,
        HttpClientModule,
        CoreModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: TokenStorage.getToken,
                allowedDomains: environment.WHITE_LISTED_DOMAINS
            }
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateBrowserLoaderFactory,
                deps: [HttpClient, TransferState]
            }
        }),
        AppRoutingModule
    ],
    providers: [
        { provide: APP_ID, useValue: 'serverApp' },
        GlobalStateService,
        DashboardLazyLoadService,
        { provide: ErrorHandler, useClass: CustomErrorHandlerService },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BrowserStateInterceptor,
            multi: true
        },
        { provide: HTTP_INTERCEPTORS, useClass: TransactionInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ApiErrorHandlingService, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: UserInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthFactorErrorInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: OAuthInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    public constructor(private browserService: PlatformBrowserService, private injector: Injector) {
        ServiceLoader.injector = this.injector;
        // Se necesita instanciar la clase para poder verificar si estamos en el browser
        /* eslint-disable @typescript-eslint/no-unused-expressions */
        new TokenStorage(this.browserService);
        /* eslint-enable @typescript-eslint/no-unused-expressions */
    }
}
