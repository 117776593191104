import { DomSanitizer } from '@angular/platform-browser';
import { PlatformBrowserService } from './../platform-browser.service';
import { MatIconRegistry } from '@angular/material/icon';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class MatIconRegisterService {
    public constructor(
        private iconRegistry: MatIconRegistry,
        private platformBrowserService: PlatformBrowserService,
        private domSanitizer: DomSanitizer
    ) {
        this.addIconClass();
    }

    public addSvgIcon(name: string, filename: string): void {
        if (this.platformBrowserService.isBrowser) {
            /* Register empty icons for server-side-rendering to prevent errors */
            this.iconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(filename));
        } else {
            this.iconRegistry.addSvgIconLiteral(name, this.domSanitizer.bypassSecurityTrustHtml('<svg></svg>'));
        }
    }

    public addIconClass(): void {
        this.iconRegistry.setDefaultFontSetClass('material-icons-outlined');
    }

    public addSvgIconSet(filename: string): void {
        if (this.platformBrowserService.isBrowser) {
            /* Register empty icons for server-side-rendering to prevent errors */
            this.iconRegistry.addSvgIconSet(this.domSanitizer.bypassSecurityTrustResourceUrl(filename));
        } else {
            this.iconRegistry.addSvgIconSetLiteral(this.domSanitizer.bypassSecurityTrustHtml('<svg></svg>'));
        }
    }
}
