import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DecimalBySystemPipe } from './decimal-by-system.pipe';
import { SafeUrlPipe } from '@app/transactions/pages/transaction-info/transaction-operation-data/waiting-payment/waiting-payment-cupon/safe-url.pipe';

@NgModule({
    declarations: [DecimalBySystemPipe, SafeUrlPipe],
    exports: [DecimalBySystemPipe, SafeUrlPipe],
    imports: [CommonModule]
})
export class PipesModule {}
