import { Injectable, RendererFactory2, ViewEncapsulation, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class LinkService {
    public constructor(private rendererFactory: RendererFactory2, @Inject(DOCUMENT) private document: Document) {}

    /**
     * Inject the State into the bottom of the <head>
     */
    public addTag(tag: LinkDefinition): void {
        try {
            const renderer: Renderer2 = this.rendererFactory.createRenderer(this.document, {
                id: '-1',
                encapsulation: ViewEncapsulation.None,
                styles: [],
                data: {}
            });
            const head: HTMLHeadElement = this.document.head;
            const link: any = renderer.createElement('link');
            let oldLink: Element | null = head.querySelector(`[rel="${tag.rel}"]`);

            if (oldLink) {
                renderer.removeChild(head, oldLink);
            }

            Object.keys(tag).forEach((prop: string): void => {
                renderer.setAttribute(link, prop, tag[prop]);
            });

            // [TODO]: get them to update the existing one (if it exists) ?
            renderer.appendChild(head, link);
        } catch (e) {
            console.error('Error within linkService : ', e);
        }
    }

    public addHreflangTag(tag: LinkDefinition): void {
        try {
            const renderer: Renderer2 = this.rendererFactory.createRenderer(this.document, {
                id: '-1',
                encapsulation: ViewEncapsulation.None,
                styles: [],
                data: {}
            });
            const head: HTMLHeadElement = this.document.head;
            const link: any = renderer.createElement('link');

            Object.keys(tag).forEach((prop: string): void => {
                renderer.setAttribute(link, prop, tag[prop]);
            });

            // [TODO]: get them to update the existing one (if it exists) ?
            renderer.appendChild(head, link);
        } catch (e) {
            console.error('Error within linkService : ', e);
        }
    }

    public removeTag(selector: string): void {
        try {
            const renderer: Renderer2 = this.rendererFactory.createRenderer(this.document, {
                id: '-1',
                encapsulation: ViewEncapsulation.None,
                styles: [],
                data: {}
            });
            const canonical: Element | null = this.document.querySelector(selector);
            const head: HTMLHeadElement = this.document.head;

            if (!!canonical) {
                renderer.removeChild(head, canonical);
            }
        } catch (e) {
            console.error('Error within linkService : ', e);
        }
    }
}

export declare type LinkDefinition = {
    charset?: string;
    crossorigin?: string;
    href?: string;
    hreflang?: string;
    media?: string;
    rel?: string;
    rev?: string;
    sizes?: string;
    target?: string;
    type?: string;
} & {
    [prop: string]: string;
};
