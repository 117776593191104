import { ChangeDetectorRef, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class IncrementalNumberService {
    private animationValueEnd: number = 0;
    private container: Array<number> = [];
    private step: number = 1;
    private interval: number = 0;
    private changeDetectorRef!: ChangeDetectorRef;

    public setChange(changeDetectorRef: ChangeDetectorRef): void {
        this.changeDetectorRef = changeDetectorRef;
    }

    public setAnimationValueEnd(animationValueEnd: number): this {
        this.animationValueEnd = animationValueEnd;

        return this;
    }

    public setContainer(container: Array<number>): this {
        this.container = container;

        return this;
    }

    public setStep(step: number): this {
        this.step = step;

        return this;
    }

    public runAnimation(timeTransitionNumbers: number): void {
        this.interval = window.setInterval((): void => {
            this.increaseNumber();
            this.changeDetectorRef.markForCheck();
        }, timeTransitionNumbers);
    }

    private increaseNumber(): void {
        if (this.container[0] >= this.animationValueEnd) {
            clearInterval(this.interval);
            this.container[0] = this.animationValueEnd;

            return;
        }
        this.container[0] = Number((this.container[0] + this.step).toFixed(2));
    }
}
