import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { animationEqualSystem } from '../animation/animation-equal-system';
import { AdvancedOptions } from '../transaction-calculator.component';

@Component({
    selector: 'app-invert-systems',
    animations: [animationEqualSystem],
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './invert-systems.component.html',
    styleUrls: ['./invert-systems.component.scss']
})
export class InvertSystemsComponent {
    @Input() public isAnimationEqualSystem: boolean = false;
    @Input() public clicked: boolean = false;
    @Input() public advancedOptions!: AdvancedOptions;
    @Output() public invertSystems: EventEmitter<void> = new EventEmitter<void>();
}
