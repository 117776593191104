import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoPublicDealComponent } from './info-public-deal.component';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { PipesModule } from '@app/core/pipes/pipes.module';
import { CoreModule } from '@app/core/core.module';
import { DealsBagAmountInfoModule } from '@app/dashboard/deals/deals-bags/deals-bag-amount-info/deals-bag-amount-info.module';
import { GainPercentModule } from '@app/dashboard/deals/gain-percent/gain-percent.module';
import { MatStepperModule } from '@angular/material/stepper';
import { SystemIconModule } from '@app/transactions/components/transaction-form/system-icon/system-icon.module';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [InfoPublicDealComponent],
    exports: [InfoPublicDealComponent],
    imports: [
        CommonModule,
        MatCardModule,
        MatButtonModule,
        PipesModule,
        TranslateModule,
        CoreModule,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
        FormsModule,
        SystemIconModule,
        DealsBagAmountInfoModule,
        GainPercentModule,
        MatStepperModule
    ]
})
export class InfoPublicDealModule {}
