import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DealsBagAmountInfoComponent } from './deals-bag-amount-info.component';
import { SystemIconModule } from '@app/transactions/components/transaction-form/system-icon/system-icon.module';

@NgModule({
    declarations: [DealsBagAmountInfoComponent],
    exports: [DealsBagAmountInfoComponent],
    imports: [CommonModule, SystemIconModule]
})
export class DealsBagAmountInfoModule {}
