import { ICountryProvider } from './country-provider-interface';
import { ArgentinaBankRepository } from './repositories/argentina-bank-repository';
import { IBankRepository } from './repositories/bank-repository-interface';
import { BoliviaBankRepository } from './repositories/bolivia-bank-repository';
import { ColombiaBankRepository } from './repositories/colombia-bank-repository';
import { GlobalBankEsRepository } from './repositories/global-bank-es-repository';
import { GlobalBankRepository } from './repositories/global-bank-repository';
import { MexicoBankRepository } from './repositories/mexico-bank-repository';
import { VenezuelaBankRepository } from './repositories/venezuela-bank-repository';

export class CountryProvider implements ICountryProvider {
    public readonly data: { [countryCode: string]: IBankRepository } = {
        '': new ArgentinaBankRepository(),
        'es-BO': new BoliviaBankRepository(),
        'es-CO': new ColombiaBankRepository(),
        'es-MX': new MexicoBankRepository(),
        'en-US': new GlobalBankRepository(),
        'es-US': new GlobalBankEsRepository(),
        'es-VE': new VenezuelaBankRepository()
    };
    public readonly defaultCountry: IBankRepository = new ArgentinaBankRepository();

    public getCountry(countryCode: string): IBankRepository {
        return this.data[countryCode] ? this.data[countryCode] : new ArgentinaBankRepository();
    }

    public getSystemId(countryCode: string): string {
        return this.data[countryCode] ? this.data[countryCode].id : new ArgentinaBankRepository().id;
    }

    public getSystemIds(countryCode: string): Array<string> {
        let country: IBankRepository | undefined = this.data[countryCode];

        return country ? [country.id, country.systemPair] : [this.defaultCountry.id, this.defaultCountry.systemPair];
    }

    public getCurrency(countryCode: string): string {
        return this.data[countryCode] ? this.data[countryCode].currency : new ArgentinaBankRepository().currency;
    }
}
