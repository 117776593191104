import { environment as base } from './environment.base';

export let environment: { [property: string]: any } = {
    ...base,
    ...process.env
};

if (process.env.WHITE_LISTED_DOMAINS !== undefined) {
    environment.WHITE_LISTED_DOMAINS = process.env.WHITE_LISTED_DOMAINS.split(',');
}

if (process.env.production !== undefined) {
    switch (process.env.production) {
        case 'true':
            environment.production = true;
            break;
        case 'false':
            environment.production = false;
            break;
        default:
            break;
    }
}

if (process.env.FIREBASE_API_KEY !== undefined) {
    environment.FIREBASE_CONFIG = {
        ...environment.FIREBASE_CONFIG,
        apiKey: process.env.FIREBASE_API_KEY
    };
}

if (process.env.FIREBASE_PROJECT_ID !== undefined) {
    environment.FIREBASE_CONFIG = {
        ...environment.FIREBASE_CONFIG,
        authDomain: process.env.FIREBASE_PROJECT_ID + '.firebaseapp.com',
        projectId: process.env.FIREBASE_PROJECT_ID,
        storageBucket: process.env.FIREBASE_PROJECT_ID + '.appspot.com'
    };
}

if (process.env.FIREBASE_MESSAGING_SENDER_ID !== undefined) {
    environment.FIREBASE_CONFIG = {
        ...environment.FIREBASE_CONFIG,
        messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID
    };
}

if (process.env.FIREBASE_APP_ID !== undefined) {
    environment.FIREBASE_CONFIG = {
        ...environment.FIREBASE_CONFIG,
        appId: process.env.FIREBASE_APP_ID
    };
}
