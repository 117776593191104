import { Transaction } from '../transaction';
import { IMessageError } from './transaction-validators/transaction-validators';
import { SystemEqualsValidator } from './transaction-validators/system-equals-validator';
import { ValueIncorrectValidator } from './transaction-validators/value-incorrect-validator';
import { MinimumValuesValidator } from './transaction-validators/minimum-values-validator';
import { TransactionPrivate } from '@app/core/resources/transaction-private';

export class TransactionValidatorsServiceProvider {
    private validators: Array<any> = [ValueIncorrectValidator, SystemEqualsValidator, MinimumValuesValidator];

    public getErrors(transaction: Transaction | TransactionPrivate): Array<IMessageError> {
        let errors: Array<IMessageError> = [];
        for (let validatorName of this.validators) {
            let validator: typeof validatorName = new validatorName();
            errors = errors.concat(validator.validate(transaction));
        }

        return errors;
    }
}
