import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { GeneralInfo } from '@app/core/resources/general-info';
import { GeneralsInfoService } from '@app/core/resources/generals-info.service';
import { IntersectionObserverService } from '@app/core/services/intersection-observer.service';
import { PlatformBrowserService } from '@app/core/services/platform-browser.service';
import { Subject, Subscription } from 'rxjs';

@Component({
    selector: 'app-user-social',
    templateUrl: './user-social.component.html',
    styleUrls: ['./user-social.component.scss']
})
export class UserSocialComponent implements OnDestroy, AfterViewInit {
    @Input() public lightColor: boolean = false;
    private intersectionSubscription: Subscription = new Subscription();
    private carouselSubject: Subject<IntersectionObserverEntry> = new Subject<IntersectionObserverEntry>();
    public generalInfo: GeneralInfo = new GeneralInfo();
    public loadCarousel: boolean = false;

    public constructor(
        private browserService: PlatformBrowserService,
        private intersectionObserverService: IntersectionObserverService,
        private generalsInfoService: GeneralsInfoService
    ) {
        this.getGeneralInfo();
    }

    public ngAfterViewInit(): void {
        this.intersectionCarousel();
    }

    public ngOnDestroy(): void {
        this.intersectionSubscription.unsubscribe();
    }

    private getGeneralInfo(): void {
        if (!this.browserService.isBrowser) {
            return;
        }

        this.generalsInfoService.all().subscribe((generalinfo) => {
            if (generalinfo.data.length === 0) {
                return;
            }
            this.generalInfo = generalinfo.data[0];
        });
    }

    public intersectionCarousel(): void {
        this.intersectionSubscription = this.carouselSubject.asObservable().subscribe((): void => {
            this.loadCarousel = true;
        });
        this.intersectionObserverService.observeElements('.lazyload-user-social', this.carouselSubject);
    }
}
