export const deals_en_US: any = {
    deals_title: 'Catch deals and save, up to 5% off!',
    subtitle_one: 'Would you like to make a profit exchanging in Saldo?',
    subtitle_two: 'We present you "Deals"',
    text_info:
        'A new feature has been added where, if you are level 3 or higher, you can access exclusive benefits. Simply and easily, you can enjoy 5% discounts on the total amount.',
    question_info: 'How can I get them? Is too simple!',
    text_info_p_one:
        " and you'll find your order history, the accounts you've used, and if you're a level 3 or higher user, the \"deals\" section.",
    text_info_p_one_button: 'Log in',
    text_info_p_two:
        'There, you can see the deals you can take, with the amount and the corresponding discount you would get by taking it.',
    text_info_p_three:
        'To take advantage of the deals you\'re interested in, just click on "+" to add them to your "Deals bag". Then, simply choose your destination account where you\'d like to receive the corresponding amount for the order you\'re going to pay, and you\'re done!',
    text_info_p_four: "That's how easily you can save on your exchanges.",
    text_info_p_five: "If you haven't reached level 3 yet, what are you waiting for? (Make your orders, ",
    text_info_p_five_button: 'level up, and take advantage of these benefits',
    insted_of: 'Instead of',
    amount_cashback: 'We would send you',

    flyer_title_one: "If you haven't reached your level 3,",
    flyer_title_two: 'What are you waiting?',
    flyer_title_three: 'Level up and take advantage of these benefits'
};
