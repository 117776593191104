import { Transaction } from '@app/transactions/transaction';
import { FeeDefault } from './fee-default';
import { TransactionPrivate } from '@app/core/resources/transaction-private';

export class FeeNeteller extends FeeDefault {
    public constructor(protected transaction: Transaction | TransactionPrivate) {
        super(transaction);
    }

    public getFee(): number {
        if (this.way === 'send') {
            return 0;
        }

        this.setFeeAndDecimalPlaces();

        return this.parserDecimal(this.fee, this.decimal_places);
    }

    protected setFeeAndDecimalPlaces(): void {
        this.fee = Math.max(this.transaction.attributes['amount' + this.systemNumber] * 0.0145, 0.5);
        this.decimal_places = this.transaction.relationships['system' + this.systemNumber].data.attributes.decimal_places ?? 2;
    }
}
