import { Component, AfterViewInit, ViewChild, TemplateRef, Input, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { DealService } from '@app/core/services/deal.service';
import { GlobalStateService } from '@app/core/services/global-state.service';
import { Deal } from '@app/core/resources/deal';
import { interval, merge, Observable, of } from 'rxjs';
import { filter, flatMap } from 'rxjs/operators';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { Column, JamRefreshService, ListBaseCommonComponent, ResponsiveColumns } from 'ngx-jsonapi-material';
import { DocumentCollection } from 'ngx-jsonapi';
import { DealsBagFillingService } from '../deals-bag-filling.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { InfoPublicDealComponent } from '@app/landing-page/components/public-deals/info-public-deal/info-public-deal.component';
import { PublicDealsSystemService } from '@app/landing-page/components/public-deals/public-deals-system.service';
import { System } from '@app/core/resources/system';
import { SystemsService } from '@app/core/services/systems.service';
import { NetworkService } from '@app/core/services/network.service';

@Component({
    selector: 'app-deals-list',
    templateUrl: './deals-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./deals-list.component.scss']
})
export class DealsListComponent implements AfterViewInit {
    @ViewChild('default_system') public default_system!: TemplateRef<any>;
    @ViewChild('default_rest') public default_rest!: TemplateRef<any>;
    @ViewChild('default_gain') public default_gain!: TemplateRef<any>;
    @ViewChild('default_add') public default_add!: TemplateRef<any>;
    @ViewChild('table') public table!: ListBaseCommonComponent;
    @Input() public haveLevel: boolean = false;
    public responsiveColumns: ResponsiveColumns = new ResponsiveColumns();
    public collection: DocumentCollection<Deal> = new DocumentCollection();
    public tableColumns: Array<Column> = [
        new Column('gain_percent', 'Oferta', 'Oferta')
            .setCellClasses('cell-and-header')
            .setHeaderClasses('cell-and-header')
            .setHeaderStyles({ 'max-width': '65px' })
            .setCellStyles({ 'max-width': '65px', height: '65px' })
            .hidemobileTitle()
            .hideOnMobile()
            .templateOnly(true),
        new Column('group_id', 'Método', 'Método')
            .setCellClasses('system-cell')
            .setHeaderClasses('system-cell')
            .setHeaderStyles({ 'max-width': '96px' })
            .setCellStyles({ 'max-width': '96px' })
            .hidemobileTitle()
            .hideOnMobile()
            .templateOnly(true),
        new Column('rest_to_pay', 'Envías', 'Envías')
            .setCellStyles({ 'font-size': '18px' })
            .setCellClasses('number-cell')
            .hidemobileTitle()
            .hideOnMobile()
            .templateOnly(true),
        new Column('template', ' ', '')
            .setCellStyles({
                'max-width': '88px',
                'margin-right': '24px',
                'margin-left': '8px'
            })
            .hidemobileTitle()
            .hideOnMobile()
            .templateOnly(true)
    ];
    public filter_text: string = '';

    public constructor(
        private transferState: TransferState,
        private matDialog: MatDialog,
        private changeDetectorRef: ChangeDetectorRef,
        private publicDealsSystemService: PublicDealsSystemService,
        private systemsService: SystemsService,
        public globalStateService: GlobalStateService,
        public dealService: DealService,
        public dealsBagFillingService: DealsBagFillingService,
        public networksService: NetworkService,
        public rsRefreshService: JamRefreshService
    ) {
        this.refreshDeals();
        this.handleDealsBagService();
        this.dealsBagFillingService.userId = this.globalStateService.userId ?? '';
        if (!this.haveLevel) {
            this.getSystems();

            return;
        }
    }

    private handleDealsBagService(): void {
        let cdr: ChangeDetectorRef = this.changeDetectorRef;
        this.dealsBagFillingService.matDialog = this.matDialog;
        this.dealsBagFillingService.setMethod({
            changeDetectorList: () => {
                cdr.detectChanges();
            }
        });
        merge(this.dealsBagFillingService.remoteFilterSubject$, this.dealsBagFillingService.updateDealsBags$).subscribe(() => {
            this.refreshDealsList();
        });
    }

    public dialogInfoDeal(deal: Deal): void {
        this.publicDealsSystemService.setGroupIdAndCurrency('ars_bank', 'ARS');
        this.publicDealsSystemService.setPublicDeals(deal).calculateReceiveAmount();

        this.openDialogInfoDeals(deal);

        return;
    }

    public addDeal(deal: Deal): void {
        if (this.dealsBagFillingService.dealsBag.id === '') {
            this.dealsBagFillingService.setPage(0, 250);
        }

        if (this.dealsBagFillingService.dealsBag.relationships.deals.find(deal?.id)) {
            this.dealsBagFillingService.removeDeal(deal);

            return;
        }

        this.dealsBagFillingService.addDeal(deal);
        this.refreshDealsList();
        this.changeDetectorRef.detectChanges();
    }

    private getSystems(): void {
        this.systemsService
            .all({ include: ['rates', 'currency', 'networks'], ttl: 0 })
            .pipe(filter((systems: DocumentCollection<System>): boolean => systems.source !== 'new'))
            .subscribe((systems: DocumentCollection<System>) => {
                this.publicDealsSystemService.setSystems(
                    systems.data.filter(
                        (system): boolean =>
                            (system.attributes.account_required_send || system.attributes.account_required_receive) &&
                            (system.attributes.can_send || system.attributes.can_receive)
                    )
                );
            });
    }

    public openDialogInfoDeals(deal: Deal): void {
        let matDialog: MatDialogRef<InfoPublicDealComponent> = this.matDialog.open(InfoPublicDealComponent, {
            width: '550px',
            data: {
                publicDeals: deal,
                system: new System(),
                publicDealsSystemService: this.publicDealsSystemService,
                btnDeal: false
            }
        });
        matDialog.afterClosed().subscribe((_deal) => {
            if (_deal) {
                this.addDeal(_deal);
            }
        });
        this.changeDetectorRef.detectChanges();
    }

    public updateFilter(filters: any): void {
        this.dealsBagFillingService.remoteFilter = filters;
        this.rsRefreshService.refresh();
        this.changeDetectorRef.markForCheck();
    }

    private refreshDealsList(): void {
        setTimeout((): void => {
            this.rsRefreshService.autoRefresh();
        }, 1);
    }

    public filterByTextCallback(filter_text: string): void {
        this.filter_text = filter_text;

        if (this.filter_text === '') {
            delete this.dealsBagFillingService.remoteFilter['system.name'];
        } else {
            this.dealsBagFillingService.remoteFilter['system.name'] = this.filter_text;
        }

        this.table.updateFilter(this.dealsBagFillingService.remoteFilter);
    }

    private refreshDeals(): void {
        interval(50 * 1000)
            .pipe(
                flatMap((): Observable<any> => {
                    // prevent problem with TransferHttpCacheModule when page loaded
                    /* eslint-disable @typescript-eslint/dot-notation */
                    Object.keys(this.transferState['store']).forEach((key: string): void => this.transferState.remove(makeStateKey(key)));

                    /* eslint-enable @typescript-eslint/dot-notation */
                    return of(null);
                })
            )
            .subscribe((): void => {
                this.rsRefreshService.autoRefresh();
            });
    }

    public setCollection(collection: DocumentCollection<Deal>): void {
        if (collection.source === 'server') {
            this.dealsBagFillingService.collectionLength = collection.data.length;
        }
        setTimeout(() => {
            this.collection = collection;
        });
    }

    public ngAfterViewInit(): void {
        this.responsiveColumns
            .setXs(['gain_percent', 'group_id', 'rest_to_pay', 'template'])
            .setSm(['gain_percent', 'group_id', 'rest_to_pay', 'template'])
            .setMd(['gain_percent', 'group_id', 'rest_to_pay', 'template'])
            .setLg(['gain_percent', 'group_id', 'rest_to_pay', 'template'])
            .setXl(['gain_percent', 'group_id', 'rest_to_pay', 'template']);
        this.tableColumns.find((column): boolean => column.key === 'group_id')?.setTemplate(this.default_system);
        this.tableColumns.find((column): boolean => column.key === 'rest_to_pay')?.setTemplate(this.default_rest);
        this.tableColumns.find((column): boolean => column.key === 'gain_percent')?.setTemplate(this.default_gain);
        this.tableColumns.find((column): boolean => column.key === 'template')?.setTemplate(this.default_add);
    }
}
