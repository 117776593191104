import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-system-picture[systemId]',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './system-picture.component.html',
    styleUrls: ['./system-picture.component.scss']
})
export class SystemPictureComponent {
    public url: string = environment.API_URL;
    @Input() public systemId: string = '';
    @Input() public alt: string = '';
    @Input() public title: string = '';
}
