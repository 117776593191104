export interface ISystemFee {
    id: string;
    fee: IFee;
}

export interface IFee {
    fee_send?: { [property: string]: number };
    fee_receive?: { [property: string]: number };
}

export const systems_fee_model: Array<ISystemFee> = [
    {
        id: 'palpal',
        fee: {
            fee_receive: {
                fee: 0.054,
                additional: 0.3,
                minimum: 0
            }
        }
    },
    {
        id: 'payeer',
        fee: {
            fee_receive: {
                fee: 0.005,
                additional: 0,
                minimum: 0
            }
        }
    },
    {
        id: 'skrill',
        fee: {
            fee_send: {
                fee: 0.0145,
                additional: 0,
                minimum: 0.5
            }
        }
    },
    {
        id: 'neteller',
        fee: {
            fee_send: {
                fee: 0.0145,
                additional: 0,
                minimum: 0.5
            }
        }
    }
];
