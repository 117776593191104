import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, Self } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { HeaderCountryService } from '@app/core/components/header/header-country.service';
import { CountryProvider } from '@app/core/country-provider/country-provider';
import { PublicDeal } from '@app/core/resources/public-deal';
import { System } from '@app/core/resources/system';
import { AnalyticService } from '@app/core/services/analytic.service';
import { SystemsService } from '@app/core/services/systems.service';
import { UserCountryService } from '@app/core/services/user-country.service';
import { DocumentCollection } from 'ngx-jsonapi';
import { filter } from 'rxjs/operators';
import { InfoPublicDealComponent } from '../info-public-deal/info-public-deal.component';
import { PublicDealsSystemService } from '../public-deals-system.service';
import { CurrenciesService } from '@app/core/services/currencies.service';

@Component({
    selector: 'app-public-deal-card',
    templateUrl: './public-deal-card.component.html',
    providers: [PublicDealsSystemService],
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./public-deal-card.component.scss']
})
export class PublicDealCardComponent implements OnChanges {
    @Input() public publicDeals: PublicDeal = new PublicDeal();
    @Input() public filterPublicDeals: System = new System();
    @Input() public notShowMatDialog: boolean = false;
    public system: System = new System();
    public sendSystem: System = new System();
    private systems: Array<System> = [];
    private countryProvider: CountryProvider = new CountryProvider();

    public constructor(
        @Self() public publicDealsSystemService: PublicDealsSystemService,
        public currenciesService: CurrenciesService,
        private systemsService: SystemsService,
        private userCountryService: UserCountryService,
        private matDialog: MatDialog,
        private analyticService: AnalyticService,
        private changeDetectorRef: ChangeDetectorRef,
        private headerCountryService: HeaderCountryService
    ) {
        this.observerChangeCountry();
    }

    public ngOnChanges(): void {
        if (this.publicDeals.id !== '') {
            this.selectDefaultCountry();
        }
        if (this.filterPublicDeals.attributes.group_id !== '') {
            this.setSystemByFilter(this.filterPublicDeals.attributes.group_id, this.filterPublicDeals.attributes.currency);
        }
    }

    private observerChangeCountry(): void {
        this.headerCountryService.selectedCountry$.subscribe((country): void => {
            this.publicDealsSystemService.setGroupIdAndCurrency(
                this.countryProvider.getCountry(country.code).groupId,
                this.countryProvider.getCountry(country.code).currency
            );
            this.publicDealsSystemService.calculateReceiveAmount();
        });
    }

    private setSystemByFilter(group_id: string, currency: string): void {
        this.publicDealsSystemService.setGroupIdAndCurrency(group_id, currency);
        this.getSystemWithRate();
    }

    private selectDefaultCountry(): void {
        this.userCountryService.getCountry().subscribe((country): void => {
            this.publicDealsSystemService.setGroupIdAndCurrency(
                this.countryProvider.getCountry(country.code).groupId,
                this.countryProvider.getCountry(country.code).currency
            );
            this.getSystemWithRate();
        });
    }

    public getSystemWithRate(): void {
        this.systemsService
            .all({ include: ['rates', 'currency'], ttl: 0 })
            .pipe(filter((systems: DocumentCollection<System>): boolean => systems.source !== 'new'))
            .subscribe((systems: DocumentCollection<System>) => {
                this.systems = systems.data.filter((system) => system.attributes.name);
                this.publicDealsSystemService
                    .setSystems(this.systems.filter((system) => system.attributes.can_send || system.attributes.can_receive))
                    .setPublicDeals(this.publicDeals)
                    .calculateReceiveAmount();
                this.changeDetectorRef.detectChanges();
            });
    }

    public openDialogInfo(): void {
        if (!this.publicDealsSystemService.receiveSystem.id || this.notShowMatDialog) {
            return;
        }
        this.trackAnalytics();
        this.matDialog.open(InfoPublicDealComponent, {
            width: '550px',
            autoFocus: false,
            data: {
                publicDeals: this.publicDeals,
                system: this.system,
                publicDealsSystemService: this.publicDealsSystemService,
                btnDeal: true
            }
        });

        this.changeDetectorRef.detectChanges();
    }

    public trackAnalytics(): void {
        this.analyticService.trackCustom(
            'publicDeal',
            'system: ' + this.publicDeals.relationships.system.data?.id + '-gain_percent:' + this.publicDeals.attributes.gain_percent
        );
    }
}
