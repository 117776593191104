import { DecimalSystemHelper } from '@app/core/helpers/decimal-system-helper';
import { Account } from '@app/core/resources/account';
import { Network } from '@app/core/resources/networks';
import { Rate } from '@app/core/resources/rate';
import { System } from '@app/core/resources/system';
import { DocumentCollection } from 'ngx-jsonapi';

export default class TransactionAmountCalculator {
    private networkId: string = '';
    private amount: number = 0;
    private system1: System = new System();
    private system2: System = new System();
    private account: Account = new Account();

    public setNetworkId(networkId: string): this {
        this.networkId = networkId;

        return this;
    }

    public setSystem1(system1: System): this {
        this.system1 = system1;

        return this;
    }

    public setSystem2(system2: System): this {
        this.system2 = system2;

        return this;
    }

    public setAccount(account: Account): this {
        this.account = account;

        return this;
    }

    public setAmount(amount: number): this {
        this.amount = amount;

        return this;
    }

    private getFixedFee(way: number): number {
        if (way === 1) {
            return this.system1.attributes.fixed_fee_send;
        }

        if (
            !this.system2.relationships.networks ||
            !this.system2.relationships.networks.data ||
            this.system2.relationships.networks.data.length === 0
        ) {
            return this.system2.attributes.fixed_fee_receive;
        }

        return this.getFixedFeeByNewtwork();
    }

    private getFixedFeeByNewtwork(): number {
        const network: Network = this.getNetwork();
        const currencyPrice: number = this.system2.relationships.currency.data
            ? this.system2.relationships.currency.data.attributes.price
            : 0;

        return currencyPrice * network.attributes.fixed_fee_usd;
    }

    private getNetwork(): Network {
        if (this.account.id !== '') {
            return this.account.relationships.network.data ?? new Network();
        }

        let networkId: string = this.system2.attributes.default_network_id ?? '';

        if (this.networkId) {
            networkId = this.networkId;
        }

        return this.system2.relationships.networks.data.find((network) => network.id === networkId) ?? new Network();
    }

    private getRatePrice(system1: System, system2: System): number {
        let rates: DocumentCollection<Rate> = system1.relationships.rates ?? new DocumentCollection();
        let rate: Rate | undefined = rates.data.find((eachRate): boolean => eachRate.attributes.system_id === system2.id);

        return rate !== undefined && rate.attributes !== undefined ? rate.attributes.price : 0;
    }

    public calculateAmount(way: number): number {
        let ratePrice: number = this.getRatePrice(this.system1, this.system2);
        const internalFixedFee1: number = this.getFixedFee(1) ?? 0;
        const internalFixedFee2: number = this.getFixedFee(2) ?? 0;
        const system: System = way === 1 ? this.system1 : this.system2;

        let amount: number = 0;

        if (ratePrice !== 0) {
            ratePrice = 1 / ratePrice;
        }

        if (way === 1) {
            amount = (this.amount + internalFixedFee2) / ratePrice + internalFixedFee1;
        } else {
            amount = (this.amount - internalFixedFee1) * ratePrice - internalFixedFee2;
        }

        return new DecimalSystemHelper().parseAmountByDecimal(system, Math.max(amount, 0));
    }

    public getSendAmount(sendSystem: System, receiveSystem: System, receiveAmount: number): number {
        return this.setSystem1(sendSystem).setSystem2(receiveSystem).setAmount(Number(receiveAmount)).calculateAmount(1);
    }

    public getReceiveAmount(receiveSystem: System, sendSystem: System, sendAmount: number): number {
        return this.setSystem1(sendSystem).setSystem2(receiveSystem).setAmount(Number(sendAmount)).calculateAmount(2);
    }
}
