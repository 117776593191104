import { Component, Input } from '@angular/core';
import { PlatformBrowserService } from '@app/core/services/platform-browser.service';

@Component({
    selector: 'app-header-steps',
    templateUrl: './header-steps.component.html',
    styleUrls: ['./header-steps.component.scss']
})
export class HeaderStepsComponent {
    @Input() public isExit: boolean = false;
    @Input() public hiddenTitle: boolean = false;

    public constructor(public browserService: PlatformBrowserService) {}
}
