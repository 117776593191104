import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DealsBagFillingService } from '../../deals-bag-filling.service';

@Component({
    selector: 'app-unsaved-changes-dialog',
    templateUrl: './unsaved-changes-dialog.component.html',
    styleUrls: ['./unsaved-changes-dialog.component.scss']
})
export class UnsavedChangesDialogComponent {
    public constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: any,
        private dialogRef: MatDialogRef<UnsavedChangesDialogComponent>,
        public dealsBagFillingService: DealsBagFillingService
    ) {}

    public closeDialog(data: boolean): void {
        this.dialogRef.close(data);
    }
}
