import { Component, Input } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-system-icon',
    templateUrl: './system-icon.component.html'
})
export class SystemIconComponent {
    public url: string = environment.API_URL;
    @Input() public systemId: string = '';
    @Input() public alt: string = '';
    @Input() public title: string = '';
    @Input() public width: string = '18';
    @Input() public height: string = '18';
    @Input() public size: string = 'small';
}
