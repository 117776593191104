import { IBankRepository } from './bank-repository-interface';

export class BoliviaBankRepository implements IBankRepository {
    public readonly id: string = 'banco_bol';
    public readonly currency: string = 'BOB';
    public readonly groupId: string = 'banco_bol';
    public readonly title: string = 'Intercambiar saldo Bolivia';
    public readonly urlHref: string = '/a/banco_bol/payoneer/0/100';
    public readonly countryName: string = 'Bolivia';
    public readonly currencyName: string = 'Pesos bolivianos';
    public readonly widthPositionSprite: string = '-96px';
    public readonly heightPositionSprite: string = '-106px';
    public readonly systemPair: string = 'payoneer';
    public readonly langCode: string = 'es-BO';
}
