import { NgModule } from '@angular/core';
import { LandingPageComponent } from './pages/landing-page.component';
import { BestRatesModule } from '@app/landing-page/components/best-rates/best-rates.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { TransactionCalculatorModule } from '@app/transactions/components/transaction-calculator/transaction-calculator.module';
import { CoreModule } from '@app/core/core.module';
import { HeaderStepsComponent } from './components/header-steps/header-steps.component';
import { ContactModule } from '@app/landing-page/components/contact/contact.module';
import { SuccessfulTransactionsComponent } from './components/successful-transactions/successful-transactions.component';
import { RouterModule } from '@angular/router';
import { LastTransactionsComponent } from './components/last-transactions/last-transactions.component';
import { ScriptService } from '@app/core/services/script.service';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { SystemsFeatureComponent } from './components/systems-feature/systems-feature.component';
import { InfoBlockModule } from '@app/core/components/info-block/info-block.module';
import { PaymentMethodsModule } from './components/payment-methods/payment-methods.module';
import { UserSocialModule } from './components/user-social/user-social.module';
import { PublicDealsModule } from './components/public-deals/public-deals.module';
import { TranslateModule } from '@ngx-translate/core';
import { SkeletonModule } from '@app/core/components/skeleton/skeleton.module';

@NgModule({
    declarations: [
        LandingPageComponent,
        HeaderStepsComponent,
        SuccessfulTransactionsComponent,
        LastTransactionsComponent,
        SystemsFeatureComponent
    ],
    exports: [LandingPageComponent, HeaderStepsComponent, SystemsFeatureComponent],
    providers: [ScriptService],
    imports: [
        CommonModule,
        BestRatesModule,
        InfoBlockModule,
        PaymentMethodsModule,
        MatButtonModule,
        UserSocialModule,
        TransactionCalculatorModule,
        ContactModule,
        CoreModule,
        PublicDealsModule,
        RouterModule,
        TranslateModule,
        MatIconModule,
        SkeletonModule
    ]
})
export class LandingPageModule {}
