import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RateDroppedDeals } from '@app/core/errors/rate-dropped-deals';
import { RateDroppedTransactions } from '@app/core/errors/rate-dropped-transactions';
import { UnavailableDeal } from '@app/core/errors/unavailable-deal';
import { Context } from '@app/core/patrons/context';
import { catchError, of, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApiErrorHandlingService implements HttpInterceptor {
    public intercept(req: HttpRequest<any>, next: HttpHandler): any {
        return next.handle(req).pipe(
            catchError((element) => {
                if (!(element.error && element.error.errors && element.error.errors.length > 0)) {
                    return throwError(element);
                }
                switch (true) {
                    case element.error.errors[0].code && element.error.errors[0].code.includes('rate_dropped'):
                        if (req.url.includes('transactions')) {
                            new Context().setStrategy(new RateDroppedTransactions()).execute();

                            return of(true);
                        }

                        new Context().setStrategy(new RateDroppedDeals()).execute();

                        return of(true);
                    case element.error.errors[0].code && element.error.errors[0].code.includes('unavailable_deal'):
                        new Context().setStrategy(new UnavailableDeal()).execute();

                        return of(true);
                    default:
                        return throwError(element);
                }
            })
        );
    }
}
