import { Injectable } from '@angular/core';
import { Deal } from '@app/core/resources/deal';
import { PublicDeal } from '@app/core/resources/public-deal';
import { System } from '@app/core/resources/system';
import TransactionAmountCalculator from '@app/transactions/transaction-amount-calculator';

@Injectable({
    providedIn: 'root'
})
export class PublicDealsSystemService {
    public systems: Array<System> = [];
    public publicDeal: PublicDeal | Deal = new PublicDeal();
    public groupId: string = '';
    public sendSystem: System = new System();
    public amountWithoutGain: number = 0;
    public amount2: number = 0;
    public currency: string = '';
    public receiveSystem: System = new System();

    public setSystems(systems: any): this {
        this.systems = systems;

        return this;
    }

    public setPublicDeals(publicDeal: PublicDeal | Deal): this {
        this.publicDeal = publicDeal;

        return this;
    }

    public setGroupIdAndCurrency(groupId: string = 'palpal', currency: string = 'USD'): void {
        this.groupId = groupId === 'global' ? 'zelle' : groupId;
        this.currency = currency;
    }

    public calculateReceiveAmount(): void {
        if (this.systems.length === 0) {
            return;
        }

        if (this.groupId === this.publicDeal.attributes.group_id) {
            this.setGroupIdAndCurrency();
        }

        this.receiveSystem =
            this.systems.filter((system: System): boolean => system.attributes.group_id === this.groupId)[0] ?? new System();
        this.sendSystem =
            this.systems.filter((system: System): boolean => system.attributes.group_id === this.publicDeal.attributes.group_id)[0] ??
            new System();
        let amount: number = this.publicDeal.attributes.rest_to_pay * (this.publicDeal.attributes.gain_percent / 100 + 1);
        let transactionAmountCalculator: TransactionAmountCalculator = new TransactionAmountCalculator();
        this.amount2 = transactionAmountCalculator.getReceiveAmount(this.receiveSystem, this.sendSystem, amount);
        this.amountWithoutGain = transactionAmountCalculator.getReceiveAmount(
            this.receiveSystem,
            this.sendSystem,
            this.publicDeal.attributes.rest_to_pay
        );
    }
}
