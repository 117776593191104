import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { DocumentCollection } from 'ngx-jsonapi';
import { interval, Observable } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { System } from '../resources/system';
import { SystemsService } from './systems.service';

@Injectable({
    providedIn: 'root'
})
export class RefreshRatesService {
    public constructor(private transferState: TransferState) {}

    public refresh(systemsService: SystemsService): Observable<DocumentCollection<System>> {
        return interval(60 * 1000).pipe(
            flatMap((): Observable<DocumentCollection<System>> => {
                // prevent problem with TransferHttpCacheModule when page loaded
                /* eslint-disable @typescript-eslint/dot-notation */
                Object.keys(this.transferState['store']).forEach((key: string): void => this.transferState.remove(makeStateKey(key)));
                /* eslint-enable @typescript-eslint/dot-notation */

                return systemsService.all({ include: ['rates'] });
            })
        );
    }
}
