import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from '@app/core/components/footer/footer.component';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { SystemsService } from '@app/core/services/systems.service';
import { RatesService } from '@app/core/services/rates.service';
import { RouteHandlerComponent } from './components/route-handler/route-handler.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { HeaderComponent } from './components/header/header.component';
import { HeaderService } from '@app/core/services/header-service';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { LinkService } from '@app/core/services/link-service';
import { SystemPictureComponent } from './components/system-picture/system-picture.component';
import { RouterModule } from '@angular/router';
import { LogoRedirectsComponent } from './components/logo-redirects/logo-redirects.component';
import { DarkTitleComponent } from '@app/landing-page/components/dark-title/dark-title.component';
import { MatIconModule } from '@angular/material/icon';
import { ControlErrorsDirective } from './services/form-errors/control-errors.directive';
import { ControlErrorComponent } from './components/control-error/control-error.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { FormSubmitDirective } from './services/form-errors/form-submit.directive';
import { ShellNoRenderDirective } from './directives/shell-no-render.directive';
import { ShellRenderDirective } from './directives/shell-render.directive';
import { SystemIconModule } from '@app/transactions/components/transaction-form/system-icon/system-icon.module';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatDividerModule } from '@angular/material/divider';
import { RsAvatarModule } from '@app/dashboard/rs-avatar/rs-avatar.module';
import { CheckTransactionStatesModule } from '@app/transactions/pages/check-transaction-states/check-transaction-states.module';
import { TrimDirective } from './account-service-provider/trim.directive';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        FooterComponent,
        RouteHandlerComponent,
        HeaderComponent,
        ErrorPageComponent,
        LogoRedirectsComponent,
        DarkTitleComponent,
        ControlErrorsDirective,
        ControlErrorComponent,
        FormSubmitDirective,
        ShellNoRenderDirective,
        ShellRenderDirective,
        SystemPictureComponent,
        TrimDirective
    ],
    exports: [
        FooterComponent,
        SystemPictureComponent,
        LogoRedirectsComponent,
        DarkTitleComponent,
        ControlErrorsDirective,
        ControlErrorComponent,
        ShellNoRenderDirective,
        ShellRenderDirective,
        FormSubmitDirective,
        HeaderComponent,
        TrimDirective
    ],
    imports: [
        CommonModule,
        FlexModule,
        MatFormFieldModule,
        ExtendedModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        CheckTransactionStatesModule,
        MatSnackBarModule,
        SystemIconModule,
        MatDividerModule,
        MatMenuModule,
        RsAvatarModule,
        TranslateModule,
        RouterModule
    ],
    providers: [HeaderService, LinkService]
})
export class CoreModule {
    public constructor(public systemsService: SystemsService, public ratesService: RatesService) {
        /**/
    }
}
