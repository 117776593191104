import { Injectable, NgZone } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { PlatformBrowserService } from '../../core/services/platform-browser.service';

export type Severity = 'error' | 'info' | 'warning' | 'success';

@Injectable({
    providedIn: 'root'
})
export class NotifyService {
    public constructor(public snackBar: MatSnackBar, private browserService: PlatformBrowserService, private zone: NgZone) {}

    public notify(notification: string, severity: Severity = 'error'): void {
        this.zone.run((): void => {
            this.snackBar.open(notification, 'Cerrar', {
                duration: this.browserService.isBrowser ? 30 * 1000 : 0,
                verticalPosition: 'top',
                horizontalPosition: 'right',
                panelClass: [severity]
            });
        });
    }

    public error(notification: string): void {
        this.notify(notification, 'error');
    }
}
