import { MatIconRegisterService } from '@app/core/services/http-interceptors/mat-icon-register.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-dark-title',
    templateUrl: './dark-title.component.html',
    styleUrls: ['./dark-title.component.scss']
})
export class DarkTitleComponent implements OnInit {
    @Input() public text: string = '';
    @Input() public withAnchor: boolean = false;
    public titleId: string = '';

    public constructor(matIconRegisterService: MatIconRegisterService) {
        matIconRegisterService.addSvgIcon('link', 'assets/link-icon.svg');
    }

    public ngOnInit(): void {
        if (this.text) {
            this.titleId = this.text.replace(/\s+/g, '-').toLowerCase();
        }
    }
}
