import { MatDialog } from '@angular/material/dialog';
import { IStrategy } from '../patrons/strategy';
import { ServiceLoader } from '../services/service-loader';
import { MessageAlertQuotationHelper } from '@app/transactions/helper/message-alert-quotation.helper';
import { SystemsService } from '../services/systems.service';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { TransactionFormService } from '@app/transactions/components/transaction-form/transaction-form.service';
import { Transaction } from '@app/transactions/transaction';

export class RateDroppedTransactions implements IStrategy {
    public matDialog!: MatDialog;
    public systemsService!: SystemsService;
    public messageAlertQuotationHelper!: MessageAlertQuotationHelper;
    public transactionFormService!: TransactionFormService;
    public transferState!: TransferState;

    public constructor() {
        this.matDialog = ServiceLoader.injector.get(MatDialog);
        this.transactionFormService = ServiceLoader.injector.get(TransactionFormService);
        this.systemsService = ServiceLoader.injector.get(SystemsService);
        this.transferState = ServiceLoader.injector.get(TransferState);
    }

    public execute(): void {
        let transactionAux: Transaction = this.transactionFormService.getTransaction();
        this.messageAlertQuotationHelper.openDialog('La cotización de este pedido ha cambiado.');
        // prevent problem with TransferHttpCacheModule when page loaded
        /* eslint-disable @typescript-eslint/dot-notation */
        Object.keys(this.transferState['store']).forEach((key: string): void => this.transferState.remove(makeStateKey(key)));
        /* eslint-enable @typescript-eslint/dot-notation */

        this.systemsService.all({ include: ['rates'] }).subscribe(() => transactionAux.recalculate());
    }
}
