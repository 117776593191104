import { SystemFeeHelper } from './system-fee.helper';
import { System } from '../resources/system';
import { QuotationHelper, IQuotationWithCurrency, ISystemWithAmount } from './quotation.helper';
import { MarketComparatorHelper } from './market-comparator.helper';

export type FeeWay = 'receive' | 'send';

export interface ICalculatorDetail {
    system: System;
    amount: number;
    amountWithFee: string;
    hasFee: boolean;
    quotation: string;
    quotationCurrency: string;
    quotationCurrencyComparison: string;
    comparisonQuotationMarket: string;
    comparisonQuotationMarketPercent: number;
    tooltipInfo: { [property: string]: number };
}

export interface IDataForCalculator {
    system: System | null;
    auxSystem: System | null;
    amount: number;
    auxAmount: number;
}

export class CalculatorDetailHelper {
    private dataForCalculator: IDataForCalculator = {
        system: new System(),
        auxSystem: new System(),
        amount: 0,
        auxAmount: 0
    };

    public constructor(dataForCalculator: IDataForCalculator) {
        this.dataForCalculator = dataForCalculator;
    }

    public getDetail(feeWay: FeeWay): ICalculatorDetail | null {
        if (!this.dataForCalculator.system) {
            return null;
        }
        const systemFee: SystemFeeHelper = new SystemFeeHelper(this.dataForCalculator.system, this.dataForCalculator.amount);
        let amountWithFee: number = Number(this.dataForCalculator.amount) + systemFee.getFeeBySystem()[feeWay];
        let quotationAndCurrency: IQuotationWithCurrency = this.getQuotation(amountWithFee);

        return {
            system: this.dataForCalculator.system,
            amount: this.dataForCalculator.amount,
            amountWithFee: amountWithFee.toFixed(this.dataForCalculator.system.attributes.decimal_places),
            hasFee: systemFee.getFeeBySystem()[feeWay] !== 0,
            quotation: quotationAndCurrency.quotation,
            quotationCurrency: quotationAndCurrency.currency,
            quotationCurrencyComparison: quotationAndCurrency.currencyComparison,
            comparisonQuotationMarket: this.getMarketComparison(quotationAndCurrency.quotation),
            comparisonQuotationMarketPercent: new MarketComparatorHelper().getMarketComparisonPercent(
                this.dataForCalculator.system,
                this.dataForCalculator.auxSystem ?? new System(),
                Number(quotationAndCurrency.quotation)
            ),
            tooltipInfo: systemFee.getInfoFeeSystem(feeWay)
        };
    }

    private getQuotation(amountWithFee: number): IQuotationWithCurrency {
        if (!this.dataForCalculator.system || !this.dataForCalculator.auxSystem) {
            return {
                currency: '',
                currencyComparison: '',
                quotation: '0.00'
            };
        }

        const quotationHelper: QuotationHelper = new QuotationHelper();

        let systemWithAmount1: ISystemWithAmount = {
            system: this.dataForCalculator.system,
            amount: amountWithFee
        };
        let systemWithAmount2: ISystemWithAmount = {
            system: this.dataForCalculator.auxSystem,
            amount: this.dataForCalculator.auxAmount
        };

        return quotationHelper.getQuotation(systemWithAmount1, systemWithAmount2);
    }

    private getMarketComparison(quotation: string): string {
        if (!this.dataForCalculator.system || !this.dataForCalculator.auxSystem) {
            return '';
        }

        const marketComparator: MarketComparatorHelper = new MarketComparatorHelper();

        return marketComparator.getMarketComparison(this.dataForCalculator.system, this.dataForCalculator.auxSystem, Number(quotation));
    }
}
