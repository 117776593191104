import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IImageSettings } from '@app/core/interfaces/images-settings';
import { IntersectionObserverService } from '@app/core/services/intersection-observer.service';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
    selector: 'app-info-block',
    templateUrl: './info-block.component.html',
    styleUrls: ['./info-block.component.scss']
})
export class InfoBlockComponent implements OnDestroy, AfterViewInit {
    @Input() public content: string = '';
    @Input() public titleBlock: string = '';
    @Input() public titleLazyLoad: string = '';
    @Input() public imageUrl: string = '';
    @Input() public withAnchor: boolean = false;
    @Input() public imageSettings: IImageSettings = {};
    private intersectionSubscription: Subscription = new Subscription();
    private imgSubject: Subject<IntersectionObserverEntry> = new Subject<IntersectionObserverEntry>();
    private img$: Observable<IntersectionObserverEntry> = this.imgSubject.asObservable();
    public notCompatibilityIntersection: boolean = false;
    public urlFake: SafeResourceUrl = '';

    public constructor(private intersectionObserverService: IntersectionObserverService, private domSanitizer: DomSanitizer) {
        this.urlFake = this.domSanitizer.bypassSecurityTrustResourceUrl(
            'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg=='
        );
    }

    public ngAfterViewInit(): void {
        this.intersectionSubscription = this.img$.subscribe((entry): void => {
            if (!entry.target) {
                this.notCompatibilityIntersection = true;

                return;
            }
            let img: HTMLImageElement | null = entry.target.querySelector('img');
            let titleAux: string = this.titleBlock;
            this.titleBlock = this.titleLazyLoad === '' ? this.titleBlock : this.titleLazyLoad;
            if (!img || entry.target.getAttribute('title') !== this.titleBlock) {
                return;
            }
            this.titleBlock = titleAux;
            img.setAttribute('src', this.imageUrl);
        });
        this.intersectionObserverService.observeElements('app-info-block', this.imgSubject);
    }

    public ngOnDestroy(): void {
        this.intersectionSubscription.unsubscribe();
    }
}
