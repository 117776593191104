import { Resource } from 'ngx-jsonapi';

export class ClientValue extends Resource {
    public type: string = 'client_values';
    public attributes: {
        code: string;
        ip: string;
    } = {
        code: '',
        ip: ''
    };
}
