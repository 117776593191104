import { Injectable } from '@angular/core';
import { PlatformBrowserService } from './platform-browser.service';
import { ScriptService } from './script.service';

@Injectable({
    providedIn: 'root'
})
export class AnalyticService {
    public constructor(private browserService: PlatformBrowserService, public scriptService: ScriptService) {}

    public trackCustom(event: string, value: string | number): void {
        if (!this.browserService.isBrowser) {
            return;
        }

        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
            event: event,
            saldoValue: value
        });
    }
}
