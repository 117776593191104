import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { mergeMap, filter, take, switchMap } from 'rxjs/operators';

import { OAuthService } from 'angular-oauth2-oidc';
import { GlobalStateService } from '@app/core/services/global-state.service';
import { environment } from 'environments/environment';
import { PlatformBrowserService } from '@app/core/services/platform-browser.service';
import { pathsSaldo } from 'environments/paths-saldo';

@Injectable()
export class OAuthInterceptor implements HttpInterceptor {
    private refreshing: boolean = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    public constructor(
        private oAuthService: OAuthService,
        private browserService: PlatformBrowserService,
        private globalStateService: GlobalStateService
    ) {
        this.oAuthService.tokenEndpoint = environment.API_URL + pathsSaldo.AUTH_PATH + 'token';
        if (this.browserService.isBrowser) {
            this.oAuthService.setStorage(localStorage);
        }
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url.includes('auth/v1/token') && request.body.updates[4] && environment.env === 'develop') {
            const modifiedBody: URLSearchParams = new URLSearchParams();
            modifiedBody.set('grant_type', 'undefined');
            modifiedBody.set('scope', request.body.updates[1].value);
            modifiedBody.set('client_id', request.body.updates[2].value);
            modifiedBody.set('username', request.body.updates[3].value);
            modifiedBody.set('password', request.body.updates[4].value);

            const modifiedReq: HttpRequest<any> = request.clone({
                setHeaders: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: modifiedBody.toString()
            });

            return next.handle(modifiedReq);
        }

        if (request.url.indexOf('auth/v1/token') === -1 && request.url.indexOf('/v3/users/') > 0) {
            let expiration: number = this.oAuthService.getAccessTokenExpiration();
            let actual_time: number = new Date().getTime();
            let accessTokenLifetime: number = environment.ACCESS_TOKEN_LIFETIME * 1000;

            if (expiration && expiration - actual_time < accessTokenLifetime) {
                if (this.refreshing) {
                    return this.refreshTokenSubject.pipe(
                        filter((result): boolean => result !== null),
                        take(1),
                        switchMap((): Observable<HttpEvent<any>> => next.handle(this.addAuthenticationToken(request)))
                    );
                }

                this.refreshTokenSubject.next(null);
                this.refreshing = true;

                return from(this.oAuthService.refreshToken()).pipe(
                    mergeMap((data: { [key: string]: any }): Observable<HttpEvent<any>> => {
                        localStorage.setItem('token', data.access_token.toString());
                        localStorage.setItem('refresh_token', data.refresh_token.toString());
                        this.globalStateService.setToken(data.access_token);
                        this.refreshTokenSubject.next(data);
                        this.refreshing = false;

                        return next.handle(this.addAuthenticationToken(request));
                    })
                );
            }
        }

        return next.handle(request);
    }

    public addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
        const accessToken: string = this.oAuthService.getAccessToken();

        if (!accessToken) {
            return request;
        }

        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${this.oAuthService.getAccessToken()}`
            }
        });
    }
}
