import { Transaction } from '@app/transactions/transaction';
import { FeeDefault } from './fee-providers/fee-default';
import { FeePayPal } from './fee-providers/fee-paypal';
import { FeeSkrill } from './fee-providers/fee-skrill';
import { FeeNeteller } from './fee-providers/fee-neteller';
import { TransactionPrivate } from '@app/core/resources/transaction-private';

export class FeeProvider {
    private transaction: Transaction | TransactionPrivate = new Transaction();

    public setTransaction(transaction: Transaction | TransactionPrivate): void {
        this.transaction = transaction;
    }

    public getBySystem(way: string, systemNumber: number): FeeDefault {
        if (
            this.transaction.relationships['system' + systemNumber].data.attributes['fee_' + way] >= 0 &&
            this.transaction.relationships['system' + systemNumber].data.attributes['fee_' + way] < 100
        ) {
            return new FeeDefault(this.transaction).setWay(way).setSystemNumber(systemNumber);
        }
        switch (this.transaction.relationships['system' + systemNumber].data.id) {
            case 'palpal':
                return new FeePayPal(this.transaction).setWay(way).setSystemNumber(systemNumber);
            case 'skrill':
                return new FeeSkrill(this.transaction).setWay(way).setSystemNumber(systemNumber);
            case 'neteller':
                return new FeeNeteller(this.transaction).setWay(way).setSystemNumber(systemNumber);
            default:
                return new FeeDefault(this.transaction).setWay(way).setSystemNumber(systemNumber);
        }
    }
}
