import { Pipe, PipeTransform } from '@angular/core';
import { System } from '../resources/system';
import { FormatDecimalsHelper } from '../helpers/format-decimals-helper';

@Pipe({
    name: 'decimalBySystem'
})
export class DecimalBySystemPipe implements PipeTransform {
    public transform(value: number | null, system: System): string {
        return new FormatDecimalsHelper().formatDecimal(value, system.attributes.decimal_places);
    }
}
