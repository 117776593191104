import { Resource } from 'ngx-jsonapi';

export class GeneralInfo extends Resource {
    public type: string = 'generals_info';
    public attributes: {
        quantity: number;
        trust_score: string;
        trust_number_of_reviews: string;
        facebook_number_of_comments: string;
    } = {
        quantity: 0,
        trust_score: '',
        trust_number_of_reviews: '',
        facebook_number_of_comments: ''
    };
}
