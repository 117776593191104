import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicDealCardComponent } from './public-deal-card.component';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { CoreModule } from '@app/core/core.module';
import { PipesModule } from '@app/core/pipes/pipes.module';
import { QuotationPublicDealModule } from '../quotation-public-deal/quotation-public-deal.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [PublicDealCardComponent],
    exports: [PublicDealCardComponent],
    imports: [CommonModule, MatCardModule, CoreModule, PipesModule, TranslateModule, QuotationPublicDealModule]
})
export class PublicDealCardModule {}
