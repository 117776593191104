import { Component, HostListener } from '@angular/core';
import { PlatformBrowserService } from '@app/core/services/platform-browser.service';

@Component({
    selector: 'app-calculator-content-loader',
    templateUrl: './calculator-content-loader.component.html',
    styleUrls: ['./calculator-content-loader.component.scss']
})
export class CalculatorContentLoaderComponent {
    public widthRect: any = '';

    public constructor(private browserService: PlatformBrowserService) {
        this.setWidthRect();
    }

    @HostListener('window:resize', ['$event'])
    public onResize(event: any): void {
        if (event.target.visualViewport.width >= 600) {
            this.widthRect = 220;

            return;
        }

        this.widthRect = '100%';
    }

    private setWidthRect(): void {
        if (!this.browserService.isBrowser || !window.visualViewport) {
            return;
        }

        if (window.visualViewport.width >= 600) {
            this.widthRect = 220;

            return;
        }
        this.widthRect = '100%';
    }
}
