import { Injectable, Renderer2, Inject, RendererFactory2 } from '@angular/core';
import { ScriptStore } from './script.store';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class ScriptService {
    public loaded_scripts: Array<string> = [];
    private scripts: any = {};
    private renderer: Renderer2;

    public constructor(private rendererFactory: RendererFactory2, @Inject(DOCUMENT) private document: Document) {
        ScriptStore.forEach((script: any): void => {
            this.scripts[script.name] = {
                loaded: false,
                src: script.src
            };
        });
        this.renderer = this.rendererFactory.createRenderer(null, null);
    }

    public async createScript(name: string, text: string, type: string): Promise<{ [key: string]: any }> {
        return new Promise<{ [key: string]: any }>((resolve): void => {
            // resolve if already loaded
            if (this.loaded_scripts.indexOf(name) !== -1) {
                resolve({ script: name, loaded: true, status: 'Already Loaded' });
            } else {
                // load script
                let script: any = this.renderer.createElement('script');
                script.type = type;
                script.text = text;
                if (script.readyState) {
                    // IE
                    this.loaded_scripts.push(name);
                    script.onreadystatechange = (): any => {
                        if (script.readyState === 'loaded' || script.readyState === 'complete') {
                            script.onreadystatechange = null;
                            resolve({ script: name, loaded: true, status: 'Loaded' });
                        }
                    };
                } else {
                    // Others
                    script.onload = (): any => {
                        this.loaded_scripts.push(name);
                        resolve({ script: name, loaded: true, status: 'Loaded' });
                    };
                }
                script.onerror = (): void => {
                    resolve({ script: name, loaded: false, status: 'Loaded' });
                };
                this.renderer.appendChild(this.document.head, script);
            }
        });
    }
}
