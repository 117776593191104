import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeeMessageComponent } from './fee-message.component';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { PipesModule } from '@app/core/pipes/pipes.module';
import { SystemIconModule } from '../transaction-form/system-icon/system-icon.module';
import { SystemTrendModule } from '../transaction-calculator/system-select/system-trend/system-trend.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [FeeMessageComponent],
    exports: [FeeMessageComponent],
    imports: [CommonModule, MatSlideToggleModule, PipesModule, SystemIconModule, SystemTrendModule, TranslateModule]
})
export class FeeMessageModule {}
