<div class="data-container flex-row-start-center">
    <div [ngClass]="linePosition === 'down' ? 'line-joins-down' : 'line-joins-up'"></div>
    <div [ngClass]="pointBorder === 'border' ? 'point-line-border' : 'point-line-fill'" class="point-line"></div>
    <div class="flex-row-start-center system-info-container">
        <div *ngIf="details">
            <p class="label-one" *ngIf="showLabelWithoutFee; else withFee" [ngClass]="{'not-fee': !disableLabelOne }"><span class="show-text">{{ accompanying_info[position].shortLabel | translate }}&nbsp;</span> <span>{{ details.amountWithFee }} {{ details.system.attributes.currency}}</span></p>
        </div>
        <div class="icon-tooltip-container" *ngIf="details">
            <div class="more-info-icon show-tooltip"><b>i</b></div>
                <app-tooltip>
                    <p>
                        <b>{{ accompanying_info[position].label | translate }}</b> {{ details.amountWithFee }} {{ details.system.attributes.currency }}
                    </p>
                    <p>
                        <b [innerHTML]="'calculator_commision_info' | translate: {element_one: details.system.attributes.name, element_two: details ? details.tooltipInfo.fee : '',element_three: details ? details.tooltipInfo.additional : '' , element_four: details ? details.system.attributes.currency : '' }"></b>
                    </p>
                    <p class="quotation-tooltip-mobile">
                        <b>Cotización</b> {{ title }}
                    </p>
                </app-tooltip>
            </div>
        </div>
        <div *ngIf="details" class="quotation-container">
            <p [title]="title | translate" class="tree-dot" [ngClass]="{'not-fee': quotationDisabled }"><b> {{ details.quotation }} {{ details.quotationCurrency + ' / ' + details.quotationCurrencyComparison }} </b> {{ details && details.comparisonQuotationMarket ? '(' + details.comparisonQuotationMarket + ')' : '' }}</p>
        </div>
    </div>

<ng-template #withFee>
    <p class="label-one"><span class="show-text tree-dot">{{ accompanying_info[position].shortLabel | translate }}&nbsp;</span><span (click)="feeService.transaction$.next(transaction);" class="rs-link text-fixed-width" [matMenuTriggerFor]="menu">{{ details ? details.amountWithFee : '' }} {{ details ? details.system.attributes.currency : '' }}</span></p>
    <mat-menu class="menu-system-info" #menu="matMenu">
        <app-fee-message (click)="$event.stopPropagation()" [way]="way"></app-fee-message>
    </mat-menu>
</ng-template>
