import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GainPercentComponent } from './gain-percent.component';

@NgModule({
    declarations: [GainPercentComponent],
    exports: [GainPercentComponent],
    imports: [CommonModule]
})
export class GainPercentModule {}
