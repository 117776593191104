import { Injectable } from '@angular/core';
import { Service } from 'ngx-jsonapi';
import { Rate } from '@app/core/resources/rate';

@Injectable({
    providedIn: 'root'
})
export class RatesService extends Service {
    public resource: typeof Rate = Rate;
    public type: string = 'rates';

    public constructor() {
        super();
        this.register();
    }
}
