import { Injectable, NgZone } from '@angular/core';
import {
    MatLegacySnackBar as MatSnackBar,
    MatLegacySnackBarRef as MatSnackBarRef,
    LegacyTextOnlySnackBar as TextOnlySnackBar
} from '@angular/material/legacy-snack-bar';
import { PlatformBrowserService } from '@app/core/services/platform-browser.service';
import { Observable, from, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Messaging, deleteToken, getToken, isSupported, onMessage } from '@angular/fire/messaging';
import { ServiceLoader } from '@app/core/services/service-loader';

@Injectable({
    providedIn: 'root'
})
export class FirebaseMessagingService {
    private messaging!: Messaging;
    public constructor(public snackBar: MatSnackBar, private browserService: PlatformBrowserService, private zone: NgZone) {
        isSupported().then((supported: boolean) => {
            if (!supported) {
                return;
            }
            this.messaging = ServiceLoader.injector.get(Messaging);
        });
    }

    public requestPermission(): Observable<string> {
        if (!this.browserService.isBrowser || !this.messaging) {
            return of('');
        }

        return from(Notification.requestPermission()).pipe(
            mergeMap((permission) => {
                if (permission !== 'granted') {
                    return of('');
                }

                return from(getToken(this.messaging));
            })
        );
    }

    public deleteToken(key: string): Observable<boolean> {
        if (!this.browserService.isBrowser || !this.messaging) {
            return of(false);
        }

        localStorage.removeItem(key);

        return from(deleteToken(this.messaging));
    }

    public listen(): void {
        if (!this.browserService.isBrowser || !this.messaging) {
            return;
        }

        onMessage(this.messaging, {
            next: (payload) => {
                let content: string = payload.notification ? payload.notification.title ?? '' : '';
                content = payload.notification && payload.notification.body ? content + ': ' + payload.notification.body : content;
                this.notificate(content, '');
            },
            error: (error) => console.log('Message error', error),
            complete: () => console.log('Done listening to messages')
        });
    }

    public notificate(notification: string, _action: string): void {
        if (!this.browserService.isBrowser) {
            return;
        }
        this.zone.run((): void => {
            let snackbarRef: MatSnackBarRef<TextOnlySnackBar> = this.snackBar.open(notification, 'Cerrar', {
                duration: this.browserService.isBrowser ? 30 * 1000 : 0,
                verticalPosition: 'top',
                horizontalPosition: 'right',
                panelClass: ['info']
            });
            snackbarRef.onAction().subscribe(() => {
                // window.open(action, '_blank');
            });
        });
    }
}
