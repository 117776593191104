import { Injectable } from '@angular/core';
import { Transaction } from '@app/transactions/transaction';
import { TransactionValidatorsServiceProvider } from '@app/transactions/transaction-validators-provider/transaction-validators-service-provider';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TransactionFormService {
    public transactionUpdatedSource: ReplaySubject<any> = new ReplaySubject(1);
    public transactionUpdated$: Observable<any> = this.transactionUpdatedSource.asObservable();
    public isOpenDialog: boolean = false;
    private transaction: Transaction = new Transaction();
    private transactionValidatorService: TransactionValidatorsServiceProvider = new TransactionValidatorsServiceProvider();

    public setTransaction(transaction: Transaction): void {
        this.transaction = transaction;
    }

    public getTransaction(): Transaction {
        return this.transaction;
    }

    public transactionHasErrors(): boolean {
        return this.transactionValidatorService.getErrors(this.transaction).length === 0 ? true : false;
    }
}
