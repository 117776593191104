import { IStrategy } from '../patrons/strategy';
import { UnsavedChangesDialogComponent } from '@app/dashboard/deals/components/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { DealsBagFillingService } from '@app/dashboard/deals/deals-bag-filling.service';
import { ServiceLoader } from '../services/service-loader';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

export class RateDroppedDeals implements IStrategy {
    public matDialog!: MatDialog;
    private dealsBagService!: DealsBagFillingService;

    public constructor() {
        this.matDialog = ServiceLoader.injector.get(MatDialog);
        this.dealsBagService = ServiceLoader.injector.get(DealsBagFillingService);
    }

    public execute(): void {
        this.dealsBagService.dealsBag.attributes.amount2 = '0';
        this.openDialog('La cotización de esta bolsa de ofertas ha cambiado.');
    }

    public openDialog(message: string): void {
        let dialog: MatDialogRef<UnsavedChangesDialogComponent> = this.matDialog.open(UnsavedChangesDialogComponent, {
            width: '600px',
            autoFocus: false,
            data: {
                text: message,
                updateDeals: 'Aceptar'
            }
        });

        dialog.afterClosed().subscribe((): void => {
            this.dealsBagService.getDealsBagAmount();
        });
    }
}
