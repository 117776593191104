import { Resource, IAttributes, DocumentResource, DocumentCollection } from 'ngx-jsonapi';
import { System } from '@app/core/resources/system';
import { ActivatedRoute } from '@angular/router';
import { Invoice } from '@app/core/resources/invoice';
import { Meta } from '@app/core/resources/meta';
import { State } from '@app/core/resources/state';
import { Validation } from '@app/core/resources/validation';
import { DirectTransfer } from '@app/core/resources/direct-transfer';
import { ExtraTransactionInfo } from '@app/core/resources/extra-transaction-info';
import { DecimalSystemHelper } from '@app/core/helpers/decimal-system-helper';
import TransactionAmountCalculator from './transaction-amount-calculator';

export interface ITransactionsAttributes extends IAttributes {
    __operation: null | 'send' | 'receive';
    name: string;
    email: string;
    mid: string;
    key: string;
    phone_number: string;
    system1: string;
    amount1: number;
    account_address1: string;
    system2: string;
    amount2: number;
    account_address2: string;
    instructions_url: string;
    marked_as_sent: boolean;
    transaction_temporal_values_id: string;
    public_observation: string;
    canceled_at: string;
    source: string;
    state: number;
    referral_id?: string;
    agreement1_id: number;
    account_network1: string;
    account_network2: string;
}

export class Transaction extends Resource {
    public type: string = 'transactions';
    public attributes: ITransactionsAttributes = {
        __operation: null,
        name: '',
        email: '',
        mid: '',
        key: '',
        phone_number: '',
        system1: '',
        amount1: 0,
        account_address1: '',
        system2: '',
        amount2: 0,
        account_address2: '',
        source: '',
        instructions_url: '',
        marked_as_sent: false,
        state: 0,
        transaction_temporal_values_id: '',
        public_observation: '',
        agreement1_id: 0,
        canceled_at: '',
        account_network1: '',
        account_network2: ''
    };

    public relationships: {
        states: DocumentCollection<State>;
        system1: DocumentResource<System>;
        system2: DocumentResource<System>;
        invoice: DocumentResource<Invoice>;
        direct_transfers1: DocumentCollection<DirectTransfer>;
        direct_transfers2: DocumentCollection<DirectTransfer>;
        metas: DocumentCollection<Meta>;
        extra_transaction_info: DocumentCollection<ExtraTransactionInfo>;
        validations: DocumentCollection<Validation>;
    } = {
        states: new DocumentCollection<State>(),
        system1: new DocumentResource<System>(),
        system2: new DocumentResource<System>(),
        invoice: new DocumentResource<Invoice>(),
        direct_transfers1: new DocumentCollection<DirectTransfer>(),
        direct_transfers2: new DocumentCollection<DirectTransfer>(),
        metas: new DocumentCollection<Meta>(),
        extra_transaction_info: new DocumentCollection<ExtraTransactionInfo>(),
        validations: new DocumentCollection<Validation>()
    };

    private transctionAmountCalculator: TransactionAmountCalculator = new TransactionAmountCalculator();

    private internal_attributes: {
        recomendation: string;
    } = {
        recomendation: ''
    };

    /** @deprecated */
    public setOperation(params: any): void {
        this.attributes.__operation = params.amount1 === '0' ? 'send' : 'receive';
    }

    public setWay(way: 'send' | 'receive'): void {
        this.attributes.__operation = way;
    }

    /** @deprecated */
    public getOperation(): 'send' | 'receive' {
        return this.attributes.__operation === null ? 'receive' : this.attributes.__operation;
    }

    public setRecomendation(recomendation: string): void {
        this.internal_attributes.recomendation = recomendation;
    }

    public getRecomendation(): string {
        return this.internal_attributes.recomendation;
    }

    public calculateSendAmount(sendSystem: System, receiveSystem: System, receiveAmount: number): void {
        this.attributes.amount1 = this.transctionAmountCalculator.getSendAmount(sendSystem, receiveSystem, receiveAmount);
        this.attributes.amount2 = receiveAmount;
        this.attributes.__operation = 'send';
    }

    public calculateReceiveAmount(sendSystem: System, receiveSystem: System, sendAmount: number): void {
        this.attributes.amount1 = sendAmount;
        this.attributes.amount2 = this.transctionAmountCalculator.getReceiveAmount(receiveSystem, sendSystem, sendAmount);
        this.attributes.__operation = 'receive';
    }

    public updateWithParams(sendSystem: System, receiveSystem: System, params: any): void {
        if (params.amount2 === 0 && sendSystem.attributes.decimal_places === 0) {
            params.amount1 = new DecimalSystemHelper().parseAmountByDecimal(sendSystem, params.amount1);
        }
        if (params.amount1 === '0' && receiveSystem.attributes.decimal_places === 0) {
            params.amount2 = new DecimalSystemHelper().parseAmountByDecimal(receiveSystem, params.amount2);
        }
        this.attributes.system1 = sendSystem.id;
        this.attributes.system2 = receiveSystem.id;
        this.addRelationship(sendSystem, 'system1');
        this.addRelationship(receiveSystem, 'system2');
        this.setOperation(params);
        if (this.attributes.__operation === 'send') {
            this.attributes.amount2 = params.amount2;
        }
        if (this.attributes.__operation === 'receive') {
            this.attributes.amount1 = params.amount1;
        }
    }

    public calculate(sendSystem: System, receiveSystem: System, operation: 'send' | 'receive', amount: number): void {
        if (this.systemsAreEquals()) {
            return;
        }

        // calc send amount if operation is send
        // calc receive amount if operation is receive
        if (operation === 'send') {
            this.attributes.amount2 = amount;
            this.calculateSendAmount(sendSystem, receiveSystem, amount);
        } else if (operation === 'receive') {
            this.attributes.amount1 = amount;
            this.calculateReceiveAmount(sendSystem, receiveSystem, amount);
        }
    }

    public recalculate(): void {
        let system1: System | null | undefined = this.relationships.system1.data;
        let system2: System | null | undefined = this.relationships.system2.data;
        if (!system1 || !system2) {
            return;
        }

        this.transctionAmountCalculator.setNetworkId(this.attributes.account_network2);

        if (this.getOperation() === 'send') {
            this.attributes.amount1 = this.transctionAmountCalculator.getSendAmount(system1, system2, this.attributes.amount2);
        } else if (this.getOperation() === 'receive') {
            this.attributes.amount2 = this.transctionAmountCalculator.getReceiveAmount(system2, system1, this.attributes.amount1);
        }
    }

    public attributesAreSameInUrl(activatedRoute: ActivatedRoute): boolean {
        return (
            this.attributes.system1 === activatedRoute.snapshot.params.system1 &&
            this.attributes.system2 === activatedRoute.snapshot.params.system2 &&
            this.attributes.amount1 === activatedRoute.snapshot.params.amount1 &&
            this.attributes.amount2 === activatedRoute.snapshot.params.amount2
        );
    }

    private systemsAreEquals(): boolean {
        if (this.attributes.system1 !== this.attributes.system2) {
            return false;
        }

        this.attributes.amount2 = 0;
        this.setRecomendation('Elija otro método de intercambio.');

        return true;
    }
}
