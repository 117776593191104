import { TransactionUrlService } from './../transactions/transaction-url.service';
import { Component, HostListener, OnInit, OnDestroy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Transaction } from '@app/transactions/transaction';
import { Observable, Subscription } from 'rxjs';
import { DocumentCollection } from 'ngx-jsonapi';
import { System } from '@app/core/resources/system';
import { TransactionObserverService } from '@app/transactions/services/transaction-observer.service';
import { debounceTime, filter } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Params, PRIMARY_OUTLET, Router, UrlSegment, UrlSegmentGroup, UrlTree } from '@angular/router';
import { ReferralsService } from '@app/core/services/referrals.service';
import { HeaderService } from '@app/core/services/header-service';
import { CurrenciesService } from '@app/core/services/currencies.service';
import { SystemsService } from '@app/core/services/systems.service';
import { IMessageError } from '@app/transactions/transaction-validators-provider/transaction-validators/transaction-validators';
import { SourceService } from '@app/core/services/source.service';
import { TransactionFormService } from '@app/transactions/components/transaction-form/transaction-form.service';
import { CurrencyPiorityService } from '@app/core/services/currency-piority.service';
import { ReplaceSystemHelper } from '@app/core/helpers/replace-system.helper';
import { UrlUpdateService } from '@app/core/services/url-update.service';
import { PlatformBrowserService } from '@app/core/services/platform-browser.service';
import { TranslateService } from '@ngx-translate/core';
import { LenguagesHelper, supportedLenguage } from '@app/core/helpers/lenguages.helper';
import { UserCountryService } from '@app/core/services/user-country.service';
import { TranslateSectionService } from '@app/core/services/translate-section.service';
import { SystemsUpdatedService } from '@app/transactions/services/systems-updated.service';
import { NetworkService } from '@app/core/services/network.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
    public transaction: Transaction = new Transaction();
    public systems$: Observable<DocumentCollection<System>> = new Observable();
    public transactionSubscription: Subscription;
    public systems: DocumentCollection<System> = new DocumentCollection();
    // @TODO Use transaction relationships
    public sendSystem: System = new System();
    public receiveSystem: System = new System();
    public firstTitleMobile: boolean = false;
    public isMobile: boolean = false;
    public path: string = '';
    public recomendation: string = '';
    public validatorErrors: Array<IMessageError> = [];
    public step: 'a' | 'b' = 'a';
    public isExit: boolean = false;
    private system1_param: string = '';
    private system2_param: string = '';
    private routerEventUnsubscription: Subscription = new Subscription();
    private amountParams: Params = {
        amount1: 0,
        amount2: 0
    };
    private lenguagesHelper: LenguagesHelper = new LenguagesHelper();

    public constructor(
        private router: Router,
        private referralsService: ReferralsService,
        private sourceService: SourceService,
        private userCountryService: UserCountryService,
        private activatedRoute: ActivatedRoute,
        private translateSectionService: TranslateSectionService,
        private systemsService: SystemsService,
        private currencyPriorityService: CurrencyPiorityService,
        private transactionFormService: TransactionFormService,
        private readonly translate: TranslateService,
        private currenciesService: CurrenciesService,
        private urlUpdateService: UrlUpdateService,
        private browserService: PlatformBrowserService,
        private changeDetectorRef: ChangeDetectorRef,
        private systemsUpdatedService: SystemsUpdatedService,
        public networkService: NetworkService,
        private headerService: HeaderService
    ) {
        this.activatedRoute.queryParams.subscribe((queryParams): void => {
            this.sourceService.listenParams(queryParams);
        });
        this.router.navigateByUrl(this.referralsService.extractReferralIdFromUrlParams(this.router.url));
        this.transactionSubscription = new TransactionObserverService(this.transaction)
            .observeTransaction()
            .pipe(
                filter((change): boolean => ['amount1', 'amount2', 'system1', 'system2'].includes(change.attribute_name)),
                debounceTime(100)
            )
            .subscribe((): void => {
                this.sendSystem = this.getSystemById(this.transaction.attributes.system1);
                this.receiveSystem = this.getSystemById(this.transaction.attributes.system2);
                this.path = TransactionUrlService.getUrl(this.transaction, 'a');
            });
        this.activatedRoute.params.subscribe((params): void => {
            this.step = params.step ?? 'a';
        });
        this.routerEventUnsubscription = this.router.events.subscribe((navigation: any) => {
            if (navigation instanceof NavigationEnd) {
                this.userCountryService.getCountry().subscribe((lang): void => {
                    this.userCountryService.redirectByLang(this.router.url, lang.code);
                    this.translateSectionService.translate(lang.code, 'landing', this.translate);
                });
                this.path = this.router.url;
                this.fillTransactionByParams();
            }
        });

        // this.firstTitleMobile = this.activatedRoute.snapshot.queryParamMap['params'].special_offer ? true : false;
        this.currenciesService.register(); // required on ssr
    }

    private getSystemById(systemId: string): System {
        let system: System | null = this.systems.find(systemId);
        if (system === null) {
            return new System();
        }

        return system;
    }

    public ngOnInit(): void {
        if (!this.browserService.isBrowser) {
            return;
        }
        this.systems$ = this.systemsService.allIncludeRates();
        this.systems$.subscribe((systems: DocumentCollection<System>): void => {
            this.systems = systems;
            this.fillTransactionByParams();
        });
    }

    public ngAfterViewInit(): void {
        if (!this.browserService.isBrowser) {
            return;
        }
        this.checkSizeScreen(window.innerWidth);
    }

    private extractSystemIdsAndAmountByUrl(): void {
        let url: string = this.path === '' ? this.router.url : this.path;
        let tree: UrlTree = this.router.parseUrl(url);

        let urlSegmentgroup: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
        if (!urlSegmentgroup) {
            return;
        }

        let urlSegment: Array<UrlSegment> = urlSegmentgroup.segments;
        if (urlSegment.length <= 1) {
            return;
        }
        if (supportedLenguage.includes(urlSegment[0].path)) {
            this.system1_param = urlSegment[2]?.path;
            this.system2_param = urlSegment[3]?.path;
            this.amountParams.amount1 = urlSegment[4] ? urlSegment[4].path : 0;
            this.amountParams.amount2 = urlSegment[5] ? urlSegment[5].path : 0;

            return;
        }
        this.system1_param = urlSegment[1]?.path;
        this.system2_param = urlSegment[2]?.path;
        this.amountParams.amount1 = urlSegment[3] ? urlSegment[3].path : 0;
        this.amountParams.amount2 = urlSegment[4] ? urlSegment[4].path : 0;
    }

    private fillTransactionByParams(): void {
        this.setParamsIfUrlContainPound();
        this.extractSystemIdsAndAmountByUrl();

        if (!this.system1_param || !this.system2_param) {
            return;
        }

        let [system1_param, system2_param] = this.extractSystemsByParams();

        this.sendSystem = system1_param;
        this.receiveSystem = system2_param;

        if (this.sendSystem.id === '' || this.receiveSystem.id === '') {
            return;
        }

        if (this.sendSystem && this.receiveSystem) {
            this.systemsUpdatedService.emitSystemsUpdatedEvent(this.sendSystem, this.receiveSystem);
            this.setTransactionAttributesWithParams(this.sendSystem, this.receiveSystem, this.amountParams);
            this.headerService.updateaSeoAndLanguageSettings(
                this.step,
                this.sendSystem,
                this.receiveSystem,
                this.lenguagesHelper.extractLenguageFromPath(this.router.url)
            );
            this.verifyIfReplacementSystem(this.sendSystem, this.receiveSystem);
        }
    }

    private setParamsIfUrlContainPound(): void {
        let matches: RegExpMatchArray | null = this.router.url.match(/(#(.*))$/);
        if (!matches) {
            return;
        }
        if (this.browserService.isBrowser) {
            this.path = matches[2];
            window.history.replaceState({}, '', matches[2]);
        }
    }

    private extractSystemsByParams(): any {
        let replaceSystemHelper: ReplaceSystemHelper = new ReplaceSystemHelper(this.systems);
        let system1_param: any = replaceSystemHelper.getSystemOrReplacementSystem(this.system1_param);
        let system2_param: any = replaceSystemHelper.getSystemOrReplacementSystem(this.system2_param);

        return [system1_param, system2_param];
    }

    private verifyIfReplacementSystem(system1: System, system2: System): void {
        if (system1.id === this.system1_param && system2.id === this.system2_param) {
            return;
        }

        this.urlUpdateService.updateUrlWithTransactionData(this.transaction, this.path);
    }

    public ngOnDestroy(): void {
        this.transactionSubscription.unsubscribe();
        this.routerEventUnsubscription.unsubscribe();
    }

    private setTransactionAttributesWithParams(sendSystem: System, receiveSystem: System, params: Params): void {
        if (this.setTransactionIfExistAmount(sendSystem, receiveSystem, params)) {
            return;
        }

        this.setTransactionNotExistAmount(sendSystem, receiveSystem, params);
    }

    private setTransactionIfExistAmount(sendSystem: System, receiveSystem: System, params: Params): boolean {
        if (params.amount1 || params.amount2) {
            this.transaction.updateWithParams(sendSystem, receiveSystem, params);
            this.transaction.recalculate();

            return true;
        }

        return false;
    }

    private setTransactionNotExistAmount(sendSystem: System, receiveSystem: System, params: Params): void {
        if (params.amount1 || params.amount2) {
            return;
        }

        let paramsAux: Params = {
            amount1: 0,
            amount2: 0
        };

        this.currencyPriorityService.callbacksAndBreakByCurrency(
            sendSystem,
            receiveSystem,
            (amount): void => {
                paramsAux.amount1 = amount;
                this.transaction.attributes.__operation = 'receive';
            },
            (amount): void => {
                paramsAux.amount2 = amount;
                this.transaction.attributes.__operation = 'send';
            }
        );

        this.transaction.updateWithParams(sendSystem, receiveSystem, paramsAux);
        this.transaction.recalculate();
        this.changeDetectorRef.detectChanges();
    }

    public changeIsExit(isExit: boolean): void {
        this.isExit = isExit;
    }

    @HostListener('window:popstate', ['$event'])
    public onPopState(event: PopStateEvent): void {
        event.preventDefault();
        if (this.transactionFormService.isOpenDialog) {
            this.transactionFormService.transactionUpdatedSource.next({ title: 'close' });

            return;
        }

        if (this.path.includes('/b/')) {
            if (this.transaction.getOperation() === 'send') {
                this.path =
                    '/a/' +
                    this.transaction.attributes.system1 +
                    '/' +
                    this.transaction.attributes.system2 +
                    '/0/' +
                    this.transaction.attributes.amount2;
            } else {
                this.path =
                    '/a/' +
                    this.transaction.attributes.system1 +
                    '/' +
                    this.transaction.attributes.system2 +
                    '/' +
                    this.transaction.attributes.amount1;
            }
        }
    }

    @HostListener('window:resize', ['$event'])
    public checkSizeMobile(event: any): void {
        this.checkSizeScreen(event.target.innerWidth);
    }

    public checkSizeScreen(width: number): void {
        if (width < 979) {
            this.isMobile = true;
            this.changeDetectorRef.detectChanges();

            return;
        }
        this.isMobile = false;
    }

    public updateaSeoAndLanguageSettings(): void {
        if (this.sendSystem && this.receiveSystem) {
            this.headerService.updateaSeoAndLanguageSettings(
                this.step,
                this.sendSystem,
                this.receiveSystem,
                this.lenguagesHelper.extractLenguageFromPath(this.router.url)
            );
        }
    }

    public validatorErrorsUpdate(validatorErrors: Array<IMessageError>): void {
        this.validatorErrors = validatorErrors;
    }

    public updateUrl(path: string): void {
        this.path = path;
    }

    public isLanding(): boolean {
        return this.step === 'a';
    }
}
