import { PRIMARY_OUTLET, Router, UrlSegmentGroup, UrlTree } from '@angular/router';
import { listLenguages } from '@app/list-lenguages';
import { ServiceLoader } from '../services/service-loader';

export const supportedLenguage: Array<string> = ['es-AR', 'es-BO', 'es-VE', 'es-MX', 'es-CO', 'en-US', 'es-US'];
export const supportedLenguageV2: any = {
    AR: '',
    BO: 'es-BO',
    VE: 'es-VE',
    MX: 'es-MX',
    CO: 'es-CO',
    US: 'en-US',
    IN: 'es-US'
};

export class LenguagesHelper {
    private router: Router;
    public static readonly DEFAULT_LANG: string = '';

    public constructor() {
        this.router = ServiceLoader.injector.get(Router);
    }

    public getLangBySection(path: string, section: string): { [key: string]: string } {
        let lang: string = this.extractLenguageFromPath(path);

        return this.getTranslateByLang(lang, section);
    }

    public getTranslateByLang(lang: string, section: string): { [key: string]: string } {
        if (lang === '') {
            return listLenguages[section + '-es-AR'];
        }

        if (!Object.keys(listLenguages).includes(section + '-' + lang)) {
            return listLenguages[section + '-es-AR'];
        }

        return listLenguages[section + '-' + lang];
    }

    public extractLenguageFromPath(path: string): string {
        let tree: UrlTree = this.router.parseUrl(path);

        let urlSegmentgroup: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
        if (!urlSegmentgroup) {
            return '';
        }

        if (!supportedLenguage.includes(urlSegmentgroup.segments[0].path)) {
            return '';
        }

        return urlSegmentgroup.segments[0].path;
    }
}
