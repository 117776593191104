export const landing_es_AR: any = {
    title: 'Convierte y envía dinero digital en 3 simples pasos',
    subtitle: 'Es muy simple',
    step_one: 'Cotizas y haces tu pedido.',
    step_two: 'Recibes las instrucciones para hacer tu envío.',
    step_three: 'Te enviamos el saldo cotizado.',

    calculator_label_one: 'Envías {{element}}',
    calculator_label_two: 'Recibes {{element}}',

    calculator_label_one_search: 'Indica un método de intercambio',
    calculator_label_two_search: 'Indica un método de intercambio',

    same_crypto_selected_error: 'Elija otro método de intercambio',

    calculator_commision_info_you_need: 'Necesitas',
    calculator_commision_info_you_need_advanced_p_one: 'Necesitas {{ element }} ',

    calculator_commision_info_seller_get: 'Llega',
    calculator_commision_info_seller_get_advanced_p_one: 'Llega neto a destino {{ element }}',

    calculator_commision_info_the_tolal: 'Total',
    calculator_commision_info_the_tolal_advanced_p_one: 'Costo total del envío de {{ element }}',

    calculator_commision_info_you_get: 'Recibes neto',
    calculator_commision_info_you_get_advanced_p_one: 'Costo total de envío {{ element }}',

    calculator_commision_info:
        'Comisión de {{ element_one }} es {{ element_two }}% más un adicional fijo de {{ element_three }} centavos de {{ element_four }}',

    calculator_exactly_amount: 'Recibirás exactamente <b>{{element_one}} {{element_two}} por red {{element_three}}</b>',

    calculator_exactly_amount_info: 'Sin importar el exchange o billetera que uses, <b>Saldo se hará cargo de la comisión de la red.</b>',

    next_button: 'Siguiente',
    contact_title: 'Contáctanos',

    last_10d_operations: 'Operaciones en los últimos 10 días',

    buy_usd_ars: 'Compra',
    sell_usd_ars: 'Venta',

    buy_eur_ars: 'Compra',
    sell_eur_ars: 'Venta',

    trustpilot_opinions_title: '+600 reseñas en ',

    public_dials: 'Atrapa ofertas y paga hasta 5% menos.',
    public_dials_you_send: 'Envías',
    public_dials_we_send: 'Te enviamos',
    public_dials_level_valid: '*Válido sólo para usuarios nivel 3, 4 y 5. Puedes obtener',
    public_dials_level_valid_deals: '*Válido sólo para usuarios nivel 3, 4 y 5.',
    public_dials_level_valid_info_button: 'más información aquí.',
    public_dials_view_offers_button: 'Ver más',

    public_dials_view_offers_button_save: '¡Ahorra {{ element }}%!',
    public_deals_view_offers_would_send: 'Te enviaríamos',
    public_dials_view_offers_button_save_br_one: `Te enviamos {{ element_one }} {{element_two }} cada <span class="text-disable">{{ element_three }}</span> {{ element_four }} {{ element_five }}`,
    public_dials_view_offers_button_save_br_one_way1: `Por cada {{ element_one }}, te enviamos <span class="text-disable">{{element_two }}</span> {{ element_three }} {{ element_four }}`,
    public_dials_view_offers_button_save_br_two: 'Son {{element_one}} {{element_two}} más atrapando esta oferta',
    public_dials_view_offers_button_close: ' Cerrar',
    change_usd_or_eur_to_COP: 'Cambia USD y EUR por COP',
    change_usd_or_eur_to_ARS: 'Cambia USD y EUR por ARS',
    change_usd_or_eur_to_USD: 'Cambia USD y EUR por USD',
    change_usd_or_eur_to_VES: 'Cambia USD y EUR por VES',
    change_usd_or_eur_to_MXN: 'Cambia USD y EUR por MXN',
    exchange_eur: 'Cotiza Euros a',
    change_usd_or_eur_to_ars_info_text:
        '<p>Con Saldo puedes comprar, vender e intercambiar dólares y euros de cuentas bancarias del exterior, usando plataformas como PayPal, Skrill, Neteller, Transferencia Bancaria en Euros, Perfect Money, entre otros.</p>',
    change_button: 'Cotizar Euros a Pesos Argentinos',

    want_to_buy_paypal: '¿Quieres recargar tu cuenta PayPal?',
    want_to_buy_paypal_info_text:
        '<p>Saldo te ofrece más de 20 métodos de intercambio para que accedas al mundo cripto, de manera fácil y segura.</p>',
    buy_paypal_button: 'Cotizar PayPal a Cuenta Bancaria',

    VCC_paypal: 'Ventajas de una tarjeta en tu cuenta PayPal',
    VCC_paypal_text_info_p_one:
        '<p>Te ofrecemos y enviamos por correo una tarjeta virtual (VCC) para que la cargues y verifiques tu cuenta. Tiene una vigencia de 3 a 5 años.</p><p>Tendrás un mayor límite de compras en línea, además podrás recibir montos más altos de dinero que una cuenta no verificada.</p>',
    VCC_paypal_button: 'Comprar Tarjeta VCC',

    trustpilot_opinions: 'Lo que dicen de nosotros',
    trustpilot_opinions_sub: 'Nuestros clientes dicen <b> Excelente {{ element }}/5',

    contact: 'Contáctanos',
    contact_subtitle: '¿Tienes alguna duda o sugerencia? Estamos para ayudarte',
    contact_name: 'Nombre',
    contact_email: 'Correo electrónico',
    contact_order_number: 'Numero de pedido (opcional)',
    contact_message: 'Mensaje',
    contact_send_ticket_button: 'Enviar',
    contact_sent_warning: 'Mensaje enviado exitosamente.',

    warning_message_bitcoin_recibe: 'Recibes por la red Bitcoin o BEP20',
    warning_message_bitcoin_send: 'Puedes enviar Bitcoins por las redes Bitcoin o Binance Smart Chain (BEP20).',

    warning_message_dai_recibe: 'Recibes DAI por red Ethereum (ERC20).',
    warning_message_dai_send: 'Puedes enviar DAI por las redes Ethereum (ERC20) o Binance Smart Chain (BEP20).',

    warning_message_tether_recibe: 'Recibes USDT por red Binance Smart Chain (BEP20) o Tron (TRC20)',
    warning_message_tether_send: 'Puedes enviar USDT por las redes Ethereum (ERC20), Tron (TRC20) o Binance Smart Chain (BEP20).',

    warning_message_commission:
        'Recuerda que {{ element }} te cobrará una comisión de {{ element_one }} {{element_two}}, por lo tanto recibirás un neto de {{ element_three }} {{element_four}}.',
    warning_message_commission_button_on_off: 'Deseo recibir un neto de',
    warning_message_zinli_recibe:
        'Antes de realizar el pedido, revisa los <a href="https://blog.saldo.com.ar/zinli-ahora-en-saldo/#limitaciones" target="_blank" title="¿Qué es Zinli y cómo funciona?" class="rs-link">límites de tu cuenta Zinli:</a><br>- Zinli Standard (registro con pasaporte): hasta 1000 USD mensuales.<br>- Zinli Lite (registro con cédula): hasta 500 USD mensuales.',

    warning_message_skill_recibe:
        'Los pagos se realizan en USD. Procura que tu cuenta se encuentre configurada para recibir en dicha divisa. En caso de tener alguna duda puedes visitar nuestro <a href="https://blog.saldo.com.ar/abrir-una-cuenta-skrill-en-usd/" target="_blank" title="¿Cómo Abrir una Cuenta de Skrill en Dólares?" class="rs-link">blog</a>.',
    warning_message_skill_button_blog_recibe: 'blog',

    warning_message_neteller_recibe:
        'Los pagos se realizan en USD. Procura que tu cuenta se encuentre configurada para recibir en dicha divisa. En caso de tener alguna duda puedes visitar nuestro <a href="https://blog.saldo.com.ar/fondear-neteller-con-baja-comision-una-guia-completa-con-saldoar/" target="_blank" title="Fondear Neteller con Saldoar" class="rs-link">blog</a>.',
    warning_message_neteller_button_blog_recibe: 'blog',

    warning_message_skill_send:
        'Enviarás {{ element_one }} {{ element_three }} y {{ element }} te cobrará una comisión de {{ element_five }} {{ element_three }} si eres True Skriller (verificado). Si tienes otro tipo de cuenta, como Standard, podrías pagar más comisiones',

    warning_message_neteller_send:
        'Enviarás {{ element_one }} {{ element_three }} y {{ element }} te cobrará una comisión de {{ element_five }} {{ element_three }} si eres Neteller True (verificado). Si tienes otro tipo de cuenta, como Standard, podrías pagar más comisiones',

    steps_to_complete_your_order_step_one: 'Completa tu pedido',
    step_one_first_string_info: 'Nombre y Apellido',
    step_one_second_string_info: 'Correo electrónico',
    step_one_third_string_info: 'Número de WhatsApp',
    step_one_fourth_string_info_network: 'Red',
    place_an_order: 'Iniciar pedido',
    cancel: 'Cancelar',

    steps_to_complete_your_order_step_two: 'Sigues las instrucciones',
    steps_to_complete_your_order_step_three: 'Enviamos tu saldo',

    step_one_first_string_info_ar_transfer: 'CBU/CVU/Alias para {{ element }}',
    step_one_second_string_info_ar_transfer_example: 'Por ejemplo: 2850590940090418135201 o alias.ejemplo.saldo',
    step_one_third_string_info_ar_transfer_incorrect:
        'CBU/CVU/Alias incorrecto, debe ser por ejemplo: 2850590940090418135201 o alias.ejemplo.saldo',

    step_one_first_string_info_email_transfer: 'Correo electrónico de tu cuenta de {{ element }}',
    step_one_second_string_info_email_example: 'Por ejemplo: juan@dominio.com',
    step_one_third_string_info_email_incorrect: 'Correo electrónico incorrecto, debe ser por ejemplo: juan@dominio.com',
    step_one_fourd_string_info_email_raquered: 'Este campo es requerido',

    step_one_first_string_info_account_number: 'Cuenta de {{ element }}',
    step_one_second_string_info_account_number_example: 'Por ejemplo: P9050280',
    step_one_third_string_info_account_number_incorrect: 'Cuenta incorrecta, debe ser por ejemplo: P9050280',

    step_one_alert_string_skrill:
        'Los fondos de mi cuenta Skrill no provienen de tarjetas de débito/crédito ni de cuentas que han sido fondeadas con tal método.',
    step_one_alert_string_payoneer: 'Ya he recibido pagos previamente en mi cuenta Payoneer y ya he recibido/enviado pagos sin problemas.',
    step_one_alert_string_dai:
        'Confirmo que la dirección ingresada corresponde a la red Ethereum (ERC20) y no a Binance Smart Chain (BSC).',
    step_one_alert_string_nequi: 'Entiendo que al enviar mi pago, en el mensaje de consignación, voy a colocar solamente “PAGO”',
    step_one_alert_string_palpal:
        'Entiendo que PayPal me cobrará una comisión de {{ element_one }} USD al recibir el pago, recibiendo un neto de {{ element_two }} USD',
    meta_keywords: 'saldo, online, cambia saldo, saldo argentina, comprar dólares',
    'meta_keywordses-VE': 'saldo, online, cambia saldo, saldo venezuela, comprar dólares',
    'meta_keywordses-CO': 'saldo, online, cambia saldo, saldo colombia, comprar dólares',
    'meta_keywordses-MX': 'saldo, online, cambia saldo, saldo méxico, comprar dólares',
    'meta_keywordses-BO':
        'pesos bolivianos a dólares, cuantos pesos bolivianos son un dólar, convertir pesos bolivianos a dólares, paypal a wise, comprar bitcoin con paypal, transferencias bancarias bolivia, enviar dinero a bolivia, recibir dinero en bolivia, criptomonedas bolivia, dólares a pesos bolivianos, 10 dólares a pesos bolivianos',
    meta_keywords_update:
        'cambiá {{ element_one }}, cambiá {{ element_two }}, saldo, online, cambia saldo, saldo argentina, comprar dólares',
    meta_add_header_default_title: 'Cambiar de dólares a pesos fácil y seguro',
    'meta_add_header_default_titlees-US': 'Cambia dólares a tu moneda local',
    'meta_add_header_default_titlees-VE': 'Cambiar de dólares a Bolívares fácil y seguro',
    'meta_add_header_default_titlees-CO': 'Cambiar de dólares a Pesos Colombianos',
    'meta_add_header_default_titlees-MX': 'Cambiar de dólares a pesos mexicanos',
    'meta_add_header_default_titlees-BO': 'Cambiar de dólares a pesos Bolivianos',
    meta_add_header_default_description:
        'Recargar saldo PayPal en Argentina de manera fácil y segura es posible. Compra dólares o vende tu saldo PayPal directo a mercadopago. Contamos con más de 20 métodos de intercambio ¡Aprovecha nuestras bajas comisiones!',
    'meta_add_header_default_descriptiones-US':
        'Comprar, vender e intercambiar saldo en tu moneda local, usando plataformas como PayPal, Skrill, Neteller, Transferencia Bancaria en Euros, Perfect Money, entre otros. ¡Aprovecha nuestras bajas comisiones!',
    'meta_add_header_default_descriptiones-VE':
        'Recargar saldo PayPal en Venezuela de manera fácil y segura es posible. Compra dólares o vende tu saldo PayPal a bolívares. Contamos con más de 20 métodos de intercambio ¡Aprovecha nuestras bajas comisiones!',
    'meta_add_header_default_descriptiones-CO':
        'Recargar saldo PayPal en Colombia de manera fácil y segura es posible. Compra dólares o vende tu saldo PayPal directo a tu cuenta bancaria. Contamos con más de 20 métodos de intercambio ¡Aprovecha nuestras bajas comisiones!',
    'meta_add_header_default_descriptiones-MX':
        'Recargar saldo PayPal en México de manera fácil y segura es posible. Compra dólares o vende tu saldo PayPal directo a tu cuenta bancaria. Contamos con más de 20 métodos de intercambio ¡Aprovecha nuestras bajas comisiones!',
    'meta_add_header_default_descriptiones-BO':
        'Transfiere, retira, intercambia o convierte de Payoneer, Skrill, PayPal y criptomonedas a tu moneda local de forma fácil y segura con Saldo! Aprende cómo retirar tus fondos en pesos bolivianos y obtener los mejores tipos de cambio ¡Opera ahora y comienza a cobrar a la mejor cotización!',
    meta_add_header_update_description:
        'Cambiá saldo {{ element_one }} a {{ element_two }} online de la forma más rápida. Compra, intercambio y venta de saldo PayPal, Skrill, Neteller. Depósitos con RapiPago, PagoFácil, MercadoPago, Transferencia bancaria y otros.',

    meta_set_og_default_title: 'Saldoar | Intercambia tu dinero virtual en 3 pasos.',
    meta_set_og_update_title: 'Cambia tu saldo {{ element_one }} a {{ element_two }}',
    meta_set_og_default_description:
        'Elige entre más de 20 métodos de intercambio diferentes: Transferencia Bancaria, PayPal, Mercado Pago, Skrill, Ualá, Wise...',

    meta_exchange_display: 'Pasar {{ element_one }} a {{ element_two }}',
    insted_of: 'En vez de',

    meta_description_exchange: 'online de la forma más rápida.'
};
