export const why_choose_en_US: any = {
    flayer_saldo:
        '"Saldoar was created to solve the problems that Argentines have when they operate at different levels: imports, collection of products or savings.',

    landing_social_title: '90% of our customers recommend Saldoar',

    landing_social_p: `Users love trading with us: They say the platform is safe and easy to use.
        Our latest survey of more than 15,000 users indicates that 9 out of 10 people recommended the platform to a friend so they can trade their digital money.`,

    landing_social_title_two: 'With us, you and your money will always be safe.',

    landing_social_p_two:
        'We have a customer support team that will be with you throughout the whole process, so that if you feel dissatisfied, unsure or irritated you can communicate your situation to an advisor and help you with your order.',

    landing_social_support: 'Contact support',

    landing_social_h2: 'We do things differently',

    landing_social_p_three: "We don't focus on the details of everything we do. We are proud of it, that's why:",

    landing_social_h2_two: 'And now that you know us...<br>Become our friend!</br>',

    landing_social_button: 'Create order'
};
