import { ISystemRepository } from './system-repository-interface';

export class DefaultInformationRepository implements ISystemRepository {
    public getAvailableReceive(): boolean {
        return false;
    }

    public getAvailableSend(): boolean {
        return false;
    }

    public getInfoSend(): string {
        return '';
    }

    public getInfoReceive(): string {
        return '';
    }
}
