import { Resource, DocumentCollection, DocumentResource } from 'ngx-jsonapi';
import { Account } from './account';
import { Deal } from './deal';

export class DealsBag extends Resource {
    public type: string = 'deals_bags';
    public attributes: {
        group_id: string;
        status: string;
        instructions_url: string;
        amount2: string;
        expired_at: string;
    } = {
        group_id: '',
        status: '',
        amount2: '0',
        instructions_url: '',
        expired_at: ''
    };

    public relationships: {
        send_account: DocumentResource<Account>;
        account: DocumentResource<Account>;
        deals: DocumentCollection<Deal>;
    } = {
        send_account: new DocumentResource<Account>(),
        account: new DocumentResource<Account>(),
        deals: new DocumentCollection<Deal>()
    };
}
