import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'environments/environment.base';
import { LinkService } from './link-service';

@Injectable({
    providedIn: 'root'
})
export class SeoService {
    private auxLang: any = ['', 'es-CO', 'es-MX', 'en-US', 'es-US', 'es-BO', 'es-VE'];

    public constructor(private meta: Meta, private link: LinkService, private title: Title) {}

    public addMetaName(name: string, content: string): void {
        this.meta.updateTag({
            name: name,
            content: content
        });
    }

    public addAlternateLink(section?: string, separator?: string, systemSendId?: string, systemReceiveId?: string): void {
        this.auxLang.forEach((objectUrl: any) => {
            let href: string =
                section === undefined
                    ? environment.CANONICAL_ORIGIN_URL + objectUrl
                    : environment.CANONICAL_ORIGIN_URL +
                      objectUrl +
                      (objectUrl !== '' ? '/' : '') +
                      section +
                      '/' +
                      systemSendId +
                      separator +
                      systemReceiveId;

            let lang: string = objectUrl === '' ? 'es' : objectUrl;
            this.link.removeTag('link[hreflang="' + lang + '"]');
            this.link.addHreflangTag({
                rel: 'alternate',
                href: href,
                hreflang: lang
            });
        });
    }

    public addTitle(title: string): void {
        this.title.setTitle(title);
    }

    public addCanonical(url: string): void {
        this.link.removeTag('link[rel="canonical"]');
        this.link.addTag({
            rel: 'canonical',
            href: environment.CANONICAL_ORIGIN_URL + url
        });
    }

    public addOrUpdateMetaProperty(property: string, content: string): void {
        this.meta.updateTag({
            property: property,
            content: content
        });
    }
}
