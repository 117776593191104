<div *ngIf="transaction.relationships.system2.data?.attributes?.market === 'crypto'" class="container-trend show-tooltip">
    <div class="item-trend">
        <mat-icon class="icon-trend">auto_awesome</mat-icon>
        <p class="text-trend more-info-icon" [innerHTML]="'calculator_exactly_amount' | translate: {
            element_one: transaction.attributes.amount2 ? transaction.attributes.amount2 : 0,
            element_two: transaction.relationships.system2.data?.attributes?.currency,
            element_three: selectedNetworkTitle ? selectedNetworkTitle : ''
        }">
    </div>
</div>
    <app-tooltip>
        <p [innerHTML]="'calculator_exactly_amount_info' | translate"></p>
    </app-tooltip>

