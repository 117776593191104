import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-domain-to-provider',
    templateUrl: './domain-to-provider.component.html',
    styleUrls: ['./domain-to-provider.component.scss']
})
export class DomainToProviderComponent {
    @Input() public url: string = '';
    @Input() public imagePath: string = '';
    @Input() public text: string = '';
    @Input() public width: number = 0;
    @Input() public height: number = 0;
}
