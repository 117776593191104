import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxLoadableComponent } from './ngx-loadable.component';

@NgModule({
    declarations: [NgxLoadableComponent],
    exports: [NgxLoadableComponent],
    imports: [CommonModule]
})
export class NgxLoadableModule {}
