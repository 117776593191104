import { Injectable } from '@angular/core';
import { ScriptService } from '@app/core/services/script.service';
import { PlatformBrowserService } from './platform-browser.service';

declare let fbq: Function;

@Injectable({
    providedIn: 'root'
})
export class PixelService {
    public constructor(private browserService: PlatformBrowserService, public scriptService: ScriptService) {}

    public trackCustom(eventName: string, parameters: any): void {
        if (!this.browserService.isBrowser) {
            return;
        }

        fbq('trackCustom', eventName, parameters);
    }
}
