import { Resource } from 'ngx-jsonapi';

export class ChannelToken extends Resource {
    public type: string = 'channels_tokens';
    public attributes: {
        token: string;
        device: string;
    } = {
        token: '',
        device: ''
    };
}
