import { Observable, Subject } from 'rxjs';
import { Transaction, ITransactionsAttributes } from '@app/transactions/transaction';
import { IAttributes } from 'ngx-jsonapi';
import { TransactionPrivate } from '@app/core/resources/transaction-private';

export interface IAttributeChanged {
    attribute_name: string;
    new_value: any;
}

interface IObservedTransaction extends Transaction {
    __observed: Subject<IAttributeChanged>;
}

export class TransactionObserverService {
    public transactionSource: Subject<IAttributeChanged>;
    private readonly INTERAL_ATTRIBUTE: string = '__observed';

    public constructor(transaction: IObservedTransaction | Transaction | TransactionPrivate) {
        if ('__observed' in transaction) {
            this.transactionSource = transaction.__observed;

            return;
        }
        this.transactionSource = new Subject<IAttributeChanged>();
        Object.defineProperty(transaction.attributes, this.INTERAL_ATTRIBUTE, { value: this.transactionSource, configurable: true });

        transaction.attributes = <ITransactionsAttributes>new Proxy(transaction.attributes, {
            set: (target: IAttributes, key: string, value): boolean => {
                if (target[key] !== value) {
                    target[key] = value;
                    this.transactionSource.next({ attribute_name: String(key), new_value: value });
                }

                return true;
            }
        });
    }

    public observeTransaction(): Observable<IAttributeChanged> {
        return this.transactionSource.asObservable();
    }
}
