<mat-card>
    <mat-card-content>
        <p>¡Ups! Esto no debería haber pasado, puede que haya un problema con tu sesión. {{hiddenBtnWhatsapp ? 'Avísanos para que podamos ayudarte.' : 'Recarga la página.'}}</p>
        <div *ngIf="hiddenBtnWhatsapp" class="button-actions">
            <button mat-button type="button" (click)="redirectWhatsappProblem()">Reportar problema</button>
        </div>
        <div *ngIf="!hiddenBtnWhatsapp" class="button-actions">
            <button mat-button type="button" (click)="reloadPage()">Recargar página</button>
        </div>
    </mat-card-content>
</mat-card>
