import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicDealsComponent } from './public-deals.component';
import { SlideModule } from '@app/core/components/slide/slide.module';
import { PublicDealCardModule } from './public-deal-card/public-deal-card.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { RouterModule } from '@angular/router';
import { InfoPublicDealModule } from './info-public-deal/info-public-deal.module';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { DealsSkeletonLoaderModule } from './deals-skeleton-loader/deals-skeleton-loader.module';

@NgModule({
    declarations: [PublicDealsComponent],
    exports: [PublicDealsComponent],
    imports: [
        CommonModule,
        SlideModule,
        PublicDealCardModule,
        RouterModule,
        MatButtonModule,
        TranslateModule,
        InfoPublicDealModule,
        DealsSkeletonLoaderModule,
        MatProgressSpinnerModule
    ]
})
export class PublicDealsModule {}
