import { Injectable } from '@angular/core';
import { Currency } from '../resources/currency';
import { System } from '../resources/system';

interface ICurrencies {
    [key: string]: number;
}

@Injectable({
    providedIn: 'root'
})
export class CurrencyPiorityService {
    public currencies: ICurrencies = {
        BTC: 1,
        ETH: 1,
        mBTC: 1000,
        USDt: 100,
        DAI: 100,
        EUR: 100,
        USD: 100,
        MXN: 100,
        ARS: 10000,
        COP: 100000,
        VES: 100000,
        BSF: 100000
    };

    public callbacksAndBreakByCurrency(
        sendSystem: System,
        receiveSystem: System,
        fnForSend: (amount: number) => void,
        fnForReceive: (amount: number) => void
    ): void {
        let currency1: Currency = sendSystem.relationships.currency.data ?? new Currency();
        let currency2: Currency = receiveSystem.relationships.currency.data ?? new Currency();
        if (currency1.attributes.rank < currency2.attributes.rank) {
            fnForSend(this.currencies[sendSystem.attributes.currency]);

            return;
        }

        if (currency1.attributes.rank > currency2.attributes.rank) {
            fnForReceive(this.currencies[receiveSystem.attributes.currency]);

            return;
        }

        fnForReceive(this.currencies[receiveSystem.attributes.currency]);

        return;
    }

    public callbacksAndReturnByCurrency(
        sendSystem: System,
        receiveSystem: System,
        fnForSend: (amount: number) => any,
        fnForReceive: (amount: number) => any
    ): any {
        let currency1: Currency =
            sendSystem.relationships.currency && sendSystem.relationships.currency.data
                ? sendSystem.relationships.currency.data
                : new Currency();
        let currency2: Currency =
            receiveSystem.relationships.currency && receiveSystem.relationships.currency.data
                ? receiveSystem.relationships.currency.data
                : new Currency();
        if (currency1.attributes.rank < currency2.attributes.rank) {
            return fnForSend(this.currencies[sendSystem.attributes.currency]);
        }

        if (currency1.attributes.rank > currency2.attributes.rank) {
            return fnForReceive(this.currencies[receiveSystem.attributes.currency]);
        }

        return fnForReceive(this.currencies[receiveSystem.attributes.currency]);
    }

    public getPairByPriority(system1: System, system2: System): string {
        let systems: Array<System> = [system1, system2];
        let pair: string = '';
        systems.forEach((system1Aux) => {
            systems.forEach((system2Aux) => {
                let currency1: Currency = system1Aux.relationships.currency.data ?? new Currency();
                let currency2: Currency = system2Aux.relationships.currency.data ?? new Currency();
                if (currency1.attributes.rank > currency2.attributes.rank) {
                    return;
                }
                if (
                    currency1.attributes.rank === currency2.attributes.rank &&
                    system1Aux.attributes.group_id >= system2Aux.attributes.group_id
                ) {
                    return;
                }
                pair = system1Aux.attributes.group_id + '/' + system2Aux.attributes.group_id;

                return;
            });
        });

        return pair;
    }
}
