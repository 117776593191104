import { Injectable } from '@angular/core';
import { Service } from 'ngx-jsonapi';
import { BestRate } from '@app/core/resources/best-rate';

@Injectable({
    providedIn: 'root'
})
export class BestRatesService extends Service<BestRate> {
    public resource: typeof BestRate = BestRate;
    public type: string = 'best_rates';

    public constructor() {
        super();
        this.register();
    }
}
