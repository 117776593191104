import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';

@Injectable()
export class BrowserStateInterceptor implements HttpInterceptor {
    public constructor(private transferState: TransferState) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.method !== 'GET') {
            return next.handle(req);
        }

        /* eslint-disable @typescript-eslint/typedef */
        const storedResponse = this.transferState.get(makeStateKey(req.url), null);
        /* eslint-enable @typescript-eslint/typedef */
        if (storedResponse) {
            const response: HttpResponse<any> = new HttpResponse({ body: storedResponse, status: 200 });

            return of(response);
        }

        return next.handle(req);
    }
}
