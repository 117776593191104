import { ITransactionValidator, IMessageError } from './transaction-validators';
import { Transaction } from '@app/transactions/transaction';
import { TransactionPrivate } from '@app/core/resources/transaction-private';
import { DocumentResource } from 'ngx-jsonapi';
import { Currency } from '@app/core/resources/currency';

export class MinimumValuesValidator implements ITransactionValidator {
    public validate(transaction: Transaction | TransactionPrivate): Array<IMessageError> {
        let currency1: DocumentResource<Currency> =
            transaction.relationships.system1.data?.relationships.currency ?? new DocumentResource();
        let currency2: DocumentResource<Currency> =
            transaction.relationships.system2.data?.relationships.currency ?? new DocumentResource();
        let system1_minimum_amount: number = transaction.relationships.system1.data?.attributes.minimum_amount_send ?? 0;
        let system2_minimum_amount: number = transaction.relationships.system2.data?.attributes.minimum_amount_receive ?? 0;
        let currency_system1: string | undefined = transaction.relationships.system1.data?.attributes.currency;
        let currency_system2: string | undefined = transaction.relationships.system2.data?.attributes.currency;
        let system1_default_minimum: number = currency1.data?.attributes.default_minimum ?? 0;
        let system2_default_minimum: number = currency2.data?.attributes.default_minimum ?? 0;
        let errors: Array<IMessageError> = [];
        let decimalPlaces1: number = transaction.relationships.system1.data?.attributes.decimal_places ?? 2;
        let decimalPlaces2: number = transaction.relationships.system2.data?.attributes.decimal_places ?? 2;

        if (currency_system1 === 'USD') {
            let minimum_amount1_aux: number = system1_minimum_amount === 0 ? system1_default_minimum : system1_minimum_amount;

            return this.validateAmount(
                transaction.attributes.amount1,
                minimum_amount1_aux,
                'system1',
                decimalPlaces1,
                transaction.relationships.system1.data?.attributes.currency
            );
        } else if (currency_system2 === 'USD') {
            let minimum_amount2_aux: number = system2_minimum_amount === 0 ? system2_default_minimum : system2_minimum_amount;

            return this.validateAmount(
                transaction.attributes.amount2,
                minimum_amount2_aux,
                'system2',
                decimalPlaces2,
                transaction.relationships.system2.data?.attributes.currency
            );
        }

        if (system1_minimum_amount !== 0) {
            return this.validateAmount(
                transaction.attributes.amount1,
                system1_minimum_amount,
                'system1',
                decimalPlaces1,
                transaction.relationships.system1.data?.attributes.currency
            );
        }
        if (system2_minimum_amount !== 0) {
            return this.validateAmount(
                transaction.attributes.amount2,
                system2_minimum_amount,
                'system2',
                decimalPlaces2,
                transaction.relationships.system2.data?.attributes.currency
            );
        }

        if (system1_default_minimum === undefined || system2_default_minimum === undefined) {
            // just in case
            return errors;
        }

        return errors;
    }

    private validateAmount(
        amount: number,
        minimum_amount: number,
        system: string,
        decimalPlaces: number,
        currency: string | undefined
    ): Array<IMessageError> {
        let title: string = 'El monto debe ser mayor a ';

        if (amount >= minimum_amount) {
            return [];
        }

        return [
            {
                title: title + minimum_amount.toFixed(decimalPlaces) + ' ' + currency + '.',
                pointer_attribute: system,
                detail: 'Cada método de intercambio tienen un monto minimo'
            }
        ];
    }
}
