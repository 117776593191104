import { DefaultInformationRepository } from './default-information-repository';

export class PayeerInformationRepository extends DefaultInformationRepository {
    public getAvailableReceive(): boolean {
        return true;
    }

    public getAvailableSend(): boolean {
        return false;
    }
}
