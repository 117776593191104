import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-stepper-transaction-data',
    templateUrl: './stepper-transaction-data.component.html',
    styleUrls: ['./stepper-transaction-data.component.scss']
})
export class StepperTransactionDataComponent {
    @Input() public titleStepper: Array<{ [key: string]: string }> = [
        {
            title: 'steps_to_complete_your_order_step_one'
        },
        {
            title: 'steps_to_complete_your_order_step_two'
        },
        {
            title: 'steps_to_complete_your_order_step_three'
        }
    ];
}
