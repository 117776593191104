import { Component } from '@angular/core';

@Component({
    selector: 'app-deals-skeleton-loader',
    templateUrl: './deals-skeleton-loader.component.html',
    styleUrls: ['./deals-skeleton-loader.component.scss']
})
export class DealsSkeletonLoaderComponent {
    public skeletons: any = [{ id: 1 }, { id: 2 }, { id: 3 }];
}
