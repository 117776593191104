<div class="list-and-button-container">
    <div class="search-and-refresh">
        <div class="filter-and-refresh-container">
            <div class="filter-container">
                <jam-floating-filters>
                    <ng-container class="jam-filter-content">
                        <div class="system-filter">
                            <app-system-filter [remoteFilter]="dealsBagFillingService.remoteFilter"
                                (updateFilter)="updateFilter($event)">
                            </app-system-filter>
                        </div>
                        <div class="currencies-filter">
                            <app-currencies-filter [remoteFilterCurrency]="dealsBagFillingService.remoteFilter"
                                (updateFilterCurrency)="updateFilter($event)">
                            </app-currencies-filter>
                        </div>
                    </ng-container>
                </jam-floating-filters>
            </div>
            <div class="jam-filter-header">
                <jam-refresh [collectionToRefresh]="collection" [serviceToRefresh]="$any(dealService)"
                    icon="refresh"></jam-refresh>
            </div>
        </div>
    </div>

    <jam-list-base-common-infinite-scroll *ngIf="dealService" #table [service]="$any(dealService)"
        [tableColumns]="tableColumns" [page]="dealsBagFillingService.page"
        [remoteFilter]="dealsBagFillingService.remoteFilter" [imageOrIcon]="'image'"
        nothingHereImageUrl="/assets/images/dashboard/deals-list-empty.svg" [responsiveColumns]="responsiveColumns"
        beforePath="users/{{globalStateService.userId}}" (collectionChange)="setCollection($any($event))"
        [include]="['system']">
    </jam-list-base-common-infinite-scroll>
</div>

<ng-template #default_gain let-element="element">
    <p (click)="dialogInfoDeal(element)" class="text-gain-percent">{{element.attributes.gain_percent}} %</p>
</ng-template>

<ng-template #default_system let-element="element">
    <app-system-picture (click)="dialogInfoDeal(element)" class="show-system-picture"
        [ngClass]="{'opacity-deals-selected': dealsBagFillingService.dealsBag.relationships.deals.find(element.id)}"
        systemId="{{element.relationships.system.data.attributes.group_id}}"
        alt="{{element.relationships.system.data.attributes.name}}"
        title="{{element.relationships.system.data.attributes.name}}">
    </app-system-picture>
    <app-system-icon class="show-system-icon"
        [ngClass]="{'opacity-deals-selected': dealsBagFillingService.dealsBag.relationships.deals.find(element.id)}"
        systemId="{{element.relationships.system.data.attributes.group_id}}"
        alt="{{element.relationships.system.data.attributes.name}}"
        title="{{element.relationships.system.data.attributes.name}}"></app-system-icon>
</ng-template>

<ng-template #default_rest let-element="element">
    <app-amount-list [value]="element.attributes.rest_to_pay" (click)="dialogInfoDeal(element)" [system]="element.relationships.system.data"
        [decimalPlaces]="element.relationships.system.data.attributes.decimal_places"><span class="currency">
            {{element.relationships.system.data.attributes.currency}}
        </span>
    </app-amount-list>
</ng-template>

<ng-template #default_add let-element="element">
    <div class="dark-background">
        <button mat-button class="button-info-deal" (click)="dialogInfoDeal(element)">
            <mat-icon>info</mat-icon>
        </button>
        <button *ngIf="haveLevel"
            [ngClass]="dealsBagFillingService.dealsBag.relationships.deals.find(element.id) ? 'animation-button-clear' : 'animation-button-add'"
            data-cy="button-add-deal" mat-stroked-button
            [disabled]="(dealsBagFillingService.dealsBagHaveSaving && !dealsBagFillingService.dealsBag.relationships.deals.find(element.id)) "
            class="button-to-add-deal" (click)="addDeal(element)">
            <mat-icon
                [ngClass]="dealsBagFillingService.dealsBag.relationships.deals.find(element.id) ? 'animation -icon-clear' : 'animation-icon-add'">{{
                dealsBagFillingService.dealsBag.relationships.deals.find(element.id) ? 'clear' : 'add' }}</mat-icon>
        </button>
    </div>
</ng-template>
