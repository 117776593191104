import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { System } from '@app/core/resources/system';
import { SystemsService } from '@app/core/services/systems.service';
import { mapSystemsByCurrency } from '@app/transactions/components/transaction-calculator/system-select/system-select.component';
import { DocumentCollection } from 'ngx-jsonapi';
import { filter, Subscription } from 'rxjs';

@Component({
    selector: 'app-currencies-filter',
    templateUrl: './currencies-filter.component.html',
    styleUrls: ['./currencies-filter.component.scss']
})
export class CurrenciesFilterComponent implements OnDestroy {
    @Output() public updateFilterCurrency: EventEmitter<any> = new EventEmitter();
    @Input() public remoteFilterCurrency: any = {};
    private systems: Array<System> = [];
    private subscription: Subscription = new Subscription();
    public filterConfig: any = undefined;
    public currencies: Array<string> = [];
    public systemsByCurrency: any = {};

    public constructor(private systemsService: SystemsService, private changeDetectorRef: ChangeDetectorRef) {
        this.subscription = this.systemsService
            .all()
            .pipe(filter((systemsCollection: DocumentCollection<System>): boolean => systemsCollection.source !== 'new'))
            .subscribe((systemsCollection: DocumentCollection<System>) => {
                this.systems = systemsCollection.data.filter(
                    (system: System): boolean =>
                        (system.attributes.account_required_send || system.attributes.account_required_receive) &&
                        (system.attributes.can_send || system.attributes.can_receive)
                );
                this.systemsByCurrency = mapSystemsByCurrency(this.systems);
                this.currencies = Object.keys(this.systemsByCurrency);
                this.getFilterConfig();
            });
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public setRemoteFilterCurrency(remoteFilter: any): void {
        if (remoteFilter['system.id'] !== '') {
            this.remoteFilterCurrency['system.id'] = {
                in: remoteFilter['system.id']
            };
        }
        this.updateFilterCurrency.emit(this.remoteFilterCurrency);
    }

    public getFilterConfig(): void {
        this.filterConfig = {
            type: 'options',
            attribute: 'system.id',
            options: {
                '': { text: 'Todas las monedas' }
            },
            selected: ''
        };

        this.currencies.forEach((currency: string) => {
            let systemIds: string = '';
            this.systemsByCurrency[currency].forEach((system: System) => {
                systemIds += system.id + ',';
            });
            this.filterConfig.options[systemIds] = { text: currency };
        });

        this.changeDetectorRef.markForCheck();
    }
}
