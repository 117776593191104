import { TransactionUrlService } from './../../transaction-url.service';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { System } from '@app/core/resources/system';
import { Transaction } from '@app/transactions/transaction';
import { ActivatedRoute, Router } from '@angular/router';
import { UserCountryService } from '@app/core/services/user-country.service';
import { SystemSelectComponent } from '@app/transactions/components/transaction-calculator/system-select/system-select.component';
import { SystemsUpdatedService } from '@app/transactions/services/systems-updated.service';
import { Subscription, Observable } from 'rxjs';
import { DocumentCollection } from 'ngx-jsonapi';
import { animationEqualSystem } from './animation/animation-equal-system';
import { TransactionValidatorsServiceProvider } from '@app/transactions/transaction-validators-provider/transaction-validators-service-provider';
import { IMessageError } from '@app/transactions/transaction-validators-provider/transaction-validators/transaction-validators';
import { UrlUpdateService } from '@app/core/services/url-update.service';
import { TransactionObserverService } from '@app/transactions/services/transaction-observer.service';
import { filter, debounceTime } from 'rxjs/operators';
import { PlatformBrowserService } from '@app/core/services/platform-browser.service';
import { SystemsService } from '@app/core/services/systems.service';
import { PixelService } from '@app/core/services/pixel.service';
import { CurrencyPiorityService } from '@app/core/services/currency-piority.service';
import { RefreshRatesService } from '@app/core/services/refresh-rates.service';
import { HeaderService } from '@app/core/services/header-service';
import { CountryProvider } from '@app/core/country-provider/country-provider';
import { FeeService } from '../fee-message/fee.service';
import { tuiPure } from '@taiga-ui/cdk';
import { HeaderCountryService } from '@app/core/components/header/header-country.service';
import { SystemsFeatureService } from '@app/landing-page/components/systems-feature/systems-feature.component';
import { SystemInfoService } from './system-info/system-info.service';
import { LenguagesHelper } from '@app/core/helpers/lenguages.helper';
import { NetworkSystemService } from '@app/core/services/network-system.service';
import { DecimalSystemHelper } from '@app/core/helpers/decimal-system-helper';
import { NetworkMessageHelper } from '@app/core/helpers/network-message-helper';

export type AdvancedOptions = 'open' | 'closed';

@Component({
    selector: 'app-transaction-calculator',
    animations: [animationEqualSystem],
    templateUrl: './transaction-calculator.component.html',
    styleUrls: ['./transaction-calculator.component.scss']
})
export class TransactionCalculatorComponent implements OnInit, OnDestroy {
    @Input() public transaction: Transaction = new Transaction();
    @Input() public systems$: Observable<DocumentCollection<System>> = new Observable();
    @Output() public transactionUpdated: EventEmitter<Transaction> = new EventEmitter<Transaction>();
    @Input() public showActionButton: boolean = true;
    @Input() public actionButtonText: string = '';
    @Input() public isOnlyTwoSystems: boolean = false;
    @Input() public isLanding?: boolean;
    @Output() public transactionInputUpdate: EventEmitter<Transaction> = new EventEmitter<Transaction>();
    @Output() public transactionSelectUpdate: EventEmitter<Transaction> = new EventEmitter<Transaction>();
    @Output() public validatorErrorsUpdate: EventEmitter<Array<IMessageError>> = new EventEmitter<Array<IMessageError>>();
    /** @deprecated */
    public sendSystem: System = new System();
    /** @deprecated */
    public receiveSystem: System = new System();
    public systemsCanSend: Array<System> = [];
    public systemsCanReceive: Array<System> = [];
    public clicked: boolean = false;
    public checkIsForbiddenSystem: boolean = false;
    public validatorMinimumAmountReceive: boolean = false;
    public validatorMinimumAmountSend: boolean = false;
    public validatorMinimumInit: boolean = false;
    public systemsSubscription: Subscription;
    public systemsSubscriptionForPixel: Subscription;
    public selectedCountrySubscription!: Subscription;
    public isAnimationEqualSystem: boolean = false;
    public transactionValidatorService: TransactionValidatorsServiceProvider = new TransactionValidatorsServiceProvider();
    public validatorErrors: Array<IMessageError> = [];
    public updateUrl: boolean = true;
    public advancedOptions: AdvancedOptions = 'closed';
    public networkTitle: string | null = null;
    private refreshRatesSubscription: Subscription = new Subscription();
    @Output() public isExit: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChild('sendSystemSelect') public sendSystemSelect!: SystemSelectComponent;
    @ViewChild('receiveSystemSelect') public receiveSystemSelect!: SystemSelectComponent;

    public constructor(
        public systemInfoService: SystemInfoService,
        private systemsUpdatedService: SystemsUpdatedService,
        private pixelService: PixelService,
        private urlUpdate: UrlUpdateService,
        private router: Router,
        private currencyPriorityService: CurrencyPiorityService,
        private browserServices: PlatformBrowserService,
        private refreshRatesService: RefreshRatesService,
        private systemsService: SystemsService,
        private headerService: HeaderService,
        private feeService: FeeService,
        private headerCountryService: HeaderCountryService,
        private systemsFeatureService: SystemsFeatureService,
        private changeDetectorRef: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute,
        public networkSystemService: NetworkSystemService,
        private userCountryService: UserCountryService
    ) {
        this.systemsSubscription = systemsUpdatedService.systemsUpdated$.subscribe((systems): void => {
            if (this.sendSystemSelect && this.receiveSystemSelect) {
                this.setSendSystem(systems[0]);
                this.setReceiveSystem(systems[1]);
                this.sendSystemSelect.selectSystemList(this.sendSystem);
                this.receiveSystemSelect.selectSystemList(this.receiveSystem);
                if (this.transaction.attributes.amount1 <= 0 && this.transaction.attributes.amount2 <= 0) {
                    this.updateWithDefaultAmounts(this.sendSystem, this.receiveSystem, this.transaction);
                }
                this.addProductMicrodata();
            }
        });
        this.systemsSubscriptionForPixel = systemsUpdatedService.systemsUpdated$.pipe(debounceTime(5000)).subscribe((systems): void => {
            let system1: System = systems[0];
            let system2: System = systems[1];
            this.pixelService.trackCustom('CalculatorEvent', {
                system1: system1.attributes.name,
                system2: system2.attributes.name,
                amount1: this.transaction.attributes.amount1,
                amount2: this.transaction.attributes.amount2,
                system_requested: this.transaction.attributes.__operation?.includes('send')
                    ? system1.attributes.name
                    : system2.attributes.name
            });
            this.changeDetectorRef.markForCheck();
        });
        this.observerSelectedCountry();
        this.observerSelectedSystem();
    }

    public ngOnDestroy(): void {
        this.systemsSubscription.unsubscribe();
        this.systemsSubscriptionForPixel.unsubscribe();
        this.refreshRatesSubscription.unsubscribe();
        this.selectedCountrySubscription.unsubscribe();
    }

    public ngOnInit(): void {
        this.getSystems();
        this.changedTransaction();
        if (this.browserServices.isBrowser) {
            this.refreshRatesSubscription = this.refreshRatesService.refresh(this.systemsService).subscribe((): void => {
                this.transaction.recalculate();
            });
        }
    }

    private getSystems(): void {
        this.systems$.subscribe((systems): void => {
            /* eslint-disable @typescript-eslint/dot-notation */
            let filterForMarket: string = this.activatedRoute.snapshot.queryParamMap['params'].special_offer ? 'crypto' : '';
            /* eslint-enable @typescript-eslint/dot-notation */
            systems.data = systems.data.filter((system): boolean => system.attributes.market !== filterForMarket);
            this.systemsCanSend = systems.data.filter(
                (system): boolean => system.attributes.can_send && system.attributes.replacement_system_id === ''
            );
            this.systemsCanReceive = systems.data.filter(
                (system): boolean => system.attributes.can_receive && system.attributes.replacement_system_id === ''
            );
            if (!this.transaction.attributes.system1 && !this.transaction.attributes.system2) {
                this.userCountryService.getCountry().subscribe(
                    (country) => {
                        if (country === undefined) {
                            return;
                        }
                        this.setSendSystemByCountry(country.code);
                    },
                    () => {
                        this.setSendSystemByCountry(this.userCountryService.default_country_lang_data.attributes.code);
                    }
                );
            }
            this.setSendSystemByIdIfExists(this.transaction.attributes.system1 || 'banco');
            this.setReceiveSystemByIdIfExists(this.transaction.attributes.system2 || 'palpal');
            this.systemsUpdatedService.emitSystemsUpdatedEvent(this.sendSystem, this.receiveSystem);
            if (this.transaction.attributes.amount1 <= 0 && this.transaction.attributes.amount2 <= 0) {
                this.updateWithDefaultAmounts(this.sendSystem, this.receiveSystem, this.transaction);
            }
            this.setValidatorErrors();
            this.systemInfoService.getAdvanceOptionsLocalStorage();
            this.addProductMicrodata();
        });
    }

    private observerSelectedCountry(): void {
        this.selectedCountrySubscription = this.headerCountryService.selectedCountry$.subscribe((country): void => {
            this.setSendSystemByCountry(country.code);
            this.setTransactionAmountByPriority();
            this.changeDetectorRef.markForCheck();
        });
    }

    private observerSelectedSystem(): void {
        this.systemsFeatureService.selectedSystem$.subscribe((system): void => {
            this.setReceiveSystemByIdIfExists(system);
            this.setTransactionAmountByPriority();
            this.changeDetectorRef.markForCheck();
        });
    }

    public tryAgain(): void {
        this.checkIsForbiddenSystem = false;
        this.setDefaultSystems();
    }

    private setDefaultSystems(): void {
        this.setSendSystemByIdIfExists('banco');
        this.setReceiveSystemByIdIfExists('palpal');
        this.setTransactionAmountByPriority();
        this.changeDetectorRef.markForCheck();
    }

    private setTransactionAmountByPriority(): void {
        this.currencyPriorityService.callbacksAndBreakByCurrency(
            this.transaction.relationships.system1.data ?? new System(),
            this.transaction.relationships.system2.data ?? new System(),
            (amount): void => {
                this.transaction.attributes.amount1 = amount;
                this.transaction.attributes.__operation = 'receive';
            },
            (amount): void => {
                this.transaction.attributes.amount2 = amount;
                this.transaction.attributes.__operation = 'send';
            }
        );
    }

    private setSendSystemByCountry(countryCode: string): void {
        let systemIds: Array<String> = new CountryProvider().getSystemIds(countryCode);
        let system1: System | undefined = this.systemsCanSend.find((eachSystem): boolean => eachSystem.id === systemIds[0]);
        let system2: System | undefined = this.systemsCanSend.find((eachSystem): boolean => eachSystem.id === systemIds[1]);
        if (system1 === undefined || system2 === undefined) {
            return;
        }
        if (system1 !== undefined) {
            this.setSendSystem(system1);
        }
        if (system2 !== undefined) {
            this.setReceiveSystem(system2);
        }
    }

    @tuiPure
    public sendSelectedSystemChange(system: System): void {
        this.setSendSystem(system);
        this.transaction.calculateSendAmount(this.sendSystem, this.receiveSystem, this.transaction.attributes.amount2);
        this.setValidatorErrors();
        this.systemsUpdatedService.emitSystemsUpdatedEvent(this.sendSystem, this.receiveSystem);
        this.updateUrlChangeSystem();
        this.changeDetectorRef.markForCheck();
    }

    @tuiPure
    public receiveSelectedSystemChange(system: System): void {
        this.setReceiveSystem(system);
        this.transaction.attributes.account_network2 = system.attributes.default_network_id ?? '';
        this.transaction.calculateReceiveAmount(this.sendSystem, this.receiveSystem, this.transaction.attributes.amount1);
        this.setValidatorErrors();
        this.systemsUpdatedService.emitSystemsUpdatedEvent(this.sendSystem, this.receiveSystem);
        this.updateUrlChangeSystem();
        this.changeDetectorRef.markForCheck();
    }

    private updateUrlChangeSystem(): void {
        if (!this.isOnlyTwoSystems) {
            this.urlUpdate.updateUrlWithTransactionData(this.transaction, this.router.url);
        }
    }

    public goToTransactionForm(): void {
        this.isExit.emit(true);
        setTimeout((): void => {
            let section: string = 'b';
            let lang: string = new LenguagesHelper().extractLenguageFromPath(this.router.url);
            if (lang !== '') {
                section = lang + '/' + section;
            }
            this.isExit.emit(false);
            this.router.navigate([TransactionUrlService.getUrl(this.transaction, section)]);
        }, 255);
    }

    public isSendOperation(): void {
        this.transaction.attributes.__operation = 'send';
        this.feeService.touchInput$.next(true);
    }

    public isReceiveOperation(): void {
        this.transaction.attributes.__operation = 'receive';
        this.feeService.touchInput$.next(true);
    }

    public invertSystems(): void {
        this.clicked = !this.clicked;

        if (!this.sendSystem.attributes.can_receive || !this.receiveSystem.attributes.can_send) {
            this.isAnimationEqualSystem = !this.isAnimationEqualSystem;
            this.clicked = false;

            return;
        }

        let tempSendSystem: System = this.receiveSystem;
        let tempReceiveSystem: System = this.sendSystem;
        this.setSendSystem(tempSendSystem);
        this.setReceiveSystem(tempReceiveSystem);

        if (this.transaction.attributes.__operation === 'send') {
            this.transaction.attributes.amount1 = this.transaction.attributes.amount2;
            this.transaction.attributes.amount1 = new DecimalSystemHelper().parseAmountByDecimal(
                this.sendSystem,
                this.transaction.attributes.amount1
            );
            this.transaction.calculateReceiveAmount(tempSendSystem, tempReceiveSystem, this.transaction.attributes.amount1);
            this.transaction.attributes.__operation = 'receive';
        } else {
            this.transaction.attributes.amount2 = this.transaction.attributes.amount1;
            this.transaction.attributes.amount2 = new DecimalSystemHelper().parseAmountByDecimal(
                this.receiveSystem,
                this.transaction.attributes.amount2
            );
            this.transaction.calculateSendAmount(tempSendSystem, tempReceiveSystem, this.transaction.attributes.amount2);
            this.transaction.attributes.__operation = 'send';
        }
        this.systemsUpdatedService.emitSystemsUpdatedEvent(this.sendSystem, this.receiveSystem);
        this.updateUrl = true;
        this.feeService.touchInput$.next(true);
        this.setValidatorErrors();
    }

    @tuiPure
    public updateWithDefaultAmounts(sendSystem: System, receiveSystem: System, transaction: Transaction, updateUrl: boolean = false): void {
        this.updateUrl = updateUrl;
        this.currencyPriorityService.callbacksAndBreakByCurrency(
            sendSystem,
            receiveSystem,
            (amount): void => {
                transaction.attributes.amount1 = amount;
                transaction.attributes.__operation = 'receive';
            },
            (amount): void => {
                transaction.attributes.amount2 = amount;
                transaction.attributes.__operation = 'send';
            }
        );
        transaction.recalculate();
    }

    private changedTransaction(): void {
        new TransactionObserverService(this.transaction)
            .observeTransaction()
            .pipe(
                filter((change): boolean => ['amount1', 'amount2', 'system1', 'system2'].includes(change.attribute_name)),
                debounceTime(100)
            )
            .subscribe((): void => {
                this.transaction.addRelationship(this.sendSystem, 'system1');
                this.transaction.addRelationship(this.receiveSystem, 'system2');
                this.transaction.recalculate();

                this.feeService.transaction$.next(this.transaction);
                this.setValidatorErrors();
                if (this.updateUrl && !this.isOnlyTwoSystems) {
                    this.urlUpdate.updateUrlWithTransactionData(this.transaction, this.router.url);
                }
                this.updateUrl = true;
                this.changeDetectorRef.markForCheck();
            });
    }

    private setValidatorErrors(): void {
        this.validatorErrors = this.transactionValidatorService.getErrors(this.transaction);
        if (this.validatorErrors[0] !== undefined) {
            this.transaction.setRecomendation(this.validatorErrors[0].title);
        }
        this.validatorErrorsUpdate.emit(this.validatorErrors);
    }

    private setSendSystemByIdIfExists(system_id: string): void {
        let system: System | undefined = this.systemsCanSend.find((eachSystem): boolean => eachSystem.id === system_id);
        if (system === undefined) {
            this.checkIsForbiddenSystem = true;

            return;
        }
        this.setSendSystem(system);
    }

    @tuiPure
    private setSendSystem(system: System): void {
        this.sendSystem = system;
        this.transaction.addRelationship(system, 'system1');
        this.transaction.attributes.system1 = system.id;
    }

    private setReceiveSystemByIdIfExists(system_id: string): void {
        let system: System | undefined = this.systemsCanReceive.find((eachSystem): boolean => eachSystem.id === system_id);
        if (system === undefined) {
            this.checkIsForbiddenSystem = true;

            return;
        }
        this.setReceiveSystem(system);
    }

    @tuiPure
    private setReceiveSystem(system: System): void {
        this.receiveSystem = system;
        this.transaction.addRelationship(system, 'system2');
        this.transaction.attributes.system2 = system.id;
        this.networkSelect();
    }

    private addProductMicrodata(): void {
        let transactionForProductMicrodata: Transaction = new Transaction();
        transactionForProductMicrodata.addRelationship(this.sendSystem, 'system1');
        transactionForProductMicrodata.addRelationship(this.receiveSystem, 'system2');
        this.updateWithDefaultAmounts(this.sendSystem, this.receiveSystem, transactionForProductMicrodata, false);
        this.currencyPriorityService.callbacksAndBreakByCurrency(
            this.sendSystem,
            this.receiveSystem,
            (): void => {
                this.headerService.addOrUpdateProductMicrodata(
                    transactionForProductMicrodata.attributes.amount2.toString(),
                    this.receiveSystem.attributes.currency,
                    this.sendSystem.id,
                    'send_' + transactionForProductMicrodata.attributes.amount1.toString()
                );
            },
            (): void => {
                this.headerService.addOrUpdateProductMicrodata(
                    transactionForProductMicrodata.attributes.amount1.toString(),
                    this.sendSystem.attributes.currency,
                    this.receiveSystem.id,
                    'receive_' + transactionForProductMicrodata.attributes.amount2.toString()
                );
            }
        );
        this.changeDetectorRef.markForCheck();
    }

    public networkSelect(): void {
        this.networkTitle = NetworkMessageHelper(this.receiveSystem);
        this.networkSystemService.selectedNetworkTitle = this.networkTitle;
    }

    public onNetworkChange(networkTitle: string): void {
        this.networkTitle = networkTitle;
        this.networkSystemService.selectedNetworkTitle = networkTitle;
    }
}
