import { Component, EventEmitter, Input, Output, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Transaction } from '@app/transactions/transaction';
import { MatStepper } from '@angular/material/stepper';
import { System } from '@app/core/resources/system';
import {
    DomainToProviderService,
    IProviderDetail
} from '@app/transactions/pages/transaction-data/domain-to-provider/domain-to-provider-service';
import { IMessageError } from '@app/transactions/transaction-validators-provider/transaction-validators/transaction-validators';
import { PlatformBrowserService } from '@app/core/services/platform-browser.service';
import { TransactionFormService } from '../../components/transaction-form/transaction-form.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ReplaySubject, Subscription } from 'rxjs';
import { TransactionUrlService } from '@app/transactions/transaction-url.service';
import { FeeService } from '@app/transactions/components/fee-message/fee.service';
import { WaitDestinationComponent } from '@app/dashboard/transactions/new-transaction/wait-destination/wait-destination.component';
// import { FirebaseMessagingService } from '@app/firebase/firebase-messaging.service';
import { TransactionTemporalValue } from '@app/core/resources/transaction-temporal-value';
import { TransactionTemporalValuesService } from '@app/core/services/transaction-temporal-values.service';
import { TransactionInstructionsService } from '@app/transactions/services/transaction-instructions.service';
// import { ChannelToken } from '@app/core/resources/channel-token';
import { ChannelsTokensService } from '@app/core/services/channels-tokens.service';
import { UrlUpdateService } from '@app/core/services/url-update.service';
import { Router } from '@angular/router';
import { NgxLoadableService } from '@app/core/components/ngx-loadable/ngx-loadable.service';
// import { isBrowser } from '@firebase/util';

@Component({
    selector: 'app-transaction-data',
    templateUrl: './transaction-data.component.html',
    styleUrls: ['./transaction-data.component.scss']
})
export class TransactionDataComponent implements OnInit, OnDestroy {
    public providerDetail: (email: string) => IProviderDetail = new DomainToProviderService().getProviderDetail;
    public stepper_next: boolean = false;
    public loadForm: boolean = false;
    public transactionFormSubscription: Subscription = new Subscription();
    @Input() public transaction: Transaction = new Transaction();
    @Input() public sendSystem: System | null = new System();
    @Input() public receiveSystem: System | null = new System();
    @Input() public validatorErrors: Array<IMessageError> = [];
    @Input() public path: string = '';
    @Output() public homeButtonPressed: EventEmitter<string> = new EventEmitter<string>();
    @ViewChild('stepper') public stepper!: MatStepper;
    public checked: boolean = false;
    public amountNeto: number = 0;
    public isDestinations: ReplaySubject<boolean> = new ReplaySubject(1);
    public styleContentLoader: { [key: string]: string } = {
        width: '100%',
        height: '320px'
    };
    private transactionTemporalValues: TransactionTemporalValue = new TransactionTemporalValue();

    public constructor(
        public transactionFormService: TransactionFormService,
        public transactionTemporalValuesService: TransactionTemporalValuesService,
        public channelsTokensService: ChannelsTokensService,
        private loadable: NgxLoadableService,
        private router: Router,
        private feeService: FeeService,
        private dialog: MatDialog,
        private urlUpdateService: UrlUpdateService,
        private transactionInstructionsService: TransactionInstructionsService,
        // private firebaseMessagingService: FirebaseMessagingService,
        private browserService: PlatformBrowserService
    ) {}

    public ngOnInit(): void {
        if (this.browserService.isBrowser) {
            window.scroll(0, 0);
        }
        this.transactionFormService.setTransaction(this.transaction);
        this.waitTransactionSaved();
        this.feeService.transaction$.next(this.transaction);
        this.loadable.preload('transaction-form').then(() => {
            this.loadForm = true;
        });
    }

    public ngOnDestroy(): void {
        this.transactionFormSubscription.unsubscribe();
    }

    private waitTransactionSaved(): void {
        this.transactionFormSubscription = this.transactionFormService.transactionUpdated$.subscribe((status) => {
            if (status.title === 'saved') {
                this.transactionFormService.isOpenDialog = true;
                this.setTokenFirebase(status.id);
                this.openDialog(status.email);
                history.pushState({}, 'dialog', TransactionUrlService.getUrl(this.transaction, 'b'));
            }
        });
    }

    private openDialog(email: string): void {
        let transactionUpdate: ReplaySubject<any> = this.transactionFormService.transactionUpdatedSource;
        let transactionFormService: TransactionFormService = this.transactionFormService;

        if (this.sendSystem?.attributes.market === 'crypto') {
            this.transactionInstructionsService.isDestinations.next(true);
        } else {
            this.transactionInstructionsService.isDestinations.next(false);
        }

        let dialog: MatDialogRef<WaitDestinationComponent> = this.dialog.open(WaitDestinationComponent, {
            data: {
                observable: this.transactionInstructionsService.isDestinations.asObservable(),
                email: email,
                name: this.transactionFormService.getTransaction().attributes.name.split(' ')[0],
                closeDialog: () => {
                    transactionUpdate.next({ title: 'restart_check' });
                    transactionFormService.isOpenDialog = false;
                }
            }
        });
        dialog.afterClosed().subscribe(() => {
            this.urlUpdateService.cleanParamsUrl(this.router.url);
            transactionUpdate.next({ title: 'restart_check' });
        });
    }

    private setTokenFirebase(transaction_temporal_values_id: string): void {
        if (this.browserService.isBrowser) {
            this.temporalValuesVerify(transaction_temporal_values_id);
        }
        // if (this.browserService.isBrowser && /iPad|iPhone|iPod/.test(navigator.userAgent)) {
        //     this.temporalValuesVerify(transaction_temporal_values_id);

        //     return;
        // }

        // this.firebaseMessagingService.requestPermission().subscribe((token) => {
        //     if (token && !localStorage.getItem('user_token_firebase')) {
        //         let channelToken: ChannelToken = new ChannelToken();
        //         channelToken.id = 'new_0';
        //         channelToken.attributes.token = token ?? '';
        //         channelToken.attributes.device = 'browser';

        //         this.transactionTemporalValues.addRelationship(channelToken, 'channels_tokens');
        //         localStorage.setItem('transaction_token_firebase', token);
        //     }

        //     this.temporalValuesVerify(transaction_temporal_values_id);
        // });
    }

    private temporalValuesVerify(transaction_temporal_values_id: string): void {
        this.transactionTemporalValues.id = transaction_temporal_values_id;
        this.transactionTemporalValues.is_new = false;
        this.transactionTemporalValues.save({ include: ['channels_tokens'] }).subscribe(() => {
            this.transactionInstructionsService.service = this.transactionTemporalValuesService;
            this.transactionInstructionsService.verifyTransaction(transaction_temporal_values_id);
        });
    }
}
