import { Injectable } from '@angular/core';
import { Service } from 'ngx-jsonapi';
import { TransactionStatePublic } from '../resources/transaction-state-public';

@Injectable({
    providedIn: 'root'
})
export class TransactionStatePublicService extends Service<TransactionStatePublic> {
    public resource: typeof TransactionStatePublic = TransactionStatePublic;
    public type: string = 'transactions_states_public';

    public constructor() {
        super();
        this.register();
    }
}
