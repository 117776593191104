export const about_us_en_US: any = {
    title: 'Know more about us',
    subtitle_one: 'We are here for you to exchange your balance in a secure way.',
    subtitle_two: "We've been helping people for exchanging their digital money for over 10 years.",

    symplicity_title: 'Simplicity',
    symplicity_info:
        " <p class='press-mission-vision'>Our platform is easy to use, making sure our users get what they want without any problems.</p>",

    transparency_title: 'Transparency',
    transparency_info:
        " <p class='press-mission-vision'>The small details are just as important as the big ones, we always make sure things are clear.</p>",

    customer_s_title: 'Customer service',
    customer_s_info:
        " <p class='press-mission-vision'>We count on a team of people prepared to resolve any doubts that arise in the process.</p>",

    innovation_title: 'Innovation',
    innovation_info:
        " <p class='press-mission-vision'>We work hard to help our users by using the latest technology and payment methods.</p>",

    our_team_title: 'Our Team',

    time_helping_people_banner_text:
        'We have been helping people from all over Latin America to exchange their virtual money for more than 10 years.',
    time_helping_people_banner_button: 'Start now',

    our_team_subtitle: 'We are constantly working in each area to offer you the best service.',
    our_team_support_title: 'Support',
    our_team_support_info:
        "<p class='press-mission-vision'>Our team is ready to help our customers every step of the way in using our platform.</p>",
    our_team_develop_design_title: 'Develop and design',
    our_team_develop_design_info:
        "<p class='press-mission-vision'>Our professional team is dedicated to keeping the site running properly, constantly improving the user's experience.</p>",
    our_team_security_title: 'Security',
    our_team_security_info:
        "<p class='press-mission-vision'>The combination of all these teams allows the customer to complete their order safely, without scams, and receive what was agreed.</p>",

    our_offices_title: 'Our offices',
    our_offices_info_li_one: 'Our offices are located in San Rafael, Mendoza, Argentina.',
    our_offices_info_li_two: 'We work both in-person and remotely.',

    press_download_title: 'Press downloads',
    press_download_subtitle_one: 'Logotype',
    press_download_subtitle_two: 'Institutional PDF',
    logo_file_one: 'Original Version',
    logo_file_two: 'White Version',
    file_one: 'Brand manual',
    file_two: 'Press release',

    related_news_title: 'Related news',
    related_un_new_text: 'Saldoar: This is how you can exchange your PayPal balance for bolivars.',
    related_forbes_new_text: 'How to export services and avoid official pesification.',
    related_iproup_new_text: 'Household purchases of the blue dollar are on the rise.',
    related_tiempo_final_new_text: 'Analysis of one of the best options to withdraw money from abroad.'
};
