import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ReferralsService } from './core/services/referrals.service';
import { Injectable, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SourceService } from './core/services/source.service';

@Injectable({
    providedIn: 'root'
})
export class ReferralsGuardService {
    public constructor(private referralService: ReferralsService, private sourceService: SourceService) {}

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const referralParam: string | null = route.queryParamMap.get('r');
        this.sourceService.listenParams(route.queryParams);

        if (referralParam) {
            this.referralService.extractReferralIdFromUrlParams(state.url);
        }

        return of(true);
    }
}

export const ReferralsGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
    return inject(ReferralsGuardService).canActivate(route, state);
};
