import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BestRatesService } from '@app/core/services/best-rates.service';
import { UserCountryService } from '@app/core/services/user-country.service';
import { BestRate } from '@app/core/resources/best-rate';
import { CountryProvider } from '@app/core/country-provider/country-provider';
import { HeaderCountryService } from '@app/core/components/header/header-country.service';
import { combineLatest } from 'rxjs';
import { SystemsService } from '@app/core/services/systems.service';
import { DocumentCollection } from 'ngx-jsonapi';
import { System } from '@app/core/resources/system';

export interface IRate {
    pair: string;
    price: number;
    system1_name: string;
    system2_name: string;
    link: string;
}

export interface IAllowedFilter {
    country_key: string;
    buy_filter: Array<string>;
    sell_filter: Array<string>;
}

export interface IBuyAndSell {
    pair: string;
    buy: number;
    sell: number;
    pairSystemBuy: any;
    linkBuy: string;
    pairSystemSell: any;
    linkSell: string;
}

@Component({
    selector: 'app-best-rates',
    templateUrl: './best-rates.component.html',
    styleUrls: ['./best-rates.component.scss']
})
export class BestRatesComponent implements OnInit {
    public buyBestRates: Array<IRate> = [];
    public sellBestRates: Array<IRate> = [];
    public buyAndSell: Array<IBuyAndSell> = [];
    private countryKey: string = '';
    private pairForAllCountries: Array<string> = ['BTC / USD', 'USD / BTC'];
    private bestRates: Array<BestRate> = [];
    private systems: DocumentCollection<System> = new DocumentCollection<System>();
    private filterPair: IAllowedFilter | undefined;
    private readonly currencyForAllCountries: string = 'usd';
    private readonly allowedFilter: Array<IAllowedFilter> = [
        {
            country_key: 'ars',
            buy_filter: ['USD / ARS', 'EUR / ARS'],
            sell_filter: ['ARS / USD', 'ARS / EUR']
        },
        {
            country_key: 'bob',
            buy_filter: ['USD / BOB', 'EUR / BOB'],
            sell_filter: ['BOB / USD', 'BOB / EUR']
        },
        {
            country_key: 'ves',
            buy_filter: ['USD / VES', 'EUR / VES'],
            sell_filter: ['VES / USD', 'VES / EUR']
        },
        {
            country_key: 'cop',
            buy_filter: ['USD / COP', 'EUR / COP'],
            sell_filter: ['COP / USD', 'COP / EUR']
        },
        {
            country_key: 'usd',
            buy_filter: ['USD / EUR'],
            sell_filter: ['EUR / USD']
        },
        {
            country_key: 'mxn',
            buy_filter: ['USD / MXN', 'EUR / MXN'],
            sell_filter: ['MXN / USD', 'MXN / EUR']
        }
    ];

    public constructor(
        private bestRatesService: BestRatesService,
        private headerService: HeaderCountryService,
        private changeDetectorRef: ChangeDetectorRef,
        private systemsService: SystemsService,
        private userCountryService: UserCountryService
    ) {
        this.observerSelectedCountry();
    }

    public ngOnInit(): void {
        this.userCountryService.getCountry().subscribe(
            (country) => {
                this.extractCountryKey(country.code);
                this.getBestRates();
            },
            () => {
                this.extractCountryKey(String(this.userCountryService.default_country_lang_data.attributes.code));
                this.getBestRates();
            }
        );
    }

    private observerSelectedCountry(): void {
        this.headerService.selectedCountry$.subscribe((country): void => {
            this.extractCountryKey(country.code);
            this.getBestRates();
        });
    }

    private extractCountryKey(countryCode: string): void {
        this.countryKey = new CountryProvider().getCurrency(countryCode).toLowerCase();
    }

    private getBestRates(): void {
        this.filterPair = this.allowedFilter.find((filterPair): boolean => filterPair.country_key === this.countryKey);
        combineLatest(this.bestRatesService.all(), this.systemsService.allIncludeRates()).subscribe(
            ([best_rates, systems]: [DocumentCollection<BestRate>, DocumentCollection<System>]): void => {
                this.systems = systems;
                this.buyAndSell = [];
                this.bestRates = best_rates.data;
                this.buyBestRates = this.createBestRates(this.filterPair?.buy_filter, 'buy');
                this.sellBestRates = this.createBestRates(this.filterPair?.sell_filter, 'sell');

                for (let sellIndex: number = 0; sellIndex < this.sellBestRates.length; sellIndex += 1) {
                    for (let buyIndex: number = 0; buyIndex < this.buyBestRates.length; buyIndex += 1) {
                        if (sellIndex === buyIndex) {
                            let bestRate: IBuyAndSell = {
                                pair: this.buyBestRates[buyIndex].pair,
                                buy: this.buyBestRates[buyIndex].price,
                                sell: this.sellBestRates[buyIndex].price,
                                pairSystemBuy: {
                                    system1_name: this.buyBestRates[buyIndex].system1_name,
                                    system2_name: this.buyBestRates[buyIndex].system2_name
                                },
                                linkBuy: this.buyBestRates[buyIndex].link,
                                pairSystemSell: {
                                    system1_name: this.sellBestRates[buyIndex].system1_name,
                                    system2_name: this.sellBestRates[buyIndex].system2_name
                                },
                                linkSell: this.sellBestRates[buyIndex].link
                            };
                            this.buyAndSell.push(bestRate);
                        }
                    }
                }
                this.changeDetectorRef.detectChanges();
            }
        );
    }

    private createBestRates(filterPair: Array<string> | undefined, action: 'buy' | 'sell'): Array<IRate> {
        let rates: Array<IRate> = this.extractRatesByCountryKey(action)
            .filter(({ pair }): any => filterPair?.includes(pair))
            .reverse();

        return this.reorderRates(rates);
    }

    private reorderRates(rates: Array<IRate>): any {
        let ratesWithPairDefault: Array<IRate> = rates.filter((rate): boolean => this.pairForAllCountries.includes(rate.pair));
        let ratesWithCountryKey: Array<IRate> = rates.filter((rate): boolean => !this.pairForAllCountries.includes(rate.pair));

        return ratesWithCountryKey.concat(ratesWithPairDefault);
    }

    private extractRatesByCountryKey(action: 'buy' | 'sell'): Array<IRate> {
        return this.bestRates
            .filter((best_rate): boolean => {
                let currency1: string = best_rate.attributes.currency1.toLowerCase();
                let currency2: string = best_rate.attributes.currency2.toLowerCase();

                return [currency1, currency2].includes(this.countryKey) || [currency1, currency2].includes(this.currencyForAllCountries);
            })
            .map((best_rate): any => {
                return {
                    pair: `${best_rate.attributes.currency1} / ${best_rate.attributes.currency2}`,
                    system1_name: this.systems.find(best_rate.attributes.system1)?.attributes.name,
                    system2_name: this.systems.find(best_rate.attributes.system2)?.attributes.name,
                    price: action === 'buy' ? best_rate.attributes.price : 1 / best_rate.attributes.price,
                    link: '/cobrar/' + best_rate.attributes.system1 + '-a-' + best_rate.attributes.system2
                };
            });
    }
}
