import { ICalculatorDetail, CalculatorDetailHelper, IDataForCalculator } from './calculator-detail.helper';
import { System } from '../resources/system';
import { Transaction } from '@app/transactions/transaction';

export class CalculatorProBuilderHelper {
    private transaction: Transaction = new Transaction();

    public setTransaction(transaction: Transaction): this {
        this.transaction = transaction;

        return this;
    }

    public getPositionNumber(positionNumber: number): ICalculatorDetail | null {
        let system1: System = this.transaction.relationships.system1.data ?? new System();
        let system2: System = this.transaction.relationships.system2.data ?? new System();
        let amount1: number = this.transaction.attributes.amount1;
        let amount2: number = this.transaction.attributes.amount2;

        switch (positionNumber) {
            case 1:
                return this.calculatorFactory(system1, system2, amount1, amount2).getDetail('send');
            case 2:
                return this.calculatorFactory(system1, system2, amount1, amount2).getDetail('receive');
            case 3:
                return this.calculatorFactory(system2, system1, amount2, amount1).getDetail('send');
            case 4:
                return this.calculatorFactory(system2, system1, amount2, amount1).getDetail('receive');
            default:
                return null;
        }
    }

    private calculatorFactory(system1: System, system2: System, amount1: number, amount2: number): CalculatorDetailHelper {
        let data: IDataForCalculator = {
            system: system1,
            auxSystem: system2,
            amount: amount1,
            auxAmount: amount2
        };

        return new CalculatorDetailHelper(data);
    }
}
