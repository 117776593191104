import { IStrategy } from './strategy';

export class Context {
    private strategy!: IStrategy;

    public execute(): void {
        this.strategy.execute();
    }
    public setStrategy(strategy: IStrategy): any {
        this.strategy = strategy;

        return this;
    }
}
