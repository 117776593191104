import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { TransactionPrivate } from '@app/core/resources/transaction-private';
import { Transaction } from '@app/transactions/transaction';
import { NetworkSystemService } from '@app/core/services/network-system.service';

@Component({
    selector: 'app-info-crypto-fee',
    templateUrl: './info-crypto-fee.component.html',
    styleUrls: ['./info-crypto-fee.component.scss']
})
export class InfoCryptoFeeComponent implements OnInit, OnDestroy {
    @Input() public transaction: Transaction | TransactionPrivate = new Transaction();
    public selectedNetworkTitle: string | null = null;
    public networkSubscription: Subscription = new Subscription();

    public constructor(private networkSystemService: NetworkSystemService) {}

    public ngOnInit(): void {
        this.selectedNetworkTitle = this.networkSystemService.selectedNetworkTitle;
        this.networkSubscription = this.networkSystemService.selectedNetworkTitleChanges().subscribe((title: string | null) => {
            this.selectedNetworkTitle = title;
        });
    }

    public ngOnDestroy(): void {
        this.networkSubscription.unsubscribe();
    }
}
