import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvertSystemsComponent } from './invert-systems.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [InvertSystemsComponent],
    exports: [InvertSystemsComponent],
    imports: [CommonModule, MatButtonModule, MatIconModule]
})
export class InvertSystemsModule {}
