import { Injectable } from '@angular/core';
import { Account } from '../resources/account';
import { Service } from 'ngx-jsonapi';
import { ChannelToken } from '../resources/channel-token';

@Injectable({
    providedIn: 'root'
})
export class ChannelsTokensService extends Service<Account> {
    public resource: typeof ChannelToken = ChannelToken;
    public type: string = 'channels_tokens';

    public constructor() {
        super();
        this.register();
    }
}
