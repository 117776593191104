import { Injectable } from '@angular/core';
import { JsonapiCore } from 'ngx-jsonapi';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { TokenStorage } from '@app/get-token';
import { TokenUpdateService } from '@app/dashboard/services/token-update.service';
import { FirebaseMessagingService } from '@app/firebase/firebase-messaging.service';

@Injectable()
export class GlobalStateService {
    public userId?: string;
    public level: number = 0;
    public name: string = '';
    public email: string = '';
    public token: string = '';
    public activated: boolean | null = null;

    public constructor(
        protected jsonapiCore: JsonapiCore,
        protected router: Router,
        private firebaseMessagingService: FirebaseMessagingService,
        protected jwtHelper: JwtHelperService,
        private tokenUpdateService: TokenUpdateService
    ) {
        let token: string = TokenStorage.getToken();
        if (token) {
            this.setToken(token);
        }
    }

    public setToken(token: string): void {
        let tokenPayload: any = this.jwtHelper.decodeToken(token);
        this.name = tokenPayload.data.d.name;
        this.level = tokenPayload.data.d.level;
        this.email = tokenPayload.data.d.email;
        this.userId = tokenPayload.data.d.uid;
        this.activated = tokenPayload.data.d.activated;
        this.tokenUpdateService.tokenUpdatedSource.next(this.userId);
    }

    public logout(redirect: boolean = true): void {
        this.firebaseMessagingService.deleteToken('user_token_firebase').subscribe();
        this.userId = undefined;
        this.jsonapiCore.clearCache();

        window.localStorage.clear();
        if (redirect) {
            this.rememberUrl();
        }
    }

    public rememberUrl(): void {
        this.router.navigate(['/my/login']);
    }
}
