import { IBankRepository } from './bank-repository-interface';

export class MexicoBankRepository implements IBankRepository {
    public readonly id: string = 'banco_mex';
    public readonly currency: string = 'MXN';
    public readonly groupId: string = 'mxn_bank';
    public readonly title: string = 'Intercambiar saldo México';
    public readonly urlHref: string = '/es-MX/a/banco_mex/zelle/0/100';
    public readonly countryName: string = 'México';
    public readonly currencyName: string = 'Pesos méxicanos';
    public readonly widthPositionSprite: string = '-72px';
    public readonly heightPositionSprite: string = '-106px';
    public readonly systemPair: string = 'zelle';
    public readonly langCode: string = 'es-MX';
}
