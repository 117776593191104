import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-deals-bag-amount-info',
    templateUrl: './deals-bag-amount-info.component.html',
    styleUrls: ['./deals-bag-amount-info.component.scss']
})
export class DealsBagAmountInfoComponent {
    @Input() public title: string = '';
    @Input() public showIcon: boolean = false;
    @Input() public systemId: string = '';
    @Input() public systemIconAlt: string = '';
    @Input() public amount: string = '';
    @Input() public currency: string = '';
}
