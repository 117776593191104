import { Transaction } from '@app/transactions/transaction';
import { FeeDefault } from './fee-default';
import { TransactionPrivate } from '@app/core/resources/transaction-private';

export class FeePayPal extends FeeDefault {
    private feeExtra: number = 0.3;

    public constructor(protected transaction: Transaction | TransactionPrivate) {
        super(transaction);
    }

    public getFee(): number {
        if (this.way === 'receive') {
            return 0;
        }

        this.setFeeAndDecimalPlaces();
        let fee: number = this.transaction.attributes['amount' + this.systemNumber] * this.fee + this.feeExtra;

        return parseFloat(fee.toFixed(this.decimal_places || 2));
    }

    public getAmountWithFee(): number {
        this.setFeeAndDecimalPlaces();
        let amountWithFee: number = (Number(this.transaction.attributes['amount' + this.systemNumber]) + this.feeExtra) / (1 - this.fee);

        return parseFloat(amountWithFee.toFixed(this.decimal_places || 2));
    }

    public getAmountWithoutFee(): number {
        this.setFeeAndDecimalPlaces();
        let amountWithoutFee: number = Number(this.transaction.attributes['amount' + this.systemNumber]) * (1 - this.fee) - this.feeExtra;

        return parseFloat(amountWithoutFee.toFixed(this.decimal_places || 2));
    }

    protected setFeeAndDecimalPlaces(): void {
        this.decimal_places = this.transaction.relationships['system' + this.systemNumber].data.attributes.decimal_places ?? 2;
        this.fee = 0.054;
    }
}
