import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullScreenComponent } from './full-screen.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [FullScreenComponent],
    exports: [FullScreenComponent],
    imports: [CommonModule, MatIconModule]
})
export class FullScreenModule {}
