import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LenguagesHelper } from '../helpers/lenguages.helper';
import { HeaderService } from './header-service';

@Injectable({
    providedIn: 'root'
})
export class TranslateSectionService {
    private lenguagesHelper: LenguagesHelper = new LenguagesHelper();

    public constructor(private headerService: HeaderService) {}

    public translate(lang: string, section: string, translate: TranslateService): void {
        translate.setTranslation(section, this.lenguagesHelper.getTranslateByLang(lang, section), true);
        translate.use(section);
        this.headerService.initializeSeoAndLanguageSettings(lang);
    }
}
