import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { PlatformBrowserService } from './platform-browser.service';

@Injectable({
    providedIn: 'root'
})
export class IntersectionObserverService {
    public constructor(private browserService: PlatformBrowserService) {}

    public observeElements(selectors: any, subject: Subject<any>): void {
        if (!this.browserService.isBrowser) {
            return;
        }

        const elements: NodeListOf<any> = document.querySelectorAll(selectors);
        elements.forEach((target: HTMLElement): void => {
            this.checkElementEntry(target, subject);
        });
    }

    public observeElement(selectors: any, subject: Subject<any>): void {
        if (!this.browserService.isBrowser) {
            return;
        }

        const element: HTMLElement = document.querySelector(selectors);

        this.checkElementEntry(element, subject);
    }

    private checkElementEntry(elementToObserver: HTMLElement, subject: Subject<any>): void {
        if (!('IntersectionObserver' in window)) {
            subject.next(elementToObserver);

            return;
        }

        if (elementToObserver === null || typeof elementToObserver !== 'object') {
            return;
        }

        const observer: IntersectionObserver = new IntersectionObserver((entries): void => {
            entries.forEach((entry): void => {
                if (entry.isIntersecting) {
                    observer.disconnect();
                    subject.next(entry);
                }
            });
        });

        try {
            observer.observe(elementToObserver);
        } catch (error) {
            console.log(
                'Este es el elemento que no esta definido, no deberia pasar ---> ',
                elementToObserver,
                ' ' + typeof elementToObserver
            );
            throw error;
        }
    }
}
