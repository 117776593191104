<div class="payment-methods-slide-container">
    <div class="control-left controls">
        <button class="button-left" *ngIf="withArrows && showButtonLeft" type="button" mat-fab (click)="moveSlideToRight()">
            <span class="material-icons-outlined">chevron_left</span>
        </button>
    </div>
    <div class="control-right controls">
        <button class="button-right" *ngIf="withArrows && showButtonRight" type="button" mat-fab (click)="moveSlideToLeft()">
            <span class="material-icons-outlined">chevron_right</span>
        </button>
    </div>
    <div #paymentMethodContainer class="app-payment-method-container container-elements-scroll">
        <div #paymentMethod class="app-payment-method d-flex flex-row">
            <ng-content></ng-content>
        </div>
    </div>
</div>
