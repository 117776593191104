<footer>

    <div class="rs-wrapper-v4" *ngIf="type !== 'instructions'">
        <div class="one-info-footer grid col3 col1-sm">
            <div class="flex-column-start-start">
                <h4>{{'bottom_page_account' | translate}}</h4>
                <a *ngFor="let link of myAccountLinks" class="rs-link" [routerLink]="linkWithLang(link.link)" [queryParams]="link.queryParams">{{ link.title | translate }}</a>
            </div>
            <div class="flex-column-start-start">
                <h4>{{'bottom_page_support' | translate}}</h4>
                <ng-container *ngFor="let link of helpLinks; let i = index">
                    <a class="rs-link" [routerLink]="linkWithLang(link.link)" [fragment]="link.fragment">
                        {{ link.title | translate }}
                    </a>
                </ng-container>
            </div>
            <div class="flex-column-start-start">
                <h4>{{'bottom_page_legal' | translate}}</h4>
                <a *ngFor="let link of legalLinks" class="rs-link" [routerLink]="linkWithLang(link.link)">{{ link.title | translate }}</a>
            </div>
        </div>
        <div class="two-info-footer grid col3 col1-sm">
            <div class="container-footer-icon-social">
                <a *ngFor="let icon of icons_social" title="{{icon.netName}}" [href]="icon.url"
                    [ngStyle]="{'background-position-x': icon.position}" target="_blank" rel="noopener"
                    aria-label="icon.netName"></a>
            </div>
            <div class="container-logo-footer">
                <app-logo-redirects [link]="'/'"></app-logo-redirects>
            </div>
            <p>{{ 'bottom_page_all_right_reserved' | translate }}</p>
        </div>
    </div>
</footer>
