export const referral_program_en_US: any = {
    title: 'Referral Program, earn up to $30 for each referral💸',
    subtitle: 'Thats right! Earn up to $30 for each person you invite to Saldoar and complete an order!',

    hero_button_text: '¡Start referring now!',

    subtitle_two: '¡Simpler and you earn more!',

    win_usd_first_title: 'Up to 15 USD per invitation',
    win_usd_first_info_text: 'With the first 3 orders placed by your guest, you get up to 15 USD!',

    win_usd_second_title: 'Then, another 5 USD more',
    win_usd_second_info_text: 'If the invited person reaches level 3, you increase your earnings.',

    win_usd_third_title: 'and 10 USD extra',
    win_usd_third_info_text: "If you're lucky enough to have your guest reach level 5 in Saldoar",

    win_usd_first_info: "You earn up to <b>5 USD</b> for each of the first 3 completed orders of your invitee. That's up to <b>15 USD</b>!",
    win_usd_second_info: 'You also earn <b>5 more USD</b> if your invitee reaches level 3.',
    win_usd_third_info: 'If your invitee reaches level 5, you earn another <b>10 USD</b',

    retire_usd: 'What are the requirements to start earning?',
    retire_usd_info:
        '<p>Only one: To have completed at least one operation on the platform. That way you can tell your guests how Saldoar works and how easy it is to use.</p>',

    retire_usd_button_text: 'Start referring now',

    necessary_level: 'How to refer?',
    necessary_level_info:
        '<p>Login to Saldoar and go to the home page. With your username and password log in and then go to the "Referrals" section. Copy your code from the "Invite and earn!" section to share it with whoever you want to refer. Once your referral places an order, you\'ll start earning.</p>',

    requirements_needed: 'How do you withdraw profits?',
    requirements_needed_info:
        '<p>You can withdraw them with PayPal whenever you want and the minimum withdrawal is 15 USD. You can also choose any of the other exchange methods available in Saldoar at the exchange rate of the day, such as Bitcoin, Skrill, MercadoPago, etc.</p>',

    requirements_needed_button_text: 'Login to withdraw your money',

    terms_of_use: 'Terms and conditions of use of the program',
    terms_of_use_info_li_one:
        'SaldoAr reserves the right to withhold payments in the event of any fraudulent or dishonest activity on the part of the inviter or invitee.',
    terms_of_use_info_li_two: 'The inviter must have at least one successful completed order.',
    terms_of_use_info_li_three:
        'In the event that the account of the inviter or invitee is limited or blocked, SaldoAr shall not make the payment.',
    terms_of_use_info_li_four: 'To proceed with a withdrawal of earnings, the minimum amount must be 15 USD.',
    terms_of_use_info_li_five:
        'The default method of withdrawal under the terms is PayPal. However, the user can opt for any other available method on the platform at a proportionate exchange rate.',
    terms_of_use_info_li_six: 'The terms of the referral program may be subject to change without prior notice.',
    terms_of_use_info_li_seven:
        'An invitee is considered a user who completed their first transaction through a referral link provided by the inviter.',
    terms_of_use_info_li_eight:
        'The commission for the first, second and third successful operation of the invitee will be 1% of the total amount sent, at the PayPal USD exchange rate at the time, with a maximum of 5 USD.',
    terms_of_use_info_li_nine: 'For security reasons, among others, the amount of orders from invited users may be subject to change.',
    terms_of_use_info_li_ten: 'The program only applies to the exchange of balances through the platform.',
    terms_of_use_info_li_eleven:
        'The inviter will earn 5 USD when the invitee reaches level 3 for the first time. The inviter will earn 10 USD when the invitee reaches level 5 for the first time.',
    terms_of_use_info_li_twelve:
        "The inviter and invitee accept and comply with all the <a class='rs-link' href='/wiki/terms'>terms and conditions of use and navigation</a> of SaldoAr.",
    terms_of_use_info_li_thirteen: 'None of the employees of SaldoAr can participate/operate in this program',
    terms_of_use_info_li_fourteen:
        'During the Promo Referidos x2x2, any earnings generated by your referrals between 12:00 AM on May 11, 2022 and 11:59 PM on July 10, 2022 will be counted as double.',

    flyer_info_text: 'Now that you know... <br>¡Begin to refer!</br>',
    flyer_info_text_button: 'Invite friends'
};
