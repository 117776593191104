<section class="rs-wrapper-v4 grid col2 col1-sm mb-per" >
    <app-successful-transactions class="flex-row-center-center"></app-successful-transactions>
    <div class="container-best-rates">
        <app-best-rates class="rshide-lt-sm"></app-best-rates>
    </div>
</section>

<app-systems-feature class="lazyload-user-social"></app-systems-feature>

<app-user-social></app-user-social>

<app-payment-methods [systems]="systems"></app-payment-methods>

<app-contact title="{{ 'contact_title' | translate }}"></app-contact>
