export const why_choose_es_AR: any = {
    flayer_saldo:
        'Saldoar fue creada para resolver los problemas que tienen los argentinos cuando operan en distintos niveles: importaciones, cobro de productos o ahorro.',

    landing_social_title: 'El 90% de nuestros clientes recomienda Saldoar',

    landing_social_p: `A los usuarios les encanta operar con nosotros: Aseguran que la plataforma es segura y fácil de usar.
        Nuestra última encuesta con mas de 15.000 usuarios indica que 9 de cada 10 personas le recomendaron la plataforma a un amigo para que pueda intercambiar su dinero digital.`,

    landing_social_title_two: 'Con nosotros, tú y tu dinero estarán siempre seguros',

    landing_social_p_two:
        'Tenemos un equipo de soporte al cliente que estará junto a ti a lo largo de todo el proceso, para que en caso de que te sientas desconforme, inseguro o irritado puedes comunicarle tu situación a un asesor y te ayude con tu pedido.',

    landing_social_support: 'Comunicarme con soporte',

    landing_social_h2: 'Hacemos las cosas de manera diferente',

    landing_social_p_three: 'Nos enfocamos en los detalles de todo lo que hacemos. Estamos orgullosos de ello, es por eso que:',

    landing_social_h2_two: 'Y ahora que nos conoces...<br>¡Hazte nuestro amigo!</br>',

    landing_social_button: 'Crear pedido'
};
