export const header_es_AR: any = {
    header_associates_program_button: 'Programas de referidos',
    header_my_order_button: 'Mi pedido',
    header_country_in_en_button: 'International | English',
    header_country_in_es_button: 'Internacional | Español',
    VCC_buy: 'Comprar VCC',

    bottom_page_legal: 'Legales',
    bottom_page_legal_warranty_button: 'Garantía',
    bottom_page_legal_terms_of_use_button: 'Términos y condiciones',
    bottom_page_legal_privacy_policy_button: 'Políticas de privacidad',
    bottom_page_all_right_reserved: 'Todos los derechos reservados © SALDOAR',

    bottom_page_support: 'Ayuda',
    bottom_page_support_center_button: 'Centro de ayuda',
    bottom_page_support_frequent_questions_button: 'Preguntas frecuentes',
    bottom_page_support_about_us_button: 'Quiénes somos',
    bottom_page_support_associates_program: 'Programa de referidos',

    bottom_page_account: 'Mi cuenta',
    bottom_page_account_log_in_button: 'Iniciar sesión',
    bottom_page_account_sign_in_button: 'Registrarse',

    meta_set_og_default_title: 'Saldoar | Convierte y envía dinero digital en 3 simples pasos',
    meta_set_og_update_title: 'Cambia tu saldo {{ element_one }} a {{ element_two }}',
    meta_set_og_default_description:
        'Elige entre más de 20 métodos de intercambio diferentes: Transferencia Bancaria, PayPal, Mercado Pago, Skrill, Ualá, Wise...'
};
