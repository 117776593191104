import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BestRatesComponent } from '@app/landing-page/components/best-rates/best-rates.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [BestRatesComponent],
    exports: [BestRatesComponent],
    imports: [CommonModule, RouterModule, TranslateModule]
})
export class BestRatesModule {}
