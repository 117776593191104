import { Injectable } from '@angular/core';
import { Deal } from '../resources/deal';
import { Service } from 'ngx-jsonapi';

@Injectable({
    providedIn: 'root'
})
export class DealService extends Service<Deal> {
    public resource: typeof Deal = Deal;
    public type: string = 'deals';

    public constructor() {
        super();
        this.register();
    }
}
