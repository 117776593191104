import { Subject } from 'rxjs';
import { IStrategy } from '../patrons/strategy';
import { DealsBagFillingService } from '@app/dashboard/deals/deals-bag-filling.service';
import { ServiceLoader } from '../services/service-loader';

export class UnavailableDeal implements IStrategy {
    public updateDealsBags$: Subject<any> = new Subject();
    private dealsBagService!: DealsBagFillingService;

    public constructor() {
        this.dealsBagService = ServiceLoader.injector.get(DealsBagFillingService);
    }

    public execute(): void {
        this.dealsBagService.updateDealsBags$.next(true);
    }
}
