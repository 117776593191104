<div class="rs-wrapper-v4 default-space-between-components">
    <div class="flex-row-center-center social-comment-line google-logo-container">
        <h3 title="{{'trustpilot_opinions_hover_title' | translate}}">{{ 'trustpilot_opinions_title' | translate }}</h3>
        <img class="google-logo" src="../../../../assets/landing-page/google-logo.svg" width="150" height="49.38"
            alt="Google Logo">
    </div>
    <div class="flex-row-center-center social-comment-line google-review">
        <h2>4.9</h2>
        <ng-container *ngFor="let i of [1, 2, 3, 4, 5]">
            <div class="star-container">
                <mat-icon>star_24dp</mat-icon>
            </div>
        </ng-container>
        <!-- <img src="../../../../assets/landing-page/google-review-icon.svg" width="205" height="60" alt="Google Review"> -->
        <!-- <img src="../../../../assets/landing-page/truspilot-review-icon.svg" width="249" height="58" alt="Trustpilot"> -->
    </div>
    <div class="flex-column-center-center">
        <mat-spinner *ngIf="!loadCarousel" diameter="80"></mat-spinner>
        <app-ngx-loadable class="lazy-module" module="user-cards-carousel" [show]="loadCarousel"></app-ngx-loadable>
    </div>
</div>
