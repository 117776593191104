import { ElementRef } from '@angular/core';

export class AnimationHeaderMovilHelper {
    public HEADER_HEIGHT: number = 120;
    private scrollAux: number = 0;

    public constructor(private header: ElementRef) {}

    public headerScrollMobile(isDashboard: boolean = false): void {
        if (this.scrollAux <= window.scrollY || window.scrollY === 0) {
            if (!isDashboard) {
                this.header.nativeElement.style.backgroundColor = '#f9f9f9';
            }
            this.header.nativeElement.classList.add('delete-shadow-header');
            this.header.nativeElement.classList.remove('show-header-scroll');
        } else {
            this.header.nativeElement.classList.add('show-header-scroll');
            this.header.nativeElement.classList.remove('delete-shadow-header');
        }

        this.scrollAux = window.scrollY;
    }

    public headerScrollDesktop(): void {
        if (window.pageYOffset === 0) {
            this.header.nativeElement.style.backgroundColor = 'transparent';
            this.header.nativeElement.style.height = this.HEADER_HEIGHT - window.scrollY + 'px';
            this.header.nativeElement.classList.remove('shadow-header-scroll');
        } else {
            this.header.nativeElement.classList.add('shadow-header-scroll');
            this.header.nativeElement.style.height =
                (this.HEADER_HEIGHT - window.scrollY < 0 ? 80 : this.HEADER_HEIGHT - window.scrollY) + 'px';
        }
    }
}
