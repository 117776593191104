import { CryptoHelper } from '../../crypto.helper';
import { ISystemRepository } from './system-repository-interface';

export class CryptoInformation implements ISystemRepository {
    private cryptoHelper: CryptoHelper = new CryptoHelper();
    protected cryptoId: string = '';

    public getAvailableReceive(): boolean {
        return true;
    }

    public getAvailableSend(): boolean {
        return true;
    }

    public getInfoSend(): string {
        return this.cryptoHelper.getCryptoInfo(this.cryptoId).send;
    }

    public getInfoReceive(): string {
        return this.cryptoHelper.getCryptoInfo(this.cryptoId).receive;
    }
}
