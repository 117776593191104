import { Injectable } from '@angular/core';
import { System } from '@app/core/resources/system';
import { DocumentCollection, Service } from 'ngx-jsonapi';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SystemsService extends Service<System> {
    public resource: typeof System = System;
    public type: string = 'systems';

    public constructor() {
        super();
        this.register();
    }

    public allIncludeRates(): Observable<DocumentCollection<System>> {
        return this.all({ include: ['rates', 'currency', 'networks'] }).pipe(filter((systems): boolean => systems.source !== 'new'));
    }
}
