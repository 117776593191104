export const landing_bob_es_AR: any = {
    title: 'Cotización del dólar-boliviano en tiempo real',
    subtitle:
        'Consulta la cotización actual del dólar paralelo y del dólar oficial en Bolivia, obtén información sobre el mercado cambiario y regístrate para recibir actualizaciones.',
    titleGraphic: 'Cotización de USDT a lo largo del tiempo',
    info_block_title: 'Compra USDT con PayPal',
    info_block_subtitle:
        '<p>Con Saldoar puedes convertir dólares, usando plataformas como PayPal, Wise, Skrill, Perfect Money, pagando con moneda local.</p>',
    info_block_button: 'Cotizar ahora',
    flyer_saldo: '¡Regístrate ahora y recibe actualizaciones gratuitas sobre el precio del dólar!',
    flyer_button: 'Regístrarme ahora'
};
