import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

document.addEventListener('DOMContentLoaded', (): void => {
    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .catch((err): void => console.error('ERROR in main.ts!', err));
});
