import { System } from '../resources/system';

export class DecimalSystemHelper {
    public parseAmountByDecimal(system: System, amount: number): number {
        if (system.attributes.decimal_places === 0) {
            return Math.floor(amount);
        } else {
            return parseFloat(Number(amount).toFixed(system.attributes.decimal_places));
        }
    }
}
