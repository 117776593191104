import { Injectable } from '@angular/core';
import { Service } from 'ngx-jsonapi';
import { ClientValue } from '../resources/client-value';

@Injectable({
    providedIn: 'root'
})
export class ClientValuesService extends Service<ClientValue> {
    public resource: typeof ClientValue = ClientValue;
    public type: string = 'client_values';

    public constructor() {
        super();
        this.register();
    }
}
