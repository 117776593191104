import { System } from '../resources/system';
import { CurrencyPiorityService } from '../services/currency-piority.service';
import { ServiceLoader } from '@app/core/services/service-loader';
import { FormatDecimalsHelper } from './format-decimals-helper';

export class MarketComparatorHelper {
    private markets: { [property: string]: string } = {
        USD_ARS: 'Blue',
        USD_VES: 'Paralelo',
        BTC_ARS: 'Market',
        BTC_USD: 'Market',
        BTC_EUR: 'Market'
    };
    public currencyPriority: CurrencyPiorityService;
    public formatDecimals: FormatDecimalsHelper = new FormatDecimalsHelper();

    public constructor() {
        this.currencyPriority = ServiceLoader.injector.get(CurrencyPiorityService);
    }

    public getMarketComparison(system1: System, system2: System, quotation: number): string {
        if (system1.attributes.currency === system2.attributes.currency) {
            return '';
        }

        let currencyAndDifference: any = this.currencyPriority.callbacksAndReturnByCurrency(
            system1,
            system2,
            (): { [property: string]: string | number } => {
                return {
                    diffPercentage: this.calculateDiffPercentage(this.calculateMarketQuotation(system1, system2), quotation),
                    currency: system1.attributes.currency
                };
            },
            (): { [property: string]: string | number } => {
                return {
                    diffPercentage: this.calculateDiffPercentage(this.calculateMarketQuotation(system2, system1), quotation),
                    currency: system2.attributes.currency
                };
            }
        );

        let sign: string = Math.sign(currencyAndDifference.diffPercentage) === 1 ? ' +' : ' ';
        let market: string | undefined = this.getMarketByCurrencies(system1, system2);

        return market ? market + sign + this.formatDecimals.formatDecimal(currencyAndDifference.diffPercentage, 2) + '%' : '';
    }

    public getMarketComparisonPercent(system1: System, system2: System, quotation: number): number {
        if (system1.attributes.currency === system2.attributes.currency) {
            return 0;
        }

        return this.currencyPriority.callbacksAndReturnByCurrency(
            system1,
            system2,
            (): number => {
                return this.calculateDiffPercentage(this.calculateMarketQuotation(system1, system2), quotation);
            },
            (): number => {
                return this.calculateDiffPercentage(this.calculateMarketQuotation(system2, system1), quotation);
            }
        );
    }

    private calculateMarketQuotation(systemPriority: System, system: System): number {
        let usd_price_system_priority: number | undefined = systemPriority.relationships.currency.data?.attributes.price;
        let usd_price_system: number | undefined = system.relationships.currency.data?.attributes.price;

        if (!usd_price_system || !usd_price_system_priority) {
            return 0;
        }

        return (1 / usd_price_system_priority) * usd_price_system;
    }

    private calculateDiffPercentage(value1: number, value2: number): number {
        // eslint-disable-next-line @typescript-eslint/typedef
        const HUNDRED_PERCENT = 100;

        return Number(((value2 * HUNDRED_PERCENT) / value1 - HUNDRED_PERCENT).toFixed(1));
    }

    public getMarketByCurrencies(system1: System, system2: System): string | undefined {
        let currencyPriority: any = this.currencyPriority.callbacksAndReturnByCurrency(
            system1,
            system2,
            (): string => system1.attributes.currency,
            (): string => system2.attributes.currency
        );

        if (currencyPriority === system1.attributes.currency) {
            return this.markets[currencyPriority + '_' + system2.attributes.currency];
        }

        return this.markets[currencyPriority + '_' + system1.attributes.currency];
    }
}
