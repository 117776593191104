<mat-card class="rs-wrapper-v4" *ngIf="publicDealsSystemService.receiveSystem.id !== ''">
    <mat-card-content class="container-quotation-benefit">
        <h3
            [innerHTML]="'public_dials_view_offers_button_save' | translate: {element: dialogData.publicDeals.attributes.gain_percent}">
        </h3>
    </mat-card-content>

    <mat-card-content class="container-text-public-deal">
        <p *ngIf="quotationInfo && quotationInfo.way === 2" [innerHTML]="'public_dials_view_offers_button_save_br_one' | translate: {
            element_one: 1,
            element_two: publicDealsSystemService.currency,
            element_three: (quotationInfo.quotation | decimalBySystem: publicDealsSystemService.receiveSystem),
            element_four: (quotationInfo.realQuotation | decimalBySystem: publicDealsSystemService.receiveSystem),
            element_five: dialogData.publicDeals.relationships.system.data.attributes.currency
        }"></p>
        <p *ngIf="quotationInfo && quotationInfo.way  === 1" [innerHTML]="'public_dials_view_offers_button_save_br_one_way1' | translate: {
            element_one: dialogData.publicDeals.relationships.system.data.attributes.currency,
            element_two: (quotationInfo.quotation | decimalBySystem: publicDealsSystemService.receiveSystem),
            element_three: (quotationInfo.realQuotation | decimalBySystem: publicDealsSystemService.receiveSystem),
            element_four: publicDealsSystemService.currency
        }"></p>
    </mat-card-content>

    <mat-card-content class="container-stepper">
        <mat-stepper orientation="vertical" labelPosition="bottom" linear #stepper>
            <mat-step completed="false">
                <ng-template matStepLabel>
                    <app-deals-bag-amount-info title="{{'public_dials_you_send' | translate}}" [showIcon]="true"
                        systemId="{{dialogData.publicDeals.attributes.group_id}}"
                        systemIconAlt="{{dialogData.publicDeals.relationships.system.data ? dialogData.publicDeals.relationships.system.data.attributes.name : ''}}"
                        [amount]="dialogData.publicDeals.attributes.rest_to_pay ? (dialogData.publicDeals.attributes.rest_to_pay | decimalBySystem: publicDealsSystemService.receiveSystem) : ''"
                        [currency]="dialogData.publicDeals.relationships.system.data.attributes.currency"></app-deals-bag-amount-info>
                </ng-template>
            </mat-step>
            <mat-step completed="false">
                <ng-template matStepLabel>
                    <app-deals-bag-amount-info class="quotation-send-you"
                        [title]="'public_deals_view_offers_would_send' | translate"
                        [amount]="publicDealsSystemService.amountWithoutGain ? publicDealsSystemService.amountWithoutGain.toString() : ''"
                        [currency]="publicDealsSystemService.currency"></app-deals-bag-amount-info>
                </ng-template>
            </mat-step>
            <mat-step completed="false">
                <ng-template matStepLabel>
                    <div class="second-system-deals">
                        <p>{{'public_dials_we_send' | translate}}</p>
                        <form [formGroup]="systemReceiveFormGroup">
                            <mat-form-field appearance="outline">
                                <mat-select formControlName="systemSelect">
                                    <mat-select-trigger class="flex-row-start-center">
                                        <app-system-icon class="system-icon-select-account-one"
                                            systemId="{{publicDealsSystemService.receiveSystem.attributes.group_id}}"
                                            alt="{{publicDealsSystemService.receiveSystem.attributes.name}}">
                                        </app-system-icon>
                                    </mat-select-trigger>
                                    <mat-option *ngFor="let system of publicDealsSystemService.systems"
                                        [value]="system">
                                        <app-system-icon systemId="{{system.attributes.group_id}}"
                                            alt="{{system.attributes.name}}">
                                        </app-system-icon>
                                        {{system.attributes.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </form>
                        <p>{{ publicDealsSystemService.amount2 ? publicDealsSystemService.amount2.toString() : '' }} {{
                            publicDealsSystemService.currency }}</p>
                    </div>
                </ng-template>
            </mat-step>
        </mat-stepper>
    </mat-card-content>

    <mat-card-content *ngIf="publicDealsSystemService.receiveSystem.id !== ''">
        <div class="container-quotation-benefit">
            <p [innerHTML]="'public_dials_view_offers_button_save_br_two' | translate: {
                element_one: formatToDecimal((publicDealsSystemService.amount2 - publicDealsSystemService.amountWithoutGain), publicDealsSystemService.receiveSystem),
                element_two: publicDealsSystemService.currency
            }"></p>
        </div>
    </mat-card-content>

    <div class="button-actions">
        <button mat-stroked-button type="button"
            (click)="closeDialogInfoPublicDeal()">{{'public_dials_view_offers_button_close' | translate}}</button>
        <button mat-button *ngIf="dialogData.btnDeal" (click)="redirectTo()"
            cdkFocusInitial>{{'public_dials_view_offers_button' | translate}}</button>
        <button mat-button *ngIf="!dialogData.btnDeal && globalStateService.level >= 3" type="button"
            (click)="closeDialogInfoPublicDeal(dialogData.publicDeals)">Agregar +</button>
    </div>

</mat-card>