import { Injectable } from '@angular/core';
import { Service } from 'ngx-jsonapi';
import { TransactionTemporalValue } from '../resources/transaction-temporal-value';

@Injectable({
    providedIn: 'root'
})
export class TransactionTemporalValuesService extends Service<TransactionTemporalValue> {
    public resource: typeof TransactionTemporalValue = TransactionTemporalValue;
    public type: string = 'transaction_temporal_values';

    public constructor() {
        super();
        this.register();
    }
}
