import { Router } from '@angular/router';
import { PlatformBrowserService } from '@app/core/services/platform-browser.service';
import { ServiceLoader } from '@app/core/services/service-loader';

export class RedirectInstructionsHerlper {
    public router: Router;
    private browserService: PlatformBrowserService;

    public constructor() {
        this.router = ServiceLoader.injector.get(Router);
        this.browserService = ServiceLoader.injector.get(PlatformBrowserService);
    }

    public redirectTo(state: number = 0, transactionId: string): void {
        if (!this.browserService.isBrowser) {
            return;
        }

        if (state === 16) {
            this.router.navigate(['/my/dashboard/validations']);

            return;
        }

        this.router.navigate(['/my/dashboard/transactions/' + transactionId + '/instructions']);
    }
}
