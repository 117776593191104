export const frequent_questions_en_US: any = {
    title: 'Frequent questions about SaldoAr',

    question_one: '1. What is SaldoAr and how does it work?',
    question_one_info:
        '<p>SaldoAr is a service that allows securely exchanging virtual money, connecting people around the world to achieve a quick exchange and an excellent quote.</p>',

    question_two: '2. Is it necessary to have a registered account to operate in SaldoAr?',
    question_two_info:
        "<p>It is not necessary, because to operate in <a class='rs-link' href='https://saldo.com.ar/' title='Change PayPal, Skrill or MercadoPago money' target='_blank'>SaldoAr</a>, you need to create an order with the specified info: method of payment and choose the balance you want in exchange.</p>",

    question_three: '3. Can I talk to someone to resolve my doubts?',
    question_three_info:
        "<p>You can contact us through the platform chat by <a class='rs-link' href='https://saldo.com.ar/my/login' title='Log in SaldoAr' target= '_blank'>logging in</a> and going to the My Orders Tab.</p>",

    question_four: "4. Can I receive the money in someone else's account?",
    question_four_info:
        "<p>If you make an order on <a class='rs-link' href='https://saldo.com.ar/' title='Change PayPal, Skrill or MercadoPago money' target='_blank'>SaldoAr</a>, you can receive your money in the account of your choice as long as you are the holder of the account that makes the payment. With that in mind, you can receive the money in the account of a family member, friend, or whoever you want.<p>",

    question_five: '5. SaldoAr informs AFIP about my order?',
    question_five_info:
        '<p>SaldoAr is not obliged to report your transactions to the AFIP (Argentinian Federal Administration of Public Revenue). You will be solely responsible for reporting any tax obligations and charges generated within the platform, so we recommend keeping your tax situation to avoid any issues.<p>',

    question_six: '6. Why do we request your documentation in Saldoar?',
    question_six_info:
        '<p>At Saldoar we validate the identification of all our users to guarantee a secure and reliable environment for transactions. This simple verification process confirms your identity and that the data entered is real.<p>',

    question_seven: '7. How do I view my transaction history?',
    question_seven_info:
        '<p><a class="rs-link" href="https://saldo.com.ar/my/signup" title="Cambiar saldo PayPal, Skrill o MercadoPago" target="_blank">Register</a> in Saldoar with the email address you normally use and you will be able to access the complete history of your orders.</p>',

    question_eigth: '8. What are the operating commissions?',
    question_eigth_info:
        "In Saldoar we do not have hidden commissions, so in the calculator you'll clearly see the commissions applied to the final amount that you will receive in your account.",

    go_saldoar_blog_info:
        '<p>If you didnt find the answer you\'re looking for here, you can  <a class="rs-link" href="https://blog.saldo.com.ar/" title="Blog Saldoar" target="_blank">visit Saldoar blog</a> for more information.</p>'
};
