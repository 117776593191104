import { DefaultInformationRepository } from './default-information-repository';

export class ZinliInformationRepository extends DefaultInformationRepository {
    public getAvailableReceive(): boolean {
        return true;
    }

    public getAvailableSend(): boolean {
        return true;
    }
    public getInfoReceive(): string {
        return 'warning_message_zinli_recibe';
    }
}
