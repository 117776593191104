import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { DomainToProviderComponent } from './domain-to-provider.component';

@NgModule({
    declarations: [DomainToProviderComponent],
    exports: [DomainToProviderComponent],
    imports: [CommonModule, MatButtonModule]
})
export class DomainToProviderModule {}
