import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-calculator-skeleton',
    templateUrl: './calculator-skeleton.component.html',
    styleUrls: ['./calculator-skeleton.component.scss']
})
export class CalculatorSkeletonComponent {
    @Input() public actionButtonText: string = '';
}
