import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { System } from '@app/core/resources/system';
import { Transaction } from '../transaction';

@Injectable()
/**
 * @deprecated Use TransactionObserverService instead
 */
export class SystemsUpdatedService {
    public systemsUpdatedSource: ReplaySubject<[System, System]> = new ReplaySubject<[System, System]>(1);
    public transactionUpdatedSource: ReplaySubject<Transaction> = new ReplaySubject<Transaction>(1);
    public systemsUpdated$: Observable<[System, System]> = this.systemsUpdatedSource.asObservable();

    public emitSystemsUpdatedEvent(sendSystem: System, receiveSystem: System): void {
        this.systemsUpdatedSource.next([sendSystem, receiveSystem]);
    }
}
