import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnimatedBlurredCirclesComponent } from './animated-blurred-circles.component';

@NgModule({
    declarations: [AnimatedBlurredCirclesComponent],
    exports: [AnimatedBlurredCirclesComponent],
    imports: [CommonModule]
})
export class AnimatedBlurredCirclesModule {}
