import { TranslateService } from '@ngx-translate/core';
import { SeoService } from './seo.service';
import { Injectable, inject } from '@angular/core';
import { combineLatest } from 'rxjs';
import { environment } from 'environments/environment.base';
@Injectable({
    providedIn: 'root'
})
export class OgService {
    private translateService: TranslateService;
    private seoService: SeoService;

    public constructor() {
        this.translateService = inject(TranslateService);
        this.seoService = inject(SeoService);
    }

    public addOg(url: string = '', urlOgImg: string = ''): void {
        combineLatest(
            this.translateService.get('meta_set_og_default_title'),
            this.translateService.get('meta_set_og_default_description')
        ).subscribe((langs: Array<string>) => {
            this.seoService.addOrUpdateMetaProperty('og:title', langs[0]);
            this.seoService.addOrUpdateMetaProperty('og:description', langs[1]);
            this.seoService.addOrUpdateMetaProperty('og:site_name', 'Saldoar');
            this.seoService.addOrUpdateMetaProperty('og:url', environment.CANONICAL_ORIGIN_URL + url);
            this.seoService.addOrUpdateMetaProperty('twitter:card', 'summary');
            this.seoService.addOrUpdateMetaProperty(
                'twitter:image',
                environment.CANONICAL_ORIGIN_URL + (urlOgImg === '' ? 'assets/images/og/og-cambia-saldo-v2.png' : urlOgImg)
            );
            this.seoService.addOrUpdateMetaProperty('twitter:site', '@SaldoComAr');
            this.seoService.addOrUpdateMetaProperty('twitter:title', langs[0]);
            this.seoService.addOrUpdateMetaProperty('twitter:description', langs[1]);
        });

        this.seoService.addOrUpdateMetaProperty(
            'og:image',
            environment.CANONICAL_ORIGIN_URL + (urlOgImg === '' ? 'assets/images/og/og-cambia-saldo-v2.png' : urlOgImg)
        );
    }
}
