export const frequent_questions_es_AR: any = {
    title: 'Preguntas frecuentes sobre Saldoar',

    question_one: '¿Qué es Saldoar y cómo funciona?',
    question_one_info:
        '<p>Saldoar es un servicio que permite intercambiar saldo digital de manera segura, conectando a personas de todo el mundo para lograr un intercambio rápido y a una excelente cotización.</p>',

    question_two: '¿Es necesario que me registre para poder operar?',
    question_two_info:
        '<p>No es necesario que te registres, ya que para operar solo debes ingresar a <a class="rs-link" href="https://saldo.com.ar/" title="Cambiar saldo PayPal, Skrill o MercadoPago" target="_blank">Saldoar</a>, crear tu pedido eligiendo la forma de pago a enviar y elegir el saldo que desees a cambio.</p>',

    question_three: '¿Puedo hablar con alguien para resolver mis dudas?',
    question_three_info:
        '<p>¡Claro que sí! Puedes comunicarte con un operador mediante el chat de la plataforma, <a class="rs-link" href="https://saldo.com.ar/my/login" title="Iniciar sesión en Saldoar" target="_blank">iniciando sesión</a> y dirigiéndote a la pestaña "Pedidos".</p>',

    question_four: '¿Puedo recibir el dinero en la cuenta de otra persona?',
    question_four_info:
        '<p>Si realizas un pedido en <a class="rs-link" href="https://saldo.com.ar/" title="Cambiar saldo PayPal, Skrill o MercadoPago" target="_blank">Saldoar</a> puedes recibir tu dinero en la cuenta que desees siempre y cuando seas tú el titular de la cuenta que emite el pago. Teniendo eso en cuenta podrás recibir en la cuenta de un familiar, amigo, conocido o quien tu quieras.</p>',

    question_five: '¿Saldoar informa a la AFIP?',
    question_five_info:
        '<p>Saldoar no tiene la obligación de reportar tus movimientos a la AFIP. Tu serás el único responsable de reportar las obligaciones y cargas impositivas que se generen dentro de la plataforma, por lo que te recomendamos mantener tu situación fiscal en orden para evitar inconvenientes.<p>',

    question_six: '¿Por qué solicitamos tu documentación en Saldoar?',
    question_six_info:
        '<p>En Saldoar validamos la identidad de todos nuestros usuarios para garantizar un ambiente seguro y confiable para realizar transacciones. Este simple proceso de verificación confirma tu identidad y que los datos ingresados sean reales.</p>',

    question_seven: '¿Cómo ver el historial de mis transacciones?',
    question_seven_info:
        '<p><a class="rs-link" href="https://saldo.com.ar/my/signup" title="Cambiar saldo PayPal, Skrill o MercadoPago" target="_blank">Regístrate</a> en Saldoar con el correo que usas normalmente y podrás acceder al historial completo de tus pedidos.</p>',

    question_eigth: '¿Cuáles son las comisiones por operar?',
    question_eigth_info:
        'En Saldoar no tenemos comisiones ocultas, por lo que en la calculadora verás claramente las comisiones aplicadas al monto final, que recibirás en tu cuenta.',

    go_saldoar_blog_info:
        '<p>Si aquí no encontraste la respuesta que buscabas, puedes <a class="rs-link" href="https://blog.saldo.com.ar/" title="Blog Saldoar" target="_blank">visitar el blog de Saldoar</a> para más información.</p>'
};
