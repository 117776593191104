import { DocumentCollection, DocumentResource, Resource } from 'ngx-jsonapi';
import { State } from './state';
import { System } from './system';

export class TransactionStatePublic extends Resource {
    public type: string = 'transactions_states_public';
    public attributes: {
        email: string;
        mid: string;
        amount1: number;
        amount2: number;
        token: string;
    } = {
        email: '',
        mid: '',
        amount1: 0,
        amount2: 0,
        token: ''
    };
    public relationships: {
        states: DocumentCollection<State>;
        system1: DocumentResource<System>;
        system2: DocumentResource<System>;
    } = {
        states: new DocumentCollection<State>(),
        system1: new DocumentResource<System>(),
        system2: new DocumentResource<System>()
    };
}
