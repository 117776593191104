import { Injectable } from '@angular/core';
import { CookieService } from '@gorniv/ngx-universal';

@Injectable({
    providedIn: 'root'
})
export class ReferralsService {
    private referral_id: string = '';
    private referral_regex: RegExp = /\/?(\?|\/|&)(r(=|)([\d]+))/;
    private referral_cookie_days_lifetime: number = 7;

    public constructor(private cookiesService: CookieService) {}

    public extractReferralIdFromUrlParams(url: string): string {
        let regex_match: RegExpMatchArray | null = url.match(this.referral_regex);
        if (!regex_match) {
            this.referral_id = this.cookiesService.get('r');

            return url;
        }

        this.referral_id = regex_match[4];

        if (regex_match[2] === 'r' + regex_match[4]) {
            url = url.replace(this.referral_regex, '');
        }

        this.setReferralCookie();

        return url;
    }

    public setReferralId(refId: string): void {
        this.referral_id = refId;
        this.setReferralCookie();
    }

    public getReferralId(): string {
        return this.referral_id || this.cookiesService.get('r');
    }

    private setReferralCookie(): void {
        let expire_date: Date = new Date();
        expire_date.setDate(expire_date.getDate() + this.referral_cookie_days_lifetime);
        if (this.referral_id === this.cookiesService.get('r')) {
            return;
        }
        this.cookiesService.put('r', this.referral_id.toString(), {
            expires: expire_date
        });
    }
}
