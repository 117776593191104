import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'app-animation-system-select',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./animation-system-select.component.scss'],
    templateUrl: './animation-system-select.component.html'
})
export class AnimationSystemSelectComponent {
    @Input() public systemId: string = '';
    @Input() public alt: string = '';
}
