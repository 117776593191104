import { Injectable, Inject } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { pathsSaldo } from 'environments/paths-saldo';

@Injectable({
    providedIn: 'root'
})
export class TransactionInterceptor implements HttpInterceptor {
    private headers!: HttpRequest<any>;
    public constructor(@Inject(DOCUMENT) private document: Document, public router: Router, public activatedRoute: ActivatedRoute) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let urlNotFramework: string = this.document.location.href;
        if (urlNotFramework.includes('/t/') && request.url.includes(environment.API_URL + pathsSaldo.API_PATH)) {
            let url: Array<string> = urlNotFramework.split('/');
            this.headers = request.clone({
                setHeaders: {
                    'Transaction-Key': url[6],
                    'Transaction-Mid': url[7]
                }
            });

            return next.handle(this.headers);
        }

        return next.handle(request);
    }
}
