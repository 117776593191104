import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceLoader } from '@app/core/services/service-loader';
import { environment } from 'environments/environment.base';
import { pathsSaldo } from 'environments/paths-saldo';

@Injectable({
    providedIn: 'root'
})
export class DashboardLazyLoadService {
    public constructor(private httpClient: HttpClient) {
        this.httpClient = ServiceLoader.injector.get(HttpClient);
    }

    public getInterval(userId: string): any {
        return setInterval(() => {
            this.httpClient
                .get(`${environment.API_URL + pathsSaldo.API_PATH}users/${userId}/user-alive`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('access_token') ?? ''}`
                    }
                })
                .subscribe();
        }, 1 * 30 * 1000);
    }
}
