import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { PlatformBrowserService } from '@app/core/services/platform-browser.service';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-logo-redirects',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './logo-redirects.component.html',
    styleUrls: ['./logo-redirects.component.scss']
})
export class LogoRedirectsComponent {
    @Input() public color: string = 'color';
    @Input() public link: string | undefined = '/';
    public cdnUrl: string = environment.CDN_URL;

    public constructor(private router: Router, private browserService: PlatformBrowserService) {}

    public goToLink(event: Event): void {
        event.preventDefault();
        this.router.navigate([this.link ?? '']);
        this.resetScrollElement();
    }

    private resetScrollElement(): void {
        if (this.browserService.isBrowser) {
            window.scroll(0, 0);
        }
    }
}
