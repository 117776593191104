import { about_us_en_US } from '../assets/i18n/about-us-en-us';
import { about_us_es_AR } from '../assets/i18n/about-us-es-ar';
import { dashboard_es_AR } from '../assets/i18n/dashboard-es-ar';
import { transaction_states_en_US } from '../assets/i18n/transaction-states-en-us';
import { transaction_states_es_AR } from '../assets/i18n/transaction-states-es-ar';
import { header_en_US } from '../assets/i18n/header-en-us';
import { header_es_AR } from '../assets/i18n/header-es-ar';
import { help_center_en_US } from '../assets/i18n/help-center-en-us';
import { help_center_es_AR } from '../assets/i18n/help-center-es-ar';
import { landing_en_US } from '../assets/i18n/landing-en-us';
import { landing_es_AR } from '../assets/i18n/landing-es-ar';
import { frequent_questions_en_US } from '../assets/i18n/frequent-questions-en-us';
import { frequent_questions_es_AR } from '../assets/i18n/frequent-questions-es-ar';
import { warranty_en_US } from '../assets/i18n/warranty-en-us';
import { warranty_es_AR } from '../assets/i18n/warranty-es-ar';
import { why_choose_en_US } from '../assets/i18n/why-choose-en-us';
import { why_choose_es_AR } from '../assets/i18n/why-choose-es-ar';
import { referral_program_es_AR } from '../assets/i18n/referral-program-es-ar';
import { referral_program_en_US } from '../assets/i18n/referral-program-en-us';
import { deals_en_US } from '../assets/i18n/deals-en-us';
import { deals_es_AR } from '../assets/i18n/deals-es-ar';
import { landing_bob_es_AR } from '../assets/i18n/landing-bob-es-ar';

export const listLenguages: any = {
    'landing-en-US': { ...landing_en_US, ...header_en_US },
    'landing-es-AR': { ...landing_es_AR, ...header_es_AR },
    'referral-transaction-es-AR': { ...header_es_AR, ...frequent_questions_es_AR },
    'referral-transaction-en-US': { ...header_en_US, ...frequent_questions_en_US },
    'about-as-es-AR': { ...header_es_AR, ...about_us_es_AR },
    'about-as-en-US': { ...header_en_US, ...about_us_en_US },
    'warranty-es-AR': { ...header_es_AR, ...warranty_es_AR },
    'warranty-en-US': { ...header_en_US, ...warranty_en_US },
    'help-es-AR': { ...header_es_AR, ...help_center_es_AR, ...landing_es_AR, ...frequent_questions_es_AR },
    'help-en-US': { ...header_en_US, ...help_center_en_US, ...landing_en_US, ...frequent_questions_en_US },
    'faq-es-AR': { ...header_es_AR, ...frequent_questions_es_AR },
    'faq-en-US': { ...header_en_US, ...frequent_questions_en_US },
    'why-choose-es-AR': { ...header_es_AR, ...why_choose_es_AR },
    'why-choose-en-US': { ...header_en_US, ...why_choose_en_US },
    'referral-program-es-AR': { ...header_es_AR, ...referral_program_es_AR },
    'referral-program-en-US': { ...header_en_US, ...referral_program_en_US },
    'transaction-states-es-AR': { ...header_es_AR, ...transaction_states_es_AR },
    'transaction-states-en-US': { ...header_en_US, ...transaction_states_en_US },
    'deals-es-AR': { ...header_es_AR, ...deals_es_AR, ...landing_es_AR },
    'deals-en-US': { ...header_en_US, ...deals_en_US, ...landing_en_US },
    'landing-bob-es-AR': { ...landing_bob_es_AR },
    'dashboard-es-AR': { ...dashboard_es_AR }
};
