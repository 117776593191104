import { System } from '../resources/system';
import { ISystemFee, systems_fee_model } from '../resources/systems-fee-model';

export class SystemFeeHelper {
    public readonly systemsFee: Array<ISystemFee> = systems_fee_model;

    public constructor(private system: System, private amount: number) {}

    public getFeeBySystem(): { [fee: string]: number } {
        let fee: { [fee: string]: number } = {
            send: 0,
            receive: 0
        };
        const decimal_places: number = this.system.attributes.decimal_places;
        const systemFee: ISystemFee | undefined = this.systemsFee.find((system_fee: any): boolean => system_fee.id === this.system.id);

        if (systemFee === undefined) {
            return fee;
        }

        const fee_send: { [property: string]: number } | undefined = systemFee.fee.fee_send;
        const fee_receive: { [property: string]: number } | undefined = systemFee.fee.fee_receive;
        if (fee_send) {
            fee.send = Number(Math.max(this.amount * fee_send.fee + fee_send.additional, fee_send.minimum).toFixed(decimal_places || 2));
        }
        if (fee_receive) {
            fee.receive =
                Number(Math.max(this.amount * fee_receive.fee + fee_receive.additional, fee_receive.minimum).toFixed(decimal_places || 2)) *
                -1;
        }

        return fee;
    }

    public getInfoFeeSystem(way: 'receive' | 'send'): any {
        let infoFee: any = {
            fee: 0,
            additional: 0
        };
        const systemFee: ISystemFee | undefined = this.systemsFee.find((system_fee: any): boolean => system_fee.id === this.system.id);

        if (way === 'send') {
            infoFee.fee = systemFee?.fee.fee_send ? parseFloat((systemFee?.fee.fee_send.fee * 100).toFixed(2)) : 0;
            infoFee.additional = systemFee?.fee.fee_send ? systemFee?.fee.fee_send.additional : 0;
        } else if (way === 'receive') {
            infoFee.fee = systemFee?.fee.fee_receive ? parseFloat((systemFee?.fee.fee_receive.fee * 100).toFixed(2)) : 0;
            infoFee.additional = systemFee?.fee.fee_receive ? systemFee?.fee.fee_receive.additional : 0;
        }

        return infoFee;
    }
}
