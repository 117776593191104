import { Injectable } from '@angular/core';
// import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BuildingDealAmountsHelper, IDealBagsAmount } from '@app/core/helpers/building-deal-amounts.helper';
import { Account } from '@app/core/resources/account';
import { Deal } from '@app/core/resources/deal';
import { DealsBag } from '@app/core/resources/deals-bag';
import { System } from '@app/core/resources/system';
import { AccountService } from '@app/core/services/account.service';
import { SystemsService } from '@app/core/services/systems.service';
import { IJsonApiErrorDocument } from '@app/exceptions/services/jsonapi-error.interface';
import { IPage } from 'ngx-jsonapi-material';
import { interval, Observable, of, Subject } from 'rxjs';
import { debounceTime, flatMap, startWith, takeUntil } from 'rxjs/operators';
import { UnsavedChangesDialogComponent } from './components/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { AddMoreDealsComponent } from './deals-bags/add-more-deals/add-more-deals.component';
import { AccountHelper } from './helpers/account-helper';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Injectable({
    providedIn: 'root'
})
export class DealsBagFillingService {
    private buildingDealAmountsHelper: BuildingDealAmountsHelper = new BuildingDealAmountsHelper();
    private methods: any = {};
    private accountHelper: AccountHelper = new AccountHelper();
    private updateChronometer$: Subject<any> = new Subject();
    private typesErrorsDealBag: { [keys: string]: string } = {
        account_addresses_with_low_score: 'https://blog.saldo.com.ar/error-2/',
        accumulated_risk: 'https://blog.saldo.com.ar/error-4/',
        max_amount_by_risk: 'https://blog.saldo.com.ar/error-1/',
        no_transactions_on_held_or_disputed: 'https://blog.saldo.com.ar/error-3/',
        user_validations_in_progress: 'https://blog.saldo.com.ar/error-5/',
        have_unmarked_payments: 'https://blog.saldo.com.ar/error-7/',
        max_canceled_deal_bags: 'https://blog.saldo.com.ar/error-6/'
    };
    private readonly debounceDealsBag: number = 500;
    private originalDealsBagStatus: string = '';
    public updateDealBag$: Subject<any> = new Subject();
    public updateDealsBags$: Subject<any> = new Subject();
    public remoteFilterSubject$: Subject<any> = new Subject();
    public accounts: Array<Account> = [];
    public dealsBag: DealsBag = new DealsBag();
    public dealsBagAmount!: IDealBagsAmount;
    public sendSystem: System = new System();
    public receiveSystem: System = new System();
    public account: Account = new Account();
    public send_account: Account = new Account();
    public secondsChronometer: number = 0;
    public minutesChronometer: number = 0;
    public progressBarValue: number = 0;
    public chronometerStarted: boolean = false;
    public remoteFilter: { [key: string]: any } = {};
    public page: IPage = {
        pageIndex: 0,
        pageSize: 100
    };
    public dealsBagHaveSaving: boolean = false;
    public collectionLength: number = 0;
    public matDialog!: MatDialog;
    public isSaving: boolean = false;
    public userId: string = '';

    public constructor(private accountService: AccountService, private systemsService: SystemsService) {
        this.observerUpdateDealsBag();
        this.getAllAccounts();
    }

    public setDealsBagFillingInitial(): void {
        if (this.dealsBag.id === '') {
            return;
        }
        this.setSendSystemInitial();
        this.dealsBagTimer();
    }

    private saveDealsBag(): Observable<any> {
        return this.dealsBag.save({ beforepath: `users/${this.userId}`, ttl: 0 });
    }

    private observerUpdateDealsBag(): void {
        this.updateDealBag$.pipe(debounceTime(this.debounceDealsBag)).subscribe(() => {
            this.dealsBagHaveSaving = true;
            this.saveDealsBag().subscribe(
                (): void => {
                    this.dealsBagHaveSaving = false;
                    this.originalDealsBagStatus = this.dealsBag.attributes.status;
                    this.updateView();
                },
                (error: any): void => {
                    if (this.originalDealsBagStatus === '') {
                        this.dealsBag = new DealsBag();
                    }
                    if (this.handledDealBagError(error)) {
                        return;
                    }

                    this.dealsBagHaveSaving = false;
                    this.updateDealsBags$.next(true);
                    this.updateView();
                    throw error;
                }
            );
        });
    }

    public addDeal(deal: Deal): void {
        this.setDealsBagConfigAdd(deal);
        this.setAccounts();
        this.setSendSystem();
        this.dealsBagTimer();
        this.getDealsBagAmount();
        this.updateDealBag$.next(true);
        this.updateView();
    }

    private setDealsBagConfigAdd(deal: Deal): void {
        this.dealsBag.addRelationship(deal, 'deals');
        this.dealsBag.attributes.group_id = deal.attributes.group_id;
        this.dealsBag.attributes.status = 'filling';
        this.updateRemoteFilter({ 'system.group_id': deal.attributes.group_id });
    }

    private checkAndSetAccountBybOrigin(key: string, callbackAccount: any): any {
        if (this.dealsBag.relationships[key].data && this.dealsBag.relationships[key].data.id !== '') {
            return this.accounts.filter((account): boolean => account.id === this.dealsBag.relationships[key].data.id)[0];
        }

        return this.accountHelper.setAccountByStorage(key, this.accounts.filter(callbackAccount));
    }

    private setAccounts(): void {
        this.account = this.checkAndSetAccountBybOrigin(
            'account',
            (account: Account): boolean => account.relationships.system.data?.attributes.group_id !== this.dealsBag.attributes.group_id
        );
        this.send_account = this.checkAndSetAccountBybOrigin(
            'send_account',
            (account: Account): boolean => account.relationships.system.data?.attributes.group_id === this.dealsBag.attributes.group_id
        );
    }

    public addSendAccount(account: Account): void {
        this.dealsBag.addRelationship(account, 'send_account');
        this.accountHelper.addAccountToStorage('send_account', account);
        this.getDealsBagAmount();
        this.updateView();
    }

    public addReceiveAccount(account: Account): void {
        this.dealsBag.addRelationship(account, 'account');
        this.accountHelper.addAccountToStorage('account', account);
        this.setReceiveSystem();
        this.getDealsBagAmount();
        this.updateView();
    }

    public getDealsBagAmount(): void {
        this.buildingDealAmountsHelper.getDealBagsAmount(this.dealsBag).subscribe((dealsBagAmount): void => {
            this.dealsBagAmount = dealsBagAmount;
            this.updateView();
        });
    }

    private setSendSystem(): void {
        if (this.dealsBag.relationships.deals.data.length === 0) {
            return;
        }

        this.sendSystem = this.dealsBag.relationships.deals.data[0].relationships.system.data ?? new System();
    }

    private setSendSystemInitial(): void {
        this.systemsService.all({ remotefilter: { group_id: this.dealsBag.attributes.group_id } }).subscribe((systems): void => {
            this.sendSystem = systems.data[0] ?? new System();

            if (!this.dealsBag.relationships.deals.data[0] || !this.sendSystem.id) {
                return;
            }

            this.dealsBag.relationships.deals.data[0].addRelationship(this.sendSystem, 'system');
            this.updateRemoteFilter({ 'system.group_id': this.sendSystem.attributes.group_id });
            this.setAccounts();
            this.updateView();
        });
    }

    private setReceiveSystem(): void {
        this.receiveSystem = this.dealsBag.relationships.account.data?.relationships.system.data ?? new System();
    }

    private updateView(): void {
        Object.keys(this.methods).forEach((key): void => {
            this.methods[key]();
        });
    }

    public removeDeal(deal: Deal): void {
        this.dealsBag.removeRelationship('deals', deal.id);
        this.getDealsBagAmount();

        if (this.dealsBag.relationships.deals.data.length === 0) {
            this.dealsBag.attributes.group_id = '';
            this.dealsBag.relationships.send_account.data = this.send_account = new Account();
            this.sendSystem = new System();
            this.setPage();
            this.updateRemoteFilter();
        }

        this.updateDealBag$.next(true);
        this.updateView();
    }

    public cancelDealsBag(): void {
        this.updateRemoteFilter();
        this.updateChronometer();

        this.dealsBag.attributes.status = 'canceled';
        this.dealsBag.relationships.deals.data = [];
        this.setPage();

        this.saveDealsBag().subscribe((): void => {
            this.dealsBag = new DealsBag();
        });

        this.updateView();
    }

    public payDealsBag(event: Event): void {
        event.preventDefault();

        this.isSaving = true;
        if (this.verifyOneDealAdded()) {
            return;
        }

        this.save();
    }

    private verifyOneDealAdded(): boolean {
        if (
            this.dealsBag.relationships.deals.data.length !== 1 ||
            this.collectionLength === this.dealsBag.relationships.deals.data.length
        ) {
            return false;
        }

        let dialog: MatDialogRef<AddMoreDealsComponent> = this.matDialog.open(AddMoreDealsComponent, {
            width: '600px'
        });
        dialog.afterClosed().subscribe((action: string) => {
            if (action === 'cancel' || action === undefined) {
                this.isSaving = false;

                return;
            }
            this.save();
        });

        return true;
    }

    private save(): void {
        this.dealsBag.attributes.status = 'filled';
        this.dealsBag.attributes.amount2 = this.dealsBagAmount.receive_amount;
        this.dealsBag.save({ beforepath: `users/${this.userId}` }).subscribe(
            (): void => {
                window.open(this.dealsBag.attributes.instructions_url, '_blank');
                this.dealsBag = new DealsBag();
                this.updateDealsBags$.next(true);
                this.isSaving = false;
                this.setPage();
                this.updateRemoteFilter();
                this.updateChronometer();
                this.updateView();
            },
            (error: any): void => {
                this.dealsBag.attributes.status = 'filling';
                this.isSaving = false;
                if (this.handledDealBagError(error)) {
                    return;
                }

                this.updateView();
                // eslint-disable-next-line rxjs/throw-error
                throw error;
            }
        );
    }

    private handledDealBagError(error: IJsonApiErrorDocument): boolean {
        switch (true) {
            case error.errors[0].code && this.typesErrorsDealBag.hasOwnProperty(error.errors[0].code):
                let messageError: string =
                    error.errors[0].detail +
                    ' Si deseas conocer más, ingresa al siguiente <a class="rs-link" href="' +
                    this.typesErrorsDealBag[error.errors[0].code ?? ''] +
                    '" target="_blank">artículo</a>';
                this.openDialog(messageError);

                return true;
            default:
                return false;
        }
    }

    public openDialog(message: string): void {
        let dialog: MatDialogRef<UnsavedChangesDialogComponent> = this.matDialog.open(UnsavedChangesDialogComponent, {
            width: '600px',
            autoFocus: false,
            data: {
                text: message,
                updateDeals: 'Aceptar'
            }
        });

        dialog.afterClosed().subscribe((): void => {
            this.getDealsBagAmount();
        });
    }

    public setMethod(method: any): void {
        this.methods = Object.assign(this.methods, method);
    }

    public getAllAccounts(): void {
        if (!this.userId) {
            return;
        }

        /* eslint-disable id-blacklist */
        this.accountService
            .all({
                beforepath: `users/${this.userId}`,
                include: ['system'],
                remotefilter: { status: { ne: 'archived' } },
                sort: ['status'],
                page: { number: 1, size: 100 }
            })
            .subscribe((accounts): void => {
                this.accounts = accounts.data;
                if (this.dealsBag.id !== '') {
                    this.setDealsBagFillingInitial();
                }
                this.updateView();
            });
        /* eslint-disable id-blacklist */
    }

    public setPage(pageIndex: number = 0, pageSize: number = 100): void {
        this.page.pageIndex = pageIndex;
        this.page.pageSize = pageSize;
    }

    private updateRemoteFilter(remoteFilter: { [key: string]: string } = {}): void {
        this.remoteFilter = remoteFilter;
        this.remoteFilterSubject$.next(true);
    }

    private updateChronometer(started: boolean = false): void {
        this.chronometerStarted = started;
        this.updateChronometer$.next(true);
    }

    public dealsBagTimer(): void {
        if (this.chronometerStarted) {
            return;
        }

        this.updateChronometer(true);

        let EXPIRATION_TIME: number = 15 * 60 * 1000;
        let HUNDRED_PERCENT: number = 100;
        let dateAux: number = Date.now() + 900000;
        let expired: number = Date.parse(
            this.dealsBag.attributes.expired_at ? this.dealsBag.attributes.expired_at : new Date(dateAux).toString()
        );

        interval(1 * 1000)
            .pipe(
                startWith(0),
                flatMap((): Observable<any> => {
                    let dateNow: number = Date.now();
                    let restTime: number = expired - dateNow;
                    if (restTime <= 0) {
                        this.dealsBag = new DealsBag();
                        this.updateChronometer();
                        this.updateRemoteFilter();
                        this.updateView();

                        return of(0);
                    }

                    this.minutesChronometer = Math.floor((restTime % (1000 * 60 * 60)) / (1000 * 60));
                    this.secondsChronometer = Math.floor((restTime % (1000 * 60)) / 1000);

                    return of((restTime * HUNDRED_PERCENT) / EXPIRATION_TIME);
                }),
                takeUntil(this.updateChronometer$)
            )
            .subscribe((progressBarValue): void => {
                this.progressBarValue = progressBarValue;
                this.updateView();
            });
    }
}
