// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

let apiHost: string = 'api.saldo.com.ar';
let apiUrl: string = `https://${apiHost}/`;

export const environment: { [property: string]: any } = {
    production: true,
    env: 'production',
    version: '4.0.15',
    CANONICAL_ORIGIN_URL: 'https://saldo.com.ar/',
    ACCESS_TOKEN_LIFETIME: 300,
    API_HOST: apiHost,
    API_URL: apiUrl,
    API_LAN_HOST: 'main',
    LEGACYURL: 'https://panel.saldo.com.ar/',
    TOKEN_BUGSNAG_APIKEY: '',
    TOKEN_RECAPTCHA_SITE_KEY_INVISIBLE_V2: '',
    TOKEN_RECAPTCHA_SITE_KEY_V3: '',
    WEBSITE_CRISP_ID: 'f771d8de-a5d4-4f22-954f-de573c1f2d74',
    FIREBASE_CONFIG: {
        apiKey: '',
        authDomain: '',
        projectId: '',
        storageBucket: '',
        messagingSenderId: '',
        appId: ''
    },
    FIREBASE_API_KEY: '',
    FIREBASE_PROJECT_ID: '',
    FIREBASE_MESSAGING_SENDER_ID: '',
    FIREBASE_APP_ID: '',
    CDN_URL: '',
    CACHE_DRIVER: 'memory',
    DATA_REDIS_HOST: '',
    REDIS_PASSWORD: '',
    REDIS_PORT: 6379,
    SKIP_FACEBOOK_PIXEL: false,
    WHITE_LISTED_DOMAINS: ['api.saldo.com.ar', 'panel.saldo.com.ar']
};
