import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomVideoComponent } from './custom-video.component';

@NgModule({
    declarations: [CustomVideoComponent],
    exports: [CustomVideoComponent],
    imports: [CommonModule]
})
export class CustomVideoModule {}
