import { Injectable, Inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, ObservableInput, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GlobalStateService } from '@app/core/services/global-state.service';
import { IHttpJsonApiError } from '@app/exceptions/custom-error-handler.service';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { ErrorReporterService } from '@app/exceptions/services/error-reporter.service';
import { AppError } from '@app/exceptions/services/app-error';

@Injectable()
export class AuthFactorErrorInterceptor implements HttpInterceptor {
    public constructor(
        private globalStateService: GlobalStateService,
        private errorReporterService: ErrorReporterService,
        @Inject(DOCUMENT) private document: Document,
        private router: Router
    ) {}

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((error: any): ObservableInput<any> => {
                let app_error: AppError = new AppError();
                if (!(error.error && error.error.errors && error.error.errors.length > 0)) {
                    return throwError(error);
                }
                if (error.error.errors[0].title === 'Unauthenticated.') {
                    app_error.original_error = error;
                    app_error.message = 'Follow error 403 Unauthenticated';
                    this.errorReporterService.report(app_error);

                    console.log('Error:', error, 'Request', request);
                }
                if (this.isTokenError(error)) {
                    this.globalStateService.logout(false);
                    this.document.location.href = '/my/login?continue=' + this.router.url;

                    console.log('Error:', error, 'Request', request);
                }
                if (this.isTokenError(error)) {
                    this.globalStateService.logout(false);
                    this.document.location.href = '/my/login?continue=' + this.router.url;

                    return of(error);
                }

                throw error;
            })
        );
    }

    private isTokenError(error: any): error is IHttpJsonApiError {
        if (!this.isHttpJsonApiError(error)) {
            return false;
        }

        return (
            error.status === 401 ||
            error.error.errors[0].title === 'Unauthenticated.' ||
            error.error.errors[0].detail === 'The refresh token is invalid. Token has been revoked'
        );
    }

    private isHttpJsonApiError(error: any): error is IHttpJsonApiError {
        return error.error && error.error.errors && Array.isArray(error.error.errors);
    }
}
