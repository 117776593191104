import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ICalculatorDetail } from '@app/core/helpers/calculator-detail.helper';
import { CalculatorProBuilderHelper } from '@app/core/helpers/calculator-pro-builder.helper';
import { Transaction } from '@app/transactions/transaction';
import { FeeService } from '../../fee-message/fee.service';
import { SystemInfoService } from './system-info.service';

interface IAccompanyingInfo {
    [position: string]: { [key: string]: string };
}

type Position = 1 | 2 | 3 | 4;

@Component({
    selector: 'app-system-info',
    templateUrl: './system-info.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./system-info.component.scss']
})
export class SystemInfoComponent implements OnChanges {
    @Input() public linePosition!: 'up' | 'down';
    @Input() public pointBorder!: 'border' | 'fill';
    @Input() public position!: Position;
    @Input() public way: 'send' | 'receive' | 'not_way' = 'not_way';
    @Input() public transaction: Transaction = new Transaction();
    private calculatorProBuilderHelper: CalculatorProBuilderHelper = new CalculatorProBuilderHelper();
    public details!: ICalculatorDetail | null;
    public disableLabelOne: boolean = false;
    public showLabelWithoutFee: boolean = false;
    public quotationDisabled: boolean = false;
    public isOpenMenu: boolean = false;
    public accompanying_info: IAccompanyingInfo = {
        1: {
            label: 'calculator_commision_info_you_need',
            shortLabel: 'calculator_commision_info_you_need'
        },
        2: {
            label: 'calculator_commision_info_seller_get',
            shortLabel: 'calculator_commision_info_seller_get'
        },
        3: {
            label: 'calculator_commision_info_the_tolal',
            shortLabel: 'Total'
        },
        4: {
            label: 'calculator_commision_info_you_get',
            shortLabel: 'calculator_commision_info_you_get'
        }
    };
    public title: string = '';

    public constructor(
        public feeService: FeeService,
        private changeDetectorRef: ChangeDetectorRef,
        private systemInfoService: SystemInfoService
    ) {
        this.changedTransaction();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.transaction && changes.transaction.currentValue !== undefined) {
            this.setInfo(changes.transaction.currentValue);
        }
    }

    private changedTransaction(): void {
        this.feeService.getTransaction().subscribe((transaction): void => {
            this.setInfo(transaction);
        });
    }

    private setInfo(transaction: Transaction): void {
        this.details = this.calculatorProBuilderHelper.setTransaction(transaction).getPositionNumber(this.position);
        this.transaction = transaction;
        this.systemInfoService.quotationPosition$.next({
            position: this.position,
            quotation: this.details?.quotation
        });
        this.disableQuotation();
        this.disableLabelOne = this.details !== null && this.details.hasFee;
        this.showLabelWithoutFee = !this.details?.hasFee || this.position === 2 || this.position === 3;
        this.title = `${this.details?.quotation} ${this.details?.quotationCurrency} / ${this.details?.quotationCurrencyComparison} ${
            this.details?.comparisonQuotationMarket ? '(' + this.details.comparisonQuotationMarket + ')' : ''
        }`;
        this.changeDetectorRef.detectChanges();
    }

    private disableQuotation(): void {
        this.systemInfoService.quotationPosition$.subscribe(() => {
            switch (true) {
                case this.position === 2:
                    this.quotationDisabled = this.systemInfoService.quotationPosition1 === this.systemInfoService.quotationPosition2;
                    break;
                case this.position === 3:
                    this.quotationDisabled = this.systemInfoService.quotationPosition4 === this.systemInfoService.quotationPosition3;
                    break;
                default:
                    break;
            }
            this.changeDetectorRef.detectChanges();
        });
    }
}
