import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubmitComponent } from './submit.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

@NgModule({
    declarations: [SubmitComponent],
    exports: [SubmitComponent],
    imports: [CommonModule, MatIconModule, MatButtonModule, MatProgressSpinnerModule]
})
export class SubmitModule {}
