export const dashboard_es_AR: any = {
    user_level_one: 'Principiante',
    user_level_two: 'Intermedio',
    user_level_three: 'Avanzado',
    user_level_four: 'Experto',
    user_level_five: 'Leyenda',

    user_profile: 'Perfil',
    user_log_out: 'Cerrar sesión',

    user_profile_change_password_title: 'Cambiar contraseña',
    user_profile_change_password_first_step: 'Contraseña actual',
    user_profile_change_password_second_step: 'Contraseña nueva',

    user_profile_change_password_second_step_warning: 'Se requiere: una minúscula, una mayúscula, 8 caracteres, un número.',
    user_profile_security_level_password_one: 'Débil',
    user_profile_security_level_password_two: 'Aceptable',
    user_profile_security_level_password_three: 'Muy segura',

    user_profile_change_password_third_step: 'Confirma tu nueva contraseña',
    user_profile_change_password_save_button: 'Guardar',

    alert_validation_required_message:
        'Tienes una validación pendiente. Tu cuenta está pausada hasta que completes la información que te solicitamos.',

    new_order: 'Nuevo pedido',
    orders_title: 'Pedidos',
    orders_number_order: 'N° Pedido',
    orders_status_title: 'Estado',
    orders_status_order_date: 'Fecha',
    orders_status_reload_button: 'Actualizar',
    place_order_load_new_account: 'Agregar cuenta',
    of: 'de {{ element }}',
    you_send: 'Envías',
    you_receive: 'Recibes',

    orders_steps_one_title: 'Instrucciones de pago',

    order_number: 'Pedido {{ element }}',

    orders_steps_one_we_sent: 'Te hemos enviado {{ element }}: {{ element }} {{ element }}',
    orders_steps_one_you_sent: 'Has enviado {{ element }}: {{ element }} {{ element }}',
    orders_steps_one_we_will_send: 'Te enviaremos {{ element }}: {{ element }} {{ element }}',
    orders_steps_one_you_will_send: 'Envías {{ element }}: {{ element }} {{ element }}',
    orders_steps_one_still_send: 'Aún resta enviarte {{ element }} {{ element }} {{ element }} {{ element }}',
    orders_steps_one_already_sent_button: '',

    orders_steps_one_from_your_account: 'Utiliza tu cuenta {{ element }}',
    orders_steps_one_destination_account: 'Cuenta destino {{ element }}',

    orders_steps_one_payment_number: 'PAGO {{ element }}',
    orders_steps_one_payment_from: 'De: {{ element }}',

    orders_steps_one_string_one:
        'Si tienes dudas con tu pedido, contáctate al instante con uno de nuestros operadores para que pueda ayudarte. {{ button }}',
    orders_steps_one_string_one_button: 'Ir al chat',
    orders_steps_one_string_two: 'Gracias por informarnos el pago. Pronto nos pondremos en contacto contigo.',
    orders_steps_one_string_three: '¿Problemas con tu pedido? Simplemente responde el correo donde recibiste las instrucciones.',

    orders_steps_one_canceled_warning:
        'Este pedido ha sido cancelado. Puedes crear uno nuevo o, si aún tienes dudas al respecto, responde el correo del pedido.',
    disabled_orders_view: 'No puedes ver este pedido ya...',

    orders_steps_two_title: 'Progreso del pedido',
    orders_steps_two_operator_working: '{{ element }} se encuentra trabajando en este pedido.',
    orders_steps_two_operator_worked: '{{ element }} trabajó en este pedido.',
    orders_steps_two_created_order: 'Creaste tu pedido {{ element }} {{ element }}',
    orders_steps_two_indentity: 'Antes de continuar: Identificación',

    orders_steps_three: 'Comprobantes del pedido',
    orders_steps_three_subtitle_one: 'Aguardamos los comprobantes de tus pagos.',
    orders_steps_three_subtitle_two:
        'Marcaste que enviaste el saldo, ahora necesitamos los comprobantes de tus pagos. Recuerda que deben ser desde tu cuenta.',

    orders_steps_four: 'Factura del pedido',
    orders_steps_four_upload_button: 'Agregar datos fiscales',
    orders_steps_four_create_invoices_button: 'Generar factura',
    orders_steps_four_subtitle: '¿Cómo será mi factura?',
    orders_steps_four_item_info: 'Concepto: {{ element }}',
    orders_steps_four_total_amount: 'Monto a facturar: {{ element }} {{ element }}',

    orders_steps_four_upload_board_title: 'Tus datos fiscales de Argentina',
    orders_steps_four_upload_board_one: 'CUIT *',
    orders_steps_four_upload_board_two: 'Responsabilidad *',
    orders_steps_four_upload_board_three: 'Domicilio *',
    orders_steps_four_upload_board_four: 'Provincia *',
    orders_steps_four_upload_board_five: 'Ciudad *',

    place_order_load_new_account_error: 'Se requiere la cuenta de {{ element }}',
    place_order_amount_error_one: 'El monto debe ser mayor a {{ element }}',
    place_order_amount_error_two: 'Revisa este dato.',

    place_order_select_account_pay: 'Selecciona tu cuenta *',
    place_order_select_account_receive: 'Selecciona tu cuenta o la de un amigo *',
    place_order_pay: 'Pagar',

    orders_status_level_zero_title: 'Aún sin procesar',
    orders_status_level_zero_info: 'El pedido requiere la aprobación manual de un operador',

    orders_status_level_one_title: 'Cancelado',
    orders_status_level_one_info:
        'El pedido fue cerrado sin éxito. Esto puede deberse a que tú lo cancelaste o se agotó el tiempo de espera.',

    orders_status_level_two_title: 'Omitido',
    orders_status_level_two_info:
        'El pedido fue descartado. Esto puede deberse a que has excedido los límites de operación o tienes otro pedido en curso.',

    orders_status_level_three_title: 'En preparación',
    orders_status_level_three_info: 'El pedido requiere la aprobación manual de un operador',

    orders_status_level_four_title: 'En preparación',
    orders_status_level_four_info: 'El pedido está listo y el sistema se encuentra buscando destinos.',

    orders_status_level_five_title: 'Pausado',
    orders_status_level_five_info: 'Uno de los pagos enviados o recibidos aún no ha llegado a destino.',

    orders_status_level_six_title: 'Pausado',
    orders_status_level_six_info: 'Uno de nuestros operadores se encuentra trabajando en el pedido.',

    orders_status_level_seven_title: 'Esperando tu pago',
    orders_status_level_seven_info: 'Las instrucciones fueron generadas: debes realizar los pagos y marcar "Ya lo envié".',

    orders_status_level_eight_title: 'Enviando',
    orders_status_level_eight_info: 'El pedido está en cola de pagos: recibirás el total de tu saldo en una o varias partes.',

    orders_status_level_nine_title: 'Validación requerida',
    orders_status_level_nine_info:
        'Por motivos de seguridad, se ha solicitado documentación que respalde el pedido. Entre ellas documento de identidad, selfie u otras.',

    order_status_level_ten_title: 'Enviado',
    order_status_level_ten_info: '¡Listo! El pedido ha sido completado exitosamente.',

    orders_status_level_eleven_title: 'Enviando',
    orders_status_level_eleven_info: 'El envío está casi listo, sólo quedan algunos detalles.',

    orders_status_level_twelve_title: 'Envío reprogramado',
    orders_status_level_twelve_info:
        'El pago será enviado en un pedido siguiente. Esto se debe a que se unificaron los pagos o has solicitado cambiar tu cuenta de destino.',

    deals_title: 'Ofertas',
    deals_send_methods_button: 'Todos los métodos',
    deals_send_methods_button_bank_transfer: 'Transferencia bancaria {{ element }}',
    deals_cash_send_methods_button: 'Todas las monedas',
    deals_send_methods_title: 'Método',
    deals_benefit: 'Beneficio',
    deals_view_more_button: 'Ver más',

    referral_program_title: 'Referidos',
    referral_program_string: '¡Invita y gana!',
    referral_program_text_input: 'Link de referido',
    referral_program_question: '¿Cómo funciona el programa de referidos?',
    referral_program_warn: 'Para desbloquear esta función deberás tener, al menos, un pedido exitoso.',

    referral_program_copied_alert: 'Copiado exitosamente.',
    referral_program_copied_alert_close: 'Cerrar',
    referral_program_earns: 'Ganancias',
    referral_program_minimum: 'Retiro mínimo de 15 dólares',

    referral_program_withdraw: 'Retirar',
    referral_program_withdraw_destination: 'Tu cuenta destino para el retiro *',
    referral_program_withdraw_amount: 'Monto a retirar en USD PayPal *',

    referral_program_withdraw_amount_selected_less: 'Ingrese un monto menor o igual a ',
    referral_program_withdraw_amount_selected_required: 'Este campo es requerido',
    referral_program_withdraw_amount_selected_higher: 'Ingrese un monto mayor o igual a ',

    referral_program_withdraw_info_string: 'El monto mínimo para retiro es 15 USD PayPal o su equivalente.',
    referral_program_withdraw_amount_sending: 'Monto que enviaremos',

    referral_program_board_title:
        '<div class="container-nothing-referral"> <h2>¡Comienza a ganar en dólares!</h2> <p>¿Por qué no llamas a algunos amigos?</p></div>',

    accounts_title: 'Cuentas',
    accounts_serch: 'Buscar...',

    accounts_filters: 'FILTROS',
    accounts_filters_select_option_button: 'Seleccione una opción',
    accounts_filters_select_options_one: 'Básicas',
    accounts_filters_select_options_two: 'Favoritas',
    accounts_filters_select_options_three: 'Inactivas',

    accounts_info_one: 'Dirección',
    accounts_info_two: 'Sistema',
    accounts_info_three: 'Número de identificación',
    accounts_info_four: 'Titular',

    VCC_title: 'Verificar PayPal',
    VCC_string:
        'Te ofrecemos Tarjetas de Crédito Virtuales (VCC) para que puedas verificar tu cuenta PayPal.Tienen una vigencia de 3 a 5 años y te permiten acceder a las siguientes ventajas:',

    VCC_info_li_one: 'Retirar y recibir montos más altos de dinero mensual que una cuenta no verificada.',
    VCC_info_li_two:
        'Comprar en línea sin límites (algunas tiendas en línea solo aceptan vender sus productos a usuarios con cuenta PayPal verificada).',
    VCC_info_li_three:
        'Generar más confianza tanto a tus compradores y vendedores, porque si ya estás verificado, aumenta la seguridad de tu cuenta.',

    VCC_info_warning:
        'Recuerda que con tu VCC no puede realizar compras ni emitir pagos.Están destinadas única y exclusivamente para que puedes verificar tu cuenta de PayPal.',

    VCC_sytems_title: 'Elige el método de pago que te parezca más cómodo...',
    VCC_more_info: 'Más información',
    VCC_buy: 'Comprar tarjeta VCC',

    VCC_buy_info: 'Vas a adquirir tu VCC, abonando con Transferencia bancaria Argentina',
    VCC_buy_third_step: 'Enviamos tu VCC',
    VCC_buy_select_country: 'País de tu cuenta PayPal *',

    VCC_make_order: 'Iniciar tu pedido',
    VCC_make_order_cancel: 'Cancelar',

    VCC_verified_title: 'VCC para verificar cuenta de Paypal',
    VCC_verified_info_p_one:
        'Una Tarjeta de Crédito Virtual o VCC (Virtual Credit Card) al igual que una tarjeta física, tiene numeración, fecha de caducidad y código de verificación. En realidad, se trata de una versión digital de las tarjetas físicas que todos conocemos, sólo que no debes esperar a que llegue a tu domicilio o debas retirarla en tu banco.',
    VCC_verified_info_p_two:
        'Cuando adquirimos una Tarjeta de Crédito Virtual, podemos acceder a varios beneficios. Uno de los beneficios que tenemos es el de poder verificar una cuenta de Paypal.',
    VCC_verified_info_p_three: 'En Saldoar te brindamos VCC con una vigencia de 3 a 5 años, para que puedas verificar tu cuenta de PayPal.',

    VCC_verified_question: '¿Qué beneficios tiene una cuenta PayPal verificada?',
    VCC_verified_li_one: 'Retirar y recibir montos más altos de dinero mensual que una cuenta no verificada.',
    VCC_verified_li_two: 'Comprar en tiendas en línea que solo aceptan vender sus productos a usuarios con cuenta PayPal verificada.',
    VCC_verified_li_three:
        'Generar más confianza tanto a tus compradores como vendedores, ya que este detalle puede ser visto por la contraparte.',
    VCC_verified_li_four: 'Liberación más rápida de pagos retenidos, ya que PayPal suele retenerlos en base a la confianza en tu cuenta.',
    VCC_verified_li_five: 'Reducir considerablemente las posibilidades de que tu cuenta sea limitada.',

    info_one: `<div class="container-nothing-transactions"><h2>¡Bienvenido {{element}}!</h2><p>¿Listo para comenzar a intercambiar tu saldo digital?<br>¡Crea tu pedido ahora mismo!</p></div>`,
    info_two:
        '<div class="container-nothing-accounts"><h2>Aún no tienes nada por aquí.</h2><p>Crea tu primer pedido para poder ver tus cuentas.</p></div>',
    create_order_button: 'Crear pedido',

    warning_message_commission:
        'Recuerda que {{ element }} te cobrará una comisión de {{ element_one }} {{element_two}}, por lo tanto recibirás un neto de {{ element_three }} {{element_four}}.',
    warning_message_commission_button_on_off: 'Deseo recibir un neto de',

    calculator_label_one: 'Envías {{element}}',
    calculator_label_two: 'Recibes {{element}}',

    calculator_label_one_search: 'Indica un metodo de intercambio',
    calculator_label_two_search: 'Indica un metodo de intercambio',

    private_help_center_title: 'Ayuda',

    account_adress_title: 'Dirección',
    account_name_title: 'Nombre',
    account_holder_title: 'Titular',

    account_edit_button: 'Editar',
    account_delete_button: 'Eliminar'
};
