import { Account } from '@app/core/resources/account';

export class AccountHelper {
    public addAccountToStorage(key: string, account: Account): void {
        if (!account) {
            return;
        }
        let accountStorage: { [property: string]: string } = {
            id: account.id ?? '',
            group_id: account.relationships.system.data?.attributes.group_id ? account.relationships.system.data?.attributes.group_id : ''
        };
        localStorage.setItem(key, JSON.stringify(accountStorage));
    }

    public setAccountByStorage(key: string, accounts: Array<Account>): Account {
        if (accounts.length === 0) {
            return new Account();
        }

        let accountStorage: any = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key) ?? '') : '';
        if (accountStorage === '' || (key === 'send_account' && accounts[0].id !== accountStorage.id)) {
            return accounts[0];
        }

        return accounts.filter((account): boolean => account.id === accountStorage.id)[0] ?? accounts[0];
    }
}
