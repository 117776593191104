import { Transaction } from '@app/transactions/transaction';
import { IFeeSystem } from './fee-interface';
import { TransactionPrivate } from '@app/core/resources/transaction-private';

export class FeeDefault implements IFeeSystem {
    protected way: string = '';
    protected systemNumber: number = 0;
    protected fee: number = 0;
    protected decimal_places: number = 0;

    public constructor(protected transaction: Transaction | TransactionPrivate) {}

    public getFee(): number {
        this.setFeeAndDecimalPlaces();
        if (this.fee === 1000) {
            return 0;
        }

        let amount: number = this.transaction.attributes['amount' + this.systemNumber];

        return this.parserDecimal(amount * this.fee, this.decimal_places);
    }

    public getAmountWithFee(): number {
        this.setFeeAndDecimalPlaces();
        let amountWithFee: number = Number(this.transaction.attributes['amount' + this.systemNumber]) / (1 - this.fee);

        return this.parserDecimal(amountWithFee, this.decimal_places);
    }

    public getAmountWithoutFee(): number {
        this.setFeeAndDecimalPlaces();
        let amountWithoutFee: number = Number(this.transaction.attributes['amount' + this.systemNumber]) * (1 - this.fee);

        return this.parserDecimal(amountWithoutFee, this.decimal_places);
    }

    protected setFeeAndDecimalPlaces(): void {
        this.fee = this.transaction.relationships['system' + this.systemNumber].data.attributes['fee_' + this.way];
        this.decimal_places = this.transaction.relationships['system' + this.systemNumber].data.attributes.decimal_places ?? 2;
    }

    public setWay(way: string): this {
        this.way = way;

        return this;
    }

    public setSystemNumber(systemNumber: number): this {
        this.systemNumber = systemNumber;

        return this;
    }

    protected parserDecimal(value: number, decimalPlaces: number): number {
        if (decimalPlaces === 0) {
            return Math.floor(value);
        }

        let parsedNumber: string = value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: decimalPlaces });

        return parseFloat(parsedNumber) ?? 0;
    }

    public getFinalFee(amount: number): number {
        return Math.max(amount - this.getFee(), 0);
    }
}
