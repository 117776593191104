<div class="not-design-system flex-column-start-center">
    <mat-card *ngIf="systemsCanSend.length > 0 && systemsCanReceive.length > 0; else loading" class="flex-column-start-center calculator-container">
        <app-message-error
            class="message-error-first-input"
            [arrowOrientation]="'down'"
            [message]="transaction.getRecomendation()"
            *ngIf="!checkIsForbiddenSystem && (validatorErrors[0] !== undefined && validatorErrors[0].pointer_attribute === 'system1')">
        </app-message-error>
        <app-system-info
            class="system-info-up"
            *ngIf="systemInfoService.advancedOptions === 'open'"
            [transaction]="transaction"
            [way]="'send'"
            [position]="1"
            pointBorder="fill"
            linePosition="up"></app-system-info>
        <app-system-select
            #sendSystemSelect
            *ngIf="systemsCanSend.length > 0"
            [mode]="'send'"
            [systems]="systemsCanSend"
            [(value)]="transaction.attributes.amount1"
            [isOnlyTwoSystems] = "isOnlyTwoSystems"
            [valueCounter]="transaction.attributes.amount2"
            [selectedSystem]="sendSystem"
            [counterSystem]="receiveSystem"
            (inFocus)="isReceiveOperation()"
            (selectedSystemChange)="sendSelectedSystemChange($event)"
            >
        </app-system-select>
        <app-system-info
            class="system-info-down"
            *ngIf="systemInfoService.advancedOptions === 'open'"
            [transaction]="transaction"
            [way]="'receive'"
            [position]="2"
            pointBorder="border"
            linePosition="down"></app-system-info>

        <div class="button-move-closed" *ngIf="!isOnlyTwoSystems" [ngClass]="{'button-move-open': systemInfoService.advancedOptions !== 'closed'}">
            <app-invert-systems
                tabindex="3"
                [advancedOptions]="systemInfoService.advancedOptions"
                [isAnimationEqualSystem]="isAnimationEqualSystem"
                [clicked]="clicked"
                (invertSystems)="invertSystems()">
            </app-invert-systems>
        </div>

        <app-system-info
            class="system-info-up"
            *ngIf="systemInfoService.advancedOptions === 'open'"
            [transaction]="transaction"
            [way]="'send'"
            [position]="3"
            pointBorder="border"
            linePosition="up"></app-system-info>
        <app-system-select #receiveSystemSelect
            *ngIf="systemsCanReceive.length > 0"
            [mode]="'receive'"
            [systems]="systemsCanReceive"
            [isOnlyTwoSystems] = "isOnlyTwoSystems"
            [(value)]="transaction.attributes.amount2"
            [valueCounter]="transaction.attributes.amount1"
            [selectedSystem]="receiveSystem"
            [counterSystem]="sendSystem"
            (inFocus)="isSendOperation()"
            (selectedSystemChange)="receiveSelectedSystemChange($event)"
            >
        </app-system-select>
        <app-system-info
            class="system-info-down"
            *ngIf="systemInfoService.advancedOptions === 'open'"
            [transaction]="transaction"
            [way]="'receive'"
            [position]="4"
            pointBorder="fill"
            linePosition="down"></app-system-info>
            <app-message-error
            [ngClass]="{'b-step': !isLanding}"
            class="message-error-second-input"
            [arrowOrientation]="'up'"
            [message]="transaction.getRecomendation()"
            *ngIf="!checkIsForbiddenSystem && (validatorErrors[0] !== undefined && (validatorErrors[0].pointer_attribute === 'system2' || validatorErrors[0].title === 'Elija otro método de intercambio.'))">
        </app-message-error>

        <div *ngIf="transaction.relationships.system2.data?.attributes?.market === 'crypto'" class="container-info-comission app-info-crypto-fee">
            <app-info-crypto-fee
            [transaction]="transaction">
            </app-info-crypto-fee>
        </div>

        <div *ngIf="showActionButton" class="buttons-calculator-container grid">
            <button type="button" (click)="systemInfoService.unfoldOrCloseCalculator()" class="more-options-button" mat-stroked-button><mat-icon>{{ systemInfoService.advancedOptions === 'open' ? 'expand_less' : 'more_horiz' }}</mat-icon></button>
            <button
                class="dark-background"
                tabindex="2"
                mat-button
                [disabled]="validatorErrors.length !== 0"
                (click)="goToTransactionForm()"
                >
                {{ (actionButtonText | translate) || ('next_button' | translate) }}
            </button>
        </div>
        <div *ngIf="!receiveSystem.attributes.can_receive || !sendSystem.attributes.can_send" class="blocked-calculator">
            <mat-icon>info</mat-icon>
            <p>No es posible realizar el intercambio. Intenta nuevamente cambiando los métodos de pago.</p>
            <button mat-button type="button" (click)="tryAgain()">Intentar nuevamente</button>
        </div>
    </mat-card>

    <ng-template #loading>
        <app-calculator-skeleton [actionButtonText]="actionButtonText"></app-calculator-skeleton>
    </ng-template>
</div>
