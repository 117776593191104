import { Component, OnInit } from '@angular/core';
import { CookieService } from '@gorniv/ngx-universal';

@Component({
    selector: 'app-dialog-error-redirect',
    templateUrl: './dialog-error-redirect.component.html',
    styleUrls: ['./dialog-error-redirect.component.scss']
})
export class DialogErrorRedirectComponent implements OnInit {
    public hiddenBtnWhatsapp: boolean = false;
    public auxCounterError: number = 0;
    public linkWhatsapp: string = 'https://wa.me/+5492604573247?text=';

    public constructor(private cookiesService: CookieService) {}

    public ngOnInit(): void {
        this.getCookieError();
    }

    private getCookieError(): void {
        this.auxCounterError = parseInt(this.cookiesService.get('counterError') ?? 0, 10) + 1;
        if (this.auxCounterError && this.auxCounterError === 3) {
            this.hiddenBtnWhatsapp = true;

            return;
        }
    }

    private setCookieError(): void {
        let expires: string = new Date(Date.now() + 600 * 1000).toUTCString();
        this.cookiesService.put('counterError', this.auxCounterError.toString(), { expires: expires });
    }

    public reloadPage(): void {
        this.setCookieError();
        window.location.reload();
    }

    public redirectWhatsappProblem(): void {
        window.open(this.linkWhatsapp, 'blank');
    }
}
