import { IBankRepository } from './bank-repository-interface';

export class GlobalBankEsRepository implements IBankRepository {
    public readonly id: string = 'zelle';
    public readonly currency: string = 'USD';
    public readonly groupId: string = 'global';
    public readonly title: string = 'Intercambiar saldo Dólar';
    public readonly urlHref: string = '/es-US/a/zelle/palpal/0/100';
    public readonly countryName: string = 'Internacional | Español';
    public readonly currencyName: string = 'Zelle';
    public readonly widthPositionSprite: string = '-120px';
    public readonly heightPositionSprite: string = '-106px';
    public readonly systemPair: string = 'palpal';
    public readonly langCode: string = 'es-US';
}
