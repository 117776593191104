import { System } from '@app/core/resources/system';
import { CurrenciesService } from '@app/core/services/currencies.service';
import { CurrencyPiorityService } from '@app/core/services/currency-piority.service';
import { ServiceLoader } from '@app/core/services/service-loader';

export interface IQuotationParams {
    system1: System;
    system2: System;
    amount1: number;
    amount2: number;
    restToPay: number;
}

export class QuotationHelper {
    private currencyPriorityService: CurrencyPiorityService;
    public currenciesService: CurrenciesService;

    public constructor() {
        this.currencyPriorityService = ServiceLoader.injector.get(CurrencyPiorityService);
        this.currenciesService = ServiceLoader.injector.get(CurrenciesService);
    }

    public getQuotation(quotationParams: IQuotationParams): any {
        return this.currencyPriorityService.callbacksAndReturnByCurrency(
            quotationParams.system1 ?? new System(),
            quotationParams.system2,
            (): any => {
                return {
                    quotation: (quotationParams.amount1 / quotationParams.restToPay).toFixed(
                        quotationParams.system2.attributes.decimal_places
                    ),
                    realQuotation: (quotationParams.amount2 / quotationParams.restToPay).toFixed(
                        quotationParams.system2.attributes.decimal_places
                    ),
                    pair: quotationParams.system2.attributes.currency + '/' + quotationParams.system1.attributes.currency,
                    way: 1
                };
            },
            (): any => {
                return {
                    quotation: (quotationParams.restToPay / quotationParams.amount1).toFixed(
                        quotationParams.system1.attributes.decimal_places
                    ),
                    realQuotation: (quotationParams.restToPay / quotationParams.amount2).toFixed(
                        quotationParams.system1.attributes.decimal_places
                    ),
                    pair: quotationParams.system1.attributes.currency + '/' + quotationParams.system2.attributes.currency,
                    way: 2
                };
            }
        );
    }
}
