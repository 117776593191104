import { Component, Input, OnInit } from '@angular/core';
import { System } from '@app/core/resources/system';

@Component({
    selector: 'app-system-trend',
    templateUrl: './system-trend.component.html',
    styleUrls: ['./system-trend.component.scss']
})
export class SystemTrendComponent implements OnInit {
    @Input() public system: System = new System();
    @Input() public trendWay: string = '';
    public showTrend: boolean = false;
    public levelTrend: number = 1;

    public levelTrendModel: any = {
        1: {
            class: 'level-one',
            icon: 'auto_awesome',
            text: 'Buen precio ahora'
        },
        2: {
            class: 'level-two',
            icon: 'diamond',
            text: 'Excelente precio ahora'
        }
    };

    public ngOnInit(): void {
        this.setTrendSystem();
    }

    private setLevelTrend(): void {
        let trend: number = Math.abs(this.system.attributes.trend);
        if (trend <= 1 && trend >= 0.8) {
            this.levelTrend = 2;

            return;
        }
        this.levelTrend = 1;
    }

    private setTrendSystem(): void {
        if (
            (this.trendWay === 'send' && this.system.attributes.trend >= 0.6) ||
            (this.trendWay === 'receive' && this.system.attributes.trend <= -0.6)
        ) {
            this.showTrend = true;
            this.setLevelTrend();
        }
    }
}
