import { Injectable } from '@angular/core';
import { Service } from 'ngx-jsonapi';
import { Network } from '../resources/networks';
import { Subject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NetworkSystemService extends Service<Network> {
    public resource: typeof Network = Network;
    public type: string = 'networks';

    private _selectedNetworkTitle: string | null = null;
    private selectedNetworkTitleSubject: Subject<string | null> = new Subject<string | null>();

    public get selectedNetworkTitle(): string | null {
        return this._selectedNetworkTitle;
    }

    public set selectedNetworkTitle(value: string | null) {
        this._selectedNetworkTitle = value;
        this.selectedNetworkTitleSubject.next(value);
    }

    public selectedNetworkTitleChanges(): Observable<string | null> {
        return this.selectedNetworkTitleSubject.asObservable();
    }
}
