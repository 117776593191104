import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { PlatformBrowserService } from '@app/core/services/platform-browser.service';

@Component({
    selector: 'app-slide',
    templateUrl: './slide.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./slide.component.scss']
})
export class SlideComponent implements AfterViewInit, OnChanges, OnInit {
    @Input() public elementQty: number = 0;
    @Input() public scrollFromEnd: boolean = false;
    @Input() public margin: number = 0;
    @Input() public elementWidth: number = 0;
    @Input() public withArrows: boolean = true;
    @ViewChild('paymentMethodContainer') public paymentMethodContainer!: ElementRef;
    @ViewChild('paymentMethod') public paymentMethod!: ElementRef;
    @Output() public draggingEvent: EventEmitter<boolean> = new EventEmitter();
    public orderedSystemByAmount: any = [];
    public cont: number = 0;
    public showButtonLeft: boolean = false;
    public showButtonRight: boolean = true;

    public constructor(private changeDetectorRef: ChangeDetectorRef, private browserService: PlatformBrowserService) {}

    public ngOnInit(): void {
        if (!this.browserService.isBrowser) {
            this.changeDetectorRef.detectChanges();

            return;
        }
    }

    public ngAfterViewInit(): void {
        this.verifyDeviceAndWidth();
        const slider: any = this.paymentMethodContainer.nativeElement;

        // Itera sobre cada slider
        let isDown: boolean = false;
        let startX: number;
        let scrollLeft: number;

        slider.addEventListener('mousedown', (e: MouseEvent) => {
            isDown = true;
            slider.classList.add('active');
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
            this.sendEventDragging(false);
        });

        slider.addEventListener('mouseleave', () => {
            isDown = false;
            slider.classList.remove('active');
        });

        slider.addEventListener('mouseup', () => {
            isDown = false;
            slider.classList.remove('active');
        });

        slider.addEventListener('mousemove', (e: MouseEvent) => {
            if (!isDown) return;
            e.preventDefault();
            let x: number = e.pageX - slider.offsetLeft;
            let walk: number = (x - startX) * 1;
            slider.scrollLeft = scrollLeft - walk;
            this.sendEventDragging(true);
            this.showButtonLeft = slider.scrollLeft !== 0;
            this.changeDetectorRef.detectChanges();
        });
    }

    public sendEventDragging(state: boolean): void {
        this.draggingEvent.emit(state);
    }

    public ngOnChanges(): void {
        if (!this.elementQty) {
            return;
        }

        this.showButtonRight = true;

        setTimeout(() => {
            this.verifyDeviceAndWidth();
        });
    }

    private verifyDeviceAndWidth(): void {
        if (!this.browserService.isBrowser) {
            this.changeDetectorRef.detectChanges();

            return;
        }

        let userAgent: string = navigator.userAgent || navigator.vendor;
        if (
            this.paymentMethod.nativeElement.offsetWidth < this.paymentMethodContainer.nativeElement.offsetWidth ||
            /iPad|iPhone|iPod|Android/.test(userAgent)
        ) {
            this.showButtonRight = false;
            this.changeDetectorRef.detectChanges();
        }

        if (this.scrollFromEnd) {
            this.showButtonRight = false;
            this.showButtonLeft = true;
            this.cont = 7;
            this.changeDetectorRef.detectChanges();
        }
    }

    public moveSlideToLeft(): void {
        this.cont += 1;

        if (this.cont > 0) {
            this.showButtonLeft = true;
        }

        this.runScroll();

        if (
            this.paymentMethodContainer.nativeElement.offsetWidth +
                this.elementWidth +
                this.margin +
                this.paymentMethodContainer.nativeElement.scrollLeft >=
            this.paymentMethodContainer.nativeElement.scrollWidth
        ) {
            this.showButtonRight = false;

            return;
        }
    }

    public moveSlideToRight(): void {
        this.cont -= 1;
        this.runScroll();

        if (this.cont <= 0) {
            this.showButtonLeft = false;
        }

        if (
            this.paymentMethodContainer.nativeElement.offsetWidth -
                this.elementWidth +
                this.margin +
                this.paymentMethodContainer.nativeElement.scrollLeft <=
            this.paymentMethodContainer.nativeElement.scrollWidth
        ) {
            this.showButtonRight = true;

            return;
        }
    }

    private runScroll(): void {
        this.paymentMethodContainer.nativeElement.scrollTo((this.elementWidth + this.margin) * this.cont, 0);
    }
}
