import { Component, Input, OnInit } from '@angular/core';
import { ScriptService } from '@app/core/services/script.service';
import { GeneralInfo } from '@app/core/resources/general-info';
import { ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CheckTransactionStatesComponent } from '@app/transactions/pages/check-transaction-states/check-transaction-states.component';
import { PlatformBrowserService } from '@app/core/services/platform-browser.service';
import { System } from '@app/core/resources/system';
import { environment } from 'environments/environment.base';

@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
    @Input() public systems: Array<System> = [];
    public loadCarousel: boolean = false;
    public generalInfo: GeneralInfo = new GeneralInfo();
    public is_loading: boolean = false;

    public constructor(
        private activatedRoute: ActivatedRoute,
        private scriptService: ScriptService,
        private dialog: MatDialog,
        private browserService: PlatformBrowserService
    ) {
        this.addSnippedHead();
    }

    public ngOnInit(): void {
        if (!this.browserService.isBrowser) {
            return;
        }
        this.activatedRoute.queryParams.subscribe((params: any) => {
            if (!params.mi_pedido) {
                return;
            }
            this.openDialogMyOrder(params.mid ?? '');
        });
    }

    private openDialogMyOrder(mid: string): void {
        this.dialog.open(CheckTransactionStatesComponent, {
            width: '600px',
            data: {
                mid
            }
        });
    }

    public addSnippedHead(): void {
        let snippet: string = `
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Saldoar",
                "url":"${environment.CANONICAL_ORIGIN_UR}",
                "sameAs": [
                "https://www.instagram.com/saldo.ar/",
                "https://www.youtube.com/channel/UCumZQa3vAgR1wG2bDKC8aUw'",
                "https://www.facebook.com/saldo.ar/",
                "https://twitter.com/SaldoComAr",
                "https://medium.com/saldo-com-ar"
                ]
            }
        `;

        this.scriptService
            .createScript('snippet', snippet, 'application/ld+json')
            .then((): void => {
                /*this.router.events.pipe(first()).subscribe((event: Event) => {
                });*/
            })
            .catch((errors): void => {
                console.error('Fallo la carga del snippet', errors);
            });
    }
}
