import { CryptoInformation } from './crypto-information';

export class NetellerInformationRepository extends CryptoInformation {
    public getAvailableReceive(): boolean {
        return true;
    }

    public getAvailableSend(): boolean {
        return true;
    }
    public getInfoReceive(): string {
        return 'warning_message_neteller_recibe';
    }
}
