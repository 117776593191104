import { DocumentResource, Resource } from 'ngx-jsonapi';
import { AccountDetail } from './account-detail';
import { System } from './system';
import { Network } from './networks';

export class Account extends Resource {
    public type: string = 'accounts';
    public attributes: {
        address: string;
        alias: string;
        status: string;
    } = {
        address: '',
        alias: '',
        status: 'normal'
    };

    public relationships: {
        account_detail: DocumentResource<AccountDetail>;
        network: DocumentResource<Network>;
        system: DocumentResource<System>;
    } = {
        account_detail: new DocumentResource<AccountDetail>(),
        network: new DocumentResource<Network>(),
        system: new DocumentResource<System>()
    };
}
