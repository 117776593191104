import { TransactionUrlService } from '@app/transactions/transaction-url.service';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Transaction } from '@app/transactions/transaction';
import { ActivatedRoute } from '@angular/router';
import { supportedLenguage } from '../helpers/lenguages.helper';

@Injectable({
    providedIn: 'root'
})
export class UrlUpdateService {
    private readonly isBrowser: boolean;

    public constructor(@Inject(PLATFORM_ID) public platformId: string, private activatedRoute: ActivatedRoute) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    public updateUrlWithTransactionData(transaction: Transaction, path: string): void {
        if (transaction.attributesAreSameInUrl(this.activatedRoute)) {
            return;
        }
        if (this.isBrowser) {
            window.history.replaceState({}, '', TransactionUrlService.getUrl(transaction, this.getSection(path)));
        }
    }

    public updateUrlWithSuccesFullTransaction(url: string): void {
        if (!this.isBrowser) {
            return;
        }

        let decodedUrlString: string = decodeURIComponent(url);
        let match: RegExpMatchArray | null = decodedUrlString.match('[?]');
        url = match ? url.replace('?successful_transaction=1', '') : url.replace('&successful_transaction=1', '');
        let param: string = match ? '&successful_transaction=1' : '?successful_transaction=1';

        window.history.replaceState({}, '', url + param);
    }

    public cleanParamsUrl(url: string): void {
        if (!this.isBrowser) {
            return;
        }
        window.history.replaceState({}, '', url.split('?')[0]);
    }

    private getSection(path: string): 'a' | 'b' | 'cobrar' | any {
        let lenguageCode: string = '';
        if (supportedLenguage.includes(path.split('/')[1])) {
            lenguageCode = path.split('/')[1] + '/';
        }

        if (path.includes('/a/')) {
            return lenguageCode + 'a';
        }

        if (path.includes('/b/')) {
            return lenguageCode + 'b';
        }

        if (path.includes('/cobrar/')) {
            return 'cobrar';
        }

        return lenguageCode + 'a';
    }
}
