import { Injectable } from '@angular/core';
import { Account } from '../resources/account';
import { Service } from 'ngx-jsonapi';

@Injectable({
    providedIn: 'root'
})
export class AccountService extends Service<Account> {
    public resource: typeof Account = Account;
    public type: string = 'accounts';

    public constructor() {
        super();
        this.register();
    }
}
