import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-animated-blurred-circles',
    templateUrl: './animated-blurred-circles.component.html',
    styleUrls: ['./animated-blurred-circles.component.scss']
})
export class AnimatedBlurredCirclesComponent {
    @Input() public isLanding: boolean = false;
}
