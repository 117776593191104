import { PlatformBrowserService } from './platform-browser.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';
import { ClientValuesService } from './client-values.service';
import { ClientValue } from '../resources/client-value';
import { Router } from '@angular/router';
import { LenguagesHelper, supportedLenguageV2 } from '../helpers/lenguages.helper';

export interface ICountryData {
    code: string;
    ip: string;
}

@Injectable({
    providedIn: 'root'
})
export class UserCountryService {
    public default_country_lang_data: ClientValue = new ClientValue();
    private loaded: boolean = false;
    private lenguagesHelper: LenguagesHelper = new LenguagesHelper();

    public constructor(
        private browserPlatform: PlatformBrowserService,
        public httpClient: HttpClient,
        private router: Router,
        private clientValuesService: ClientValuesService,
        private localStorageService: LocalStorageService
    ) {
        if (!this.browserPlatform.isBrowser) {
            this.loaded = true;
        }
        this.default_country_lang_data.attributes.code = '';
    }

    public getCountry(): Observable<ICountryData | any> {
        let user_lang: any = this.localStorageService.getItem('lang');
        let custom_lang: any = this.localStorageService.getItem('custom_lang');
        let lang: string = this.lenguagesHelper.extractLenguageFromPath(this.router.url);

        if (lang) {
            this.default_country_lang_data.attributes.code = lang;

            return of(this.default_country_lang_data.attributes);
        }

        if (this.loaded || user_lang === undefined || custom_lang === undefined) {
            return of(this.default_country_lang_data.attributes);
        }

        if (custom_lang !== null) {
            return of(custom_lang);
        }

        if (user_lang !== null) {
            return of(user_lang);
        }

        return this.fetchUserCountry().pipe(
            filter((country_lang_data_response: ClientValue): boolean => country_lang_data_response.attributes.code !== undefined),
            map((country_lang_data_response): ICountryData => {
                this.loaded = true;

                country_lang_data_response.attributes.code =
                    supportedLenguageV2[country_lang_data_response.attributes.code] ?? LenguagesHelper.DEFAULT_LANG;

                this.localStorageService.setItem('lang', country_lang_data_response.attributes);

                return country_lang_data_response.attributes;
            })
        );
    }

    public selectCustomCountry(country_data: ICountryData): void {
        this.localStorageService.setItem('custom_lang', country_data);
    }

    private fetchUserCountry(): Observable<ICountryData | any> {
        return this.clientValuesService.get('my');
    }

    public redirectByLang(url: string, code_lang: string): void {
        if (!code_lang) {
            return;
        }

        if (!url.includes(code_lang)) {
            let newUrl: string = /[a-z]{2}-[A-Z]{2}/.test(url)
                ? url.replace(/[a-z]{2}-[A-Z]{2}/, code_lang)
                : code_lang + (url === '/' ? '' : url);
            this.router.navigateByUrl(newUrl);
        }
    }
}
