import { Injectable } from '@angular/core';
import { Service } from 'ngx-jsonapi';
import { PublicDeal } from '../resources/public-deal';

@Injectable({
    providedIn: 'root'
})
export class PublicDealsService extends Service<PublicDeal> {
    public resource: typeof PublicDeal = PublicDeal;
    public type: string = 'public_deals';

    public constructor() {
        super();
        this.register();
    }
}
