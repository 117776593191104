import { Component, ViewChild, ElementRef, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { GeneralsInfoService } from '@app/core/resources/generals-info.service';
import { GeneralInfo } from '@app/core/resources/general-info';
import { IncrementalNumberService } from '@app/core/services/incremental-number.service';
import { IntersectionObserverService } from '@app/core/services/intersection-observer.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { PlatformBrowserService } from '@app/core/services/platform-browser.service';

@Component({
    selector: 'app-last-transactions',
    templateUrl: './last-transactions.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./last-transactions.component.scss']
})
export class LastTransactionsComponent implements AfterViewInit, OnDestroy {
    @ViewChild('lastTransaction') public lastTransaction!: ElementRef;

    public generalInfo: GeneralInfo = new GeneralInfo();
    public value: Array<number> = [0];
    private intersectionSubscription: Subscription = new Subscription();
    private lastTransactionsSubject: Subject<IntersectionObserverEntry> = new Subject<IntersectionObserverEntry>();
    private lastTransaction$: Observable<IntersectionObserverEntry> = this.lastTransactionsSubject.asObservable();

    public constructor(
        private generalsInfoService: GeneralsInfoService,
        private intersectionObserverService: IntersectionObserverService,
        private browserService: PlatformBrowserService,
        private changeDetectorRef: ChangeDetectorRef,
        private incrementalNumberService: IncrementalNumberService
    ) {
        this.incrementalNumberService.setChange(this.changeDetectorRef);
    }

    public ngAfterViewInit(): void {
        if (!this.browserService.isBrowser) {
            return;
        }

        this.generalsInfoService.all().subscribe((generalInfo): void => {
            if (generalInfo.data.length === 0) {
                return;
            }
            this.generalInfo = generalInfo.data[0];
            this.subscribeIntersection();
        });
    }

    public ngOnDestroy(): void {
        this.intersectionSubscription.unsubscribe();
    }

    public subscribeIntersection(): void {
        this.intersectionSubscription = this.lastTransaction$.subscribe((): void => {
            this.runAnimation();
        });
        this.intersectionObserverService.observeElement('.last-transaction', this.lastTransactionsSubject);
    }

    private runAnimation(): void {
        this.incrementalNumberService
            .setAnimationValueEnd(this.generalInfo.attributes.quantity)
            .setContainer(this.value)
            .setStep(80)
            .runAnimation(5);
    }
}
