<div class="steps flex-column-center-start">
    <h1 *ngIf="!hiddenTitle">{{ 'title' | translate }}</h1>
    <div [ngClass]="{'not-ssr': browserService.isBrowser}">
        <div class="container-steps">
            <div class="step flex-row-start-center">
                <div class="container-step">
                    <div class="icon-step">
                        <span class="material-icons-outlined">done</span>
                    </div>
                    <div class="number-step">
                        <span>1</span>
                    </div>
                </div>
                <p class="text-step">{{ 'step_one' | translate }}</p>
            </div>
            <div class="step flex-row-start-center">
                <div class="container-step">
                    <div class="icon-step">
                        <span class="material-icons-outlined">done</span>
                    </div>
                    <div class="number-step">
                        <span>2</span>
                    </div>
                </div>
                <p class="text-step">{{'step_two' | translate}}</p>
            </div>
            <div class="step flex-row-start-center">
                <div class="container-step">
                    <div class="icon-step">
                        <span class="material-icons-outlined">done</span>
                    </div>
                    <div class="number-step">
                        <span>3</span>
                    </div>
                </div>
                <p class="text-step">{{'step_three' | translate}}</p>
            </div>
        </div>
    </div>
</div>
