export const help_center_en_US: any = {
    help_center_title: 'Help Center',
    help_center_subtitle: 'Talk to our sales staff and clear your doubts.',

    help_center_first_button: 'Frequent Questions',
    help_center_second_button: 'Warranty',
    help_center_third_button: 'Offers',

    help_center_tutorial_title: 'Tutorials',

    which_system_help_title: 'In which system do you need help?',
    help_center_article_title: 'Our latest articles',

    help_center_article_one: 'In this section, we highlight articles from our blog that talk about ',
    help_center_article_two: 'and their uses.',

    help_center_place_order: 'Want to place an order?',
    help_center_place_order_button: 'Place an order',

    help_center_subtitle_two: 'Choose one of the following options',

    help_center_first_button_two: 'Help guides',
    help_center_second_button_two: 'Frequently asked questions',
    help_center_third_button_two: 'Community',

    help_center_first_button_two_description: 'Find out how to trade with your preferred method through our comprehensive guides.',
    help_center_second_button_two_description: 'Find all the answers to the questions most frequently asked by our customers.',
    help_center_third_button_two_description:
        'Follow us on our social networks and learn how to trade with our tips and exclusive content.',
    help_center_title_sales: 'Contact our sales team',
    help_center_title_sales_br: 'whenever you need it',
    help_center_article_button_sales: 'Talking to sales',

    video_tutorials_title: 'Watch our video tutorials and learn how to operate',
    video_tutorials_subtitle:
        'In these videos, we offer a detailed and easy-to-follow guide. Learn how to trade effectively and confidently on Saldoar.',
    video_tutorials_p: 'What is Saldoar and how does it work?',
    video_tutorials_p_two: 'How to withdraw PayPal balance?',
    video_tutorials_p_three: 'How to recharge PayPal balance?'
};
