<app-header [withBanner]="true"></app-header>

<div [ngClass]="{'transaction-data-home grid col2 col1-sm': !isLanding()}">
    <app-transaction-data *ngIf="!isLanding()" class="transaction-data-container" [transaction]="transaction"
        [receiveSystem]="receiveSystem" [sendSystem]="sendSystem" [validatorErrors]="validatorErrors"
        (homeButtonPressed)="updateUrl($event)" [path]="path">
    </app-transaction-data>
    <app-animated-blurred-circles [isLanding]="!isLanding"></app-animated-blurred-circles>

    <div class="not-design-system rs-wrapper-v4"
        [ngClass]="{'grid col2 col1-sm hero-container': isLanding(),'calculator-container-form': !isLanding()}">
        <ng-container *ngIf="isMobile && firstTitleMobile; else defaultTitle">
            <h1 class="title-mobile">{{ 'title' | translate }}</h1>
            <div [ngClass]="{'container-mobile': isLanding()}">
                <app-transaction-calculator *appShellNoRender [isLanding]="isLanding()" [transaction]="transaction" [systems$]="systems$"
                    [showActionButton]="isLanding()" (validatorErrorsUpdate)="validatorErrorsUpdate($event)"
                    (isExit)="changeIsExit($event)">
                </app-transaction-calculator>
                <div class="calculator-skeleton-ssr">
                    <app-calculator-skeleton *appShellRender></app-calculator-skeleton>
                </div>
                <app-fee-message *ngIf="!isLanding()" [isTransactionData]="true"></app-fee-message>
                <div class="rshide-sm container-best-rates" [ngClass]="{'not-show': !isLanding()}">
                    <app-best-rates></app-best-rates>
                </div>
                <app-header-steps [hiddenTitle]="true" [isExit]="isExit"
                    [ngClass]="{'not-show': !isLanding()}" class="lazyload-user-social"></app-header-steps>
            </div>
        </ng-container>

        <ng-template #defaultTitle>
            <app-header-steps [isExit]="isExit" [ngClass]="{'not-show': !isLanding()}" class="lazyload-user-social"></app-header-steps>
            <div [ngClass]="{'container': isLanding()}">
                <app-transaction-calculator *appShellNoRender [isLanding]="isLanding()" [transaction]="transaction" [systems$]="systems$"
                    [showActionButton]="isLanding()" (validatorErrorsUpdate)="validatorErrorsUpdate($event)"
                    (isExit)="changeIsExit($event)">
                </app-transaction-calculator>
                <div class="calculator-skeleton-ssr">
                    <app-calculator-skeleton *appShellRender></app-calculator-skeleton>
                </div>
                <app-fee-message *ngIf="!isLanding()" [isTransactionData]="true"></app-fee-message>
                <div class="rshide-sm container-best-rates" [ngClass]="{'not-show': !isLanding()}">
                    <app-best-rates></app-best-rates>
                </div>
            </div>
        </ng-template>
    </div>
</div>

<app-landing-page *ngIf="isLanding()" [systems]="systems.data"></app-landing-page>

<app-footer [ngClass]="{'footer-transaction-data': !isLanding()}"></app-footer>

