import { System } from '@app/core/resources/system';
import { Transaction } from './transaction';
import { DecimalSystemHelper } from '@app/core/helpers/decimal-system-helper';

export class TransactionUrlService {
    public static getUrl(transaction: Transaction, section: 'a' | 'b' | 'cobrar' | any): string {
        let path: string;
        if (section.includes('cobrar')) {
            path = `/${section}/${transaction.attributes.system1}-a-${transaction.attributes.system2}`;
        } else {
            path = `/${section}/${transaction.attributes.system1}/${transaction.attributes.system2}/`;
            let amount: number;
            if (transaction.attributes.__operation === 'send') {
                let system2: System | null | undefined = transaction.relationships.system2?.data;
                amount =
                    system2 && system2.attributes
                        ? new DecimalSystemHelper().parseAmountByDecimal(system2, transaction.attributes.amount2)
                        : 0;
                path += `0/${amount}`;
            } else {
                let system1: System | null | undefined = transaction.relationships.system1?.data;
                amount =
                    system1 && system1.attributes
                        ? new DecimalSystemHelper().parseAmountByDecimal(system1, transaction.attributes.amount1)
                        : 0;
                path += `${amount}`;
            }
        }

        return path;
    }
}
