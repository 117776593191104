export const lazy_load_modules: Array<any> = [
    {
        name: 'transaction-form',
        load: (): any =>
            import('app/transactions/components/transaction-form/transaction-form.module').then((mod): any => mod.TransactionFormModule)
    },
    {
        name: 'user-cards-carousel',
        load: (): any => {
            if (true) {
                return import('app/landing-page/components/user-social/user-cards-carousel/user-cards-carousel.module').then(
                    (mod): any => mod.UserCardsCarouselModule
                );
            }
        }
    }
];
