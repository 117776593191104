import { DealsBag } from '../resources/deals-bag';
import { System } from '../resources/system';
import { SystemsService } from '../services/systems.service';
import { ServiceLoader } from '@app/core/services/service-loader';
import { tap, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Deal } from '../resources/deal';
import TransactionAmountCalculator from '@app/transactions/transaction-amount-calculator';
import { Account } from '../resources/account';
import { DocumentCollection } from 'ngx-jsonapi';
import { Currency } from '../resources/currency';
import { CurrenciesService } from '../services/currencies.service';

export interface IDealBagsAmount {
    send_amount: string;
    we_would_send: string;
    discount_amount: string;
    receive_amount: string;
}

export class BuildingDealAmountsHelper {
    // La libreria admite los dos types, pero tslint no por eso desactive la regla
    /* eslint-disable  */
    public receiveSystem: System | null | undefined;
    public sendSystem: System | null | undefined;
    public account2: Account = new Account();
    /* eslint-enable */
    public deals: Array<Deal> = [];
    public dealsBagAmount: IDealBagsAmount = {
        send_amount: '',
        we_would_send: '',
        discount_amount: '',
        receive_amount: ''
    };
    public systemsService: SystemsService;
    public we_would_send: number = 0;
    public send_amount: number = 0;
    public discount_amount: number = 0;
    public receive_amount: number = 0;
    private transactionAmountCalculator: TransactionAmountCalculator = new TransactionAmountCalculator();

    public constructor() {
        this.systemsService = ServiceLoader.injector.get(SystemsService);
        ServiceLoader.injector.get(CurrenciesService);
    }

    public getDealBagsAmount(deals_bag: DealsBag): Observable<IDealBagsAmount> {
        this.sendSystem = deals_bag.relationships.deals.data[0]?.relationships.system.data;
        this.deals = deals_bag.relationships.deals.data;

        if (this.deals.length === 0 || !this.sendSystem) {
            this.send_amount = 0;
            this.we_would_send = 0;
            this.discount_amount = 0;
            this.receive_amount = 0;
            this.recalculate();

            return of(this.dealsBagAmount);
        }

        if (
            (deals_bag.relationships.account.data && typeof deals_bag.relationships.account.data.relationships.system === 'undefined') ||
            !deals_bag.relationships.account.data?.relationships.system.data?.id
        ) {
            this.calculateSendAmount();
            this.we_would_send = 0;
            this.discount_amount = 0;
            this.receive_amount = 0;
            this.recalculate();

            return of(this.dealsBagAmount);
        }

        this.receiveSystem = deals_bag.relationships.account.data?.relationships.system.data;
        this.account2 = deals_bag.relationships.account.data;

        return this.getSystemWithRate().pipe(
            map((): IDealBagsAmount => {
                this.discount_amount = this.receive_amount - this.we_would_send;
                this.calculateSendAmount();
                this.recalculate();

                return this.dealsBagAmount;
            })
        );
    }

    private recalculate(): IDealBagsAmount | any {
        let maximinDigits: { [property: string]: number } = {
            minimumFractionDigits: 2,
            maximumFractionDigits: this.receiveSystem?.attributes.decimal_places || 2
        };

        let factorRound: number = Math.pow(10, this.receiveSystem?.attributes.decimal_places || 2);

        this.dealsBagAmount.send_amount = this.send_amount.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: this.sendSystem?.attributes.decimal_places || 2
        });
        this.dealsBagAmount.we_would_send = this.we_would_send.toLocaleString('en-US', maximinDigits);
        this.dealsBagAmount.discount_amount = this.discount_amount.toLocaleString('en-US', maximinDigits);
        this.dealsBagAmount.receive_amount = (Math.floor(this.receive_amount * factorRound) / factorRound).toLocaleString(
            'en-US',
            maximinDigits
        );
    }

    private calculateSendAmount(): void {
        this.send_amount = 0;

        this.deals.forEach((deal): void => {
            this.send_amount = this.send_amount + deal.attributes.rest_to_pay;
        });
    }

    private getSystemWithRate(): Observable<DocumentCollection<System>> {
        let system_id: string | undefined = this.sendSystem?.id;

        return this.systemsService.all({ include: ['rates', 'currency'], ttl: 0 }).pipe(
            tap((systems: DocumentCollection<System>): void => {
                const system: System | undefined = systems.data.find((eachSystem): boolean => eachSystem.id === system_id);
                if (system === undefined || system.relationships.rates.data[0] === undefined) {
                    return;
                }

                const system2: System | null = systems.find(this.receiveSystem?.id ?? '');
                this.addCurrencyToReceiveSystemFromSystem(system2);
                this.calculateWeWouldSend(system);
                this.calculateReceiveAmount(system);
            })
        );
    }

    private addCurrencyToReceiveSystemFromSystem(system: System | null): void {
        if (!system || !this.receiveSystem) {
            return;
        }

        const currency: Currency | null | undefined = system.relationships.currency.data;

        if (!currency) {
            return;
        }

        this.receiveSystem.addRelationship(currency, 'currency');
    }

    private calculateWeWouldSend(system: System): void {
        this.we_would_send = 0;

        this.deals.forEach((deal): void => {
            if (!this.receiveSystem) {
                return;
            }

            let rest_to_pay: number = deal.attributes.rest_to_pay;
            this.we_would_send =
                this.we_would_send +
                this.transactionAmountCalculator
                    .setAccount(this.account2)
                    .setSystem1(system)
                    .setSystem2(this.receiveSystem)
                    .setAmount(rest_to_pay)
                    .calculateAmount(2);
        });
    }

    private calculateAmountWithDiscount(amount: number, discount: number): number {
        return amount * (discount / 100 + 1);
    }

    private calculateReceiveAmount(system: System): void {
        this.receive_amount = 0;

        this.deals.forEach((deal): void => {
            if (!this.receiveSystem) {
                return;
            }

            let amount: number = this.calculateAmountWithDiscount(deal.attributes.rest_to_pay, deal.attributes.gain_percent);
            this.receive_amount =
                this.receive_amount +
                this.transactionAmountCalculator
                    .setAccount(this.account2)
                    .setSystem1(system)
                    .setSystem2(this.receiveSystem)
                    .setAmount(amount)
                    .calculateAmount(2);
        });
    }
}
