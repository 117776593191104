export const warranty_en_US: any = {
    title: 'Relax, Our warranty protects you.',

    warranty_options_cards_subtitle: 'We protect you against:',

    scams_title: 'Scams',
    scams_description:
        "<p>We will send your payment again in case the money is returned to the original account due to the counterparty's misbehavior.</p>",

    increase_in_quotation: 'Increase in quotation',
    increase_in_quotation_info_text:
        '<p>Once we have successfully transferred the funds to your desired account, the exchange rate will be locked in.</p>',

    drop_in_quotation_title: 'Drop in the quotation after 48 hours',
    drop_in_quotation_info_text:
        '<p>Exceptionally, if the operation were to take more than 48 business hours after the credit of your payment, you have the right to request a re-quotation of the order at the current value.</p>',

    warranty_warning:
        "The warranty applies only to the exchange of balances if you have fulfilled every step of the process, as well as with our <a class='rs-link' href='/wiki/terms'>terms and conditions of use and navigation</a> of the site.",

    warranty_other_title: 'What else do we offer?',
    warranty_other_info_text: '<p> We ensure the safety of your transaction and provide personalized support for your needs.<p>',

    important_title: 'Important Info:',
    important_info_ul:
        "<ul> <li>Please mark I have already sent it to let us know that you have completed the payment.</li><li>Please reply to our emails as soon as possible in case we request it.</li><li>Follow each of the steps that we indicate in the instructions to avoid delays.</li><li>Please comply with our <a class='rs-link' href='/wiki/terms'>terms and conditions of use and navigation</a> to avoid delays.</li></ul>",

    convince_user_title: 'Not convinced yet?',
    convince_user_info_text: '<p>Here we show you who we are so you can trust us when you exchange your money.</p>',
    convince_user_info_link: 'Learn more about Saldoar'
};
