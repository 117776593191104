export const help_center_es_AR: any = {
    help_center_title: '¿En qué podemos ayudarte?',
    help_center_subtitle: 'Habla con nuestro personal de ventas y despeja tus dudas.',

    help_center_first_button: 'Preguntas frecuentes',
    help_center_second_button: 'Garantía',
    help_center_third_button: 'Ofertas',

    help_center_tutorial_title: 'Tutoriales',

    which_system_help_title: '¿En qué sistema necesitas ayuda?',
    help_center_article_title: 'Nuestros últimos artículos',
    help_center_article_one: 'En esta sección, destacamos artículos de nuestro blog que hablan sobre ',
    help_center_article_two: 'y sus usos',

    help_center_place_order: '¿Quieres crear un pedido?',
    help_center_place_order_button: 'Llévame a cotización',

    help_center_subtitle_two: 'Elige una de las siguientes opciones',

    help_center_first_button_two: 'Guías de ayuda',
    help_center_second_button_two: 'Preguntas frecuentes',
    help_center_third_button_two: 'Comunidad',

    help_center_first_button_two_description: 'Descubre cómo operar con tu método preferido a través de nuestras completas guías.',
    help_center_second_button_two_description: 'Encuentra todas las respuestas a las preguntas más frecuentes de nuestros clientes.',
    help_center_third_button_two_description:
        'Síguenos en nuestras redes sociales y aprende a operar con nuestros consejos y contenido exclusivo.',
    help_center_title_sales: 'Comunícate con nuestro equipo de ventas',
    help_center_title_sales_br: 'siempre que lo necesites',
    help_center_article_button_sales: 'Hablar con ventas',

    video_tutorials_title: 'Mira nuestros video-tutoriales y aprende a operar',
    video_tutorials_subtitle:
        'En estos videos, ofrecemos una guía detallada y fácil de seguir. Aprender a operar de manera efectiva y con confianza en Saldoar.',
    video_tutorials_p: '¿Qué es y cómo funciona Saldoar?',
    video_tutorials_p_two: '¿Cómo retirar saldo PayPal?',
    video_tutorials_p_three: '¿Cómo recargar saldo PayPal?'
};
