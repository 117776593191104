<mat-card>
    <h3>Estado de mi pedido</h3>
    <p>Aquí podrás consultar el estado de tu pedido, solo necesitamos algunos datos.</p>
    <form (submit)="getToken()" [formGroup]="transaction_state_form" fxLayout="column" fxLayoutAlign="start stretch">
        <mat-form-field appearance="outline" rsControlError>
            <mat-label>Número de pedido</mat-label>
            <input #field matInput type="text" formControlName="mid">
        </mat-form-field>
        <mat-form-field appearance="outline" rsControlError>
            <mat-label>Correo electrónico</mat-label>
            <input #field matInput type="text" formControlName="email">
        </mat-form-field>
        <div class="button-actions">
            <button mat-stroked-button (click)="dialogRef.close()" type="button">Cancelar</button>
            <app-submit [loading]="transactionStatePublic.is_saving">Consultar</app-submit>
        </div>
    </form>
</mat-card>
