import { Component, ChangeDetectionStrategy, Inject, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TransactionFormService } from '@app/transactions/components/transaction-form/transaction-form.service';
import {
    DomainToProviderService,
    IProviderDetail
} from '@app/transactions/pages/transaction-data/domain-to-provider/domain-to-provider-service';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
    selector: 'app-wait-destination',
    templateUrl: './wait-destination.component.html',
    styleUrls: ['./wait-destination.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WaitDestinationComponent implements OnDestroy {
    public isDestinations: boolean = true;
    private subscriptionDialogObservable: Subscription = new Subscription();
    private subscriptionTransactionUpdate: Subscription = new Subscription();
    public providerDetail: (email: string) => IProviderDetail = new DomainToProviderService().getProviderDetail;

    public constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: any,
        private changeDetectorRef: ChangeDetectorRef,
        private dialogRef: MatDialogRef<WaitDestinationComponent>,
        public transactionFormService: TransactionFormService
    ) {
        this.observerMessage();
        this.waitTransaction();
    }

    public ngOnDestroy(): void {
        this.subscriptionDialogObservable.unsubscribe();
        this.subscriptionTransactionUpdate.unsubscribe();
    }

    private observerMessage(): void {
        this.subscriptionDialogObservable = this.dialogData.observable.pipe(delay(0)).subscribe((isDestinations: boolean) => {
            this.isDestinations = isDestinations;
            this.changeDetectorRef.detectChanges();
        });
    }

    public waitTransaction(): void {
        this.subscriptionTransactionUpdate = this.transactionFormService.transactionUpdated$.subscribe((status) => {
            if (status.title === 'close') {
                this.closeDialog();
            }
        });
    }

    public closeDialog(): void {
        if (this.dialogData.closeDialog) {
            this.dialogData.closeDialog();
        }
        this.dialogRef.close('close');
    }
}
