import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserSocialComponent } from './user-social.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { NgxLoadableModule } from '@app/core/components/ngx-loadable/ngx-loadable.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
@NgModule({
    declarations: [UserSocialComponent],
    exports: [UserSocialComponent],
    imports: [CommonModule, NgxLoadableModule, MatProgressSpinnerModule, TranslateModule, MatIconModule]
})
export class UserSocialModule {}
