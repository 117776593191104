import { DocumentCollection, Resource } from 'ngx-jsonapi';
import { System } from './system';

export class Network extends Resource {
    public type: string = 'networks';
    public attributes: {
        title: string | null;
        fixed_fee_usd: number;
    } = {
        title: null,
        fixed_fee_usd: 0
    };

    public relationships: {
        systems: DocumentCollection<System>;
    } = {
        systems: new DocumentCollection<System>()
    };
}
