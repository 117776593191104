<div class="default-space-between-components">
    <div class="rs-wrapper-v4 grid col1-sm" [ngClass]="!imageUrl ? 'col1' : 'col2'">
        <div class="info-image-container" *ngIf="imageUrl">
            <img class="lazy-load info-block-img" alt="{{ imageSettings.alt ?? titleBlock }}"
                [attr.width]="imageSettings.width ?? 'auto'" [attr.height]="imageSettings.height ?? 'auto'"
                [src]="notCompatibilityIntersection ? imageUrl : urlFake">
        </div>
        <div class="info-content-container">
            <app-dark-title *ngIf="titleBlock" text="{{ titleBlock }}" [withAnchor]="withAnchor"></app-dark-title>
            <div class="info-content" *ngIf="content" [innerHTML]="content"></div>
            <ng-content></ng-content>
        </div>
    </div>
</div>
