import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { pathsSaldo } from 'environments/paths-saldo';

@Injectable()
export class UserInterceptor implements HttpInterceptor {
    private headers!: HttpRequest<any>;
    public constructor(public router: Router, public activatedRoute: ActivatedRoute) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.router.url.startsWith('/u/') && request.url.includes(environment.API_URL + pathsSaldo.API_PATH)) {
            let url: Array<string> = this.router.url.split('/');
            this.headers = request.clone({
                setHeaders: {
                    'user-id': url[3],
                    'user-key': url[4]
                }
            });

            return next.handle(this.headers);
        }

        return next.handle(request);
    }
}
