import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogErrorRedirectComponent } from './dialog-error-redirect.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';

@NgModule({
    declarations: [DialogErrorRedirectComponent],
    exports: [DialogErrorRedirectComponent],
    imports: [CommonModule, MatButtonModule, MatCardModule]
})
export class DialogErrorRedirectModule {}
