export const warranty_es_AR: any = {
    title: 'Te brindamos tranquilidad, con nuestra garantía',

    warranty_options_cards_subtitle: 'Te protegemos contra:',

    scams_title: 'Estafas',
    scams_description:
        'Enviaremos nuevamente tu pago en caso de que el dinero vuelva a la cuenta origen. Asumiremos toda la responsabilidad.',

    increase_in_quotation: 'Aumento en la cotización',
    increase_in_quotation_info_text: 'Una vez tu pago se refleje en la cuenta destino ¡Congelamos la cotización! ',

    drop_in_quotation_title: 'Caída en la cotización',
    drop_in_quotation_info_text:
        'Si abonaste y en 48hs hábiles no recibes tu depósito, puedes solicitar una recotización al precio actual. ',

    warranty_warning:
        'La garantía se aplica solo al intercambio de saldos si sigues todos los pasos y cumples <a routerlink="/wiki/terms" class="rs-link" href="/wiki/terms">términos y condiciones de uso y navegación</a> del sitio',

    warranty_other_title: '¿Qué más ofrecemos?',
    warranty_other_info_text: '<p>Total seguridad en tu proceso y atención personalizada durante tu pedido. </p>',

    important_title: 'Es importante que:',
    important_info_ul:
        '<ul><li>Marques "Ya lo envié" para avisarnos que has realizado el pago.</li><li>Respondas nuestros correos lo antes posible, en caso de que lo solicitemos.</li><li>Sigas cada uno de los pasos que te indicamos en las instrucciones</li><li>Cumplas con nuestros <a href="https://saldo.com.ar/wiki/terms" class="rs-link" title="Términos y Condiciones de Saldoar"> Términos y Condiciones de Uso y Navegación</a> para evitar demoras.</li></ul>',

    convince_user_title: '¿Aún no te convencimos?',
    convince_user_info_text: '<p>Aquí te mostramos quiénes somos para que puedas confiar al intercambiar tu dinero.</p>',
    convince_user_info_link: 'Conoce más acerca de Saldoar'
};
