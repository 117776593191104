import { IBankRepository } from './bank-repository-interface';

export class ColombiaBankRepository implements IBankRepository {
    public readonly id: string = 'banco_col';
    public readonly currency: string = 'COP';
    public readonly groupId: string = 'col_bank';
    public readonly title: string = 'Intercambiar saldo Colombia';
    public readonly urlHref: string = '/es-CO/a/banco_col/palpal/0/100';
    public readonly countryName: string = 'Colombia';
    public readonly currencyName: string = 'Pesos colombianos';
    public readonly widthPositionSprite: string = '-24px';
    public readonly heightPositionSprite: string = '-106px';
    public readonly systemPair: string = 'palpal';
    public readonly langCode: string = 'es-CO';
}
