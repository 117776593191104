import { Network } from '../resources/networks';

export function NetworkMessageHelper(receiveSystem: any): string | null {
    if (receiveSystem.relationships?.networks?.data && receiveSystem.relationships.networks.data.length > 0) {
        const networkId: string | undefined = receiveSystem.attributes.default_network_id;
        const defaultNetwork: Network | undefined = receiveSystem.relationships.networks.data.find(
            (network: Network) => network.id === networkId
        );

        if (defaultNetwork) {
            return defaultNetwork.attributes.title;
        } else {
            return null;
        }
    } else {
        return null;
    }
}
