import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoBlockComponent } from './info-block.component';
import { CoreModule } from '@app/core/core.module';

@NgModule({
    declarations: [InfoBlockComponent],
    exports: [InfoBlockComponent],
    imports: [CommonModule, CoreModule]
})
export class InfoBlockModule {}
