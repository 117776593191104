import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepperTransactionDataComponent } from './stepper-transaction-data.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatStepperModule } from '@angular/material/stepper';

@NgModule({
    declarations: [StepperTransactionDataComponent],
    exports: [StepperTransactionDataComponent],
    imports: [CommonModule, MatStepperModule, TranslateModule]
})
export class StepperTransactionDataModule {}
