<app-full-screen (clearClick)="closeDialog()">
    <ng-container *ngIf="isDestinations; else notDestinations">
        <app-custom-video src="../../../../../../assets/images/contact/buscando-destinos.webm"
            [width]=374></app-custom-video>
        <p class="text-destinations">Buscando los destinos ideales para ti...</p>
    </ng-container>
</app-full-screen>

<ng-template #notDestinations>
    <app-custom-video src="../../../../../assets/images/contact/chequear-correo-saldo.webm"
        [width]=374></app-custom-video>
    <h1>¡{{dialogData.name ? dialogData.name + ', chequea' : 'Chequea'}} tu correo!</h1>
    <p class="descent-paragraph" [innerHTML]="'En tu correo recibirás las instrucciones para continuar con tu pedido.'">
    </p>
    <app-domain-to-provider *ngIf="providerDetail(dialogData.email) && providerDetail(dialogData.email).id"
        [width]="providerDetail(dialogData.email).width" [height]="providerDetail(dialogData.email).height"
        [url]="providerDetail(dialogData.email).url"
        [imagePath]="'/assets/images/domain-to-provider/' + providerDetail(dialogData.email).id + '-v1'"
        [text]="'Ir a ' + providerDetail(dialogData.email).id">
    </app-domain-to-provider>
</ng-template>
