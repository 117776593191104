import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { System } from '@app/core/resources/system';
import { PublicDealsSystemService } from '../public-deals-system.service';
import { QuotationHelper } from '../quotation.helper';
import { SystemsService } from '@app/core/services/systems.service';
import { DealsListComponent } from '@app/dashboard/deals/deals-list/deals-list.component';
import { Deal } from '@app/core/resources/deal';
import { GlobalStateService } from '@app/core/services/global-state.service';

@Component({
    selector: 'app-info-public-deal',
    templateUrl: './info-public-deal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./info-public-deal.component.scss']
})
export class InfoPublicDealComponent implements AfterViewInit {
    @ViewChild(DealsListComponent) public dealsListComponent!: DealsListComponent;
    private quotationHelper: QuotationHelper = new QuotationHelper();
    public systemReceiveFormGroup: FormGroup;
    public deal: Deal = new Deal();
    public publicDealsSystemService!: PublicDealsSystemService;
    public quotationInfo: any = {
        realQuotation: 0,
        quotation: 0,
        pair: '',
        way: 1
    };

    public constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: any,
        private formBuilder: FormBuilder,
        private dialogRef: MatDialogRef<InfoPublicDealComponent>,
        private changeDetectorRef: ChangeDetectorRef,
        public globalStateService: GlobalStateService,
        public systemsService: SystemsService,
        public router: Router
    ) {
        this.publicDealsSystemService = this.dialogData.publicDealsSystemService;
        this.systemReceiveFormGroup = this.formBuilder.group({
            systemSelect: ['']
        });
        this.observerReceiveSystem();
    }

    public ngAfterViewInit(): void {
        this.systemReceiveFormGroup.get('systemSelect')?.setValue(this.publicDealsSystemService.receiveSystem);
        this.setQuotationInfo();
    }

    public observerReceiveSystem(): void {
        this.systemReceiveFormGroup.controls.systemSelect.valueChanges.subscribe((data) => {
            this.publicDealsSystemService.groupId = data.attributes.group_id;
            this.publicDealsSystemService.currency = data.attributes.currency;
            this.publicDealsSystemService.calculateReceiveAmount();
            this.setQuotationInfo();
        });
    }

    private setQuotationInfo(): void {
        this.quotationInfo = this.quotationHelper.getQuotation({
            system1: this.publicDealsSystemService.publicDeal.relationships.system.data ?? new System(),
            system2: this.publicDealsSystemService.receiveSystem,
            amount1: this.publicDealsSystemService.amountWithoutGain,
            amount2: this.publicDealsSystemService.amount2,
            restToPay: this.publicDealsSystemService.publicDeal.attributes.rest_to_pay
        });
        this.changeDetectorRef.markForCheck();
    }

    public redirectTo(): void {
        this.dialogRef.close();
        this.router.navigate(['/my/dashboard/deals']);
    }

    public formatToDecimal(value: number, system: System): string {
        return value.toFixed(system.attributes.decimal_places || 2);
    }

    public closeDialogInfoPublicDeal(_deal: Deal | null = null): void {
        this.dialogRef.close(_deal);
    }
}
