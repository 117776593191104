export const header_en_US: any = {
    header_associates_program_button: 'Affiliates Program',
    header_my_order_button: 'My Order',
    header_country_in_en_button: 'International | English',
    header_country_in_es_button: 'Internacional | Español',
    VCC_buy: 'Buy a VCC',

    bottom_page_account: 'My Account',
    bottom_page_account_log_in_button: 'Log in',
    bottom_page_account_sign_in_button: 'Sign up',

    bottom_page_support: 'Help',
    bottom_page_support_center_button: 'Help center',
    bottom_page_support_frequent_questions_button: 'Frequent questions',
    bottom_page_support_about_us_button: 'About us',
    bottom_page_support_associates_program: 'Affiliates Program',

    bottom_page_legal: 'Legal',
    bottom_page_legal_warranty_button: 'Warranty',
    bottom_page_legal_terms_of_use_button: 'Terms of use',
    bottom_page_legal_privacy_policy_button: 'Privacy Policy',
    bottom_page_all_right_reserved: 'All rights reserved © SALDOAR',

    meta_set_og_default_title: 'Saldoar | Exchange your virtual money in just 3 steps.',
    meta_set_og_update_title: 'Exchange your Virtual Money {{ element_one }} to {{ element_two }}',
    meta_set_og_default_description:
        'Choose from over 20 different exchange methods: Bank transfer, PayPal, Mercado Pago, Skrill, Ualá, Wise...'
};
