import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckTransactionStatesComponent } from './check-transaction-states.component';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { SubmitModule } from '@app/core/components/submit/submit.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [CheckTransactionStatesComponent],
    exports: [CheckTransactionStatesComponent],
    imports: [
        CommonModule,
        MatCardModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        SubmitModule,
        TranslateModule
    ]
})
export class CheckTransactionStatesModule {}
