import { Injectable } from '@angular/core';
import { Service } from 'ngx-jsonapi';
import { Currency } from '@app/core/resources/currency';

@Injectable({
    providedIn: 'root'
})
export class CurrenciesService extends Service<Currency> {
    public resource: typeof Currency = Currency;
    public type: string = 'currencies';

    public constructor() {
        super();
        this.register();
    }
}
