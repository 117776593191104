import { IBankRepository } from './bank-repository-interface';

export class ArgentinaBankRepository implements IBankRepository {
    public readonly id: string = 'banco';
    public readonly currency: string = 'ARS';
    public readonly groupId: string = 'ars_bank';
    public readonly title: string = 'Intercambiar saldo Argentina';
    public readonly urlHref: string = '/a/banco/palpal/0/100';
    public readonly countryName: string = 'Argentina';
    public readonly currencyName: string = 'Pesos argentinos';
    public readonly widthPositionSprite: string = '0px';
    public readonly heightPositionSprite: string = '-106px';
    public readonly systemPair: string = 'palpal';
    public readonly langCode: string = '';
}
