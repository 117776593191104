import { Inject, Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { PlatformBrowserService } from './platform-browser.service';
import { LocalStorageService } from './local-storage.service';

export interface IUtmValues {
    value: string;
    expires_at: number;
}

@Injectable({
    providedIn: 'root'
})
export class SourceService {
    private utmList: Array<string> = ['utm_source', 'utm_campaign', 'utm_medium', 'utm_content', 'utm_term', 'gclid', 'fbclid'];
    public constructor(
        @Inject(DOCUMENT) private document: Document,
        private browserService: PlatformBrowserService,
        private localStorageService: LocalStorageService
    ) {}

    public listenParams(params: Params): void {
        this.utmList.forEach((utm: string) => {
            if (params[utm]) {
                this.setUtm(utm, params[utm]);
            }
        });

        this.setSource();
    }

    private convertArrayToString(value: any): string {
        if (typeof value === 'string') {
            return value;
        }

        if (Array.isArray(value)) {
            return value.join(',');
        }

        return '';
    }

    private setSource(): void {
        if (!this.browserService.isBrowser) {
            return;
        }

        let referrelAux: any = this.document.referrer === null ? '(null)' : this.document.referrer;
        this.setUtm('referrer', referrelAux);

        if (this.getUtm('source_type')) {
            return;
        }

        switch (true) {
            case this.document.referrer === '':
                this.setUtm('source_type', 'direct');
                break;
            case this.document.referrer.includes('google'):
                this.setUtm('source_type', 'organic');
                break;
            case /(facebook|instagram|t\.co)/.test(this.document.referrer):
                this.setUtm('source_type', 'social');
                break;
            default:
                this.setUtm('source_type', 'referral');
                break;
        }
    }

    public setUtm(utm: string, value: string): void {
        this.localStorageService.setItemByExpiresAt(utm + '_v2', value, new Date().getTime() + 1000 * 60 * 60 * 24 * 10);
    }

    public getUtm(key: string): string | null {
        return this.convertArrayToString(this.localStorageService.getItemByExpiresAt(key + '_v2'));
    }
}
