<mat-card class="loading-calculator">
    <div *ngFor="let item of [1,2,3]" [ngClass]="item === 2 ? 'button-invert-container' : ''">
        <app-calculator-content-loader *ngIf="item === 1 || item === 3"></app-calculator-content-loader>
        <button *ngIf="item === 2" mat-icon-button class="big-icon-button"><mat-icon>swap_vert</mat-icon></button>
    </div>
    <div class="buttons-calculator-container grid">
        <button class="more-options-button" mat-stroked-button><mat-icon>more_horiz</mat-icon></button>
        <button class="dark-background" mat-button>{{ (actionButtonText | translate) || ('next_button' | translate) }}</button>
    </div>
</mat-card>
