<div>
    <mat-card>
        <mat-card-content>
            <app-stepper-transaction-data></app-stepper-transaction-data>
        </mat-card-content>
        <mat-card-content>
            <app-ngx-loadable *ngIf="loadForm; else skeletonForm" module="transaction-form" [show]="true">
            </app-ngx-loadable>
        </mat-card-content>
    </mat-card>
</div>

<ng-template #skeletonForm>
    <div class="skeleton-space">
        <app-skeleton></app-skeleton>
        <app-skeleton></app-skeleton>
        <app-skeleton></app-skeleton>
        <app-skeleton></app-skeleton>
    </div>
</ng-template>
