export interface IProviderDetail {
    id: string;
    name: string;
    url: string;
    height: number;
    width: number;
}

export class DomainToProviderService {
    public getProviderDetail(email: string): IProviderDetail {
        if (email === undefined) {
            return {
                id: '',
                name: '',
                url: '',
                height: 0,
                width: 0
            };
        }
        let reg_exp: RegExp = /@([a-z]+)/;
        let test: RegExpMatchArray | null = email.match(reg_exp);
        let emailProvider: string = test === null ? '' : test[1];

        switch (emailProvider) {
            case 'gmail':
                return {
                    id: 'gmail',
                    name: 'Gmail',
                    url: 'https://gmail.com',
                    height: 32,
                    width: 45
                };
            case 'hotmail':
            case 'outlook':
                return {
                    id: 'outlook',
                    name: 'Outlook',
                    url: 'https://outlook.live.com/owa/',
                    height: 42,
                    width: 45
                };
            case 'yahoo':
                return {
                    id: 'yahoo',
                    name: 'Yahoo',
                    url: 'https://login.yahoo.com',
                    height: 40,
                    width: 45
                };
            default:
                return {
                    id: '',
                    name: '',
                    url: '',
                    height: 0,
                    width: 0
                };
        }
    }
}
