import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-add-more-deals',
    templateUrl: './add-more-deals.component.html',
    styleUrls: ['./add-more-deals.component.scss']
})
export class AddMoreDealsComponent {
    public constructor(private matDialogRef: MatDialogRef<AddMoreDealsComponent>) {}

    public closeDialog(action: string): void {
        this.matDialogRef.close(action);
    }
}
