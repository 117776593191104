import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SystemTrendComponent } from './system-trend.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [SystemTrendComponent],
    exports: [SystemTrendComponent],
    imports: [CommonModule, MatIconModule]
})
export class SystemTrendModule {}
