import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PlatformBrowserService } from '@app/core/services/platform-browser.service';
import { Resource, Service } from 'ngx-jsonapi';
import { interval, Observable, ReplaySubject } from 'rxjs';
import { filter, flatMap, startWith, takeWhile } from 'rxjs/operators';
import { RedirectInstructionsHerlper } from '../helper/redirect-instructions.helper';
import { GlobalStateService } from '../../core/services/global-state.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class TransactionInstructionsService {
    private numberRequest: number = 0;
    private finishInterval: boolean = false;
    private redirectInstructionsHelper: RedirectInstructionsHerlper = new RedirectInstructionsHerlper();
    private readonly PERMITTED_REQUEST: number = 5;
    public isDestinations: ReplaySubject<boolean> = new ReplaySubject(1);
    public isSaved: boolean = false;
    public service!: Service<any>;
    public params: any = {};

    public constructor(
        @Inject(DOCUMENT) public document: Document,
        private dialog: MatDialog,
        private browserService: PlatformBrowserService,
        private route: Router,
        private globalStateService: GlobalStateService
    ) {}

    public verifyTransaction(id: string, from: string = 'landing'): void {
        this.numberRequest = 0;
        this.finishInterval = false;

        this.interval(id).subscribe(
            (resource: any) => {
                switch (resource.attributes.state) {
                    case 2:
                    case 3:
                        this.finishInterval = true;
                        this.isDestinations.next(false);
                        if (this.route.url.includes('dashboard') && this.globalStateService.userId) {
                            this.redirectInstructions(resource.attributes.instructions_url, from, resource.attributes.state, id);

                            return;
                        }
                        break;
                    case 0:
                        this.numberRequest += 1;
                        if (this.numberRequest === this.PERMITTED_REQUEST) {
                            this.isDestinations.next(false);
                            if (this.route.url.includes('dashboard') && this.globalStateService.userId) {
                                this.redirectInstructions(resource.attributes.instructions_url, from, resource.attributes.state, id);

                                return;
                            }
                        }
                        break;
                    default:
                        this.redirectInstructions(resource.attributes.instructions_url, from, resource.attributes.state, id);
                        break;
                }
            },
            () => {
                this.finishInterval = true;
                this.isDestinations.next(false);
            }
        );
    }

    private interval(id: string): Observable<Resource> {
        return interval(1 * 1000).pipe(
            startWith(0),
            flatMap((): Observable<Resource> => {
                return this.service.get(id, this.params).pipe(filter((transaction): boolean => transaction.source === 'server'));
            }),
            takeWhile((): boolean => this.numberRequest !== this.PERMITTED_REQUEST && !this.finishInterval)
        );
    }

    private redirectInstructions(instructionsUrl: string, from: string, state: number = 0, transactionId: string): void {
        if (!this.browserService.isBrowser) {
            return;
        }

        this.closeDialog();
        this.finishInterval = true;
        if (from === 'landing') {
            window.location.href = instructionsUrl;

            return;
        }

        this.redirectInstructionsHelper.redirectTo(state, transactionId);
    }

    private closeDialog(): void {
        this.dialog.closeAll();
    }
}
