export const transaction_states_en_US: any = {
    order_status: 'My order status',

    first_label: 'Order number',
    second_label: 'Email',

    consult_button: ' Consult',

    first_text_transaction: 'You send',
    second_text_transaction: 'We send',
    status_transaction: '',

    alert_text: 'If you want to contact someone on the team to find out more about your order, you need to ',
    alert_link: 'Login'
};
