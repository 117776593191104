export const referral_program_es_AR: any = {
    title: '¡Gana hasta 30 USD por cada persona invitada!',
    subtitle: '¡Así es! Gana hasta $30 por cada persona que invites a Saldoar y complete un pedido!',
    hero_button_text: '¡Comenzar a referir!',

    subtitle_two: '¡Más simple y ganas más!',

    win_usd_first_title: 'Hasta 15 USD al invitar',
    win_usd_first_info_text: 'Con los primeros 3 pedidos concretados de tu persona invitada. ¡Son hasta 15 USD! ',

    win_usd_second_title: 'Luego, otros 5 USD más',
    win_usd_second_info_text: 'Si la persona invitada llega al nivel 3, aumentas tus ganancias.',

    win_usd_third_title: 'y 10 USD extra',
    win_usd_third_info_text: 'Si tienes la suerte de que tu persona invitada llegue al nivel 5 en Saldoar.',

    retire_usd: '¿Cuáles son los requisitos para comenzar a ganar?',
    retire_usd_info:
        '<p>Sólo uno: Haber finalizado al menos una operación en la plataforma. Para que de esa forma le cuentes a tus invitados cómo funciona Saldoar y lo fácil que es utilizarlo.</p>',
    retire_usd_button_text: 'Empieza a referir ahora',

    necessary_level: '¿Cómo referir?',
    necessary_level_info:
        '<p>Inicia sesión en Saldoar y dirígete a la página de inicio. Con tu usuario y contraseña inicia sesión y luego vé a la sección “Referidos”. Copia tu código del apartado “Invita y ¡gana!” para compartirlo con quien desees referir. Una vez tu persona invitada haga un pedido,  empezarás a ganar.</p>',

    requirements_needed: '¿Cómo retiras las ganancias?',
    requirements_needed_info:
        '<p>Puedes retirarlas con PayPal cuando tú desees y el retiro mínimo es de 15 USD. También puedes elegir cualquiera de los demás métodos de intercambio disponibles en Saldoar a la cotización del día, como Bitcoin, Skrill, MercadoPago, etc.</p>',
    requirements_needed_button_text: 'Inicia sesión para retirar tu dinero',

    terms_of_use: 'Términos y condiciones del programa de afiliados',
    terms_of_use_info_li_one:
        'Saldo se reserva el derecho de no efectuar los pagos cuando se encuentre un hecho de mala fe en el invitador o persona invitada.',
    terms_of_use_info_li_two: 'El invitador debe poseer, al menos, un pedido finalizado con éxito.',
    terms_of_use_info_li_three:
        'En el caso en que la cuenta del invitador o persona invitada se encuentre limitada o bloqueada, Saldo no efectuará el pago.',
    terms_of_use_info_li_four: 'Para proceder a un retiro de ganancias, el monto mínimo debe ser 15 USD.',
    terms_of_use_info_li_five:
        'El método de retiro de las bases es PayPal. De todas formas, el invitador puede optar por cualquiera de los demás métodos disponibles en la plataforma a una cotización proporcional.',
    terms_of_use_info_li_six: 'Las condiciones del programa de referidos podrán modificarse sin previo aviso.',
    terms_of_use_info_li_seven:
        'Se considera persona invitada, aquella persona usuaria que concretó su primer operación a través de un link de referido provisto por el invitador.',
    terms_of_use_info_li_eight:
        'La comisión por la primera, segunda y tercera operación con éxito de la persona invitada será del 1% del monto total enviado, a la cotización USD PayPal del momento, con un máximo de 5 USD.',
    terms_of_use_info_li_nine:
        'Por razones de seguridad, entre otras, se podría modificar el monto de los pedidos de las personas invitadas.',
    terms_of_use_info_li_ten: 'El programa aplica únicamente al intercambio de saldos a través de la plataforma.',
    terms_of_use_info_li_eleven:
        'El invitador ganará 5 USD cuando la persona invitada llegue por primera vez a nivel 3. El invitador ganará 10 USD cuando la persona invitada llegue por primera vez a nivel 5.',
    terms_of_use_info_li_twelve:
        'El invitador y persona invitada aceptan y cumplen todos los <a class="rs-link" href="/wiki/terms">términos y condiciones de uso y navegación</a> de Saldo.',
    terms_of_use_info_li_thirteen: 'No podrá participar ningún persona usuaria que tenga relación comercial con la plataforma.',
    terms_of_use_info_li_fourteen:
        'Promo Referidos x2x2: Las ganancias generadas por tus referidos entre las 00:00 (ART) del 11/05/2022 hasta las 23:59 (ART) del 10/07/2022 se computarán el doble.',

    flyer_info_text: 'Ahora que ya lo sabes... <br>¡Comienza a referir!</br>',
    flyer_info_text_button: 'Invitar amigos'
};
