import { DocumentResource, IRelationships, Resource } from 'ngx-jsonapi';
import { Currency } from '@app/core/resources/currency';

export class BestRate extends Resource {
    public type: string = 'best_rates';
    public attributes: {
        price: number;
        system1: string;
        system2: string;
        currency1: string;
        currency2: string;
    } = {
        price: 0,
        system1: '',
        system2: '',
        currency1: 'ars',
        currency2: 'usd'
    };

    public relationships: IRelationships = {
        currency2: new DocumentResource<Currency>()
    };
}
