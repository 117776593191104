import { Resource } from 'ngx-jsonapi';

export class Rate extends Resource {
    public type: string = 'rates';
    public attributes: {
        price: number;
        system_id: string;
    } = {
        price: 0,
        system_id: ''
    };
}
