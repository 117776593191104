import { DocumentCollection, Resource, DocumentResource } from 'ngx-jsonapi';
import { Rate } from '@app/core/resources/rate';
import { Currency } from './currency';
import { SystemInformation } from './system-information';
import { Network } from './networks';

export class System extends Resource {
    public type: string = 'systems';
    public attributes: {
        minimum_amount_send: number | null;
        minimum_amount_receive: number | null;
        group_id: string;
        name: string;
        can_send: boolean;
        can_receive: boolean;
        fee_send: number;
        fee_receive: number;
        account_required_send: boolean;
        account_required_receive: boolean;
        alternative_name: string;
        currency: string;
        account_type: string;
        market: string;
        replacement_system_id: string;
        decimal_places: number;
        trend: number;
        fixed_fee_send: number;
        fixed_fee_receive: number;
        default_network_id: string | null;
    } = {
        minimum_amount_send: null,
        minimum_amount_receive: null,
        group_id: '',
        name: '',
        fee_send: 0,
        fee_receive: 0,
        can_send: false,
        can_receive: false,
        account_required_send: false,
        account_required_receive: false,
        alternative_name: '',
        currency: '',
        account_type: '',
        market: '',
        replacement_system_id: '',
        decimal_places: 2,
        trend: 0,
        fixed_fee_send: 0,
        fixed_fee_receive: 0,
        default_network_id: null
    };

    public relationships: {
        rates: DocumentCollection<Rate>;
        system_information: DocumentCollection<SystemInformation>;
        currency: DocumentResource<Currency>;
        networks: DocumentCollection<Network>;
    } = {
        rates: new DocumentCollection<Rate>(),
        system_information: new DocumentCollection<SystemInformation>(),
        currency: new DocumentResource<Currency>(),
        networks: new DocumentCollection<Network>()
    };

    public convertAmountWithRelatedRate(amount: number, receive_system_id: string): number {
        let receiveSystems: Rate | null = this.relationships.rates.find(`${this.id}_${receive_system_id}`);
        if (!receiveSystems || this.relationships.rates.data.length === 0 || !this.relationships.rates.data[0].attributes) {
            console.error('System: In order to use convertAmount you need to include Rates');

            return 0;
        }

        return amount * receiveSystems.attributes.price;
    }
}
