<picture [title]="title">
    <source srcset="{{url}}img/sistemas2/{{systemId}}.medium.webp 150w,
                {{url}}img/sistemas2/{{systemId}}.big.webp 400w"
        type="image/webp"
    />
    <img srcset="{{url}}img/sistemas2/{{systemId}}.medium.png 150w,
                {{url}}img/sistemas2/{{systemId}}.big.png 400w"
        src="{{url}}img/sistemas2/{{systemId}}.big.png" width="200" height="70"
        [alt]="alt"
    />
</picture>
