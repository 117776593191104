import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TokenUpdateService {
    public tokenUpdatedSource: ReplaySubject<any> = new ReplaySubject(1);
    public tokenUpdated$: Observable<any> = this.tokenUpdatedSource.asObservable();
}
