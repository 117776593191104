import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoCryptoFeeComponent } from './info-crypto-fee.component';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from '@app/core/components/tooltip/tooltip.module';

@NgModule({
    declarations: [InfoCryptoFeeComponent],
    exports: [InfoCryptoFeeComponent],
    imports: [CommonModule, MatIconModule, TranslateModule, TooltipModule]
})
export class InfoCryptoFeeModule {}
