import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { PlatformBrowserService } from '../services/platform-browser.service';

@Directive({
    selector: '[appShellRender]'
})
export class ShellRenderDirective implements OnInit {
    public constructor(
        public viewContainer: ViewContainerRef,
        public templateRef: TemplateRef<any>,
        private browserService: PlatformBrowserService
    ) {}

    public ngOnInit(): void {
        if (this.browserService.isBrowser) {
            this.viewContainer.clear();
        } else {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }
}
