import { Injectable } from '@angular/core';
import { Service } from 'ngx-jsonapi';
import { User } from '@app/core/resources/user';

@Injectable({
    providedIn: 'root'
})
export class UsersService extends Service<User> {
    public resource: typeof User = User;
    public type: string = 'users';

    public constructor() {
        super();
        this.register();
    }
}
