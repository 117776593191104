<div class="rs-wrapper-v4 grid default-space-between-components">
    <h2 class="mat-subheader">{{title | translate}}</h2>
    <p class="contact-subtitle">{{subtitle | translate}}</p>
    <form (submit)="submit()" *ngIf="stateMailSend !== 'sent'" action="index.html" class="width-100 grid" [formGroup]="contact_form">
        <mat-form-field appearance="outline">
            <mat-label>{{ 'contact_name' | translate }}</mat-label>
            <input matInput required autocomplete="name" formControlName="name" type="text" name="name">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>{{ 'contact_email' | translate }}</mat-label>
            <input matInput required autocomplete="email" formControlName="email" type="email" name="email">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>{{ 'contact_order_number' | translate }}</mat-label>
            <input matInput formControlName="mid" type="text" name="mid">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>{{ 'contact_message' | translate }}</mat-label>
            <textarea rows="3" matInput required autocomplete="off" formControlName="message" type="text" name="message"></textarea>
        </mat-form-field>
        <app-submit [loading]="stateMailSend === 'loading'">{{ 'contact_send_ticket_button' | translate }}</app-submit>
    </form>
</div>


<div *ngIf="stateMailSend === 'sent'" class="success-message-container">
    <picture>
        <source srcset="/assets/images/contact/email-send-intercambiar-saldo.webp" type="image/webp" title="Email enviado correctamente">
        <source srcset="/assets/images/contact/email-send-intercambiar-saldo.png" type="image/png" title="Email enviado correctamente">
        <img src="/assets/images/contact/email-send-intercambiar-saldo.png" width="100" height="100" alt="Email enviado" title="Email enviado correctamente">
    </picture>
    <p>{{ 'contact_sent_warning' | translate }}</p>
</div>
