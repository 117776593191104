<div class="rs-wrapper-v4 grid col6 col5-md col4-sm col3-xs default-space-between-components" [ngClass]="{'spaces-edge': isHelpCenter}">
    <ng-container *ngIf="isLoaded; else systemSkeleton">
        <a *ngFor="let system of systems" href="{{urlLandingSystem + system.id}}" class="system-picture">
            <app-system-picture class="hover-false" [alt]="system.attributes.name"
                title="Saldo {{system.attributes.name}}"
                [systemId]="system.id + '-comprar-vender'"></app-system-picture>
            <app-system-picture class="hover-true" [alt]="system.attributes.name"
                title="Saldo {{system.attributes.name}}"
                [systemId]="system.id + '-dolar'"></app-system-picture>
        </a>
    </ng-container>
</div>

<ng-template #systemSkeleton>
    <app-system-picture *ngFor="let systemId of systemsId" [systemId]="systemId"></app-system-picture>
</ng-template>
