import { Resource, DocumentResource } from 'ngx-jsonapi';
import { System } from './system';

export class Deal extends Resource {
    public type: string = 'deals';
    public attributes: {
        rest_to_pay: number;
        group_id: string;
        gain_percent: number;
    } = {
        rest_to_pay: 0,
        group_id: '',
        gain_percent: 0
    };

    public relationships: {
        system: DocumentResource<System>;
    } = {
        system: new DocumentResource<System>()
    };
}
