import {
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    ElementRef,
    EmbeddedViewRef,
    Injector,
    Input,
    NgModuleFactory,
    NgModuleRef,
    OnChanges,
    SimpleChanges,
    TemplateRef,
    Type,
    ViewChild,
    ViewContainerRef
} from '@angular/core';

import { NgxLoadableService } from './ngx-loadable.service';

export type FunctionReturningPromise = () => Promise<any>;

@Component({
    selector: 'app-ngx-loadable',
    templateUrl: './ngx-loadable.component.html',
    styleUrls: ['./ngx-loadable.component.scss']
})
export class NgxLoadableComponent implements OnChanges {
    @Input() public module!: string;
    @Input() public loaded!: boolean;
    @Input() public show!: boolean;
    @ViewChild('content', { read: ViewContainerRef, static: true }) public content!: ViewContainerRef;
    private mr!: NgModuleRef<any>;

    public constructor(
        public el: ElementRef,
        private inj: Injector,
        private cfr: ComponentFactoryResolver,
        private loadable: NgxLoadableService
    ) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.show && changes.show.currentValue) {
            this.render();
        }
    }

    private async render(): Promise<void> {
        const mf: NgModuleFactory<any> = await this.loadable.preload(this.module);
        this.mr = mf.create(this.inj);
        this.renderVCR(this.mr, this.content);
    }

    private renderVCR(
        mr: NgModuleRef<any> | TemplateRef<any> | Type<any>,
        vcr: ViewContainerRef
    ): EmbeddedViewRef<any> | ComponentRef<any> | undefined {
        let factory: any;
        if (!mr) {
            return;
        }
        if (mr instanceof TemplateRef) {
            vcr.remove();

            return vcr.createEmbeddedView(mr);
        }
        if (mr instanceof NgModuleRef) {
            const rootComponent: any = (mr as any)._bootstrapComponents[0];
            factory = mr.componentFactoryResolver.resolveComponentFactory(rootComponent);
        } else {
            factory = this.cfr.resolveComponentFactory(mr as Type<any>);
        }
        vcr.remove();

        return vcr.createComponent(factory);
    }
}
