import { IJsonApiErrorDocument } from './jsonapi-error.interface';

export type Bugsnag = Error | ErrorEvent | string | IJsonApiErrorDocument;
export class AppError {
    public message: string = '';
    public render: boolean = false;
    public report: boolean = false;
    public original_error!: Bugsnag;
    public detail_bag: Array<Detail> = [];
}

export class Detail {
    public message: string = '';
    public attribute_name: string = '';
}
