import { Injectable } from '@angular/core';
import { Transaction } from '@app/transactions/transaction';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FeeService {
    public transaction$: Subject<Transaction> = new Subject();
    public touchInput$: Subject<any> = new Subject();

    public getTransaction(): Observable<Transaction> {
        return this.transaction$.asObservable();
    }
}
