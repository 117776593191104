import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { PublicDeal } from '@app/core/resources/public-deal';
import { System } from '@app/core/resources/system';
import { IntersectionObserverService } from '@app/core/services/intersection-observer.service';
import { PublicDealsService } from '@app/core/services/public-deals.service';
import { DocumentCollection } from 'ngx-jsonapi';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-public-deals',
    templateUrl: './public-deals.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./public-deals.component.scss']
})
export class PublicDealsComponent implements AfterViewInit, OnDestroy, OnChanges {
    public publicDeals: Array<PublicDeal> = [];
    public emptyPublicDeals: boolean = false;
    public isLoaded: boolean = true;
    public notShowMatDialog: boolean = false;
    private combineSubscription: Subscription = new Subscription();
    private imgSubject: Subject<IntersectionObserverEntry> = new Subject<IntersectionObserverEntry>();
    private img$: Observable<IntersectionObserverEntry> = this.imgSubject.asObservable();
    @Input() public system: System = new System();
    @Input() public filterPublicDealsBySystem: string = '';
    @Input() public buyOrSell: string = '';
    @Input() public title: string = '';
    @Input() public showMoreInfo: boolean = true;
    @Input() public showDeals?: boolean = false;

    public constructor(
        public router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private intersectionObserverService: IntersectionObserverService,
        private publicDealsService: PublicDealsService
    ) {}

    public ngOnChanges(): void {
        this.getPublicDeals();
    }

    public ngAfterViewInit(): void {
        if (this.publicDeals.length === 0) {
            this.emptyPublicDeals = true;
        }
        this.getPublicDeals();
    }

    public getPublicDeals(): void {
        this.combineSubscription = this.img$.subscribe((): void => {
            this.publicDealsService
                .all({
                    remotefilter: this.buyOrSell === 'sell' ? { 'system.id': this.filterPublicDealsBySystem } : {},
                    include: ['system']
                })
                .pipe(filter((publicDeals: DocumentCollection<PublicDeal>): boolean => publicDeals.source !== 'new'))
                .subscribe((publicDeals: DocumentCollection<PublicDeal>) => {
                    this.publicDeals = publicDeals.data;
                    this.isLoaded = false;
                    this.changeDetectorRef.markForCheck();
                });
        });
        this.intersectionObserverService.observeElements('app-public-deals', this.imgSubject);
    }

    public ngOnDestroy(): void {
        this.combineSubscription.unsubscribe();
    }

    public receiveEvent($event: any): void {
        this.notShowMatDialog = $event;
    }
}
