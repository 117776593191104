import { NgModule } from '@angular/core';
import { TransactionDataComponent } from './transaction-data.component';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { TransactionCalculatorModule } from '@app/transactions/components/transaction-calculator/transaction-calculator.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { CoreModule } from '@app/core/core.module';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { CheckMailModule } from '@app/core/components/check-mail/check-mail.module';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { DomainToProviderModule } from './domain-to-provider/domain-to-provider/domain-to-provider.module';
import { MatStepperModule } from '@angular/material/stepper';
import { StepperTransactionDataModule } from '@app/transactions/components/stepper-transaction-data/stepper-transaction-data.module';
import { NgxLoadableModule } from '@app/core/components/ngx-loadable/ngx-loadable.module';
import { SkeletonModule } from '@app/core/components/skeleton/skeleton.module';

@NgModule({
    declarations: [TransactionDataComponent],
    exports: [TransactionDataComponent],
    imports: [
        CommonModule,
        ExtendedModule,
        FlexModule,
        TransactionCalculatorModule,
        MatButtonModule,
        CoreModule,
        MatCardModule,
        NgxLoadableModule,
        ContentLoaderModule,
        MatStepperModule,
        StepperTransactionDataModule,
        MatProgressSpinnerModule,
        MatIconModule,
        CheckMailModule,
        DomainToProviderModule,
        SkeletonModule
    ]
})
export class TransactionDataModule {}
