import { IBankRepository } from './bank-repository-interface';

export class VenezuelaBankRepository implements IBankRepository {
    public readonly id: string = 'pago_movil';
    public readonly currency: string = 'VES';
    public readonly groupId: string = 'banco_ves';
    public readonly title: string = 'Intercambiar saldo Venezuela';
    public readonly urlHref: string = '/es-VE/a/pago_movil/palpal/0/100';
    public readonly countryName: string = 'Venezuela';
    public readonly currencyName: string = 'Pesos venezolanos';
    public readonly widthPositionSprite: string = '-48px';
    public readonly heightPositionSprite: string = '-106px';
    public readonly systemPair: string = 'palpal';
    public readonly langCode: string = 'es-VE';
}
