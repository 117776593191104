import { DocumentCollection } from 'ngx-jsonapi';
import { System } from '../resources/system';

export class ReplaceSystemHelper {
    public constructor(private systems: DocumentCollection<System>) {}

    public getSystemOrReplacementSystem(systemId: string): System | null {
        let system: System | null = this.systems.find(systemId);
        if (!system) {
            return new System();
        }

        if (system.attributes.replacement_system_id === '') {
            return system;
        }

        return this.systems.find(system.attributes.replacement_system_id) ?? null;
    }
}
