import { ITransactionValidator, IMessageError } from './transaction-validators';
import { Transaction } from '@app/transactions/transaction';
import { TransactionPrivate } from '@app/core/resources/transaction-private';

export class SystemEqualsValidator implements ITransactionValidator {
    public validate(transaction: Transaction | TransactionPrivate): Array<IMessageError> {
        let system1: string | undefined = transaction.relationships.system1.data?.id;
        let system2: string | undefined = transaction.relationships.system2.data?.id;
        if (system1 === undefined || system2 === undefined) {
            return [];
        }

        if (system1 === system2) {
            return [
                {
                    title: 'Elija otro método de intercambio.',
                    amount: 0,
                    detail: 'Los métodos de intercambio elegidos deben ser distintos.'
                }
            ];
        }

        return [];
    }
}
