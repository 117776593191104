import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckMailComponent } from './check-mail.component';
import { FullScreenModule } from '../full-screen/full-screen.module';
import { DomainToProviderModule } from '@app/transactions/pages/transaction-data/domain-to-provider/domain-to-provider/domain-to-provider.module';
import { CustomVideoModule } from '../custom-video/custom-video.module';

@NgModule({
    declarations: [CheckMailComponent],
    exports: [CheckMailComponent],
    imports: [CommonModule, FullScreenModule, CustomVideoModule, DomainToProviderModule]
})
export class CheckMailModule {}
