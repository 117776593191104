import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { TransactionStatePublic } from '@app/core/resources/transaction-state-public';
import { TransactionStatePublicService } from '@app/core/services/transaction-state-public.service';

@Component({
    selector: 'app-check-transaction-states',
    templateUrl: './check-transaction-states.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./check-transaction-states.component.scss']
})
export class CheckTransactionStatesComponent implements OnInit {
    public transaction_state_form: FormGroup;
    public transactionStatePublic: TransactionStatePublic = new TransactionStatePublic();

    public constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public dialogData: any,
        public transactionsStatesPublicService: TransactionStatePublicService,
        public dialogRef: MatDialogRef<CheckTransactionStatesComponent>,
        public changeDetectorRef: ChangeDetectorRef
    ) {
        this.transaction_state_form = this.formBuilder.group({
            mid: ['', [Validators.required]],
            email: ['', [Validators.required]]
        });
    }

    public ngOnInit(): void {
        if (!this.dialogData || !this.dialogData.mid) {
            return;
        }
        this.transaction_state_form.controls.mid.setValue(this.dialogData.mid);
    }

    public getToken(): void {
        if (this.transaction_state_form.invalid) {
            return;
        }
        this.transactionStatePublic.attributes.mid = this.trimString(this.transaction_state_form.value.mid);
        this.transactionStatePublic.attributes.email = this.trimString(this.transaction_state_form.value.email);
        this.transactionStatePublic.save().subscribe(
            () => {
                this.router.navigateByUrl('transaction-states/' + this.transactionStatePublic.attributes.token);
                this.dialogRef.close();
                this.changeDetectorRef.detectChanges();
            },
            (error: HttpErrorResponse) => {
                this.transactionStatePublic.is_saving = false;
                this.changeDetectorRef.detectChanges();
                throw error;
            }
        );
    }

    private trimString(str: string): string {
        return str.replace(/^\s+|\s+$/g, '');
    }
}
