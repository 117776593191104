import { Injectable } from '@angular/core';
import { FormGroup, AbstractControl, ValidatorFn } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class ErrorFormRenderService {
    private form: FormGroup = new FormGroup({});

    public setForm(form: FormGroup): void {
        this.form = form;
    }

    public notifyToForm(message: string, attribute_name: string): boolean {
        if (!this.form) {
            return false;
        }

        let control: AbstractControl | null = this.findFieldControl(attribute_name);
        if (!control) {
            return false;
        }

        control.setValidators(this.validateFormError(control.value, message));
        control.updateValueAndValidity();
        control.markAsDirty();

        return true;
    }

    private findFieldControl(fieldName: string): AbstractControl | null {
        return this.form.get(fieldName);
    }

    private validateFormError(value: string, message: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (control.value !== value) {
                return null;
            }

            return { forbiddenName: message };
        };
    }
}
