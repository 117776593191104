import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    public setItem(itemName: string, data: any): void {
        if (!this.localStorageIsAvailable()) {
            return;
        }

        localStorage.setItem(itemName, JSON.stringify(data));
    }

    public getItemByExpiresAt(itemName: string): any {
        let dataToStorage: any = this.getItem(itemName);
        if (!dataToStorage) {
            return null;
        }

        if (typeof dataToStorage !== 'object') {
            dataToStorage = JSON.parse(dataToStorage);
        }

        if (this.isUtmExpired(dataToStorage.expires_at)) {
            this.removeItem(itemName);

            return null;
        }
        if (!dataToStorage.hasOwnProperty('item_value')) {
            return dataToStorage.hasOwnProperty('value') ? dataToStorage.value : null;
        }

        return dataToStorage.item_value;
    }

    public setItemByExpiresAt(itemName: string, itemValue: any, expiresAt: number = new Date().getTime() + 1000 * 60): any {
        let item: {
            item_value: string;
            expires_at: number;
        } = {
            item_value: itemValue,
            expires_at: expiresAt
        };
        this.setItem(itemName, JSON.stringify(item));
    }

    private isUtmExpired(expiresAt: number): boolean {
        return expiresAt < Date.now();
    }

    public getItem(itemName: string): any {
        if (!this.localStorageIsAvailable()) {
            return undefined;
        }

        let data: any = localStorage.getItem(itemName);

        return data ? JSON.parse(data) : null;
    }

    public removeItem(itemName: string): void {
        if (!this.localStorageIsAvailable()) {
            return;
        }

        localStorage.removeItem(itemName);
    }

    private localStorageIsAvailable(): boolean {
        try {
            localStorage.setItem('test', 'test');
            localStorage.removeItem('test');

            return true;
        } catch (e) {
            return false;
        }
    }
}
