import { Component, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-full-screen',
    templateUrl: './full-screen.component.html',
    styleUrls: ['./full-screen.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FullScreenComponent {
    @Output() public clearClick: EventEmitter<any> = new EventEmitter();
}
