import { DocumentResource, Resource } from 'ngx-jsonapi';
import { System } from './system';

export class PublicDeal extends Resource {
    public type: string = 'public_deals';
    public attributes: {
        group_id: string;
        currency: string;
        gain_percent: number;
        rest_to_pay: number;
    } = {
        group_id: '',
        currency: '',
        gain_percent: 0,
        rest_to_pay: 0
    };

    public relationships: {
        system: DocumentResource<System>;
    } = {
        system: new DocumentResource<System>()
    };
}
