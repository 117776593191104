import { Compiler, Injectable, NgModuleFactory } from '@angular/core';
import { lazy_load_modules } from '@app/core/resources/lazy-load-modules';

@Injectable({
    providedIn: 'root'
})
export class NgxLoadableService {
    public constructor(private compiler: Compiler) {}
    public preload(module: string): Promise<NgModuleFactory<any>> {
        return this.getModule(module)
            .load()
            .then((Module: any) => this.compiler.compileModuleAsync(Module));
    }

    private getModule(module: string): any {
        const mod: any = lazy_load_modules.find((m) => m.name === module);

        return mod;
    }
}
