import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DealsSkeletonLoaderComponent } from './deals-skeleton-loader.component';
import { MatCardModule } from '@angular/material/card';
import { SkeletonModule } from '../../../../core/components/skeleton/skeleton.module';

@NgModule({
    declarations: [DealsSkeletonLoaderComponent],
    exports: [DealsSkeletonLoaderComponent],
    imports: [CommonModule, MatCardModule, SkeletonModule]
})
export class DealsSkeletonLoaderModule {}
