import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ReferralsService } from '@app/core/services/referrals.service';
import { filter } from 'rxjs/operators';
import { PlatformBrowserService } from './core/services/platform-browser.service';
import { MatIconRegisterService } from '@app/core/services/http-interceptors/mat-icon-register.service';
import { FirebaseMessagingService } from './firebase/firebase-messaging.service';
import { ErrorTrustedService } from './exceptions/services/error-trusted.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DialogErrorRedirectComponent } from './core/components/dialog-error-redirect/dialog-error-redirect.component';
import { CrispService } from './core/services/crisp.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements AfterViewInit, OnInit {
    public checkErrorTrusted: boolean = false;

    public constructor(
        private crispService: CrispService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private matIconRegisterService: MatIconRegisterService,
        private browserService: PlatformBrowserService,
        private firebaseMessagingService: FirebaseMessagingService,
        private referralsService: ReferralsService,
        private errorTrustedService: ErrorTrustedService,
        private matDialog: MatDialog
    ) {
        this.firebaseMessagingService.listen();
        this.matIconRegisterService.addSvgIconSet('assets/solido-icons-v4.svg');
        if (this.browserService.isBrowser) {
            if (this.activatedRoute.snapshot.queryParams.refId) {
                this.referralsService.setReferralId(this.activatedRoute.snapshot.queryParams.refId);
            }

            this.router.events.pipe(filter((event): boolean => event instanceof NavigationEnd)).subscribe((): void => {
                if (this.activatedRoute.snapshot.queryParams.show_referral_id) {
                    localStorage.setItem('show_referral_id', this.activatedRoute.snapshot.queryParams.show_referral_id);
                }

                if (localStorage.getItem('show_referral_id')) {
                    let next_query_params: { [property: string]: any } = { ...this.activatedRoute.snapshot.queryParams };
                    delete next_query_params.show_referral_id;
                    next_query_params.refId = localStorage.getItem('show_referral_id');
                    this.router.navigate([this.router.url.split('?')[0]], { queryParams: next_query_params });
                }
            });
        }
    }

    public ngOnInit(): void {
        if (!this.browserService.isBrowser) {
            return;
        }

        this.crispService.initializeCrisp();
    }

    public ngAfterViewInit(): void {
        this.errorTrustedService.isError.subscribe(() => {
            if (this.checkErrorTrusted || !this.browserService.isBrowser) {
                return;
            }
            this.matDialog.open(DialogErrorRedirectComponent, {
                width: '600px',
                autoFocus: false
            });
            this.checkErrorTrusted = true;
        });
    }

    public onActivate(): void {
        if (this.browserService.isBrowser) {
            window.scroll(0, 0);
        }
    }
}
