import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { AppError } from '../services/app-error';
import Bugsnag, { NotifiableError } from '@bugsnag/js';

@Injectable({
    providedIn: 'root'
})

// A great spot to send exceptions to Sentry, BugSnag, etc.
export class ErrorReporterService {
    private started: boolean = false;

    public constructor() {
        this.start();
    }

    private start(): void {
        let bugsnag_api_key: string = environment.TOKEN_BUGSNAG_APIKEY ?? '';
        if (!bugsnag_api_key) {
            return;
        }

        Bugsnag.start({
            apiKey: bugsnag_api_key,
            appVersion: environment.version
        });
        this.started = true;
    }

    public report(error: AppError): void {
        if (!this.started) {
            return;
        }
        console.log('Bugsnag.notify', error, 'Original', error.original_error, 'started', this.started);
        Bugsnag.notify(error.original_error as NotifiableError);
    }
}
