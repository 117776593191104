import { BitcoinInformationRepository } from './repositories/bitcoin-information-repository';
import { DaiInformationRepository } from './repositories/dai-information-repository';
import { PaypalInformationRepository } from './repositories/paypal-information-repository';
import { SkrillInformationRepository } from './repositories/skrill-information-repository';
import { UsdtInformationRepository } from './repositories/usdt-information-repository';
import { PayeerInformationRepository } from './repositories/payeer-information-repository';
import { DefaultInformationRepository } from './repositories/default-information-repository';
import { ZinliInformationRepository } from './repositories/zinli-information-repository';
import { NetellerInformationRepository } from './repositories/neteller-information-repository';

export class InformationRepository {
    public readonly dataInformation: { [system_id: string]: any } = {
        palpal: new PaypalInformationRepository(),
        skrill: new SkrillInformationRepository(),
        zinli: new ZinliInformationRepository(),
        bitcoin: new BitcoinInformationRepository(),
        dai: new DaiInformationRepository(),
        usdt: new UsdtInformationRepository(),
        neteller: new NetellerInformationRepository(),
        payeer: new PayeerInformationRepository()
    };

    public getSystemInformation(system_id: string): any {
        return this.dataInformation[system_id] ?? new DefaultInformationRepository();
    }
}
