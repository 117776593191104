import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ReferralsService } from '@app/core/services/referrals.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-route-handler',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: ``
})
export class RouteHandlerComponent {
    public constructor(private referralsService: ReferralsService, private router: Router) {
        let clean_url: string = this.referralsService.extractReferralIdFromUrlParams(this.router.url);

        if (clean_url !== this.router.url) {
            this.router.navigateByUrl(clean_url);
        } else {
            this.router.navigate(['/error'], { skipLocationChange: true });
        }
    }
}
