import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactComponent } from '@app/landing-page/components/contact/contact.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SubmitModule } from '@app/core/components/submit/submit.module';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [ContactComponent],
    exports: [ContactComponent],
    imports: [CommonModule, ReactiveFormsModule, MatInputModule, SubmitModule, TranslateModule]
})
export class ContactModule {}
