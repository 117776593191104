<div class="rs-wrapper-v4 flex-row-center-center">
    <div class="text-error-container">
        <h1>¡Ups! Acá no hay nada.</h1>
        <h4>La página que buscas no existe o ha sido eliminada.</h4>
        <p class="secondary-text">Aquí tienes algunos enlaces útiles: </p>
        <ol type="links">
            <a href="https://saldo.com.ar/" class="rs-link" >
                <li>Volver al inicio</li>
            </a>
           <a href="https://saldo.com.ar/ayuda" class="rs-link" >
               <li>Centro de Ayuda</li>
           </a>
            <a href="https://saldo.com.ar/ayuda#faq" class="rs-link" >
                <li>Preguntas Frecuentes</li>
            </a>
        </ol>
    </div>
    <div class="img-error-container">
        <img data-server="sever_app 404" data-cy="image-error" width="500" height="700" src="/assets/images/errors/error-404-v4.png" alt="Error 404">
    </div>
</div>
