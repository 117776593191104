<table>
    <tr>
        <th>&zwnj;</th>
        <th>{{ 'buy_usd_ars' | translate }}</th>
        <th>{{ 'sell_usd_ars' | translate }}</th>
    </tr>
    <tr *ngFor="let best_rate of buyAndSell">
        <td>{{ best_rate.pair }}</td>
        <td><a class="rs-link" title="Cambiar {{best_rate.pairSystemBuy.system1_name}} a {{best_rate.pairSystemBuy.system2_name}}" [routerLink]="best_rate.linkBuy">{{ best_rate.buy | number: '1.2-2' }}</a></td>
        <td><a class="rs-link" title="Cambiar {{best_rate.pairSystemSell.system1_name}} a {{best_rate.pairSystemSell.system2_name}}" [routerLink]="best_rate.linkSell">{{ best_rate.sell | number: '1.2-2' }}</a></td>
    </tr>
</table>
