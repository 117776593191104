import { Injectable } from '@angular/core';
import { NotifyService } from './notify.service';
import { ErrorFormRenderService } from './error-form-render.service';
import { AppError } from '../services/app-error';

@Injectable({
    providedIn: 'root'
})
export class ErrorRendererService {
    public constructor(private errorFormService: ErrorFormRenderService, private notifyService: NotifyService) {}

    public render(error: AppError): void {
        if (error.detail_bag.length === 0) {
            this.notifyService.notify(error.message);

            return;
        }

        error.detail_bag.forEach((detail): void => {
            if (detail.attribute_name) {
                if (!this.errorFormService.notifyToForm(detail.message, detail.attribute_name)) {
                    this.notifyService.notify(detail.message);
                }
            } else {
                this.notifyService.notify(detail.message);
            }
        });
    }
}
