import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlideComponent } from './slide.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

@NgModule({
    declarations: [SlideComponent],
    exports: [SlideComponent],
    imports: [CommonModule, MatButtonModule]
})
export class SlideModule {}
