import { System } from '../resources/system';
import { CurrencyPiorityService } from '../services/currency-piority.service';
import { ServiceLoader } from '../services/service-loader';

export interface ISystemWithAmount {
    system: System;
    amount: number;
}

export interface IQuotationWithCurrency {
    quotation: string;
    currency: string;
    currencyComparison: string;
}

export class QuotationHelper {
    public currencyPriority: CurrencyPiorityService;

    public constructor() {
        this.currencyPriority = ServiceLoader.injector.get(CurrencyPiorityService);
    }

    public getQuotation(systemWithAmount1: ISystemWithAmount, systemWithAmount2: ISystemWithAmount): IQuotationWithCurrency {
        if (!systemWithAmount1.system.id || !systemWithAmount2.system.id) {
            return {
                quotation: '0.00',
                currencyComparison: '',
                currency: ''
            };
        }

        return this.currencyPriority.callbacksAndReturnByCurrency(
            systemWithAmount1.system,
            systemWithAmount2.system,
            (): IQuotationWithCurrency => {
                return {
                    quotation: (systemWithAmount2.amount / systemWithAmount1.amount).toFixed(
                        systemWithAmount2.system.attributes.decimal_places
                    ),
                    currency: systemWithAmount2.system.attributes.currency,
                    currencyComparison: systemWithAmount1.system.attributes.currency
                };
            },
            (): IQuotationWithCurrency => {
                return {
                    quotation: (systemWithAmount1.amount / systemWithAmount2.amount).toFixed(
                        systemWithAmount1.system.attributes.decimal_places
                    ),
                    currency: systemWithAmount1.system.attributes.currency,
                    currencyComparison: systemWithAmount2.system.attributes.currency
                };
            }
        );
    }
}
