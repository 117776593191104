export const deals_es_AR: any = {
    deals_title: 'Atrapa ofertas y ahorra, ¡hasta un 5% de descuento!',

    subtitle_one: '¿Te gustaría obtener beneficios operando en Saldo?',
    subtitle_two: 'Te presentamos "Ofertas"',

    text_info:
        'Una nueva funcionalidad con la cual, siendo nivel 3 en adelante, podrás acceder a beneficios exclusivos. De manera simple, sencilla y sobretodo beneficiosa, pueden obtener descuentos de hasta el 5% del monto total de la “oferta” que tomes.',

    question_info: '¿Cómo hacerlo? ¡Simple!',
    text_info_p_one:
        ' y encontrarás tu historial de pedidos, las cuentas con las cuales has operado y además, si sos usuarios nivel 3 o superior, la sección “Ofertas”',
    text_info_p_one_button: 'Inicia sesión',
    text_info_p_two:
        'Allí podrás observar las ofertas que puedas tomar, con su monto y el beneficio correspondiente que obtendrías al tomarla.',
    text_info_p_three:
        'Para poder aprovechar las ofertas en las que estés interesado, solo debes hacer click en “+” para agregarlas a tu “Bolsa de Ofertas”; en la cual solo debes elegir tu cuenta de destino dónde te gustaría recibir el monto correspondiente al pedido que vas a abonar y...¡Listo!',
    text_info_p_four: 'Así de fácil puedes ahorrar en tus intercambios.',
    text_info_p_five:
        'Si aún no alcanzas tu nivel 3, ¿qué estás esperando? (Realiza tus pedidos, <a href="https://ayuda.saldo.com.ar/es/article/como-subir-de-nivel-rapido-en-saldocomar-1fkhwvc/?bust=1713375936609" target="_blank" class="rs-link">sube de nivel y aprovechá estos beneficios</a>).',
    text_info_p_five_button: 'sube de nivel y aprovechá estos beneficios',

    insted_of: 'En vez de',
    amount_cashback: 'Te enviaríamos',

    flyer_title_one: 'Si aún no alcanzas tu nivel 3,',
    flyer_title_two: '¿qué estás esperando?',
    flyer_title_three: 'Sube de nivel y aprovecha estos beneficios.'
};
