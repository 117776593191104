<app-info-block *appShellNoRender titleBlock="{{'public_dials' | translate}}" class="block-of-public-deals" id="publicDeals">
    <app-public-deals [showDeals]="showDeals" [attr.id]="'public-deals-id'"></app-public-deals>
</app-info-block>
<div *ngIf="!showDeals">
    <app-info-block  *ngFor="let info of infoBlockContent;"
        [titleBlock]="info.title | translate"
        [content]="info.content | translate"
        [imageUrl]="info.imageUrl"
        [imageSettings]="info.imageSettings"
        title="{{ info.title | translate }}">
        <a [title]="info.title | translate" (click)="quote(info.linkUrl)" class="rs-link-info-block">{{ info.linkText | translate }}</a>
    </app-info-block>
</div>
