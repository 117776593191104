export const transaction_states_es_AR: any = {
    order_status: 'Estado de mi pedido',

    first_label: 'Número de pedido',
    second_label: 'Correo electrónico',

    consult_button: 'Consultar',

    first_text_transaction: 'Envías',
    second_text_transaction: 'Enviamos',
    status_transaction: '',

    alert_text: 'Si quieres contactar a alguien del equipo para saber más sobre tu pedido debes ',
    alert_link: 'Iniciar Sesión'
};
