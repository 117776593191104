export interface ICryptoInfo {
    send: string;
    receive: string;
}
export class CryptoHelper {
    private cryptos: { [id: string]: ICryptoInfo } = {
        bitcoin: {
            send: 'warning_message_bitcoin_send',
            receive: 'warning_message_bitcoin_recibe'
        },
        usdt: {
            send: 'warning_message_tether_send',
            receive: 'warning_message_tether_recibe'
        },
        dai: {
            send: 'warning_message_dai_send',
            receive: 'warning_message_dai_recibe'
        }
        // ethereum: {
        //     send: 'Puedes enviar ETH por las redes Ethereum (ERC20) o Binance Smart Chain (BEP20).',
        //     receive: 'Binance Smart Chain (BEP20).'
        // }
    };

    public getCryptoInfo(id: string): ICryptoInfo {
        let cryptoAux: ICryptoInfo = {
            send: '',
            receive: ''
        };

        return this.cryptos[id] ?? cryptoAux;
    }
}
